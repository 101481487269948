import type { Entity } from '@tunasong/schemas'
import { useCallback, useEffect, useRef } from 'react'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../api/entities.js'
import { childFilter } from './child.js'
import type { UseChildren } from './child.js'
import { useWithIncremental } from './with-incremental.hook.js'
import type { QueryFn } from './with-incremental.hook.js'

interface UseChildrenIncProps extends UseChildren {
  pageSize?: number
  startKey?: string
}

/** Children entities, incrementally loaded.  */
export const useChildrenIncremental = <T extends Entity = Entity>({
  filter,
  includeSys = true,
  pageSize = 25,
  startKey,
  parentId,
}: UseChildrenIncProps) => {
  const [load, loadResult] = entitiesApi.useLazyLoadChildEntitiesPartialQuery()

  const onLoad: QueryFn<T> = useCallback(
    async ({ limit, startKey }: { limit: number; startKey?: string }) => {
      invariant(parentId, 'parentId is required')
      const data = await load({
        parentId,
        startKey,
        limit,
      }).unwrap()

      return {
        ...data,
        entities: data.entities.filter(childFilter({ parentId, filter, includeSys })) as unknown as T[],
      }
    },
    [filter, includeSys, load, parentId]
  )

  /** Handle incremental load hook */
  const { getNext, resetIncrementalState, ...incremental } = useWithIncremental<T>({
    onLoad: parentId ? onLoad : null,
    pageSize,
    startKey,
  })
  // Initial load. We need to track the parentId for the load to ensure we load only once.
  const currentParentId = useRef<string | null>(null)
  useEffect(() => {
    if (parentId === currentParentId.current) {
      return
    }
    currentParentId.current = parentId ?? null
    if (parentId) {
      resetIncrementalState()
      getNext()
    }
  }, [getNext, parentId, resetIncrementalState])

  const { isLoading, isFetching, isError, error } = loadResult

  return { ...incremental, resetIncrementalState, getNext, isLoading, isFetching, isError, error }
}
