import {
  Alert,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { dayjs, logger } from '@tunasong/models'
import type { EntityVersion } from '@tunasong/models'
import { entitiesApi, useThunkDispatch } from '@tunasong/redux'
import { isVersioned } from '@tunasong/schemas'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useNavigate } from '@tunasong/ui-lib'
import { useCallback, useEffect, useState } from 'react'
import type { FC, MouseEvent, SyntheticEvent } from 'react'
import TimeTravelSelect from './history-timetravel.js'

export interface EntityHistoryProps {
  className?: string
  entity: Persisted<Entity>
  onComplete(): void
}

export const EntityHistory: FC<EntityHistoryProps> = props => {
  const { className, entity, onComplete } = props

  const dispatch = useThunkDispatch()
  const navigate = useNavigate()
  const [history, setHistory] = useState<EntityVersion[]>([])
  const [loading, setLoading] = useState(false)
  const [restoreEntityVersion] = entitiesApi.useRestoreEntityVersionMutation()
  const [restoreTimeTravel] = entitiesApi.useEntityTimeTravelRestoreMutation()
  const [loadEntityHistory] = entitiesApi.useLazyLoadEntityHistoryQuery()

  useEffect(() => {
    if (!(entity.id && isVersioned(entity))) {
      return
    }

    setLoading(true)
    loadEntityHistory({ id: entity.id }, true)
      .unwrap()
      .then(history => {
        /** Entity may or may not be part of the history. If it isn't, push it */
        const currentVersion = { id: entity.id, version: entity.version, versionAt: entity.versionAt }
        const all = [history.find(v => v.version === entity.version) ? null : currentVersion, ...history].filter(
          Boolean
        ) as EntityVersion[]
        const sorted = all.sort((a, b) => (a.version < b.version ? 1 : a.version === b.version ? 0 : -1))
        setHistory(sorted)
      })
      .catch(e => logger.error(e))
      .finally(() => setLoading(false))
  }, [dispatch, entity, loadEntityHistory])

  const handleRestore = useCallback(
    (version: EntityVersion) => (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()
      setLoading(true)
      restoreEntityVersion({ version })
        .unwrap()
        .then(() => {
          setLoading(false)
          onComplete()
        })
        .catch(e => logger.error(e))
    },
    [onComplete, restoreEntityVersion]
  )

  const handleTimetravel = useCallback(
    ({ entity, travelTo }: { entity: Persisted<Entity>; travelTo: string }) => {
      setLoading(true)
      restoreTimeTravel({ id: entity.id, timeTravelTo: travelTo })
        .unwrap()
        .then(() => {
          setLoading(false)

          /** We need to refresh the page here to ensure that the editor starts a-fresh */
          navigate(0)

          onComplete()
        })
        .catch(e => logger.error(e))
    },
    [navigate, onComplete, restoreTimeTravel]
  )

  const [tab, setTab] = useState(0)
  const handleTab = useCallback((ev: SyntheticEvent, val: number) => setTab(val), [])

  return (
    <>
      <Tabs onChange={handleTab} value={tab}>
        <Tab label="Time Travel" value={0} />
        <Tab label="Version History" value={1} disabled={!isVersioned(entity)} />
      </Tabs>
      {loading ? <LinearProgress /> : null}
      {tab === 0 ? (
        <>
          <Alert title="Restore" severity="info" sx={{ my: 2 }}>
            Choose the date you want to restore {entity.name} to. Note that only the content of the {entity.type} will
            be restored, and not the metadata.
          </Alert>
          <TimeTravelSelect entity={entity} onChange={handleTimetravel} disableRestore={loading} />
        </>
      ) : null}
      {tab === 1 && isVersioned(entity) ? (
        <>
          <Typography variant="body2" sx={{ my: 2 }}>
            When you restore an old version, the old version content will be copied onto the new version.{' '}
          </Typography>

          <List className={className}>
            {history.map((version, idx) => (
              <ListItem key={idx}>
                <ListItemText>{version.version}</ListItemText>
                <ListItemText>{dayjs(version.versionAt).format('LLL')}</ListItemText>
                <ListItemSecondaryAction>
                  <Button onClick={handleRestore(version)} disabled={loading || version.version === entity.version}>
                    Restore
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
    </>
  )
}

export default EntityHistory
