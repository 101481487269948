import { Avatar, LinearProgress, Typography } from '@mui/material'
import type { AvatarProps } from '@mui/material'
import { green, grey, orange, red } from '@mui/material/colors'
import type { TunerOutput } from '@tunasong/audio-dsp'
import { HBox, VBox } from '@tunasong/ui-lib'
import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { AudioController } from '../engine/index.js'

export interface TunerViewProps extends AvatarProps {
  audioController: AudioController
  showInput?: boolean
}

export const TunerView: FC<TunerViewProps> = props => {
  const { audioController, showInput = false, ...avatarProps } = props

  const [tuner, setTuner] = useState<TunerOutput>()

  const handleTuner = useCallback((ev: CustomEvent<TunerOutput | null>) => {
    const tuner = ev.detail
    if (!tuner) {
      setTuner(undefined)
      return
    }
    /** Average out the previous reading to avoid too much flux */
    setTuner(currentTuner => ({
      ...tuner,
      cents: currentTuner?.note === tuner.note ? ((currentTuner?.cents ?? 0) + tuner.cents) / 2 : tuner.cents,
    }))
  }, [])

  useEffect(() => {
    audioController.enableDSPFeature('tuner', true)
    audioController.addFeatureListener('tuner', handleTuner)

    return () => {
      audioController.enableDSPFeature('tuner', false)
      audioController.removeFeatureListener('tuner', handleTuner)
    }
  }, [audioController, handleTuner])

  const centsOver = tuner?.cents && tuner.cents > 0 ? tuner?.cents : null
  const centsUnder = tuner?.cents && tuner.cents < 0 ? Math.abs(tuner.cents) : null

  const inTune = tuner?.note && Math.abs(tuner?.cents ?? 0) <= 10
  const slightlyOffTune = tuner?.note && Math.abs(tuner?.cents ?? 0) <= 20
  const outOfTune = tuner?.note && Math.abs(tuner?.cents ?? 0) > 20

  // const progressColor = inTune ? 'success' : slightlyOffTune ? 'warning' : outOfTune ? 'error' : 'inherit'
  const avatarColor = inTune ? green[500] : slightlyOffTune ? orange[500] : outOfTune ? red[500] : grey[800]

  /** We want to compress between 0 and 20, and expand elsewhere */
  const displayOver = Math.min(100, centsOver ? centsOver * 5 : 0)
  const displayUnder = Math.min(100, centsUnder ? centsUnder * 5 : 0)

  return (
    <VBox sx={{ alignItems: 'center', flex: 1 }}>
      {showInput ? <Typography variant="caption">{audioController.name}</Typography> : null}

      <HBox justifyContent="space-between" alignItems="center" sx={{ minHeight: 50, mb: 2, width: '100%' }}>
        <LinearProgress
          variant="determinate"
          // color={centsUnder ? progressColor : 'inherit'}
          value={displayUnder}
          sx={{ flex: 1, transform: 'scaleX(-1)' }}
        />

        <Avatar sx={{ ...avatarProps.sx, bgcolor: avatarColor, color: 'white' }} {...avatarProps}>
          {tuner ? tuner.note : '-'}
          <Typography variant="caption"> {tuner?.octave}</Typography>
        </Avatar>

        <LinearProgress variant="determinate" sx={{ flex: 1 }} value={displayOver} />
      </HBox>
      {!tuner ? (
        <Typography variant="caption" color="warning">
          No signal
        </Typography>
      ) : (
        <Typography variant="caption" color="warning">
          {inTune ? 'Perfect!' : 'Tune to center green'}
        </Typography>
      )}
    </VBox>
  )
}

export default TunerView
