import type { MusicContextType } from '@tunasong/music-lib'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'
import { editorsSlice } from '../features/editors/editors-slice.js'

import { useSelector } from './selector.hook.js'
import { useThunkDispatch } from './thunk-dispatch.hook.js'

interface UseMusicContextProps<T> {
  /** If editorId is not specified, the default context values are given */
  editorId?: string | null
  selector: (context: MusicContextType) => T | undefined
}
export const useMusicContext = <T>({ editorId, selector }: UseMusicContextProps<T>) => {
  const value: T | undefined = useSelector(state => {
    const id = editorId ?? 'default'
    const context = state.editors[id]?.context
    return context ? selector(context) : undefined
  })

  const dispatch = useThunkDispatch()
  const setContext = useCallback(
    (context: Partial<MusicContextType>) => {
      invariant(editorId, 'editorId must be specified')
      dispatch(editorsSlice.actions.setContext({ id: editorId, context }))
    },
    [dispatch, editorId]
  )

  return [value, setContext] as const
}
