/** Hook to play a score */

import { useAudioEngine } from '@tunasong/audio-ui'
import { EasyScore } from '@tunasong/music-lib'
import type { Rhythm, Score } from '@tunasong/models'
import { useCallback, useRef } from 'react'

export const usePlayScore = (score: Score, rhythm?: Rhythm | null) => {
  const { lang } = score

  const engineRef = useRef(useAudioEngine())
  const play = useCallback(() => {
    const bars: string[] = lang.split('\n')
    /** Set tempo */
    if (rhythm) {
      engineRef.current.rhythm = rhythm
    }

    /** Each stave is a bar, so we want to play them in sequence */
    for (const bar of bars) {
      const events = EasyScore.parseEvents(bar)
      engineRef.current.playSequence(events)
    }
  }, [lang, rhythm])
  return play
}
