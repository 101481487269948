/** Adapted from https://gist.github.com/karpolan/80cf28cb742851fcb3abb7796c4f7fdc */
import { LinearProgress } from '@mui/material'
import React from 'react'
import type { JSX } from 'react'

/**
 * Wraps the React Component with React.Suspense and FallbackComponent while loading.
 */

export function withSuspense<T>(
  WrappedComponent: React.FC<T>,
  FallbackComponent: JSX.Element | null = <LinearProgress />
): React.FC<T> {
  return (props: T) => (
    <React.Suspense fallback={FallbackComponent}>
      {/* @todo Since IntrinsicAttributes is injected by JSX - how do we avoid this cast? */}
      <WrappedComponent {...(props as T & JSX.IntrinsicAttributes)} />
    </React.Suspense>
  )
}
