import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import type { FC } from 'react'
import { createTheme } from './create-theme.js'

/** Theme is defined in ui-lib/styles.ts */

type ThemeType = 'light' | 'dark'
export interface TunaThemeProps {
  theme?: ThemeType
  /** @default 14 */
  textSize?: number
  children?: React.ReactNode
}

export const TunaTheme: FC<TunaThemeProps> = ({ children, textSize = 14 }) => {
  const muiTheme = createTheme({ textSize })
  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
}
