import type { BoxProps } from '@mui/material'
import { useLayoutsExtended } from '@tunasong/plugin-lib'
import type { BaseLayoutSpec } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import { useNavigateToEntity } from '../navigation/navigate.js'
import { LayoutSelector } from './layout-selector.js'

export interface EntityLayoutSelectorProps extends Omit<BoxProps, 'onChange'> {
  defaultLayoutName: string
  entity: Persisted<Entity>
}

export const EntityLayoutSelector: FC<EntityLayoutSelectorProps> = props => {
  const { entity, defaultLayoutName } = props

  const layouts = useLayoutsExtended(entity)
  const defaultLayout = layouts.find(layout => layout.name === defaultLayoutName)
  const navigateToEntity = useNavigateToEntity()
  const navigateToLayout = (layout: BaseLayoutSpec) => navigateToEntity(entity, { layout: layout.name })

  return <LayoutSelector key={entity.id} layouts={layouts} selected={defaultLayout} onChange={navigateToLayout} />
}

export default LayoutSelector
