import { Box, ListItemAvatar, ListItemButton, ListItemText, MenuList, TextField, Typography } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { useAllEntityCommands } from '@tunasong/plugin-lib'
import type { Command } from '@tunasong/plugin-lib'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useMemo, useState } from 'react'
import type { FC, MouseEvent } from 'react'
import { PulseProgress } from '../../progress/pulse.js'
import { Next } from '@tunasong/icons'

export interface EventCommandSelectProps extends Omit<BoxProps, 'onClick'> {
  entity?: Persisted<Entity>
  targetEntity?: Persisted<Entity>
  selected?: Command | null
  // show only the selected command
  showOnlySelected?: boolean
  onClick: (action: Command | null) => void
}

/** Select Action Command for a specific entity. This is another (command) entity. */

export const EventCommandSelect: FC<EventCommandSelectProps> = props => {
  const { entity, selected, showOnlySelected = false, onClick, ...restProps } = props

  const allCommands = useAllEntityCommands(entity)
  const [filter, setFilter] = useState('')

  const handleClick = (command: Command) => (ev: MouseEvent) => {
    ev.preventDefault()
    // deselect if the same command is clicked
    if (command === selected) {
      onClick(null)
      return
    }
    onClick(command)
  }
  const commands = useMemo(() => {
    const cmds = allCommands.filter(command =>
      showOnlySelected ? (selected?.id ? command.id === selected.id : true) : true
    )

    if (!filter) {
      return cmds
    }
    return cmds.filter(command => command.name.toLowerCase().includes(filter.toLowerCase()))
  }, [allCommands, filter, selected?.id, showOnlySelected])

  if (!allCommands) {
    return (
      <Typography variant="caption">
        Loading commands...
        <PulseProgress />
      </Typography>
    )
  }

  if (!entity) {
    return <Typography variant="caption">No entity selected</Typography>
  }
  if (allCommands.length === 0) {
    return <Typography variant="caption">No commands</Typography>
  }

  return (
    <Box {...restProps}>
      <TextField label="Filter..." fullWidth variant="standard" onChange={ev => setFilter(ev.target.value)} />
      <MenuList>
        {commands.map(command => (
          <ListItemButton onClick={handleClick(command)} key={command.id} selected={selected?.id === command?.id}>
            {command.Icon ? (
              <ListItemAvatar>
                <command.Icon />
              </ListItemAvatar>
            ) : null}
            <ListItemText primary={command.name} secondary={command.id} />
          </ListItemButton>
        ))}
      </MenuList>
    </Box>
  )
}

export default EventCommandSelect
