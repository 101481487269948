import { audioSlice } from './audio/audio-slice.js'
import { configSlice } from './config/config-slice.js'
import { editorsSlice } from './editors/editors-slice.js'
import { entitiesFeature } from './entities/index.js'
import { notificationsSlice } from './notifications/notifications-slice.js'
import { presenceSlice } from './presence/presence-slice.js'
import { storageFeature } from './storage/index.js'
import { suggestionsSlice } from './suggestions/suggestions-slice.js'
import { uiSlice } from './ui/ui-slice.js'
import { userSlice } from './user/user-slice.js'
import type { UserState } from './user/user-slice.js'
import { webRTCFeature } from './webrtc/index.js'

export type { SidebarToolName } from './ui/ui-slice.js'

export type { PresenceEnterEntityMessage, PresenceLeaveEntityMessage } from './presence/presence-slice.js'
export type {
  WebRTCMessage,
  WebRTCNewICECandidateMessage,
  WebRTCStopTranceiverMessage,
  WebRTCStopTransceiverMessage,
  WebRTCVideoAnswerMessage,
  WebRTCVideoOfferMessage,
  WebRTCVideoRequestMessage,
} from './webrtc/webrtc-slice.js'
/** Export feature store types */
export { type UserState }

export const features = {
  storage: storageFeature,
  webrtc: webRTCFeature,
  config: configSlice,
  entities: entitiesFeature,
  suggestions: suggestionsSlice,
  user: userSlice,
  audio: audioSlice,
  notifications: notificationsSlice,
  presence: presenceSlice,
  editors: editorsSlice,
  ui: uiSlice,
}
