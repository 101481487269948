/* eslint-disable no-underscore-dangle */
import { z } from 'zod'
import type { FormField } from './form-field.js'
import { decodeMetadata } from './zod-meta.js'

const getFormType = (schema: z.ZodTypeAny): FormField['type'] | 'optional' | 'default' => {
  switch (schema._def.typeName) {
    case 'ZodString':
      const isDate = schema._def.checks.some(
        (v: unknown) => typeof v === 'object' && v && 'kind' in v && v.kind === 'datetime'
      )
      return isDate ? 'datetime' : 'text'
    case 'ZodNumber':
      return 'number'
    case 'ZodBoolean':
      return 'boolean'
    case 'ZodObject':
      return 'object'
    case 'ZodOptional':
      return 'optional'
    case 'ZodLiteral':
      return 'literal'
    case 'ZodEnum':
      return 'enum'
    case 'ZodArray':
      return 'list'
    case 'ZodDefault':
      return 'default'
    default:
      throw new Error(`Field type ${schema._def.typeName} not implemented`)
  }
}
/** @todo we can store metadata in `description` */

export const getFormFromSchema = (schema: z.ZodTypeAny): FormField => {
  const metadata = decodeMetadata(schema.description)
  const base = {
    required: true,
    metadata,
  } satisfies Partial<FormField>

  const type = getFormType(schema)

  switch (type) {
    case 'text':
      return {
        ...base,
        type: 'text',
      }
    case 'boolean':
      return {
        ...base,
        type: 'boolean',
      }
    case 'enum':
      return {
        ...base,
        type: 'enum',
        values: schema._def.values,
      }
    case 'datetime':
      return {
        ...base,
        type: 'datetime',
      }
    case 'literal':
      return {
        ...base,
        type: 'literal',
        value: schema._def.value,
      }
    case 'number':
      return {
        ...base,
        type: 'number',
      }
    case 'optional':
      return {
        ...getFormFromSchema(schema._def.innerType),
        required: false,
      }
    case 'default':
      return {
        ...getFormFromSchema(schema._def.innerType),
        required: false,
        defaultValue: schema._def.defaultValue(),
      }

    case 'object':
      const shape = schema._def.shape() as z.ZodTypeAny

      const childForm: Record<string, FormField> = {}
      for (const [name, val] of Object.entries(shape)) {
        childForm[name] = getFormFromSchema(val)
      }
      return {
        ...base,
        type: 'object',
        childForm,
      }
    case 'list':
      /**
       * @todo add support for min / max array size
       */
      return {
        ...base,
        type: 'list',
        valueType: getFormFromSchema(schema._def.type),
      }

    default:
      throw new Error(`Field type ${schema._def.typeName} not implemented`)
  }
}
