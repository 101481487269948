import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import Midi from '@tonaljs/midi'
import type { NoteOctave } from '@tunasong/music-lib'
import type { Scale } from '@tunasong/schemas'
import { useCallback } from 'react'
import type { FC } from 'react'
import { Keyboard as BaseKeyboard } from './keyboard/index.js'
import type { NoteLabelType } from './keyboard/keyboard.js'
import { NoteLabelForScale } from './keyboard/label.js'

export interface NoteRange {
  /** Midi note string - e.g., c3  */
  firstNote: string
  /** Midi note string - e.g., f5  */
  lastNote: string
}

export interface KeyboardNoteInfo {
  note: NoteOctave
  midiNumber: number
}

export interface KeyboardProps extends BoxProps {
  className?: string

  /** Key labels */
  label?: NoteLabelType

  /** Show label only when the note is active, i.e., playing. @default false (always show according to label prop) */
  showLabelOnActiveOnly?: boolean

  activeNotes?: NoteOctave[]

  noteRange?: NoteRange

  scale?: Scale | null

  onPlayNote?(note: KeyboardNoteInfo): void
  onStopNote?(note: KeyboardNoteInfo): void
}

export const Keyboard: FC<KeyboardProps> = props => {
  const {
    sx,
    onPlayNote,
    onStopNote,
    label = 'note-scale',
    noteRange,
    scale,
    activeNotes: activeNoteOctaves,
    showLabelOnActiveOnly = false,
  } = props

  const firstNote = Midi.toMidi(noteRange?.firstNote ?? 'c3') as number
  const lastNote = Midi.toMidi(noteRange?.lastNote ?? 'f5') as number

  const baseRange = noteRange
    ? { first: Midi.toMidi(noteRange.firstNote) ?? 64, last: Midi.toMidi(noteRange.lastNote) ?? 72 }
    : { first: firstNote, last: lastNote }

  const activeNotes = (activeNoteOctaves?.map(n => Midi.toMidi(n.toLowerCase())).filter(Boolean) as number[]) ?? []
  const handlePlay = useCallback(
    (midiNumber: number) => {
      if (onPlayNote) {
        onPlayNote({ note: Midi.midiToNoteName(midiNumber) as NoteOctave, midiNumber })
      }
    },
    [onPlayNote]
  )
  const handleStop = useCallback(
    (midiNumber: number) => {
      if (onStopNote) {
        onStopNote({ note: Midi.midiToNoteName(midiNumber) as NoteOctave, midiNumber })
      }
    },
    [onStopNote]
  )

  return (
    <Box sx={{ height: 150, width: '100%', ...sx }}>
      <BaseKeyboard
        noteRange={baseRange}
        onPlayNoteInput={handlePlay}
        onStopNoteInput={handleStop}
        activeNotes={activeNotes}
        renderNoteLabel={NoteLabelForScale(scale, label, showLabelOnActiveOnly)}
      />
    </Box>
  )
}
