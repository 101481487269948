import type { Track } from '@tunasong/schemas'
import { useEffect, useState } from 'react'
import { ChannelController } from '../../engine/index.js'
import { useMixer } from '../../hooks/index.js'

/** Get a channel for the track. If a channel already exists, return that, otherwise create a new channel */
export const useTrackChannel = (track: Track) => {
  const mixer = useMixer()

  const [channel, setChannel] = useState<ChannelController | null>(null)

  /** Create or get an existing channel for the Track */
  const { id, name, gain = 1, pan = 0, muted = false, solo = false, armed = false } = track

  /** Update the volume, pan and so on from the channel settings */
  useEffect(() => {
    if (!channel?.active) {
      return
    }
    // eslint-disable-next-line react-compiler/react-compiler
    channel.gain = gain
    channel.pan = pan
    channel.muted = muted
    channel.solo = solo
    channel.armed = armed
  }, [armed, channel, gain, muted, pan, solo])

  useEffect(() => {
    if (!(id && mixer)) {
      return
    }
    const existingChannel = mixer.getChannel(id)
    if (existingChannel) {
      setChannel(existingChannel)
      return
    }
    /** No existing channel for track. Create one */
    const source = mixer.context.createGain()
    const newChannel = mixer.addChannel(
      {
        id,
        name,
        type: 'general',
        gain: 1.0,
        inputNode: source,
        effects: [],
      },
      'media'
    )

    setChannel(newChannel)

    return () => {
      mixer.removeChannel(id)
      setChannel(null)
    }
  }, [id, mixer, name])

  return channel
}
