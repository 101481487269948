import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import type { MenuItemProps } from '@mui/material'
import { useEntity } from '@tunasong/redux'
import { MeetingRoom } from '@tunasong/icons'
import React from 'react'
import type { FC } from 'react'

export interface RoomMenuProps extends Omit<MenuItemProps, 'button'> {
  entityId?: string
  open?: boolean
}

export const RoomMenu: FC<RoomMenuProps> = props => {
  const { entityId, open = false, ...restProps } = props
  const { entity } = useEntity(entityId)

  if (!entity) {
    return null
  }

  return (
    <MenuItem title={`Video collaboration for ${entity.type}: ${entity.name}`} color="inherit" {...restProps}>
      <ListItemIcon>
        <MeetingRoom color={open ? 'secondary' : 'inherit'} />
      </ListItemIcon>
      <ListItemText>Video Room</ListItemText>
    </MenuItem>
  )
}

export default RoomMenu
