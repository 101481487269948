const yDocMessageTypes = ['ping', 'sync'] as const

export type YDocMessageType = (typeof yDocMessageTypes)[number]

export interface YDocMessageBase {
  type: YDocMessageType
  entityId: string
}

export interface YDocPing extends YDocMessageBase {
  type: 'ping'
}

export interface YDocSyncRequest extends YDocMessageBase {
  type: 'sync'
  sourceConnectionId?: string | null
  /** If this is a message that should be targeted to a specific connection, this will be set */
  targetConnectionId?: string | null
  /**
   * Base64 encoded binary diff of each message.
   *
   * @note this message contains the protocol header, so it cannot be used directly with Y.Doc.
   * Apply to Doc with @tunasong/sync SyncMessage.applySyncMessage()
   */
  messagesBase64: string[]
}
export interface YDocSync extends YDocSyncRequest {
  /** The connectionId of the originating peer. This is set by the WS server function */
  sourceConnectionId: string
}

export type YDocMessage = YDocPing | YDocSync

export function isYDocMessage(msg: unknown): msg is YDocMessage {
  return yDocMessageTypes.includes((msg as YDocMessage)?.type)
}

export function isYDocSync(msg: unknown): msg is YDocSync {
  return (msg as YDocMessage)?.type === 'sync'
}

export function isYDocPing(msg: unknown): msg is YDocPing {
  return (msg as YDocMessage)?.type === 'ping'
}
