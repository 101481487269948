import { useThunkDispatch, features, useSelector } from '@tunasong/redux'
import type { SidebarToolName } from '@tunasong/redux'
import { useCallback } from 'react'

export const useSidebar = () => {
  const dispatch = useThunkDispatch()
  const activeTool = useSelector(state => state.ui.sidebar.activeTool)
  const open = useSelector(state => state.ui.sidebar.open)
  const setOpen = useCallback(
    (active = true) => {
      dispatch(features.ui.actions.setSidebarOpen(active))
    },
    [dispatch]
  )
  const setActiveTool = useCallback(
    (activeTool: SidebarToolName | null) => {
      dispatch(features.ui.actions.setSidebarActiveTool(activeTool))
    },
    [dispatch]
  )
  /** If activeTool is null, set it, otherwise reset to null */
  const toggleActiveTool = useCallback(
    (activeTool: SidebarToolName) => {
      dispatch(features.ui.actions.toggleSidebarActiveTool(activeTool))
    },
    [dispatch]
  )

  return { setActiveTool, activeTool, toggleActiveTool, setOpen, open }
}
