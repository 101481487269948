import { Dialog, DialogContent, Tab, Tabs } from '@mui/material'
import { isString } from '@tunasong/models'
import type { SearchSummary } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import { DialogTitle, useNavigateToEntity } from '@tunasong/ui-lib'
import { useCallback, useState } from 'react'
import { FullSearch } from './full-search.js'
import { allQueries } from './queries/index.js'
import { SearchList } from './search-list.js'

export const useSearchDialog = (currentEntity?: Persisted<Entity>) => {
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  const navigateToEntity = useNavigateToEntity()

  const handleOpen = useCallback(
    (result: SearchSummary | Persisted<Entity> | string | null) => {
      if (!result || isString(result)) {
        return
      }
      navigateToEntity(result)
      setOpen(false)
    },
    [navigateToEntity]
  )
  const [tab, setTab] = useState('Search')

  const dialog = open ? (
    <Dialog
      open={open}
      PaperProps={{ sx: { position: 'fixed', top: 32, m: 0, minHeight: 200 } }}
      onClose={handleClose}
      title="Search"
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogTitle onClose={handleClose}>
        <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
          <Tab label="Search" value="Search" />
          <Tab label="All Songs" value="All Songs" />
        </Tabs>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {tab === 'Search' ? (
          <FullSearch onOpen={handleOpen} onClose={handleClose} currentEntity={currentEntity} />
        ) : null}
        {tab === 'All Songs' ? (
          <SearchList
            query={allQueries.type({
              query: 'type',
              types: ['song'],
              size: 100,
              sort: 'updated',
            })}
            onChange={navigateToEntity}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  ) : null

  return {
    open,
    setOpen,
    dialog,
  }
}
