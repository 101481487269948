/** ToneJS based Wav player */

import SoundfontLib from 'soundfont-player'
import { AudioContext } from 'standardized-audio-context'
import type { InputNode } from 'tone'
import { midiToNote } from '../events/index.js'
import type { MidiNoteEvent } from '../events/index.js'
import type { AudioInstrument } from './instrument.js'
import { logger } from '@tunasong/models'

const instrumentNames = [
  'acoustic_grand_piano',
  'bright_acoustic_piano',
  'electric_grand_piano',
  'honkytonk_piano',
  'electric_piano_1',
  'electric_piano_2',
  'harpsichord',
  'clavinet',
  'celesta',
  'glockenspiel',
  'music_box',
  'vibraphone',
  'marimba',
  'xylophone',
  'tubular_bells',
  'dulcimer',
  'drawbar_organ',
  'percussive_organ',
  'rock_organ',
  'church_organ',
  'reed_organ',
  'accordion',
  'harmonica',
  'tango_accordion',
  'acoustic_guitar_nylon',
  'acoustic_guitar_steel',
  'electric_guitar_jazz',
  'electric_guitar_clean',
  'electric_guitar_muted',
  'overdriven_guitar',
  'distortion_guitar',
  'guitar_harmonics',
  'acoustic_bass',
  'electric_bass_finger',
  'electric_bass_pick',
  'fretless_bass',
  'slap_bass_1',
  'slap_bass_2',
  'synth_bass_1',
  'synth_bass_2',
  'violin',
  'viola',
  'cello',
  'contrabass',
  'tremolo_strings',
  'pizzicato_strings',
  'orchestral_harp',
  'timpani',
  'string_ensemble_1',
  'string_ensemble_2',
  'synth_strings_1',
  'synth_strings_2',
  'choir_aahs',
  'voice_oohs',
  'synth_choir',
  'orchestra_hit',
  'trumpet',
  'trombone',
  'tuba',
  'muted_trumpet',
  'french_horn',
  'brass_section',
  'synth_brass_1',
  'synth_brass_2',
  'soprano_sax',
  'alto_sax',
  'tenor_sax',
  'baritone_sax',
  'oboe',
  'english_horn',
  'bassoon',
  'clarinet',
  'piccolo',
  'flute',
  'recorder',
  'pan_flute',
  'blown_bottle',
  'shakuhachi',
  'whistle',
  'ocarina',
  'lead_1_square',
  'lead_2_sawtooth',
  'lead_3_calliope',
  'lead_4_chiff',
  'lead_5_charang',
  'lead_6_voice',
  'lead_7_fifths',
  'lead_8_bass__lead',
  'pad_1_new_age',
  'pad_2_warm',
  'pad_3_polysynth',
  'pad_4_choir',
  'pad_5_bowed',
  'pad_6_metallic',
  'pad_7_halo',
  'pad_8_sweep',
  'fx_1_rain',
  'fx_2_soundtrack',
  'fx_3_crystal',
  'fx_4_atmosphere',
  'fx_5_brightness',
  'fx_6_goblins',
  'fx_7_echoes',
  'fx_8_scifi',
  'sitar',
  'banjo',
  'shamisen',
  'koto',
  'kalimba',
  'bagpipe',
  'fiddle',
  'shanai',
  'tinkle_bell',
  'agogo',
  'steel_drums',
  'woodblock',
  'taiko_drum',
  'melodic_tom',
  'synth_drum',
  'reverse_cymbal',
  'guitar_fret_noise',
  'breath_noise',
  'seashore',
  'bird_tweet',
  'telephone_ring',
  'helicopter',
  'applause',
  'gunshot',
] as const

export type InstrumentName = (typeof instrumentNames)[number]

/**
 * @see https://github.com/danigb/soundfont-player
 * @see https://github.com/gleitz/midi-js-soundfonts (soundfont files)
 * */
export class Soundfont implements AudioInstrument {
  player: SoundfontLib.Player | null = null
  /** Map of active AudioNodes indexed by MIDI note number */
  activeNotes: Record<number, AudioNode> = {}
  inputNode: InputNode | null = null

  constructor(
    private context: AudioContext,
    private name: InstrumentName,
    private preload = false
  ) {}

  async load() {
    const player = await SoundfontLib.instrument(this.context as never, this.name, {
      soundfont: 'FluidR3_GM',
      nameToUrl: (name: string, soundfont: string, format = 'mp3') =>
        `https://tunasong.com/instruments/soundfonts/${soundfont}/${name}-${format}.js`,
      desination: this.inputNode,
    })
    return player
  }

  async connect(node: InputNode) {
    this.inputNode = node
    if (this.preload) {
      this.player = await this.load()
    }
  }
  disconnect(): void {
    /** @todo disconnect here */
  }
  async onEvent(event: MidiNoteEvent) {
    if (!this.player) {
      this.player = await this.load()
    }

    const { type, note } = event
    const name = midiToNote(note.number)

    /**  Handle events  */
    if (type === 'noteon') {
      const { value } = event
      const gain = typeof value === 'number' ? value : 1
      const audioNode = this.player.start(name, 0, {
        gain,
        /** Parameters here */
      }) as unknown as AudioNode
      /** The type of AudioNode is wrong in the types */
      this.activeNotes[note.number] = audioNode
    } else if (type === 'noteoff') {
      const audioNode = this.activeNotes[note.number]
      if (!audioNode) {
        logger.warn(`No active note for ${note.number} (${name})`)
        return
      }
      /** Stop requires an array of IDs (the doc says AudioNode but that's wrong) */
      this.player.stop(0, [(audioNode as never)['id']] as never)
      delete this.activeNotes[note.number]
    } else {
      throw new Error(`Unknown event type ${type}`)
    }
  }
}
