/**
 * Shorten and summarize the activities for an entity. Typically a single user makes a lot of edits, so we can collapse that.
 *
 */

import type { Activity } from '@tunasong/models'
import type { Persisted } from '@tunasong/schemas'

export const summarizeEntityActivities = (activities: Persisted<Activity>[]): Persisted<Activity>[] => {
  const result: Persisted<Activity>[] = []

  /** The number of activities by the user without any other user's changes */
  let userActivityCount = 0
  let startDate = ''
  let endDate = ''

  for (const [idx, activity] of activities.entries()) {
    userActivityCount++
    /** @note The activities are in reverse chronological order. */
    endDate = userActivityCount === 1 ? activity.createdAt : endDate
    if (activity.userId === activities[idx + 1]?.userId) {
      continue
    }
    /** We now want to yield the activity */

    if (userActivityCount === 1) {
      result.push(activity)
    } else {
      startDate = activity.createdAt
      result.push({
        id: 'synthetic',
        type: 'summary',
        userId: activity.userId,
        entityId: activity.entityId,
        count: userActivityCount,
        createdAt: startDate,
        updatedAt: endDate,
      })
    }

    userActivityCount = 0
  }
  return result
}
