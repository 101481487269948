import { Typography } from '@mui/material'
import { useEntitiesById } from '@tunasong/redux'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useRecent } from '@tunasong/search'
import { EntityGrid, useDimensions, useNavigateToEntity } from '@tunasong/ui-lib'
import { useState } from 'react'
import type { FC } from 'react'

export interface RecentProps {
  /** Entities to exclude from the list, e.g., if they are already shown */
  exclude?: Persisted<Entity>[]
  /** Title of the recent list */
  title?: string
}

export const Recent: FC<RecentProps> = props => {
  const { exclude, title } = props
  const navigateToEntity = useNavigateToEntity()
  const { isTiny } = useDimensions()
  const steps = isTiny ? 2 : 3
  const [rows] = useState(2)

  const { isLoading: isSearching, searchResults } = useRecent({ exclude, maxCount: rows * steps })
  const { entities, isLoading } = useEntitiesById(searchResults.slice(0, rows * steps).map(e => e.id))

  return (
    <>
      {title ? <Typography variant="subtitle1">{title}</Typography> : null}
      <EntityGrid
        sx={{ py: theme => theme.spacing(1), pb: theme => theme.spacing(3) }}
        size="small"
        skeletonItems={steps * rows}
        placeholder="No recent activities found"
        entities={entities}
        onOpen={navigateToEntity}
        loading={isSearching || isLoading}
      />
    </>
  )
}

export default Recent
