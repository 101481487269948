import { ListItemIcon, MenuItem, Typography } from '@mui/material'
import type { MenuItemProps } from '@mui/material'
import { Dialog, ShortcutBox, shortcuts } from '@tunasong/ui-lib'
import { Help as HelpIcon } from '@tunasong/icons'
import { useCallback, useState } from 'react'
import type { FC, MouseEvent } from 'react'

export interface HelpMenuItemProps extends Omit<MenuItemProps, 'button'> {
  className?: string
}

export const HelpMenuItem: FC<HelpMenuItemProps> = props => {
  const { className, onClick, ...restProps } = props
  const [showHelp, setShowHelp] = useState(false)
  const handleShowHelp = useCallback(
    (ev: MouseEvent<HTMLLIElement>) => {
      if (onClick) {
        onClick(ev)
      }
      setShowHelp(true)
    },
    [onClick]
  )
  const handleHideHelp = useCallback(() => setShowHelp(false), [])

  return (
    <>
      <MenuItem className={className} title="Show help" color="inherit" onClick={handleShowHelp} {...restProps}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <Typography variant="inherit">Help</Typography>
      </MenuItem>
      <Dialog open={showHelp} onClose={handleHideHelp} title="Keyboard Shortcuts">
        {Object.values(shortcuts).map((s, idx) => (
          <ShortcutBox key={idx} shortcut={s} />
        ))}
      </Dialog>
    </>
  )
}

export default HelpMenuItem
