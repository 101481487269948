/** Reserved hotkey */

import React from 'react'
import { keyLabels } from './key-codes.js'
import type { OS } from '../browser/index.js'

/** Reserved hotkeys to avoid overriding too many standard keys */
const reservedHotkeys = ['mod+c', 'mod+x', 'mod+z', 'mod+v', 'mod+q']

export const createHotkey = (e: React.KeyboardEvent | KeyboardEvent, os: OS): string | null => {
  const label = keyLabels[e.code] ?? e.code
  let k = ``
  /** Ctrl on Windows => mod, Meta on Mac => mod */
  k += e.shiftKey ? 'shift+' : ''
  k += e.ctrlKey ? (os === 'windows' ? `mod+` : 'ctrl+') : ''
  k += e.metaKey ? (os === 'mac' ? 'mod+' : `meta+`) : ''
  k += e.altKey ? `alt+` : ''
  k += label.toLowerCase()

  const hasModifier = e.altKey || e.ctrlKey || e.metaKey
  const isFkey = e.code.startsWith('F') && (e.code.length === 2 || e.code.length === 3)

  const isValid =
    isFkey || (label && hasModifier && e.key !== 'Shift' && e.key !== 'Control' && e.key !== 'Meta' && e.key !== 'Alt')
  const keyValue = isValid ? k : null

  return keyValue && reservedHotkeys.includes(keyValue) ? null : keyValue
}
