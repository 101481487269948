export const isUrl = (url?: string) => {
  if (!url) {
    return false
  }
  try {
    new URL(url.trim())
    return url.startsWith('http://') || url.startsWith('https://')
  } catch {
    return false
  }
}

export const urlPath = (url: string) => {
  try {
    const u = new URL(url.trim())
    return `${u.pathname}${u.search ? u.search : ''}`
  } catch {
    return null
  }
}
