/** Allow renderLeaf to render content from different plugins */

import { useEditor } from '@tunasong/plugin-lib'
import type { TText } from '@tunasong/plugin-lib'
import { makeStyles } from '@tunasong/ui-lib'

import classNames from 'classnames'
import React, { useMemo } from 'react'

const useStyles = makeStyles()(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  strikethrough: {
    textDecoration: 'line-through',
  },
  underline: {
    textDecoration: 'underline',
  },
  highlight: {
    backgroundColor: theme.vars.palette.warning.light,
  },
}))

interface RenderLeafProps {
  leaf: TText
  attributes: Record<string, unknown>
  children: React.ReactNode
}

export const RenderLeaf = (props: RenderLeafProps) => {
  const { leaf, attributes, children } = props
  const { classes } = useStyles()

  /** Get the plugins  */
  const editor = useEditor()
  const { pluginList: plugins } = editor
  const leafStyle = useMemo(() => {
    const pluginStyles = plugins.map(p => p.getLeafStyle && p.getLeafStyle(leaf)).filter(Boolean)
    let styles: React.CSSProperties = {
      position: 'relative',
    }
    for (const style of pluginStyles) {
      styles = { ...styles, ...style }
    }
    return styles
  }, [leaf, plugins])

  return (
    <span
      {...attributes}
      style={leafStyle}
      className={classNames({
        [classes.bold]: leaf.bold,
        [classes.italic]: leaf.italic,
        [classes.strikethrough]: leaf.strikethrough,
        [classes.underline]: leaf.underline,
        [classes.highlight]: leaf.highlight,
      })}
    >
      {plugins.map(p => (p.RenderLeaf ? <p.RenderLeaf key={p.key} leaf={leaf} /> : null))}
      {children}
    </span>
  )
}

export default RenderLeaf
