import { FormControl, InputLabel, ListItemText, MenuItem, Select, capitalize } from '@mui/material'
import { shortUuid } from '@tunasong/models'
import { useMemo } from 'react'
import type { FC } from 'react'
import { useController } from 'react-hook-form'
import type { EnumFormField } from '../../../lib/form-field.js'
import type { FormComponentProps } from '../form-component.js'

export interface EnumSchemaFieldProps extends FormComponentProps<EnumFormField> {}

export const EnumSchemaField: FC<EnumSchemaFieldProps> = props => {
  const { name, spec, control } = props
  const label = capitalize(spec.metadata?.label ?? name)

  const labelId = useMemo(() => shortUuid(), [])
  const elementId = useMemo(() => shortUuid(), [])

  const enumValues = Array.isArray(spec.values) ? spec.values : (Object.values(spec.values) as string[])
  const enumKeys = Array.isArray(spec.values) ? spec.values.map(v => capitalize(v)) : Object.keys(spec.values)

  const { field } = useController({
    name,
    control,
  })

  return (
    <FormControl fullWidth={true} sx={{ my: 1 }}>
      <InputLabel id={labelId}>{label}</InputLabel>

      <Select
        labelId={labelId}
        label={label}
        variant="outlined"
        {...field}
          value={field.value ?? ''}
        inputProps={{ id: elementId }}
      >
        {enumValues.map((val, idx) => (
          <MenuItem key={idx} value={val}>
            <ListItemText>{enumKeys[idx]}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default EnumSchemaField
