import { Box, IconButton, Paper, TextField, Typography } from '@mui/material'
import { Delete } from '@tunasong/icons'
import { dayjs, shortUuid } from '@tunasong/models'
import { entitiesApi, useChildren, useEntityUpdate } from '@tunasong/redux'
import type { Audio, AudioComment, Persisted, Video } from '@tunasong/schemas'
import { Popup, UserAvatar, makeStyles, useCurrentUser } from '@tunasong/ui-lib'
import { Fragment, useCallback, useMemo, useState } from 'react'
import type { KeyboardEvent, FC, MouseEvent } from 'react'
import invariant from 'tiny-invariant'
import { getClickPositionSeconds } from '../timeline/util.js'

const useStyles = makeStyles<{ height: number }>()((theme, { height }) => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    transition: `0.2s ease-out`,
  },
}))

export interface CommentStreamProps {
  playing?: boolean
  duration: number
  audioPosition: number
  height?: number
  pixelsPerSecond: number
  parent: Persisted<Audio | Video>
}

export const CommentStream: FC<CommentStreamProps> = props => {
  const { duration, pixelsPerSecond, audioPosition, playing, height = 36, parent } = props
  const { classes } = useStyles({ height })

  const { entities: storedComments } = useChildren<AudioComment>({
    parentId: parent.id,
    filter: 'audiocomment',
  })
  const [createEntity] = entitiesApi.useCreateEntityMutation()

  const { userId } = useCurrentUser()
  invariant(userId, 'User must be logged in to comment')

  const [comments, setComments] = useState<Persisted<AudioComment>[]>([])
  const [selected, setSelected] = useState<Persisted<AudioComment> | null>(null)
  const [edit, setEdit] = useState<Persisted<AudioComment> | null>(null)
  const [editEl, setEditEl] = useState<HTMLElement | null>(null)

  const updateEntity = useEntityUpdate<AudioComment>({ debounceDelay: 100 })

  /** Merge comments, pri the local ones  */
  const showComments = useMemo(
    () =>
      [...comments, ...storedComments.filter(c1 => !comments.some(c2 => c1.id === c2.id))].sort(
        (a, b) => a.start - b.start
      ),
    [comments, storedComments]
  )

  const handleInitialCreate = useCallback(
    (ev: MouseEvent) => {
      const seconds = getClickPositionSeconds(ev, duration)

      const comment: Persisted<AudioComment> = {
        id: shortUuid(),
        type: 'audiocomment',
        userId,
        parentId: parent.id,
        start: seconds,
        children: [{ text: '' }],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      setComments(comments => [...comments, comment])
      setEdit(comment)
      setSelected(comment)

      createEntity({ entity: comment, parent })
    },
    [createEntity, duration, parent, userId]
  )

  const onClose = useCallback(() => {
    setEditEl(null)
    setSelected(null)
    setEdit(null)
  }, [])

  const handleEventClick = useCallback(
    (comment: Persisted<AudioComment>) => (ev: MouseEvent) => {
      ev.stopPropagation()
      // toggle active on click
      if (userId === comment.userId) {
        setEdit(edit => (edit === comment ? null : comment))
      }
      setSelected(selected => (selected === comment ? null : comment))
    },
    [userId]
  )

  const handleChange = useCallback(
    (comment: Persisted<AudioComment>) => (ev: KeyboardEvent<HTMLDivElement>) => {
      /** Allow shift-enter to newline */
      if (ev.shiftKey || ev.key !== 'Enter') {
        return
      }
      ev.preventDefault()

      invariant(comment.id, 'Comment must have an id')

      const el = ev.target as HTMLInputElement
      const text = el.value
      const updated = { ...comment, children: [{ text }] }

      setComments(comments => [...comments.filter(c => c.id !== comment.id), updated])
      setSelected(updated)
      setEdit(null)
      updateEntity(comment.id, updated)
    },
    [updateEntity]
  )

  const handleDelete = useCallback(
    (comment: AudioComment) => (ev: MouseEvent) => {
      ev.stopPropagation()
      invariant(comment.id, 'Comment id must be set')
      updateEntity(comment.id, { trash: true })
      // update local state, set trash: true for comment.id
      setComments(comments => comments.filter(c => c.id !== comment.id))
      setSelected(null)
      setEdit(null)
    },
    [updateEntity]
  )

  /** Get the maxWidth, accounting for the next comment */
  // const getMaxWidth = useCallback(
  //   (comment: AudioComment) => {
  //     const next = showComments.find(c => c.start > comment.start)
  //     const nextStart = next?.start ?? duration
  //     const width = pixelsPerSecond * (nextStart - comment.start)
  //     return width
  //   },
  //   [duration, pixelsPerSecond, showComments]
  // )
  const active = useMemo(() => {
    // find the closest element to the current position
    if (selected || !playing) {
      return selected
    }
    let shortest = Infinity
    let active: Persisted<AudioComment> | null = null
    for (const comment of showComments) {
      const distance = Math.abs(comment.start - audioPosition)
      if (distance < shortest) {
        shortest = distance
        active = comment
      }
    }
    return active ? active : null
  }, [audioPosition, playing, selected, showComments])

  return (
    <>
      <Box className={classes.root} draggable={false} onClick={handleInitialCreate} title={'Click to comment'}>
        {showComments.length === 0 ? (
          <Typography color="primary" variant="caption">
            Click anywhere to comment
          </Typography>
        ) : null}
        {showComments.map((comment, idx) => (
          <Fragment key={comment.id ?? idx}>
            <UserAvatar
              sx={{ left: pixelsPerSecond * comment.start - 8, position: 'absolute' }}
              ref={comment === edit ? setEditEl : undefined}
              size="tiny"
              userId={comment.userId}
              link={false}
              onClick={handleEventClick(comment)}
            />

            {active && active.id === comment.id && !edit ? (
              <Paper
                sx={{
                  ml: 1.3,
                  mt: 3,
                  padding: 1,
                  borderLeft: theme => `2px solid ${theme.palette.secondary.main}`,
                  borderRadius: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  left: pixelsPerSecond * comment.start - 8,
                }}
              >
                <Typography variant="caption" color="primary" sx={{ pb: 0.5 }}>
                  {active.children[0].text}
                </Typography>
                <Typography variant="caption" sx={{ color: theme => theme.palette.text.disabled }}>
                  {dayjs(active.createdAt).format('LL')}
                </Typography>
              </Paper>
            ) : null}
          </Fragment>
        ))}
      </Box>

      {edit ? (
        <Popup
          open={true}
          anchorEl={editEl}
          onClose={onClose}
          placement="right-end"
          disablePortal={false}
          clickAway={true}
        >
          <Paper
            sx={{
              p: 2,
              ml: 1.2,
              backgroundColor: theme => theme.vars.palette.layers,
              borderLeft: theme => `2px solid ${theme.palette.secondary.main}`,
              borderRadius: 0,

              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TextField
              key={edit.id}
              disabled={userId !== edit.userId}
              variant="outlined"
              size="small"
              label="Comment"
              multiline={true}
              autoFocus={true}
              onKeyDown={handleChange(edit)}
              defaultValue={edit.children[0].text}
            />
            <IconButton
              sx={{
                color: theme => theme.palette.action.disabled,
                '&:hover': {
                  color: 'error.main',
                },
              }}
              onClick={handleDelete(edit)}
            >
              <Delete color="inherit" />
            </IconButton>
          </Paper>
        </Popup>
      ) : null}
    </>
  )
}
