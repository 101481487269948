/** Extend dayjs as a replacement for moment.js */

import dayjs from 'dayjs'
import type { Dayjs, ManipulateType, OpUnitType, QUnitType, UnitType } from 'dayjs'

// Locales
import 'dayjs/locale/nb.js'

/** For type info, see discussion in https://github.com/iamkun/dayjs/issues/364 */

/** UTC - required by iCal */
import utc from 'dayjs/plugin/utc.js'
dayjs.extend(utc)

/** to support fromNow() */
import relativeTime from 'dayjs/plugin/relativeTime.js'
dayjs.extend(relativeTime)

import localizedFormat from 'dayjs/plugin/localizedFormat.js'
dayjs.extend(localizedFormat)

/** Durations */
import duration from 'dayjs/plugin/duration.js'
import type { Duration, DurationUnitType } from 'dayjs/plugin/duration.js'
dayjs.extend(duration)

/** Import new locales on demand like this */
// import 'dayjs/locale/fr'

interface ConfigTypeMap {
  default: string | number | Date | Dayjs | ExtendedDayjs | null | undefined
}

export type ConfigType = ConfigTypeMap[keyof ConfigTypeMap]

/** The global type stuff caused problems so I reexport the types here */
interface ExtendedDayjs extends Dayjs {
  (date?: dayjs.ConfigType): ExtendedDayjs

  /** My own convenience */
  today(): ExtendedDayjs
  utc(isUtc: boolean): ExtendedDayjs

  // /** Duration */
  add(value: number, unit?: ManipulateType | undefined): ExtendedDayjs
  add(duration: plugin.Duration): ExtendedDayjs
  diff(date?: ConfigType, unit?: QUnitType | OpUnitType, float?: boolean): number
  set(unit: UnitType, value: number): ExtendedDayjs
  subtract(value: number, unit?: ManipulateType | undefined): ExtendedDayjs
  subtract(duration: plugin.Duration): ExtendedDayjs
  duration(input?: string, unit?: DurationUnitType): plugin.Duration
  isDuration(d: unknown): d is plugin.Duration

  startOf(unit: ManipulateType): ExtendedDayjs
  /** Relative time */
  fromNow(withoutSuffix?: boolean): string
  from(compared: ConfigType, withoutSuffix?: boolean): string
  toNow(withoutSuffix?: boolean): string
  to(compared: ConfigType, withoutSuffix?: boolean): string
}

const extendedDayjs = dayjs as unknown as ExtendedDayjs

extendedDayjs.today = () => extendedDayjs(extendedDayjs().format('YYYY-MM-DDT00:00:00Z'))

export { extendedDayjs as dayjs, type Duration, type ExtendedDayjs }
export default extendedDayjs
