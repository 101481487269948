import { useTheme } from '@mui/material'
import * as Sentry from '@sentry/react'
import { logger } from '@tunasong/models'
import { GlobalStyles, isDevelopment, isMobileDevice, useCurrentUser } from '@tunasong/ui-lib'
import type { CSSObject } from '@tunasong/ui-lib'
import { useEffect, useState } from 'react'

export const useSentry = () => {
  const { email, name } = useCurrentUser()
  const theme = useTheme()

  const styles: CSSObject = {
    '#sentry-feedback': { bottom: theme.spacing(4) },
  }

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (initialized) {
      return
    }

    if (isDevelopment()) {
      logger.info('Not instrumenting Sentry in development mode')
      return
    }

    /** @see https://docs.sentry.io/platforms/javascript/guides/react/user-feedback/ */

    // disable feedback for mobile devices since it obscures the UI
    const feedbackIntegration = !isMobileDevice()
      ? Sentry.feedbackIntegration({
          // Additional SDK configuration goes in here, for example:
          colorScheme: theme.palette.mode,

          useSentryUser: {
            email: 'email',
            name: 'name',
          },
          isNameRequired: false,
        })
      : undefined

    Sentry.setUser({
      email,
      name,
    })
    Sentry.init({
      dsn: 'https://a73f60957eae44bca3f427a151b4b58d@o526050.ingest.sentry.io/5641062',
      // @todo we get Error: Multiple Sentry Session Replay instances are not supported
      integrations: [Sentry.browserTracingIntegration(), feedbackIntegration /* Sentry.replayIntegration() */].filter(
        Boolean
      ),
      /** @todo adjust the percentage of errors to be posted */
      tracesSampleRate: 1.0,
      /** @todo reduce to e.g., 0.1 when stable */
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
    setInitialized(true)
  }, [email, initialized, name, theme.palette.mode])

  return {
    styles: <GlobalStyles styles={styles} />,
  }
}
