import { useDecorateRemoteCursors } from '@slate-yjs/react'
import { useCallback } from 'react'
import type { PlateEditor } from '@udecode/plate-core/react'
import type { NodeEntry } from 'slate'

export { getRemoteCaretsOnLeaf, getRemoteCursorsOnLeaf } from '@slate-yjs/react'

export const useDecorateRemoteCursorsV2 = () => {
  const decorate = useDecorateRemoteCursors()
  const decorateV2 = useCallback(({ entry }: { editor: PlateEditor; entry: NodeEntry }) => decorate(entry), [decorate])
  return decorateV2
}
