import { Weblature } from './weblature.js'
import { Instrument } from './instrument.js'

/* Constructor for creating an Instrument object with a given tuning.
   The number of strings is implicitly given by the length of the tuning array.

   tuning - the tuning (see Weblature.setTuning). */

const guitarTuning = [
  Weblature.note('E', 2),
  Weblature.note('A', 2),
  Weblature.note('D', 3),
  Weblature.note('G', 3),
  Weblature.note('B', 3),
  Weblature.note('E', 4),
]

/* Constructor for creating a Guitar.

   Initially, it gets the standard EADGBE tuning. */

export class Guitar extends Instrument {
  constructor() {
    super(guitarTuning)
  }
}
