import type { NoteDegree } from '../note/index.js'
import type { ScaleDegree } from './scale-degree.js'

export interface Mode {
  mode: ScaleDegree
  /** Intervals in half steps from tonic */
  type: 'major' | 'minor'
  intervals: NoteDegree[]
}

export type ModeName = 'ionian' | 'dorian' | 'phrygian' | 'lydian' | 'mixolydian' | 'aeolian' | 'locrian'

/** Get the name of a mode from the degree */
export const ALL_MODES: Record<ModeName, Mode> = {
  ionian: {
    mode: 'I',
    type: 'major',
    intervals: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'],
  },
  dorian: {
    mode: 'II',
    type: 'minor',
    intervals: ['I', 'II', 'bIII', 'IV', 'V', 'VI', 'bVII', 'VIII'],
  },
  phrygian: {
    mode: 'III',
    type: 'minor',
    intervals: ['I', 'bII', 'bIII', 'IV', 'V', 'bVI', 'bVII', 'VIII'],
  },
  lydian: {
    mode: 'IV',
    type: 'major',
    intervals: ['I', 'II', 'III', '#IV', 'V', 'VI', 'VII', 'VIII'],
  },
  mixolydian: {
    mode: 'V',
    type: 'major',
    intervals: ['I', 'II', 'III', 'IV', 'V', 'VI', 'bVII', 'VIII'],
  },
  aeolian: {
    type: 'minor',
    mode: 'VI',
    intervals: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'bVII', 'VIII'],
  },
  locrian: {
    type: 'minor',
    mode: 'VII',
    intervals: ['I', 'bII', 'bIII', 'IV', 'bV', 'bVI', 'bVII', 'VIII'],
  },
}

export type ModeType = keyof typeof ALL_MODES
export const MODE_NAMES = Object.keys(ALL_MODES) as ModeType[]
