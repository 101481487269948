/**
 * Store the yDoc in S3
 */

import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import type { S3Storage, Storage } from '@tunasong/schemas'
import invariant from 'tiny-invariant'

const client = new S3Client({})

export const getStorageObjectKey = ({ entityId, userId }: { entityId: string; userId: string }) =>
  `users/${userId}/docs/${entityId}/doc.ydoc`

const storeDoc = async ({
  yDocAsUpdates,
  bucketName,
  entityId,
  userId,
  docType,
}: {
  /** The yDoc encoded as updates */
  yDocAsUpdates: Uint8Array
  bucketName: string
  entityId: string
  userId: string
  docType: string
}) => {
  invariant(bucketName, 'No bucketName')
  invariant(entityId, 'No entityId')
  invariant(yDocAsUpdates, 'No stringEncodedyDoc')

  const objectKey = getStorageObjectKey({ entityId, userId })

  const result = await client.send(
    new PutObjectCommand({
      Bucket: bucketName,
      Key: objectKey,
      ContentType: 'application/octet-stream',
      /** yDoc encoded as updates */
      Body: yDocAsUpdates,
    })
  )
  const { VersionId: versionId } = result
  return {
    versionId,
    filename: objectKey,
    type: 'S3',
    docType: docType as S3Storage['docType'],
  } satisfies S3Storage
}

/** Retrieve the doc from S3 as Uint8Array */
const getDoc = async ({ bucketName, storage }: { bucketName: string; storage: S3Storage }) => {
  invariant(bucketName, 'No bucketName')
  invariant(storage, 'No storage')

  const { filename: objectKey, versionId } = storage

  try {
    const result = await client.send(
      new GetObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
        VersionId: versionId,
      })
    )
    const data = await result.Body?.transformToByteArray()
    return data ?? null
  } catch (error) {
    console.warn('Error getting doc', error)
  }
  return null
}

function isDocPath(param: { entityId?: string; userId: string; storage?: Storage }): param is {
  entityId: string
  userId: string
  storage: S3Storage
} {
  const { entityId, userId, storage } = param
  return Boolean(
    entityId &&
      storage?.type === 'S3' &&
      storage.docType === 'yDoc' &&
      (storage?.filename.startsWith(`users/${userId}/docs/${entityId}/`) ||
        // @todo remove this after migration
        storage?.filename.startsWith(`docs/${entityId}/`))
  )
}

export const S3Doc = {
  storeDoc,
  getDoc,
  isDocPath,
}
