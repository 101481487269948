import { getCaretClientRect } from './cursor.js'
import type { VirtualElement } from '@popperjs/core'

/** Get a "fake" anchorEl for tracking the cursor position */
export const getCursorTrackingAnchorEl = (): VirtualElement | null => {
  const selection = window.getSelection()
  if (!selection) {
    return null
  }

  const initialRect = getCaretClientRect(selection)

  const getBoundingClientRect = () => {
    const selection = window.getSelection()
    if (!selection) {
      return initialRect
    }
    const rect = getCaretClientRect(selection)
    return rect
  }
  return {
    getBoundingClientRect,
  }
}

export const getSelectionAnchorEl = (): VirtualElement | null => {
  const selection = window.getSelection()
  if (!selection || selection.rangeCount === 0) {
    return null
  }
  const initialRect = selection.getRangeAt(0).getBoundingClientRect()

  const getBoundingClientRect = () => initialRect
  return {
    getBoundingClientRect,
  }
}
