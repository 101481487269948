import { logger } from '@tunasong/models'
import { entitiesApi, searchApi, useThunkDispatch } from '@tunasong/redux'
import type { EdgeType, Entity, EntityType, Persisted } from '@tunasong/schemas'
import { allQueries } from './queries/index.js'

export interface AutolinkProps {}

/** Advanced Search where the esQuery can be specified fully */
export const useAutolink = (props: AutolinkProps = {}) => {
  const {} = props

  const dispatch = useThunkDispatch()

  const findCandidates = async ({ entity, targetTypes }: { entity: Entity; targetTypes?: EntityType[] }) => {
    // @todo can we / should we remove e.g., file extensions from the name?
    const query = entity.name

    const advancedQuery = allQueries.autolink({
      query,
      filter: {
        types: targetTypes,
      },
    })
    logger.debug('findCandidates', { advancedQuery })

    return dispatch(
      searchApi.endpoints.search.initiate({
        query: advancedQuery,
        queryType: 'advanced',
      })
    ).unwrap()
  }

  const [createEdge, createEdgeStatus] = entitiesApi.useCreateEntityEdgeMutation()

  const autoLink = async ({
    entity,
    edgeType,
    targetTypes,
    direction = 'out',
  }: {
    entity: Persisted<Entity>
    edgeType: EdgeType
    targetTypes: EntityType[]
    direction: 'in' | 'out'
  }) => {
    const candidate = await findCandidates({ entity, targetTypes })

    // eslint-disable-next-line no-underscore-dangle
    const target = candidate.body.hits.hits[0]?._source
    if (!target) {
      return
    }

    const sourceId = direction === 'out' ? entity.id : target.id
    const targetId = direction === 'out' ? target.id : entity.id
    // Link to the first candidate
    const edge = {
      source: sourceId,
      target: targetId,
      relation: edgeType,
    }
    const result = await createEdge({
      edge,
    }).unwrap()

    logger.debug('autoLink', { edge, result })
  }

  return { findCandidates, autoLink }
}
