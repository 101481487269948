import { EditorsContext } from '@tunasong/editor'
import { PluginContext } from '@tunasong/plugin-lib'
import React, { useMemo } from 'react'
import type { FC } from 'react'
import { editorContext } from './editors.js'
import { Repository } from './plugin-repository.js'
import { useRegisterMimeTypesFromPlugins } from '../hooks/register-mime.js'

const MimeTypeComponent = () => {
  /**
   * @todo this seems like a candidate for a refactor
   * Register mime types in Redux
   */
  useRegisterMimeTypesFromPlugins()
  return null
}

/** Inject classes and values in the relevant context(s) */
export const DIContainer: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const pluginRepository = useMemo(() => new Repository(), [])
  return (
    <PluginContext.Provider value={pluginRepository}>
      <MimeTypeComponent />
      <EditorsContext.Provider value={editorContext}>{children}</EditorsContext.Provider>
    </PluginContext.Provider>
  )
}
