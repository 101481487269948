import { logger } from '@tunasong/models'
import type { EventDuration } from '../note/index.js'
import type { EasyScoreDuration } from './duration.js'

/** Convert duration from NoteEvent duration to EasyScoreDuration */
export const convertDuration = (duration: EventDuration): EasyScoreDuration => {
  /** First parse the initial number */
  if (typeof duration === 'number') {
    throw new Error(`Cannot convert clock time durations to EasyScore`)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, num = 1, eventDuration, dotted] = /^(\d*)(.)(.)?$/.exec(duration) ?? []

  if (eventDuration === 'm' && num === '1') {
    return 'w'
  }
  if (eventDuration === 'm' && num === '1') {
    return 'w'
  }
  if (eventDuration === 'n' && (num === '8' || num === '16')) {
    return num
  }
  if (eventDuration === 'n' && num === '2') {
    return 'h'
  }
  if (eventDuration === 'n' && num === '4') {
    return 'q'
  }

  logger.warn(`Unable to convert ${duration} to EasyScore`)
  return eventDuration as EasyScoreDuration
}
