/** Hook to control the video and audio tracks of the local and shared streams */

import { isDev } from '@tunasong/models'
import { useEffect, useState } from 'react'
import { useAudioEngine } from './audio-engine.js'
import { useMixer } from './mixer.hook.js'

export const useMic = () => {
  const mixer = useMixer()
  const engine = useAudioEngine()

  const [headsetMode, setHeadsetMode] = useState(false)
  const [isMuted, setIsMuted] = useState(isDev())
  const [isActive, setActive] = useState(false)

  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(null)
  useEffect(() => {
    setSelectedDevice(engine.defaultInputDevice)
  }, [engine.defaultInputDevice])

  const mute = (m = true) => {
    if (!mixer) {
      return
    }
    // const channel = mixer.getChannels('mic')
    mixer.muteChannels('mic', m)
    const bus = mixer.getBus('mic')
    bus.muted = m
    setIsMuted(m)
  }

  const stop = () => {
    if (!engine?.defaultInput) {
      return
    }
    setActive(false)
    engine.defaultInput.monitor = false
  }

  /** Start the selected device */
  const start = async () => {
    if (!engine.defaultInput) {
      return
    }
    setActive(true)
    engine.defaultInput.monitor = true
  }

  const setDevice = (newDevice: MediaDeviceInfo) => {
    if (!newDevice) {
      return
    }
    // eslint-disable-next-line react-compiler/react-compiler
    engine.defaultInputDevice = newDevice
  }

  const headset = (usingHeadset: boolean) => {
    // if (!micStream.current) {
    //   return
    // }
    setHeadsetMode(usingHeadset)

    // /** If using headset, turn off echo cancellation */
    // const constraints = usingHeadset ? micHeadset : micDefaults
    // micStream.current.getAudioTracks().forEach(async t => {
    //   await t.applyConstraints(constraints)
    //   logger.debug('Applied constraints to audio track', t.getConstraints())
    // })
  }

  return {
    /** The local and share streams. Video and audio tracks are managed by this hook */
    ready: Boolean(selectedDevice),
    selectedDevice,
    headset,
    headsetMode,
    isMuted,
    isActive,
    mute,
    setDevice,
    start,
    stop,
  }
}

export type MicControl = ReturnType<typeof useMic>
