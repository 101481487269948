/** Sync handler class */
import { logger } from '@tunasong/models'
import type { EntityUpdate } from '@tunasong/models'
import { fromUint8Array, toUint8Array } from 'js-base64'
import * as decoding from 'lib0/decoding'
import * as encoding from 'lib0/encoding'
import * as awarenessProtocol from 'y-protocols/awareness'
import * as Y from 'yjs'
import { getElementValue } from '../lib-v2/index.js'
import { YJSMessageType, YJSMessageTypeName, YJSSyncType } from './types.js'

export class SyncHandler {
  clientIsSynced: Record<string, boolean> = {}

  initialStateVector: Uint8Array
  constructor(
    public doc: Y.Doc,
    private awareness: awarenessProtocol.Awareness | null
  ) {
    this.initialStateVector = Y.encodeStateVector(doc)
  }

  static createFromUpdates = ({
    entityId,
    updates,
  }: {
    entityId: string

    updates: Pick<EntityUpdate, 'yDocSyncMessage'>[]
  }) => {
    const doc = new Y.Doc({ guid: entityId })

    /** Reconstruct doc from the stored updates */
    const syncHandler = new SyncHandler(doc, null)
    syncHandler.applyJSONUpdates(updates)
    return syncHandler
  }

  static createFromYDocString = ({ entityId, yDoc }: { entityId: string; yDoc: string }) => {
    const doc = new Y.Doc({ guid: entityId })
    Y.applyUpdate(doc, toUint8Array(yDoc))
    const syncHandler = new SyncHandler(doc, null)
    return syncHandler
  }

  static isSyncMessage = (m: Uint8Array | string): boolean => {
    const msg = typeof m === 'string' ? toUint8Array(m) : m
    const decoder = decoding.createDecoder(msg)
    const messageType = decoding.readVarUint(decoder) as YJSMessageType
    return messageType === YJSMessageType.Sync
  }

  /** Check if this is a sync message with data we can apply a doc */
  static isSyncMessageType = (
    m: Uint8Array | string,
    type: YJSSyncType | YJSSyncType[] = [YJSSyncType.Update, YJSSyncType.SyncStep2]
  ): boolean => {
    const syncTypes = Array.isArray(type) ? type : [type]
    const msg = typeof m === 'string' ? toUint8Array(m) : m
    const decoder = decoding.createDecoder(msg)
    const messageType = decoding.readVarUint(decoder)
    if (messageType !== YJSMessageType.Sync) {
      return false
    }
    const syncType = decoding.readVarUint(decoder)
    /** The syncTypes contain data we can apply */

    return syncTypes.includes(syncType)
  }

  static encodeUpdate = (val: Uint8Array | string): Uint8Array => {
    const update = typeof val === 'string' ? toUint8Array(val) : val
    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, YJSMessageType.Sync)
    encoding.writeVarUint(encoder, YJSSyncType.Update)
    encoding.writeVarUint8Array(encoder, update)
    return encoding.toUint8Array(encoder)
  }

  static encodeUpdatesAsString = (updates: Uint8Array) => fromUint8Array(updates)

  encodeAwareness = (clientKeys?: number[]): Uint8Array | null => {
    const awarenessStates = this.awareness?.getStates()
    if (!this.awareness || !awarenessStates || awarenessStates.size === 0) {
      return null
    }

    const keys: number[] = clientKeys ?? Array.from(awarenessStates.keys())

    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, YJSMessageType.Awareness)
    encoding.writeVarUint8Array(encoder, awarenessProtocol.encodeAwarenessUpdate(this.awareness, keys))
    return encoding.toUint8Array(encoder)
  }

  /** Encode diff between doc1 (initial doc) and doc2 (updated doc). Used to update server after e.g., offline */
  encodeDiffUpdate = (otherDoc: Y.Doc): Uint8Array => {
    const stateVector1 = Y.encodeStateVector(otherDoc)
    const diffUpdate = Y.encodeStateAsUpdate(this.doc, stateVector1)

    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, YJSMessageType.Sync)
    encoding.writeVarUint(encoder, YJSSyncType.Update)
    encoding.writeVarUint8Array(encoder, diffUpdate)
    return encoding.toUint8Array(encoder)
  }

  /** Compute the state vector, and ask for changes. Works well with a server that has all the content */
  encodeSyncStep0 = (): Uint8Array => {
    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, YJSMessageType.Sync)
    encoding.writeVarUint(encoder, YJSSyncType.SyncStep0)
    encoding.writeVarUint8Array(encoder, Y.encodeStateVector(this.doc))
    return encoding.toUint8Array(encoder)
  }

  encodeSyncStep1 = (): Uint8Array => {
    const encoder = encoding.createEncoder()
    encoding.writeVarUint(encoder, YJSMessageType.Sync)
    encoding.writeVarUint(encoder, YJSSyncType.SyncStep1)
    encoding.writeVarUint8Array(encoder, Y.encodeStateVector(this.doc))
    return encoding.toUint8Array(encoder)
  }

  /** Apply the sync message and return the response(s) for the message (may be more than one message) */
  applySyncMessage = (message: Uint8Array, transactionOrigin: string): Uint8Array[] => {
    const encoder = encoding.createEncoder()
    const decoder = decoding.createDecoder(message)
    const messageType: YJSMessageType = decoding.readVarUint(decoder)

    const responses: Uint8Array[] = []

    switch (messageType) {
      case YJSMessageType.Sync:
        encoding.writeVarUint(encoder, YJSMessageType.Sync)
        const syncMessageType: YJSSyncType = decoding.readVarUint(decoder)
        const payload = decoding.readVarUint8Array(decoder)

        logger.debug('Got sync message', YJSMessageTypeName[messageType], syncMessageType)

        if (syncMessageType === YJSSyncType.SyncStep0) {
          logger.debug(`Sync step 0 ${transactionOrigin} => ${this.doc.clientID}`, this.clientIsSynced)
          this.clientIsSynced[transactionOrigin] = false
          /** Fall through to SyncStep1 */
        }
        if (syncMessageType === YJSSyncType.SyncStep0 || syncMessageType === YJSSyncType.SyncStep1) {
          logger.debug(`Sync step 1 ${transactionOrigin} => ${this.doc.clientID}`, this.clientIsSynced)

          /** Always respond with SyncStep2 first. This enables the peer to mark this client as synced. */
          encoding.writeVarUint(encoder, YJSSyncType.SyncStep2)
          encoding.writeVarUint8Array(
            encoder,
            Y.encodeStateAsUpdate(this.doc, payload /* Payload is a state vector, so we send only the diff */)
          )
          responses.push(encoding.toUint8Array(encoder))

          /** If we receive SyncStep1 from a peer that we have not seen before, we respond with SyncStep1 */
          if (!this.clientIsSynced[transactionOrigin]) {
            /** We send SyncStep1 in a separate message since there are size limitations on WS messages */
            responses.push(this.encodeSyncStep1())
          }
        } else if (syncMessageType === YJSSyncType.SyncStep2) {
          /** Payload is state encoded as an update */
          Y.applyUpdate(this.doc, payload)
          this.clientIsSynced[transactionOrigin] = true
        } else if (syncMessageType === YJSSyncType.Update) {
          Y.applyUpdate(this.doc, payload)
        }
        break
      case YJSMessageType.Awareness:
        if (this.awareness) {
          awarenessProtocol.applyAwarenessUpdate(this.awareness, decoding.readVarUint8Array(decoder), transactionOrigin)
        }
        break

      case YJSMessageType.QueryAwareness:
        if (this.awareness) {
          encoding.writeVarUint(encoder, YJSMessageType.Awareness)
          encoding.writeVarUint8Array(
            encoder,
            awarenessProtocol.encodeAwarenessUpdate(this.awareness, Array.from(this.awareness.getStates().keys()))
          )
          responses.push(encoding.toUint8Array(encoder))
        }
        break
      default:
        throw new Error(`Unknown message type ${messageType}`)
    }
    logger.debug(`Responses for type: ${YJSMessageTypeName[messageType]}: ${responses.length}`)
    return responses
  }

  /**
   * Get the difference between the initial doc and the current doc state
   * Use this when the client goes online and we need to send changes to the server.
   */
  getChanges = () => {
    const currentState = Y.encodeStateAsUpdate(this.doc)
    const diffUpdate = Y.diffUpdate(currentState, this.initialStateVector)

    return diffUpdate
  }

  /** Clear the sync status. This is typically when the client starts an initial sync again after e.g., being offline */
  clearSyncStatus = () => (this.clientIsSynced = {})

  encodeDoc = () => fromUint8Array(this.encodeDocUint8Array())
  encodeDocUint8Array = () => Y.encodeStateAsUpdate(this.doc)

  /** Apply a JSON encoded list of Entity Updates. Returns the resulting doc state encoded as updates. */
  applyJSONUpdates = (updates: Pick<EntityUpdate, 'yDocSyncMessage'>[] | string[], transactionOrigin = 'server') => {
    const yDocUpdates = updates.map(u => (typeof u === 'string' ? u : u.yDocSyncMessage))
    for (const updateStr of yDocUpdates) {
      const update = toUint8Array(updateStr)
      this.applySyncMessage(update, transactionOrigin)
    }

    return this.encodeDocUint8Array()
  }

  toJSON = () => getElementValue(this.doc)
}
