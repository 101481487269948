import { Avatar } from '@mui/material'
import type { AvatarProps } from '@mui/material'

import type { EntityType } from '@tunasong/schemas'
import { useElementTypeMedia } from '@tunasong/plugin-lib'
import classNames from 'classnames'
import { useMemo } from 'react'
import type { FC } from 'react'
import { useBackgroundColors } from '../color/index.js'
import { makeStyles } from '../styles.js'

const useStyles = makeStyles()(theme => ({
  tiny: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  small: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}))

export interface EntityAvatarProps extends AvatarProps {
  className?: string
  type: EntityType
  size?: 'small' | 'large' | 'tiny'
}

export const EntityAvatar: FC<EntityAvatarProps> = props => {
  const { className, type, size = 'large' } = props
  const { classes } = useStyles()
  const { icon: Icon, materialColor } = useElementTypeMedia(type)

  const { classes: colorClasses } = useBackgroundColors()
  const cardColorClass = colorClasses[materialColor]

  const avatarClasses = useMemo(
    () => ({
      root: classNames(className, { [classes.small]: size === 'small', [classes.tiny]: size === 'tiny' }),
      colorDefault: cardColorClass,
    }),
    [cardColorClass, className, classes, size]
  )

  return (
    <Avatar classes={avatarClasses} aria-label="entity">
      <Icon />
    </Avatar>
  )
}

export default EntityAvatar
