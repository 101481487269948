import { Checkbox, Divider, FormControlLabel, TextField, Typography } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { capitalize } from '@tunasong/models'
import { VBox, useRedraw, useRedrawInterval } from '@tunasong/ui-lib'
import React, { Fragment, useCallback, useState } from 'react'
import type { ChangeEvent, FC } from 'react'
import { BusController } from './engine/bus.js'
import { useAudioEngine } from './hooks/index.js'
import { useMic } from './hooks/mic.js'
import InputSelect from './input-select.js'
import { Volume } from './volume.js'
import ChromaView from './dsp/chroma-view.js'
import ChordView from './dsp/chord-view.js'
import { TunerView } from './dsp/index.js'

export interface AudioMixerProps extends BoxProps {
  className?: string
}

export const AudioMixer: FC<AudioMixerProps> = props => {
  const { className, ...restProps } = props

  const engine = useAudioEngine()

  const [showDelayCompensation, setShowDelayCompensation] = useState(false)
  const handleDelayCompensation = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => setShowDelayCompensation(ev.target.checked),
    []
  )

  /** Since we change values in the mixer we need to explicitly redraw when changing */
  const refresh = useRedraw()

  const handleChangeDelayComp = useCallback(
    (bus: BusController) => (ev: { target: { value: string } }) => {
      const delay = Number.parseInt(ev.target.value, 10)
      bus.delayCompensation = delay
      refresh()
    },
    [refresh]
  )

  /** Refresh every second to handle updated mixer settings */
  useRedrawInterval(500)

  const { selectedDevice, setDevice } = useMic()

  if (!engine) {
    return null
  }
  const { mixer } = engine

  const { sampleRate } = mixer.context

  const { channels, busses } = mixer

  return (
    <VBox className={className} {...restProps}>
      <InputSelect type="audioinput" selectedId={selectedDevice?.deviceId} onChange={setDevice} />

      <Divider sx={{ mt: 2 }}>Sources</Divider>

      {channels.map(channel => (
        <Fragment key={channel.id}>
          <Volume controller={channel} title={capitalize(channel.name)} onChange={refresh} />
          {channel.monitor ? (
            <>
              <TunerView audioController={channel} />
              <ChromaView audioController={channel} />
              <ChordView sx={{ fontSize: 32 }} audioController={channel} />
            </>
          ) : null}
        </Fragment>
      ))}

      <FormControlLabel
        control={
          <Checkbox
            checked={showDelayCompensation}
            onChange={handleDelayCompensation}
            value="delayCompensation"
            color="primary"
          />
        }
        label="Delay compensation"
      />
      <Divider sx={{ mt: 2 }}>Busses</Divider>
      {busses.map(bus => (
        <React.Fragment key={bus.name}>
          {showDelayCompensation && (
            <TextField type="number" defaultValue={bus.delayCompensation} onBlur={handleChangeDelayComp(bus)} />
          )}
          <Volume key={bus.name} controller={bus} title={capitalize(bus.name)} onChange={refresh} />
          {bus.monitor ? (
            <>
              <ChromaView audioController={bus} />
              <ChordView sx={{ fontSize: 32 }} audioController={bus} />
            </>
          ) : null}
        </React.Fragment>
      ))}
      <Typography display="block" variant="caption">
        Sample rate: {sampleRate}. Base Latency: {Math.round(mixer.context.baseLatency * 1000)} ms.
      </Typography>
    </VBox>
  )
}

export default AudioMixer
