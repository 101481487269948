import { isCoreElement } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'

export interface Line extends CoreElement {
  type: 'line'
}

export function isLine(node?: unknown): node is Line {
  return Boolean(isCoreElement(node) && node.type === 'line')
}
