import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { shortUuid } from '@tunasong/models'
import type { AudioPeaks } from '@tunasong/models'
import { isSafari, makeStyles, useContainerSize, useMergeRefs } from '@tunasong/ui-lib'
import cn from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { generateBars } from '../lib/wave-drawer.js'

const useStyles = makeStyles<{ clipPath: string }>()((theme, { clipPath }) => ({
  root: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    fontSize: 12,
  },
  badge: {
    display: 'block',
    padding: theme.spacing(0, 1, 0, 1),
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.vars.palette.primary.light,
    color: theme.vars.palette.primary.contrastText,
  },

  waveformContainer: {
    flex: 1,
  },
  waveformBg: {
    clipPath,
    fill: theme.vars.palette.primary.light,
  },
  waveformProgress: {
    clipPath,
    fill: theme.vars.palette.success.main,
  },
}))

export interface WaveProps extends Omit<BoxProps, 'position'> {
  className?: string
  /** `fixed`: render each peak one time. `dynamic` fill the available box, calculate peak characteristics  */
  mode?: 'fixed' | 'dynamic'
  peaks?: AudioPeaks
  badge?: string | null
  /** Position in the wave, from 0.0 to 1.0 */
  position?: number
  barWidth?: number
}

export const Wave = (props: WaveProps) => {
  const { className, badge, peaks, barWidth = 5, position = 0, mode = 'dynamic', ref: rootRef, ...restProps } = props
  const clipId = useMemo(() => `waveform-${shortUuid()}`, [])
  const clipPath = `#${clipId}`
  const clipStyle = { clipPath }
  const { classes } = useStyles({ clipPath: `url("${clipPath}")` })
  const progressWidth = position * 100

  const [localRef, setLocalRef] = useState<HTMLElement | null>(null)
  const mergedRef = useMergeRefs(setLocalRef, rootRef)
  const maxBuckets = mode === 'fixed' ? peaks?.length ?? 0 : Math.min(peaks?.length ?? 200, 200)

  const spacing = 0.2

  const containerSize = useContainerSize(localRef)
  const numBuckets =
    mode === 'fixed'
      ? peaks?.length ?? 0
      : containerSize?.width
        ? Math.min(maxBuckets, containerSize.width / (barWidth + spacing))
        : 0

  const bars = peaks ? generateBars({ peaks, numBuckets, spacing }) : []

  /** Hack to enable wave is rendering on Safari */
  const [renderKey, setRenderKey] = useState(shortUuid())
  useEffect(() => {
    if (!isSafari()) {
      return
    }
    setTimeout(() => {
      setRenderKey(shortUuid())
    }, 1000)
  }, [])

  return (
    <Box {...restProps} className={cn(className, classes.root)} ref={mergedRef}>
      {badge ? <div className={classes.badge}>{badge}</div> : null}

      <svg className={classes.waveformContainer} viewBox="0 0 100 100" preserveAspectRatio="none">
        <rect className={classes.waveformBg} style={clipStyle} x="0" y="0" height="100" width="100" />
        <rect className={classes.waveformProgress} style={clipStyle} x={0} y={0} height={100} width={progressWidth} />
      </svg>
      {/* this SVG is the "clipping mask" - the waveform bars */}
      <svg height="0" width="0">
        <defs>
          <clipPath key={renderKey} id={clipId}>
            {bars}
          </clipPath>
        </defs>
      </svg>
    </Box>
  )
}

export default Wave
