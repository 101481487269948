import { TextField, capitalize } from '@mui/material'
import type { FC } from 'react'
import type { FormComponentProps } from '../form-component.js'

export const TextSchemaField: FC<FormComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, spec, FormControl, register, ...restProps } = props
  const label = capitalize(spec.metadata?.label ?? name)
  const multiline = Boolean(spec.metadata?.multiline)

  // Register the input element with the form
  const htmlInput = register(name, {
    // Set value to undefined if it is an empty string
    setValueAs: value => (value === '' ? undefined : value),
  })

  return <TextField multiline={multiline} label={label} slotProps={{ htmlInput }} {...restProps} />
}

export default TextSchemaField
