import type { Theme } from '@mui/material'
import { logger } from '@tunasong/models'
import { DIMINISHED, GuitarLib } from '@tunasong/music-lib'
import type { ChordType } from '@tunasong/music-lib'

export const defaultWidth = 75
export const defaultHeight = 100

/**
 * Layout
 *
 *  Chord name
 *  --padding--
 *  x   o    x
 *  ============ (nut)
 *    o          (fingering)
 *  ------------ (fret)
 *        o      (fingering)
 *  ------------ (fret)
 *
 *    2   3       (fingering fret number)
 */

export interface DrawParams {
  canvas: HTMLCanvasElement | null
  fingering?: GuitarLib.FingeringWithMetadata[]
  theme: Theme
  label: string
  degree?: string
  type: ChordType
}

export const draw = ({ canvas, fingering, theme, label, degree, type }: DrawParams) => {
  if (!(canvas && fingering)) {
    logger.warn('GuitarChord: cannot draw without a canvas and fingerings')
    return
  }
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    logger.warn('GuitarChord: cannot draw without a 2D context')
    return
  }

  /** Scale properly */
  const PIXEL_RATIO = window.devicePixelRatio || 1

  canvas.width = (canvas.clientWidth || defaultWidth) * PIXEL_RATIO
  canvas.height = (canvas.clientHeight || defaultHeight) * PIXEL_RATIO

  const height = canvas.height
  const width = canvas.width

  const numFrets = 4

  /**
   * Find the fretOffset
   */
  const frets = (fingering || [])
    .map(f => f.fret)
    .filter(fret => fret > 0)
    .sort()
  const minFret = Math.min.apply(null, frets)
  const maxFret = Math.max.apply(null, frets)
  const fretOffset = maxFret > numFrets ? minFret - 1 : 0

  /** Variables */
  const labelFontSize = Math.min(16, Math.floor(width / (label.length / 1.5))) * PIXEL_RATIO
  const degreeFontSize = 14 * PIXEL_RATIO
  const fontSize = 10 * PIXEL_RATIO

  /** Colors */

  const lineWidth = 1 * PIXEL_RATIO
  const fretStyle = theme.palette.action.focus
  const numStrings = 6

  const fingeringRadius = (8 * PIXEL_RATIO) / 2
  const fingeringStyle = theme.notes?.main
  const fingeringStyleInScale = theme.notes?.inScale
  const fingeringStyleOutOfScale = theme.notes?.outOfScale

  const nameHeight = labelFontSize + 2

  const openMutedOffset = nameHeight
  const openMutedHeight = fontSize + 8

  const nutHeight = 4
  const nutOffset = openMutedOffset + openMutedHeight

  const fretLineWidth = 2 * PIXEL_RATIO
  const fretBoardOffset = nutOffset + nutHeight
  const fullFretboardHeight = height - fretBoardOffset
  const fretBoardHeight = fullFretboardHeight / numFrets
  const fretBoardWidth = width

  const stringPadding = 8 * PIXEL_RATIO
  const stringSpacing = (fretBoardWidth - 2 * stringPadding) / (numStrings - 1)
  const stringWidth = 2
  const stringStyle = theme.palette.action.disabled

  const barreHeight = 2 * fingeringRadius

  /** Position functions, relative to fretOffset */
  const getStringPosition = (str: number) => fretBoardWidth - (stringPadding + stringSpacing * str)
  const getFingerPosition = (fret: number, str: number) => {
    const x = fretBoardWidth - (stringPadding + stringSpacing * (str - 1))
    const y = fretBoardOffset + (fret - fretOffset) * fretBoardHeight - fretBoardHeight / 2
    return [x, y]
  }

  /** Draw fretboard box */
  ctx.beginPath()
  ctx.moveTo(0, 0)
  ctx.fillStyle = theme.palette.secondary.main
  ctx.strokeStyle = theme.palette.divider
  ctx.lineWidth = lineWidth
  ctx.strokeRect(0, fretBoardOffset, width, fullFretboardHeight)
  ctx.stroke()

  const drawLabel = () => {
    ctx.beginPath()
    ctx.font = `${labelFontSize}px sans-serif`
    ctx.fillStyle = theme.palette.text.primary
    const labelSize = ctx.measureText(label)
    ctx.fillText(label, fretBoardWidth / 2 - labelSize.width / 2, labelFontSize)
    ctx.stroke()
  }
  const drawDegree = () => {
    let lb: string
    if (!degree) {
      return
    }
    switch (type) {
      case 'm':
        lb = degree.toLowerCase()
        break
      case 'dim':
        lb = `${degree.toLowerCase()}${DIMINISHED}`
        break
      default:
        lb = degree
    }
    ctx.beginPath()
    ctx.font = `${degreeFontSize}px sans-serif`
    ctx.fillStyle = theme.notes?.inScale
    const size = ctx.measureText(lb)

    ctx.fillText(lb, fretBoardWidth - size.width / 2 - 8 * PIXEL_RATIO, degreeFontSize)
    ctx.stroke()
  }

  /** Barre */
  const drawBarre = () => {
    const barreRange = GuitarLib.getBarreRange(fingering)
    if (!barreRange) {
      return
    }
    const { barre, startString, endString } = barreRange

    const [x1, y1] = getFingerPosition(barre, endString)
    const [x2, y2] = getFingerPosition(barre, startString)
    /** Since we draw the fingerings, we can simply draw a simple line. We need to add fingeringRadius / 2 margin */

    ctx.beginPath()
    ctx.strokeStyle = fingeringStyle

    ctx.lineWidth = barreHeight
    ctx.moveTo(x1 - fingeringRadius, y1)
    ctx.lineTo(x2 + fingeringRadius, y2)
    ctx.stroke()
  }

  const drawFretInfo = () => {
    ctx.font = `${fontSize}px sans-serif`
    ctx.fillStyle = theme.palette.action.active
    for (const { fret, str } of fingering) {
      const l = fret === GuitarLib.OPEN_STRING ? '' : fret === GuitarLib.MUTED_STRING ? 'X' : `${fret}`
      const labelSize = ctx.measureText(l)

      ctx.fillText(l, getStringPosition(str - 1) - labelSize.width / 2, openMutedOffset + fontSize + 2)
    }
  }

  const drawNut = () => {
    if (fretOffset > 0) {
      return
    }
    ctx.beginPath()
    ctx.moveTo(0, nutOffset)
    ctx.lineWidth = nutHeight
    ctx.lineTo(fretBoardWidth, nutOffset)
    ctx.stroke()
  }

  const drawFrets = () => {
    /** Set the line width for the frets */
    ctx.lineWidth = fretLineWidth
    ctx.strokeStyle = fretStyle
    ctx.beginPath()
    for (let fret = 1; fret <= numFrets; fret++) {
      const x = fretBoardWidth
      const y = fret * fretBoardHeight + fretBoardOffset
      ctx.moveTo(0, y)
      ctx.lineTo(x, y)
    }
    ctx.stroke()
  }

  const drawStrings = () => {
    ctx.beginPath()
    ctx.lineWidth = stringWidth
    ctx.strokeStyle = stringStyle
    for (let str = 0; str < numStrings; str++) {
      const x = getStringPosition(str)
      ctx.moveTo(x, fretBoardOffset)
      ctx.lineTo(x, fretBoardOffset + fretBoardHeight * numFrets)
    }
    ctx.stroke()
  }

  /** The fingering is at or below the barre */
  const isBarred = (barreRange: GuitarLib.BarreRange | null, fret: number, str: number) => {
    const { barre = 0, startString = 0, endString = 0 } = barreRange ?? {}
    return fret <= barre && str >= startString && str <= endString
  }

  /** From right to left */
  const drawFingering = () => {
    /** Fingerings */
    /** We don't want to draw barred notes */
    const barreRange = GuitarLib.getBarreRange(fingering)

    const radius = fingeringRadius
    for (const { fret, str, inScale } of fingering) {
      /** Muted or open strings or barred? */
      if (fret <= 0 || isBarred(barreRange, fret, str)) {
        continue
      }
      const [x, y] = getFingerPosition(fret, str)
      /** Color based on in/out of scale */
      ctx.fillStyle =
        inScale === true ? fingeringStyleInScale : inScale === false ? fingeringStyleOutOfScale : fingeringStyle

      ctx.beginPath()
      ctx.moveTo(x, y)
      ctx.arc(x, y, radius, 0, Math.PI * 2)
      ctx.fill()
    }
  }
  drawLabel()
  drawDegree()
  drawFretInfo()
  drawNut()
  drawFrets()
  drawStrings()
  drawFingering()
  drawBarre()
}
