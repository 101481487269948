import type { Comment, Persisted } from '@tunasong/schemas'
import { ChatEditor } from '../editor/index.js'
import type { TunaEditor } from '@tunasong/plugin-lib'

export const createChatFeedItem = (comment: Persisted<Comment>, onEditor?: (editor: TunaEditor) => void) => ({
  ...comment,
  content: <ChatEditor key={comment.id} readOnly={true} autoFocus={false} element={comment} onEditor={onEditor} />,
  actions: undefined,
})

export const createChatFeed = (feedComments: Persisted<Comment>[] = []) => feedComments.map(c => createChatFeedItem(c))
