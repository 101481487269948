import { captureException, logger } from '@tunasong/models'
import type { FilterFunc } from '@tunasong/models'
import type { Entity, EntityType } from '@tunasong/schemas'
import { useChildren } from './child-entities.js'

/** Get the child entity matching the filter. If there are > 1 children matching the critiera this hook will throw */
export const useChild = <T extends Entity = Entity>({
  parentId,
  filter,
  networkFirst = false,
}: {
  parentId: string | null | undefined
  filter: EntityType | FilterFunc
  networkFirst?: boolean
}) => {
  const { entities: children, isSuccess, ...restProps } = useChildren<T>({ parentId, filter, networkFirst })
  try {
    if (children.length > 1) {
      logger.error(`useChild found more than one child matching the filter:`, { parentId, children })
      throw new Error(`useChild found more than one child matching the filter: ${JSON.stringify(filter)}`)
    }
    /** If we have loaded and found no children, return null */
  } catch (e) {
    captureException(e)
  }
  const child = isSuccess && children.length === 0 ? null : children[0]

  return { child, isSuccess, ...restProps }
}
