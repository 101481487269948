import { features, storageApi, useSelector, useThunkDispatch } from '@tunasong/redux'
import type { AudioMedia } from '@tunasong/schemas'
import { useEffect } from 'react'
import invariant from 'tiny-invariant'
import { useRenderPeaks } from './render-peaks.js'

interface Peaks {
  media?: AudioMedia
  /** Get the peaks from this url */
  url?: string
  /** Render Peaks if the peaks are not available */
  renderPeaks?: boolean
}

export const usePeaks = ({ media, url: sourceUrl, renderPeaks = false }: Peaks) => {
  const dispatch = useThunkDispatch()
  let peaksPath = media?.features?.peaks?.data
  /** Don't try to load temporary peaks */
  const p = media?.features?.peaks

  const isTemporary = p?.type === 'peaks' ? p.temporary : false

  invariant(!(sourceUrl && media), 'Cannot provide both media and url')

  /** Legacy peaks path */
  if (typeof peaksPath === 'object') {
    peaksPath = undefined
  }

  const mediaUrl = media?.storageUrls?.url

  const reduxPeaks = useSelector(state => (peaksPath ? state.audio.peaks[peaksPath] : null))
  const { currentData: loadedPeaks, isLoading: reduxLoading } = storageApi.useGetFileJSONQuery(
    { storagePath: peaksPath ?? '' },
    { skip: Boolean(!peaksPath || reduxPeaks || isTemporary) }
  )
  useEffect(() => {
    if (!(peaksPath && loadedPeaks)) {
      return
    }
    dispatch(features.audio.actions.setPeaks({ peaks: loadedPeaks as never, path: peaksPath }))
  }, [dispatch, loadedPeaks, peaksPath])

  const url = sourceUrl ?? mediaUrl

  /** If we don't have a peaksPath and wants to renderPeaks, use fallback method */
  const fallbackUrl = url && renderPeaks ? url : null
  const { peaks: renderedPeaks, loading: renderedLoading } = useRenderPeaks(fallbackUrl)

  const peaks = reduxPeaks ?? renderedPeaks
  const loading = reduxLoading || renderedLoading

  return { peaks, loading }
}
