import { Box, Divider, Typography } from '@mui/material'
import { Backlinks, Edges, useCurrentUser } from '@tunasong/ui-lib'
import type { FC } from 'react'

export interface GraphProps {}

export const Graph: FC<GraphProps> = props => {
  const {} = props
  const { userId } = useCurrentUser()
  return (
    <Box>
      <Typography variant="caption">
        <Divider textAlign="center" sx={{ my: 1 }}>
          Links
        </Divider>
        <Edges sourceId={userId} />
        <Divider textAlign="center" sx={{ my: 1 }}>
          Backlinks
        </Divider>
        <Backlinks id={userId} />
      </Typography>
    </Box>
  )
}

export default Graph
