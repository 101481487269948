import type { Entity, Persisted } from '@tunasong/schemas'
import { useMemo } from 'react'
import type { Command } from '../plugin-types.js'
import { useCommandSpecRunner } from './command-runner.hook.js'
import { getCustomCommands } from './custom-commands.js'
/**
 * Generate commands from the custom commands
 * @returns null when the runner is not ready
 */
export const useCustomCommands = (cmdEntity?: Persisted<Entity>): Command[] => {
  const runner = useCommandSpecRunner()

  // Generate command specs from the custom commands
  const commands = useMemo(() => getCustomCommands({ cmdEntity, runner }), [cmdEntity, runner])

  return commands
}
