import type { AudioPeaks, BusName } from '@tunasong/models'
import { useEffect, useState } from 'react'
import { useMixer } from '../hooks/mixer.hook.js'
import { AnalyserNode } from 'standardized-audio-context'

interface FFTProps {
  bus?: BusName
  /** Default FFT size. You will receive fftSize / 2 samples back */
  fftSize?: number
}

/** Return the FFT analyzer from a bus */
export const useFFT = ({ bus = 'record', fftSize = 256 }: FFTProps) => {
  const mixer = useMixer()
  const [peaks, setPeaks] = useState<AudioPeaks>()
  /** Get FFT peaks  */
  useEffect(() => {
    if (!mixer) {
      return
    }
    const analyzer = new AnalyserNode(mixer.context, {
      fftSize,
      maxDecibels: 0,
      minDecibels: -90,
      smoothingTimeConstant: 0.85,
    })

    const dataArray = new Float32Array(analyzer.frequencyBinCount)

    let id: number
    const calcPeaks = () => {
      analyzer.getFloatTimeDomainData(dataArray)
      const peaks: AudioPeaks = {
        type: 'peak',
        length: analyzer.frequencyBinCount,
        /** @todo support multiple channels? */
        channels: 1,
        data: dataArray,
      }
      setPeaks(peaks)
      id = requestAnimationFrame(calcPeaks)
    }
    id = requestAnimationFrame(calcPeaks)

    const recordBus = mixer.getBus(bus)

    recordBus.output.connect(analyzer)

    return () => {
      cancelAnimationFrame(id)
      recordBus.output.disconnect(analyzer)
    }
  }, [bus, fftSize, mixer])

  return peaks
}
