/** Lazy routes. Not part of router.tsx due to fast refresh lint */

import { SuspenseBusy, withSuspense } from '@tunasong/ui-lib'
import React from 'react'
import type { FC } from 'react'

export const LazyLogin = withSuspense(
  React.lazy(async () => import('./auth/login.js')),
  <SuspenseBusy />
)

export const LazyErrorBoundary = withSuspense(
  React.lazy(async () => import('./error-boundary.js')),
  <SuspenseBusy />
)

export const LazyPage = withSuspense(
  React.lazy(async () => import('./pages/page.js')),
  <SuspenseBusy />
)

export const LazyTermsOfService = withSuspense(
  React.lazy(async () => import('./pages/terms-of-service.js')),
  <SuspenseBusy />
)

export const LazyWaitlistPage = withSuspense(
  React.lazy(async () => import('./pages/waitlist.js')),
  <SuspenseBusy />
)

export const LazyEmbed = withSuspense(
  React.lazy(async () => import('./pages/embed.js')),
  <SuspenseBusy />
)

export const LazyIndexPage = withSuspense(
  React.lazy(async () => import('./pages/index.js')),
  <SuspenseBusy />
)

export const LazyLogout = withSuspense(
  React.lazy(async () => import('./auth/logout.js')),
  <SuspenseBusy />
)

export const LazyLabs: FC = withSuspense(
  React.lazy(async () => import('@tunasong/labs')),
  <SuspenseBusy />
)
