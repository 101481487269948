import { FormControlLabel, Switch, capitalize } from '@mui/material'
import type { FC } from 'react'
import { useController } from 'react-hook-form'
import type { FormComponentProps } from '../form-component.js'

export const BooleanSchemaField: FC<FormComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, spec, register, control, FormControl, ...restProps } = props
  const label = capitalize(spec.metadata?.label ?? name)
  const { field } = useController({
    name,
    control,
    defaultValue: spec.defaultValue,
  })

  return <FormControlLabel control={<Switch checked={Boolean(field.value)} {...field} />} label={label} />
}

export default BooleanSchemaField
