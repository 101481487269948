import { Button, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import type { FC } from 'react'

export interface MidiDeviceItemProps {
  device: BluetoothDevice
  onForgetDevice?: (device: BluetoothDevice) => void
}

export const MidiDeviceItem: FC<MidiDeviceItemProps> = props => {
  const { device, onForgetDevice } = props

  /** @todo do this properly, e.g., emitting events from Midi */
  const [connected, setConnected] = useState(false)
  useEffect(() => {
    const interval = setInterval(() => {
      setConnected(device?.gatt?.connected ?? false)
    }, 1000)
    return () => clearInterval(interval)
  }, [device?.gatt?.connected])

  return (
    <ListItem key={device.id}>
      <ListItemText primary={device.name} secondary={connected ? `Connected` : `Not connected`} />
      <ListItemSecondaryAction>
        {onForgetDevice ? <Button onClick={() => onForgetDevice(device)}>Forget</Button> : null}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default MidiDeviceItem
