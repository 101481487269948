import { TextField } from '@mui/material'
import { useCallback } from 'react'
import type { FC } from 'react'
import type { FormComponentProps } from '../form-component.js'

export const NumberSchemaField: FC<FormComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, spec, register, FormControl, ...restProps } = props
  const setValueAs = useCallback((v?: string) => (v ? parseInt(v, 10) : undefined), [])
  return (
    <TextField
      type="number"
      label={spec.metadata?.label ?? spec.name}
      inputProps={{ ...register(name, { setValueAs }) }}
      {...restProps}
    />
  )
}

export default NumberSchemaField
