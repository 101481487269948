import { Grid } from '@mui/material'

import type { AudioEvent } from '@tunasong/schemas'
import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { formatTime } from '../lib/format-time.js'
import { Event } from './event.js'
import cn from 'classnames'
import { makeStyles } from '@tunasong/ui-lib'

const useStyles = makeStyles()(() => ({
  root: {
    alignItems: 'center',
    overflowY: 'scroll',
  },
}))

export interface EventLogProps {
  className?: string
  activeEvent?: AudioEvent
  maxEvents?: number
}

export const EventLog: FC<EventLogProps> = props => {
  const { className, activeEvent, maxEvents = 10 } = props
  const { classes } = useStyles()
  const [history, setHistory] = useState<AudioEvent[]>([])
  useEffect(() => {
    if (!activeEvent) {
      return
    }
    setHistory(history => [activeEvent, ...history].slice(0, maxEvents))
  }, [activeEvent, maxEvents])

  return (
    <Grid container className={cn(className, classes.root)}>
      {history.map((event, idx) => (
        <React.Fragment key={idx}>
          <Grid item xs={4}>
            {formatTime(event.start)}
          </Grid>
          <Grid item xs={4}>
            {event.end && formatTime(event.end)}
          </Grid>
          <Grid item xs={4}>
            <Event key={idx} event={event} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default EventLog
