import { ButtonBase } from '@mui/material'
import { Add, Delete, Pool, Preview } from '@tunasong/icons'
import type { Track } from '@tunasong/schemas'
import { makeStyles } from '@tunasong/ui-lib'
import cn from 'classnames'
import type { FC } from 'react'

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  delete: {
    color: theme.vars.palette.error.main,
  },
  button: {
    color: theme.vars.palette.action.active,
    // borderColor: ,
    padding: 2,
    margin: 2,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    '& >svg': {
      width: 16,
      height: 16,
    },
  },
}))

export interface ChannelHeaderProps {
  className?: string
  selected?: Track
  onToggleDetails(): void
  onNewTrack?(): void
  onTogglePool?(): void
  onDeleteTrack?(track: Track): void
}

export const ChannelHeader: FC<ChannelHeaderProps> = props => {
  const { className, selected, onTogglePool, onDeleteTrack, onNewTrack, onToggleDetails } = props

  const { classes } = useStyles()
  return (
    <div className={cn(className, classes.root)}>
      <ButtonBase title="Show/Hide Media Pool" className={classes.button} onClick={onTogglePool}>
        <Pool />
      </ButtonBase>
      <ButtonBase title="Show/Hide Details" className={classes.button} onClick={() => onToggleDetails()}>
        <Preview />
      </ButtonBase>
      <ButtonBase title="New Track" className={classes.button} onClick={onNewTrack}>
        <Add />
      </ButtonBase>
      {selected && (
        <ButtonBase
          title="Delete Track"
          className={cn(classes.delete, classes.button)}
          onClick={onDeleteTrack ? () => onDeleteTrack(selected) : undefined}
        >
          <Delete />
        </ButtonBase>
      )}
    </div>
  )
}

export default ChannelHeader
