import { Paper } from '@mui/material'
import cn from 'classnames'
import React from 'react'
import type { FC } from 'react'
import { makeStyles } from '../styles.js'

const useStyles = makeStyles()(theme => ({
  root: { display: 'flex', color: 'inherit' },
  hotkey: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
}))

interface HotkeyProps {
  className?: string
  elevation?: number
  children: React.ReactNode
}

export const Hotkey: FC<HotkeyProps> = props => {
  const { elevation = 1, children, className } = props
  const { classes } = useStyles()

  return (
    <Paper className={cn(className, classes.root)} elevation={elevation}>
      <div className={classes.hotkey}>{children}</div>
    </Paper>
  )
}

export default Hotkey
