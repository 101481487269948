import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Suggestion } from '@tunasong/models'

// Define a type for the slice state
export interface SuggestionsState {
  suggestions: Suggestion[]
  isLoading: boolean
}

// Define the initial state using that type
const initialState: SuggestionsState = {
  suggestions: [],
  isLoading: false,
}

/**
 * @note if you get an Immer error here, pin the Immer version to ensure a single instance
 * @see https://github.com/reduxjs/redux-toolkit/issues/1181
 */
const name = 'suggestions'
export const suggestionsSlice = createSlice({
  name,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    suggestions: (state, action: PayloadAction<Suggestion[]>) => {
      state.suggestions = action.payload
      state.isLoading = false
    },
    clearSuggestions: state => {
      state.suggestions = []
    },
  },
})
