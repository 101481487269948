import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'

/** Default CSP */

export interface SEOProps {
  title: string
  description?: string
  lang?: string
}

export const SEO: FC<SEOProps> = ({ description, title }) => {
  const metaDescription = description

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={'torgeir@tunasong.com'} />
      <meta name="twitter:title" content={title} />

      <noscript>{`
        Tunasong requires Javascript to work properly. Please enable it in your browser.
    `}</noscript>
    </Helmet>
  )
}
