import { logger } from '@tunasong/models'
import type { Line, Section } from '@tunasong/models'
import { isText } from '@tunasong/schemas'
import { ChordLib, isChordVariant } from '../chord/index.js'
import type { Chord } from '../chord/index.js'
import { tokenize } from './tokenize-song.js'
import type { SongToken } from './tokenize-song.js'

/** Simple parse - only identify chords */
export interface SimpleToken {
  type: 'chord' | 'text' | 'section'
  value: string
}

export type SimpleLine = SimpleToken[]
export const simpleParseSong = (text: string): SimpleLine[] => {
  const lines: SimpleLine[] = []
  const tokens = tokenize(text)
  if (!tokens) {
    return lines
  }
  for (const [, token] of tokens.entries()) {
    if (!token.line) {
      throw new Error(`Word ${token} does not have line information`)
    }
    lines[token.line] = lines[token.line] ? lines[token.line] : []
    const line = lines[token.line]
    switch (token.type) {
      case 'chord':
        const m = token.value.match(/(\s+)$/)
        line.push({ type: 'chord', value: token.value.trim() })
        /** Padding */
        if (m && m[1]) {
          const t = m[1].replace('\n', '').replace('\r', '')
          if (t) {
            line.push({ type: 'text', value: t })
          }
        }
        break
      case 'word':
        line.push({ type: 'text', value: token.value })
        break
      case 'section':
        line.push({ type: 'section', value: token.value })
        break
      default:
        break
    }
    line.push({ type: 'text', value: ' ' })
  }
  return lines.filter(l => typeof l !== 'undefined')
}

/**
 * Parse freetext and create a Line[] array
 *
 * @todo assumes chords and text on separate lines
 * @todo autodetect whether a line is text or chords
 * @todo use a proper parser - https://tomassetti.me/parsing-in-javascript/#lexer
 */

export const parseSong = (text: string) => {
  const lines: Line[] = []
  const tokens = tokenize(text)
  if (!tokens) {
    return lines
  }

  /**
   * 1) Determine if lines are chord or text lines
   * 2) Match line positions with chords
   * 3) Combine chords with words, or create empty phrases
   */

  const words = [...tokens].filter(t => t.type === 'word')
  const chords = [...tokens].filter(t => t.type === 'chord')

  /** Map words to lines */
  for (const [, word] of words.entries()) {
    if (!word.line) {
      throw new Error(`Word ${word} does not have line information`)
    }
    lines[word.line] = lines[word.line] ? lines[word.line] : { type: 'line', children: [{ text: '' }] }
    const line = lines[word.line]

    const [chord, chordToken] = findChordForWord(chords, word)
    /** Remove the found chord from the list of chords */
    if (chordToken) {
      const index = chords.indexOf(chordToken)
      if (index < 0) {
        throw new Error(`Invalid chordToken ${JSON.stringify(chordToken)}`)
      }
      chords.splice(index, 1)
    }

    /** Add the phrase to the line */
    line.children.push({
      type: 'line',
      text: word.text || ' ', // Keep a non-empty string here
      ...chord,
    })
  }

  /** Fill in unused chords */
  for (const unusedChord of chords) {
    const lineNo = unusedChord.line || 0
    const wordLine = lines[lineNo + 1]
    const column = unusedChord.column || 0
    const wordCols = words.filter(w => w.line === lineNo + 1).map(w => w.column || 0)

    /** If we have "chords" on text lines this will happen */
    if (!wordLine) {
      logger.debug(`Chord found on line not followed by text line`, unusedChord)
      continue
    }

    /** Case 1 - we have a chord before a word on a line */
    if (column < Math.min.apply(null, wordCols)) {
      wordLine.children = [
        {
          text: '',
          ...tokenToChord(unusedChord),
        },
        ...wordLine.children,
      ]
      continue
    }
    /** Case 2 - we have a chord after all the words */
    if (column > Math.max.apply(null, wordCols)) {
      wordLine.children = [
        ...wordLine.children,
        {
          // type: 'line',
          text: '',
          ...tokenToChord(unusedChord),
        },
      ]
      continue
    }
  }

  /** Remove empty lines */
  return lines.filter(l => !!l)
}

const tokenToChord = (chordToken: SongToken) => {
  const chordName = chordToken.text.trim()
  return ChordLib.fromName(chordName)
}

/**
 * Heuristics for finding the corresponding chord for a word
 * @todo Naiive implementation
 */
const findChordForWord = (chords: SongToken[], word: SongToken): [Chord?, SongToken?] => {
  const startCol = word.column || 0
  const endCol = startCol + word.text.length

  const line = word.line || 0
  const chordsAbove = chords.filter(c => c.line === line - 1)

  /** A chord is a match only if chord position is "within" the word */
  const match = chordsAbove.find(chord => {
    const chordCol = chord.column ? chord.column : 0
    return chordCol >= startCol && chordCol <= endCol
  })

  if (!match) {
    return []
  }
  return [tokenToChord(match), match]
}

/** Generate an ascii text from the section */
export const sectionToTab = (section?: Section) => {
  let ascii = ''
  if (!(section && section.children)) {
    return ascii
  }

  /** @todo output type, tempo, etc. */
  for (const line of section.children) {
    let chordLine = ''
    let textLine = ''

    for (const phrase of 'children' in line ? line.children : []) {
      const text = isText(phrase) ? phrase.text : ''
      const chord = phrase

      textLine = `${textLine} ${text}`
      chordLine = `${chordLine} ${isChordVariant(chord) ? ChordLib.getName(chord) : ''}`

      /** Now pad them */
      const len = Math.max(textLine.length, chordLine.length)
      textLine = textLine.padEnd(len, ' ')
      chordLine = chordLine.padEnd(len, ' ')
    }
    ascii = `${ascii}${chordLine}\n${textLine}\n`
  }
  return ascii
}
