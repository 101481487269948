/**
 * Making chords from scales http://www.ethanhein.com/wp/2015/making-chords-from-scales/
 */
import type { KeyType, NoteName, Scale } from '@tunasong/schemas'
import { isChordNotes } from '../chord/chord-types.js'
import type { Chord, ChordType, ChordVariant } from '../chord/chord-types.js'
import { chordEquals } from '../chord/equals.js'
import { getNotes as getChordNotes } from '../chord/get-notes.js'
import type { NoteDegree } from '../note/index.js'
import * as NoteLib from '../note/index.js'
import { ALL_MODES } from './mode.js'
import type { ModeType } from './mode.js'
import { SCALE_DEGREE_NAMES, getScaleIndex } from './scale-degree.js'
import type { ScaleDegree } from './scale-degree.js'

export type Degree = 'I' | 'II' | 'III' | 'IV' | 'V' | 'VI' | 'VII' | 'VIII'

const MAJOR_SCALE_DEGREE_CHORD_TYPES: Record<Degree, ChordType> = {
  I: '',
  II: 'm',
  III: 'm',
  IV: '',
  V: '',
  VI: 'm',
  VII: 'dim',
  VIII: '',
}
const MINOR_SCALE_DEGREE_CHORD_TYPES: Record<Degree, ChordType> = {
  I: 'm',
  II: 'dim',
  III: '',
  IV: 'm',
  V: 'm',
  VI: '',
  VII: '',
  VIII: 'm',
}

const CHORD_DEGREES = Object.keys(MAJOR_SCALE_DEGREE_CHORD_TYPES)

const MODE_INTERVALS: Record<string, NoteDegree[]> = {}
for (const [name, m] of Object.entries(ALL_MODES)) {
  MODE_INTERVALS[name] = m.intervals
}

interface ScaleSpec {
  type: KeyType
  intervals: NoteDegree[]
}

const SCALES: Record<KeyType, ScaleSpec> = {
  major: {
    type: 'major',
    intervals: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'],
  },
  minor: {
    type: 'minor',
    // 1, 2, ♭3, 4, 5, ♭6, ♭7, 8
    intervals: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'bVII', 'VIII'],
  },

  /** Add the modes */
  ...ALL_MODES,

  harmonicminor: {
    type: 'minor',
    // 1, 2, ♭3, 4, 5, ♭6, 7, 8
    intervals: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'VII', 'VIII'],
  },

  // augmented: {
  //   type: 'major',
  //   intervals: ['I', 'II', 'bIII', 'IV', 'V', 'bVI', 'VII', 'VIII'],
  // },

  // diminished: {
  //   type: 'minor',
  //   intervals: [0, 2, 1, 2, 1, 2, 1, 2, 1],
  // },

  // blues: {
  //   type: 'minor',
  //   intervals: [0, 3, 2, 1, 1, 3, 2],
  // },
  // majorpentatonic: {
  //   type: 'major',
  //   intervals: [0, 2, 2, 3, 2, 3],
  // },
  // minorpentatonic: {
  //   type: 'minor',
  //   intervals: [0, 3, 2, 2, 3, 2],
  // },

  // /** Other scales */
  // spanish: [0, 1, 3, 1, 2, 1, 2, 2],
  // /** Japanese */
  // insen: [0, 1, 4, 2, 3, 2],
  // hirajoshi: [0, 1, 4, 2, 1, 4],
}

export type ScaleType = keyof typeof SCALES | ModeType
export const ALL_SCALES = Object.keys(SCALES) as ScaleType[]

export interface ScaleMatch {
  scale: Scale
  inScale: NoteName[]
  outOfScale: NoteName[]
  score: number
}

const equals = (a: Scale, b: Scale) => a && b && a?.root === b?.root && a?.type === b?.type

const spec = (scale: Scale): ScaleSpec => (scale.type ? SCALES[scale.type] : SCALES['major'])

const fromChords = (chords: ChordVariant[]): Scale[] => {
  const notes = chords.map(c => getChordNotes(c)).reduce((all, n) => [...all, ...n], [])
  return fromNotes(notes)
}

const isMode = (scale: Scale) => scale.type !== 'major' && scale.type !== 'minor'
const isKey = (scale: Scale) => !isMode(scale)

/**
 * Return scales where one or more notes are part of the scale.
 *
 * @param notes the notes to compare with scale(s)
 * @param scale compare to this specific scale. Otherwise match with all scales.
 * @returns Scales that have one or more notes, in sorted order (highest score first).
 */
const scaleMatch = (notes: NoteName[], scale?: Scale): ScaleMatch[] => {
  const match: ScaleMatch[] = []
  /** We don't suggest scales where the tonic is not in the set of notes */
  const scales: Scale[] = notes.map(n => ALL_SCALES.map(s => ({ root: n, type: s }))).flat()
  const compareScales = scale ? [scale] : scales

  const noteIdx = notes.map(n => ({ note: n, idx: NoteLib.getNoteChromaticIndex(n) }))

  for (const candidateScale of compareScales) {
    const scaleNotes = getNotes(candidateScale)
    const candIdx = scaleNotes.map(n => NoteLib.getNoteChromaticIndex(n))
    const outOfScale = [...noteIdx].filter(x => !candIdx.includes(x.idx))
    const inScale = [...noteIdx].filter(x => !outOfScale.includes(x))

    /** Don't create duplicates */
    if (match.find(s => equals(s.scale, candidateScale))) {
      continue
    }
    const score = inScale.length / notes.length
    match.push({
      scale: candidateScale,
      inScale: inScale.map(n => n.note),
      outOfScale: outOfScale.map(n => n.note),
      score,
    })
  }

  return match.sort((a, b) => (a.score === b.score ? 0 : a.score > b.score ? -1 : 1))
}

const scaleMatchChords = (chords: Chord[], scale?: Scale): ScaleMatch[] => {
  /** First get all the notes from the chords and make them unique */
  const notes = [...new Set(chords.map(getChordNotes).flat())]

  /** Step 1 - match against the notes */
  const matches = scaleMatch(notes, scale)

  const boosted = matches.map(m => {
    let { score } = m
    /** Step 2 - re-rank (90% for every match, 80% for modes) */
    score = score * (isMode(m.scale) ? 0.9 : 1.0)
    /** Step 3 - boost scales that have the I chord, and more if it is the first chord. Applies only to ChordVariants */
    const hasIChord = Boolean(chords.find(chord => getChordDegree(m.scale, chord) === 'I'))
    const firstChordIsIChord = getChordDegree(m.scale, chords[0]) === 'I'
    score = hasIChord ? score * (firstChordIsIChord ? 1.0 : 0.95) : score * 0.9

    return { ...m, score }
  })

  return boosted.sort((a, b) => (a.score === b.score ? 0 : a.score > b.score ? -1 : 1))
}

/** Return scales where all notes are in the scale */
const fromNotes = (scaleNotes: NoteName[]): Scale[] =>
  scaleMatch(scaleNotes)
    .filter(m => m.score === 1.0)
    .map(m => m.scale)

const getName = (scale?: Scale | null) => (scale ? `${scale.root} ${scale.type}` : '-')

/** Internal - not exposed. Use getNote() */
const getNoteWithExclude = (scale: Scale, degree: ScaleDegree, exclude?: NoteName) => {
  const scaleInfo = scale.type ? SCALES[scale.type] : SCALES['major']
  const index = getScaleIndex(degree)
  /** Scale degree maps to NoteDegree, which may have sharps / flats */
  const noteDegree = scaleInfo.intervals[index] as NoteDegree
  return NoteLib.getNote(scale.root, noteDegree, exclude)
}

const scaleNotesCache: { [scale: string]: NoteName[] } = {}

const getNotes = (scale: Scale) => {
  const scaleName = getName(scale)
  if (scaleNotesCache[scaleName]) {
    return scaleNotesCache[scaleName]
  }

  /** @todo this supports only seven-notes scales */
  const degrees: ScaleDegree[] = ['II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII']
  /** Start with 'I' */
  const notes: NoteName[] = [scale.root]
  let currentNote = scale.root
  for (const degree of degrees) {
    const nextNote = getNoteWithExclude(scale, degree, currentNote)
    notes.push(nextNote)
    currentNote = nextNote
  }
  scaleNotesCache[scaleName] = notes
  return notes
}

const getNoteByDegree = (scale: Scale, degree: ScaleDegree) => {
  const index = getScaleIndex(degree)
  return getNotes(scale)[index]
}

const getChords = (scale: Scale) => SCALE_DEGREE_NAMES.map(d => getChord(scale, d))

const getDegree = (scale: Scale, note?: NoteName | null) =>
  note ? (CHORD_DEGREES[getNotes(scale).indexOf(note)] as ScaleDegree) : null

const getChordByScaleDegrees = (scale: Scale, noteDegrees: ScaleDegree[]) => {
  /** Pick from the scale */
  const scaleNotes = getNotes(scale)
  return noteDegrees.map(degree => scaleNotes[getScaleIndex(degree)])
}

const getChord = (scale: Scale, degree: ScaleDegree): ChordVariant => {
  const { type } = spec(scale)
  if (!['major', 'minor'].includes(type)) {
    throw new Error(`chord only supports major and minor scale types`)
  }
  return {
    root: getNoteByDegree(scale, degree),
    variant: type === 'major' ? MAJOR_SCALE_DEGREE_CHORD_TYPES[degree] : MINOR_SCALE_DEGREE_CHORD_TYPES[degree],
  }
}

/**
 * Find the scale degree from the chord. The chord must match perfectly and without e.g., 7ths
 *
 * If you need to find whether a chord is inside of a scale, use @ref inScale instead
 */
const getChordDegree = (scale: Scale, ch: Chord) => {
  /** Use the tonic of the chord */
  // const chordNotes = ChordLib.getNotes(ch)

  /** @todo support ChordNotes as well? */
  if (isChordNotes(ch)) {
    return
  }

  const tonicDegree = getDegree(scale, ch.root)
  if (!tonicDegree) {
    return
  }
  const c = getChord(scale, tonicDegree)
  return chordEquals(c, ch) ? tonicDegree : undefined
}
/**
 *
 * @param scale The scale
 * @param chord The chord to evaluate.
 * @returns true if the chord is in the scale, i.e., contains only notes in the scale, false otherwise.
 */
const inScale = (scale: Scale, chord: Chord) => {
  const notes = isChordNotes(chord) ? chord.notes : getChordNotes(chord)
  const chordNotesChromatic = notes.map(n => NoteLib.getNoteChromaticIndex(n))
  const scaleChromatic = getNotes(scale).map(n => NoteLib.getNoteChromaticIndex(n))

  const difference = [...chordNotesChromatic].filter(x => !scaleChromatic.includes(x))
  return difference.length === 0
}

const noteInScale = (scale: Scale, note?: NoteName | null) => {
  const scaleChromatic = getNotes(scale).map(n => NoteLib.getNoteChromaticIndex(n))
  return note ? scaleChromatic.includes(NoteLib.getNoteChromaticIndex(note)) : false
}

/**
 * Get the correct name for a note in the scale, e.g., A# in D major will map to Bb.
 * If the note is not in the scale, it is left unchanged.
 */
const getScaleNoteName = (scale: Scale, note: NoteName) => {
  const chromatic = NoteLib.getNoteChromaticIndex(note)
  const scaleNotes = getNotes(scale)
  const scaleChromatic = scaleNotes.map(n => NoteLib.getNoteChromaticIndex(n))
  const index = scaleChromatic.indexOf(chromatic)
  return index === -1 ? note : scaleNotes[index]
}

const transpose = (scale: Scale, semiTones: number) => {
  const transposed = NoteLib.transpose(scale.root, semiTones)
  return { ...scale, root: transposed }
}

export const sortByDegree = (scale: Scale, notes: NoteName[] = []) =>
  notes.sort((a, b) => {
    const degreeA = getDegree(scale, a)
    const degreeB = getDegree(scale, b)

    return (
      (degreeA ? NoteLib.getNoteDegreeInfo(degreeA).chromaticDistance : Number.MAX_SAFE_INTEGER) -
      (degreeB ? NoteLib.getNoteDegreeInfo(degreeB).chromaticDistance : Number.MAX_SAFE_INTEGER)
    )
  })

/**
 * Get the relative major / minor scale. It's the 6th degree from major to minor, and 3nd from minor to major.
 * http://www.simplifyingtheory.com/relative-minor-major/
 */

const getRelative = (scale: Scale): Scale => {
  const degree = scale.type === 'major' ? 'VI' : 'III'
  return {
    root: getNoteByDegree(scale, degree),
    type: scale.type === 'major' ? 'minor' : 'major',
  }
}

export default {
  fromChords,
  fromNotes,
  getChord,
  getChords,
  getChordDegree,
  getChordByScaleDegrees,
  getNotes,
  getNoteByDegree,
  getScaleNoteName,
  getName,
  getDegree,
  inScale,
  noteInScale,
  getRelative,
  isMode,
  isKey,
  equals,
  scaleMatch,
  scaleMatchChords,
  sortByDegree,
  transpose,
}
