import { Box, FormLabel, capitalize } from '@mui/material'
import type { FieldValues } from 'react-hook-form'
import type { FormField, ObjectFormField } from '../../../lib/form-field.js'
import type { FormComponentGroupComponentProps } from '../form-component.js'
import { formBoxStyles } from '../sx.js'

export interface ObjectSchemaFieldProps<S extends FieldValues>
  extends FormComponentGroupComponentProps<ObjectFormField, S> {}

export function ObjectSchemaField<S extends FieldValues>(props: ObjectSchemaFieldProps<S>) {
  const { name: objectName, spec, FormControl, errors, ...restProps } = props
  const label = capitalize(spec.metadata?.label ?? objectName)

  return (
    <Box sx={formBoxStyles}>
      <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>
      {Object.entries(spec.childForm).map((e, idx) => {
        const [name, formEl] = e as [string, FormField]
        const fieldName = objectName ? `${objectName}.${name}` : name
        return (
          <FormControl
            key={name}
            autoFocus={idx === 0}
            name={fieldName}
            formField={formEl}
            errors={errors}
            {...restProps}
          />
        )
      })}
    </Box>
  )
}

export default ObjectSchemaField
