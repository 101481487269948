/** Generate a prompt from a configuration */

import { deepMerge, logger } from '@tunasong/models'
import type { CoreMessage } from 'ai'
import invariant from 'tiny-invariant'
import type { AIContext, AIContextSettings } from './ai-schema.js'
import type { AIProvider } from './providers.js'
import { getProviderByModel } from './providers.js'
import { aiSystemContextSettings } from './system-context-settings.js'

export const generatePromptSpec = ({
  context,
  userConfig,
  promptText,
}: {
  context: AIContext
  userConfig?: AIContextSettings
  promptText?: string
}): { provider: AIProvider; model: string; messages: CoreMessage[] } => {
  const globalConfig = aiSystemContextSettings.global
  const systemConfig = aiSystemContextSettings[context]

  invariant(systemConfig, `No system context settings found for context: ${context}`)

  // Merge the system settings with the user settings
  const config = deepMerge(globalConfig, systemConfig, userConfig)

  // Check that we have a specified provider and model
  invariant(config.model, 'No model specified in context settings')

  const prompt = promptText ?? config?.defaultPrompt

  if (!prompt) {
    throw new Error('No prompText or config.defaultPrompt provided - one is required')
  }
  const { systemPrompt, personality, textStyle, imageStyle } = config ?? {}

  const messages: CoreMessage[] = []

  const sysPrompt = systemPrompt ?? globalConfig.systemPrompt

  if (sysPrompt) {
    messages.push({ role: 'system', content: sysPrompt })
  }
  if (personality) {
    messages.push({ role: 'system', content: `Your personality when responding is: ${personality}` })
  }
  if (textStyle) {
    messages.push({
      role: 'system',
      content: `When responding with text content, use the following style: ${textStyle}`,
    })
  }

  if (imageStyle) {
    messages.push({
      role: 'system',
      content: `When generating image content, use the following image style: ${imageStyle}`,
    })
  }

  messages.push({ role: 'user', content: prompt })

  // Get provider from the model
  const provider = getProviderByModel(config.model)
  if (!provider) {
    throw new Error(`No provider found for model: ${config.model}`)
  }

  return { provider, model: config.model, messages }
}
