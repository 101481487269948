import {
  Box,
  capitalize,
  Hidden,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import type { ListItemButtonProps, Orientation } from '@mui/material'
import { Favorite, MoreVertIcon } from '@tunasong/icons'
import { dayjs, isFacade, isPersistedEntity } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
// import { useEntityUpdate } from '@tunasong/redux'
import { isEntity, isPersisted } from '@tunasong/schemas'
import cn from 'classnames'
import React, { useCallback, useMemo, useRef } from 'react'
import type { FC, MouseEvent } from 'react'
import { useDeviceInfo } from '../browser/device.hook.js'
import { useUIExtensions } from '../extensions.hook.js'
import { useMergeRefs } from '../hooks/merge-refs.js'
import { TunaEntityBreadcrumbs } from '../navigation/entity-breadcrumbs.js'
import { UserAvatar } from '../profile/index.js'
import { TagList } from '../tags/tag-list.js'
import { useCurrentUser } from '../user/index.js'
import EntityAvatar from './entity-avatar.js'
import { EntityIcon } from './entity-icon.js'
import { EntityListItemFacade } from './entity-list-item-facade.js'
import { useStyles } from './list-item.styles.js'
import { usePreloadOnHover } from './preload-on-hover.js'

export interface EntityListItemProps extends RenderMenuItemProps, Omit<ListItemButtonProps, 'classes'> {
  /** Mark the item as a favorite */
  favorite?: boolean
  hideSubtitle?: boolean
  orientation?: Orientation
  iconStyle?: 'avatar' | 'icon'
  showBreadcrumbs?: boolean
  ref?: React.Ref<HTMLDivElement>
  onClick?(ev: MouseEvent): void
}

export const EntityListItem: FC<EntityListItemProps> = props => {
  const {
    className,
    favorite,
    orientation = 'horizontal',
    element,
    size,
    hideSubtitle = false,
    iconStyle = 'icon',
    actions = true,
    showBreadcrumbs = false,
    onClick,
    ref,
    ...restProps
  } = props
  const { classes } = useStyles()
  const me = useCurrentUser()
  // const updateEntity = useEntityUpdate()
  const { isMobile } = useDeviceInfo()
  const plugins = usePlugins('all')

  const localRef = useRef<HTMLDivElement | null>(null)
  const mergedRef = useMergeRefs(localRef, ref)

  usePreloadOnHover({ entityId: element?.id, containerEl: localRef.current })

  const uiExtensions = useUIExtensions()

  const listItemTextClasses = useMemo(
    () => ({
      primary: cn({ [classes.primarySmall]: size === 'small', [classes.primaryTiny]: size === 'tiny' }, classes.text),
      secondary: cn(
        { [classes.secondarySmall]: size === 'small', [classes.secondaryTiny]: size === 'tiny' },
        classes.text
      ),
      padding: 0,
    }),
    [classes.primarySmall, classes.primaryTiny, classes.secondarySmall, classes.secondaryTiny, classes.text, size]
  )

  // const handleRating = useCallback(
  //   (ev: SyntheticEvent<Element, Event>, rating: number | null) => {
  //     if (!isPersisted(element)) {
  //       return
  //     }
  //     ev.preventDefault()
  //     ev.stopPropagation()
  //     updateEntity(element.id, { starred: rating && rating > 0 ? true : false })
  //   },
  //   [element, updateEntity]
  // )

  const hasPlugin = useMemo(() => plugins?.some(p => p.node?.type === element.type), [element.type, plugins])
  const disabled = props.disabled || !hasPlugin

  const label = element.name
  const hasExternalOwner = isPersisted(element) ? element.userId !== me.userId : false
  const subtitle =
    isPersistedEntity(element) && !hideSubtitle ? (
      <>
        {capitalize(element.type)}. Updated: {dayjs(element.updatedAt).fromNow()}
        {element.description ? ' - ' + element.description : ''}
        <Hidden mdDown={true}>
          <TagList tags={element.tags} size="small" />
        </Hidden>
      </>
    ) : null

  /** Actions */
  const [menuAnchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const handleActionsClose = useCallback(() => setAnchorEl(null), [])
  const handleActions = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    setAnchorEl(el => (Boolean(el) ? null : ev.currentTarget))
  }, [])

  const title = disabled ? `No plugin for ${element.type}` : undefined

  const showMenuAction = !isMobile && isPersisted(element)

  const handleContext = useCallback((ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setAnchorEl(el => (Boolean(el) ? null : ev.currentTarget))
  }, [])

  if (!(isEntity(element) && isPersisted(element))) {
    return null
  }

  return isFacade(element) ? (
    <EntityListItemFacade
      className={cn(className, classes.root)}
      element={element}
      {...restProps}
      ref={mergedRef}
      /** Override disabled if we have no plugin here */
      disabled={disabled}
    />
  ) : (
    <ListItemButton
      ref={mergedRef as React.Ref<HTMLDivElement>}
      tabIndex={0}
      className={className}
      classes={{
        root: classes.root,
      }}
      disabled={disabled}
      sx={{
        cursor: disabled ? 'not-allowed !important' : 'pointer',
        '&.MuiListItemButton-root': {
          flexDirection: orientation === 'vertical' ? 'column' : 'row',
          flexGrow: orientation === 'vertical' ? 0 : 1,
        },
      }}
      title={title}
      aria-orientation={orientation}
      onClick={disabled ? undefined : onClick}
      onContextMenu={handleContext}
      // We disable ripple to allow DND previews - @see https://github.com/react-dnd/react-dnd/issues/832, otherwise the preview will contain more than the item
      disableRipple={true}
      {...restProps}
    >
      {iconStyle === 'avatar' ? (
        <ListItemAvatar className={cn({ [classes.avatarTiny]: size === 'tiny' })}>
          <EntityAvatar className={classes.avatar} type={element.type} size={size} />
        </ListItemAvatar>
      ) : null}
      {iconStyle === 'icon' ? (
        <ListItemIcon
          className={cn({ [classes.avatarTiny]: size === 'tiny' })}
          sx={{
            justifyContent: orientation === 'vertical' ? 'center' : undefined,
          }}
        >
          <EntityIcon entity={element} />
        </ListItemIcon>
      ) : null}

      <ListItemText
        classes={listItemTextClasses}
        sx={{
          paddingRight: orientation === 'vertical' ? 0 : hasExternalOwner ? 12 : 8,
        }}
        primary={
          <>
            {label}
            {showBreadcrumbs ? <TunaEntityBreadcrumbs entity={element} showHome={false} /> : null}
          </>
        }
        secondary={subtitle}
        secondaryTypographyProps={{
          component: Box,
        }}
      />
      <ListItemSecondaryAction
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          transition: 'opacity 0.3s',
          opacity: 0,
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        {favorite ? <Favorite color="error" titleAccess="You have this as a favorite" /> : null}
        {actions && !disabled ? (
          <>
            {/* {!element.trash && (
              <>
                <Rating
                  sx={{ px: 1 }}
                  name={element.id}
                  value={element.starred ? 1 : 0}
                  max={5}
                  onChange={handleRating}
                />
              </>
            )} */}
            {hasExternalOwner && <UserAvatar userId={element.userId} size="tiny" />}
            {showMenuAction ? (
              <>
                <IconButton tabIndex={-1} color="inherit" aria-label="settings" onClick={handleActions}>
                  <MoreVertIcon />
                </IconButton>
              </>
            ) : null}
            {/* Expensive component so we run it on demand */}
            {menuAnchorEl && uiExtensions.entityList?.ItemMenu ? (
              <uiExtensions.entityList.ItemMenu
                entity={element}
                // clickAway will close the context menu on mobile
                clickAway={!isMobile}
                anchorEl={menuAnchorEl}
                onClose={handleActionsClose}
              />
            ) : null}
          </>
        ) : null}
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}
