import type { DSPFeatureName, DSPFeatures, DSPFeaturesMessage, DSPSignalMessage } from '@tunasong/audio-dsp'
// import dspWorklet from '@tunasong/audio-dsp/dsp.worklet?url&worker'
import DSPWorker from '@tunasong/audio-dsp/dsp.worker?worker'
import { logger } from '@tunasong/models'
import type { IAnalyserNode, IAudioContext } from 'standardized-audio-context'
import { AudioWorkletNode } from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import type { IAudioController } from './interfaces.js'

export class DSPController {
  private dspNode: AudioWorkletNode<IAudioContext> | null = null
  private dspEnableFeature: Record<DSPFeatureName, boolean> = {
    chroma: false,
    chord: false,
    rms: false,
    tuner: false,
    fft: false,
  }
  // private workletModule: Promise<void>
  private audioDSPWorker: Worker
  private signal: Float32Array
  private spectrum: Float32Array
  private analyser: IAnalyserNode<IAudioContext>
  private animationFrame: number | null = null
  private currentIteration = 0
  private context: IAudioContext
  private source: IAudioController
  private onFeatures: (features: DSPFeatures) => void

  constructor(params: {
    context: IAudioContext
    source: IAudioController
    // FFT Size, defaults to 4096. Must be power of 2
    fftSize?: number
    // FFT Smoothing Time Constant, defaults to 0.8
    fftSmoothingTimeConstant?: number
    onFeatures: (features: DSPFeatures) => void
  }) {
    const { context, source, onFeatures, fftSize = 4096, fftSmoothingTimeConstant = 0.8 } = params
    invariant(context.audioWorklet, 'Audio Context does not support audio worklets')
    invariant(source, 'Source Audio Controller must be specified')
    invariant(onFeatures, 'onFeatures callback must be specified')

    this.context = context
    this.source = source
    this.onFeatures = onFeatures

    this.audioDSPWorker = new DSPWorker()

    this.audioDSPWorker.onmessage = this.handleDSPMessage

    this.analyser = context.createAnalyser()
    // Configure analyser
    this.analyser.fftSize = fftSize // Must be power of 2
    /** @see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/smoothingTimeConstant */
    this.analyser.smoothingTimeConstant = fftSmoothingTimeConstant

    // Create buffer for FFT data
    this.spectrum = new Float32Array(this.analyser.frequencyBinCount)
    this.signal = new Float32Array(this.analyser.fftSize)

    // Connect the analyser
    this.source.connect(this.analyser)
  }

  get hasEnabledFeatures() {
    return Object.values(this.dspEnableFeature).some(v => v)
  }

  // Set up periodic FFT sampling

  // Sample each 10 animation frames
  sendFFTToProcessor = () => {
    if (!this.hasEnabledFeatures) {
      return
    }

    this.currentIteration++
    // Run FFT every 10 frames
    if (this.currentIteration % 10 === 0) {
      // Get current frequency data
      this.analyser.getFloatTimeDomainData(this.signal)
      this.analyser.getFloatFrequencyData(this.spectrum)

      // Send to processor
      const msg: DSPSignalMessage = {
        type: 'dspsignal',
        data: {
          sampleRate: this.context.sampleRate,
          signal: this.signal,
          ampSpectrum: this.spectrum,
          features: this.dspEnableFeature,
        },
      }
      // logger.debug('Sending FFT to processor', {
      //   features: this.dspEnableFeature,
      //   iteration: this.currentIteration,
      //   msg,
      // })
      this.audioDSPWorker.postMessage(msg)
    }

    // Schedule next update
    this.animationFrame = requestAnimationFrame(this.sendFFTToProcessor)
  }

  cancelAnalysis = () => {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame)
      this.animationFrame = null
    }
  }

  disconnect = () => {
    this.cancelAnalysis()
    this.audioDSPWorker.terminate()
    this.dspNode?.disconnect()
    this.dspNode = null
  }

  enableFeature = async (feature: DSPFeatureName, enabled: boolean) => {
    /** Ensure the module is loaded first */
    invariant(this.dspEnableFeature, 'DSP worklet module not loaded properly')

    this.dspEnableFeature[feature] = enabled

    logger.debug('DSP Feature enabled', feature, enabled, this.hasEnabledFeatures)
    if (this.hasEnabledFeatures) {
      // This will schedule each animation frame
      this.cancelAnalysis()
      this.sendFFTToProcessor()
    }
  }

  private handleDSPMessage = (e: { data: DSPFeaturesMessage }) => {
    if (e.data.eventType !== 'audiofeatures') {
      return
    }
    const { features } = e.data

    /** Send to listener */
    this.onFeatures(features)
  }
}
