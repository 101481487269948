import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
/** for swiped events */
import 'swiped-events'
import { useDelayedInvoke } from '../hooks/delayed-invoke.js'
import Pulse from '../progress/pulse.js'
import { logger } from '@tunasong/models'

/**
 * support for swipes - @see https://github.com/john-doherty/swiped-events
 */

export interface ScrollContainerProps extends BoxProps {
  className?: string
  children?: React.ReactNode
  topEl?: HTMLElement | null
  onUp?: () => Promise<void>
  onDown?: () => Promise<void>
}

export const ScrollContainer: React.FC<ScrollContainerProps> = props => {
  const { children, topEl, onUp, onDown, className, ...restProps } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [busy, setBusy] = useState<'up' | 'down' | null>(null)
  const lastDirection = useRef<'up' | 'down' | null>(null)
  const debounce = useDelayedInvoke(50, 'idle')

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const el = ref.current

    /** Don't trigger multiple times */
    let triggeredUp = false
    let triggeredDown = false

    const getContainerPos = () => {
      /** Check if the top of the container is visible */
      const topOfContainer = el.scrollTop === 0
      /** Check if the bottom of the container is visible */
      const bottomOfContainer = el.scrollTop + el.clientHeight + 1 >= el.scrollHeight
      return { topOfContainer, bottomOfContainer }
    }

    const handleCommonEv = (e: Event, direction: 'up' | 'down') => {
      const { topOfContainer, bottomOfContainer } = getContainerPos()
      const loadUp = topOfContainer && direction === 'up'
      const loadDown = bottomOfContainer && direction === 'down'

      if (!(loadUp || loadDown)) {
        triggeredUp = false
        triggeredDown = false
      }

      if (onUp && loadUp && !triggeredUp) {
        lastDirection.current = 'up'
        debounce(async () => {
          setBusy('up')
          onUp().finally(() => setBusy(null))
        })
        triggeredUp = true
      }
      if (onDown && loadDown && !triggeredDown) {
        lastDirection.current = 'down'
        debounce(async () => {
          setBusy('down')
          onDown().finally(() => setBusy(null))
        })

        triggeredDown = true
      }
    }

    const handleWheel = (e: Event) => {
      invariant(e instanceof WheelEvent, 'Event is not a wheel event')

      /** Check for the direction of the scroll event */
      const direction = e.deltaY > 0 ? 'down' : 'up'
      handleCommonEv(e, direction)
    }

    /**
     * @see https://www.npmjs.com/package/swiped-events for swipe events
     *
     * To emulate swipe events, @see https://www.frontify.com/en/blog/how-to-emulate-touch-events-in-chrome/
     */
    const handleSwipeEvent = (e: Event) => {
      invariant(e instanceof CustomEvent, 'Event is not a custom event')
      const direction = e.detail.dir
      /** A "swipe up" is "down" for scroll, and vice versa */
      const scrollDirection = direction === 'up' ? 'down' : 'up'
      handleCommonEv(e, scrollDirection)
    }

    /** Debounce the wheel event */
    let timeout: number | NodeJS.Timeout
    const handleWheelEvent = (e: Event) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        handleWheel(e)
      }, 50)
    }

    el.addEventListener('wheel', handleWheelEvent, { passive: true })
    el.addEventListener('swiped', handleSwipeEvent, { passive: true })
    return () => {
      el.removeEventListener('wheel', handleWheelEvent)
      el.removeEventListener('swiped', handleSwipeEvent)
    }
  }, [debounce, onDown, onUp, ref])

  const lastTopElRef = useRef<HTMLElement | null>(topEl ?? null)
  // Keep the top element in view when scrolling up
  useEffect(() => {
    logger.debug('Scroll effect', {
      topEl,
      lastDirection: lastDirection.current,
      lastTopEl: lastTopElRef.current,
    })
    if (topEl !== lastTopElRef.current && lastDirection.current === 'up') {
      lastTopElRef.current?.scrollIntoView({ block: 'start' })
    }
    lastTopElRef.current = topEl ?? null
  }, [topEl])

  return (
    <Box className={className} ref={ref} sx={{ overflow: 'auto', flex: 1, ...restProps.sx }} {...restProps}>
      {busy === 'up' ? <Pulse /> : null}
      {children}
      {busy === 'down' ? <Pulse /> : null}
    </Box>
  )
}
