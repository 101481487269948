import { ListItemButton, MenuList } from '@mui/material'
import type { MenuListProps } from '@mui/material'
import type { Persisted } from '@tunasong/schemas'
import type { Entity } from '@tunasong/schemas'
import React, { useCallback } from 'react'
import { useFavorites } from '../favorites/favorites.hook.js'
import type { NavigationOptions } from '../navigation/navigate.js'
import { EntityListItem } from './entity-list-item.js'

export interface EntityListProps extends MenuListProps {
  className?: string
  entities: Persisted<Entity>[]
  selected?: Persisted<Entity>[]
  controls?: boolean
  size?: 'tiny' | 'small' | 'large'
  placeholder?: string
  actions?: boolean
  showBreadcrumbs?: boolean

  onOpen?(entity: Persisted<Entity>, options: NavigationOptions): void
}

export const EntityList = (props: EntityListProps) => {
  const {
    className,
    ref,
    entities,
    actions,
    selected = [],
    size = 'small',
    showBreadcrumbs = false,
    onOpen,
    ...restProps
  } = props

  const { favorites } = useFavorites()

  const handleOpen = useCallback(
    (entity: Persisted<Entity>) => (ev: React.MouseEvent<HTMLLIElement>) => {
      if (!onOpen) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
      const navigationMode = ev.shiftKey ? 'dialog' : 'page'
      onOpen(entity, { navigationMode })
    },
    [onOpen]
  )

  const items = entities.filter(e => !e.trash)

  return (
    <MenuList ref={ref} className={className} component={'div'} {...restProps}>
      {items.length === 0 ? (
        <ListItemButton disabled>{restProps.placeholder}</ListItemButton>
      ) : (
        entities
          .filter(e => !e.trash)
          .map((element, index) => {
            const favorite = favorites.some(f => f.id === element.id)
            return (
              <EntityListItem
                key={index}
                size={size}
                favorite={favorite}
                actions={actions}
                selected={selected.includes(element)}
                element={element}
                showBreadcrumbs={showBreadcrumbs}
                onClick={handleOpen(element)}
              />
            )
          })
      )}
    </MenuList>
  )
}
