import { useTheme } from '@mui/material'
import { isLoopEvent } from '@tunasong/schemas'
import type { LoopEvent } from '@tunasong/schemas'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { RendererPlugin } from '../renderer.js'
import useStyles from './styles.js'

/** Render a loop marker */
const Loop: RendererPlugin = ({ laneHeight, event, pixelsPerSecond, onUpdateEvent }) => {
  const { classes } = useStyles()
  const theme = useTheme()

  const [loop, setLoop] = useState<LoopEvent>()

  const [isDragging, setIsDragging] = useState(false)
  const mode = useRef<'start' | 'end' | 'move'>(undefined)

  const handleBeginMove = useCallback((ev: React.SyntheticEvent) => {
    ev.stopPropagation()
    mode.current = 'move'
    setIsDragging(true)
  }, [])

  const handleBeginStart = useCallback((ev: React.SyntheticEvent) => {
    ev.stopPropagation()
    mode.current = 'start'
    setIsDragging(true)
  }, [])

  const handleBeginEnd = useCallback((ev: React.SyntheticEvent) => {
    ev.stopPropagation()
    mode.current = 'end'
    setIsDragging(true)
  }, [])

  const handleMouseUp = useCallback(
    (ev: MouseEvent) => {
      if (!isDragging) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
      setIsDragging(false)
      if (loop && onUpdateEvent) {
        onUpdateEvent(loop)
      }
    },
    [isDragging, loop, onUpdateEvent]
  )

  const handleMouseMove = useCallback(
    (ev: MouseEvent) => {
      const curMode = mode.current
      if (!(isDragging && curMode)) {
        return
      }
      const deltaSecs = ev.movementX / pixelsPerSecond
      setLoop(loop => {
        if (!loop) {
          return
        }
        const start = ['start', 'move'].includes(curMode) ? Math.max(loop.start + deltaSecs, 0) : loop?.start
        const end = ['end', 'move'].includes(curMode) ? Math.max(loop.end + deltaSecs) : loop?.end
        return {
          ...loop,
          start,
          end,
        }
      })
    },
    [isDragging, pixelsPerSecond]
  )
  useEffect(() => {
    /** These change, and we need to remove the same ones */
    if (!isDragging) {
      return
    }
    document.addEventListener('click', handleMouseUp)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('click', handleMouseUp)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove, handleMouseUp, isDragging])

  useEffect(() => {
    if (!isLoopEvent(event)) {
      return
    }
    setLoop(event)
  }, [event])

  if (!loop) {
    return null
  }

  const x1 = loop.start * pixelsPerSecond
  const x2 = loop.end * pixelsPerSecond
  const width = x2 - x1
  const y = 0
  const height = laneHeight / 2.5
  const fill = isDragging ? theme.palette.secondary.main : theme.palette.grey[500]
  const handleWidth = 16

  const startPoly = `${x1},${y} ${x1},${laneHeight} ${x1 + handleWidth},${y}`
  const endPoly = `${x2},${y} ${x2},${laneHeight} ${x2 - handleWidth},${y}`

  return (
    <g className={classes.loop}>
      <title>Loop {event.name}</title>
      <polygon className={classes.loopHandle} points={startPoly} fill={fill} onMouseDown={handleBeginStart} />
      <rect
        className={classes.loop}
        x={x1}
        y={y}
        width={width}
        height={height}
        fill={fill}
        onMouseDown={handleBeginMove}
      />
      <polygon className={classes.loopHandle} points={endPoly} fill={fill} onMouseDown={handleBeginEnd} />
    </g>
  )
}

export default Loop
