import { hasParent, includeElement } from '@tunasong/models'
import type { FilterFunc } from '@tunasong/models'
import type { ElementType, Entity, Persisted } from '@tunasong/schemas'
import { entitiesApi } from '../api/entities.js'

export interface EntitiesParams {
  /** Use only root entities, i.e., entities that have no parents */
  root?: boolean
  /** Load only partial */
  partial?: boolean
  showTrash?: boolean
  filter?: ElementType | FilterFunc
  /** Load owned entities */
  owned?: boolean
  // polling interval (in ms)
  pollingInterval?: number
}

/** Load entity, partially loading children if those have not been loaded */
export const useEntities = <T extends Entity = Entity>({
  root = true,
  filter,
  showTrash = false,
  owned = true,
  pollingInterval,
}: EntitiesParams = {}) => {
  /** We use the hook to load the data only, and work from the entities store. The API will sync.  */
  const { currentData: entities, isLoading } = entitiesApi.useLoadAllEntitiesQuery(
    {},
    { skip: !owned, pollingInterval }
  )

  const allEntities = (entities ?? [])
    .filter(e => includeElement(e, filter))
    .filter(e => (root ? !hasParent(e) : true))
    .filter(e => (showTrash ? true : Boolean(!e.trash)))
    /** Unique */
    .filter((v, i, a) => a.findIndex(v2 => v.id === v2.id) === i)

  return { entities: allEntities as Persisted<T>[], isLoading }
}
