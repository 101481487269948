import { LinearProgress } from '@mui/material'
import type { AIConfig as AIConfigType } from '@tunasong/ai-lib'
import { aiConfigSchema } from '@tunasong/ai-lib'
import { AIConfigForm } from '@tunasong/ai-lib/react'
import { useUserConfig } from '@tunasong/ui-lib'
import type { FC } from 'react'

export const AIConfig: FC = () => {
  const [userConfig, updateUserConfig, hasLoaded] = useUserConfig()

  const handleUpdateAIConfig = (aiConfig: AIConfigType) => updateUserConfig('ai', aiConfig)

  const aiConfigResult = aiConfigSchema.safeParse(userConfig.ai)

  return hasLoaded ? (
    <AIConfigForm defaultValue={aiConfigResult.data} onChange={handleUpdateAIConfig} />
  ) : (
    <LinearProgress />
  )
}

export default AIConfig
