import { LinearProgress } from '@mui/material'
import { Pool as PoolIcon } from '@tunasong/icons'
import type { FilterFunc } from '@tunasong/models'
import { getAcceptedMimeTypes, usePlugins } from '@tunasong/plugin-lib'
import { useChildren } from '@tunasong/redux'
import type { Entity, Persisted } from '@tunasong/schemas'
import { isMedia } from '@tunasong/schemas'
import cn from 'classnames'
import { useState } from 'react'
import type { FC } from 'react'
import { DialogTitle } from '../dialog/index.js'
import { EntityList } from '../entity/index.js'
import { useResponsiveContainer } from '../layout/index.js'
import { UploadStoredEntity } from '../media/index.js'
import type { NavigationOptions } from '../navigation/navigate.js'
import { makeStyles } from '../styles.js'
import { useUploadStoredEntity } from '../upload/index.js'

const useStyles = makeStyles()(() => ({
  root: {
    flex: 1,
  },
}))

export interface PoolProps {
  className?: string
  entity?: Persisted<Entity>
  onOpen?: (entity: Persisted<Entity>, options: NavigationOptions) => void
  filter?: FilterFunc
  onUploaded?<T extends Entity>(entity: T): void
  onClose?(): void
}

export const Pool: FC<PoolProps> = props => {
  const { className, entity, filter = isMedia, onClose, onOpen, onUploaded } = props
  const { classes } = useStyles()
  const { entities: childEntities } = useChildren({ parentId: entity?.id, filter })
  const { isUploading } = useUploadStoredEntity()
  const plugins = usePlugins('all')

  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  /** Need to return something here, or set classes */
  const size = useResponsiveContainer(ref, {
    tiny: 150,
    small: 250,
    large: 500,
  })

  const mimeTypes = getAcceptedMimeTypes(plugins)

  if (!entity) {
    return null
  }

  return (
    <div className={cn(className, classes.root)} ref={setRef}>
      {onClose && (
        <DialogTitle onClose={onClose} controls={<PoolIcon />}>
          Media Pool
        </DialogTitle>
      )}
      <EntityList entities={childEntities} onOpen={onOpen} actions={false} size={size} controls={false} />
      {isUploading ? <LinearProgress /> : null}
      <UploadStoredEntity parentId={entity.id} onUploaded={onUploaded} accept={mimeTypes} />
    </div>
  )
}

export default Pool
