import { isSection } from '@tunasong/models'
import type { Scale } from '@tunasong/schemas'
import { useEditor } from '@tunasong/plugin-lib'
import type { TunaEditor } from '@tunasong/plugin-lib'
import { isSong } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'
import { useEffect, useState } from 'react'
import { Location, Node, Path, Range } from 'slate'
import { ReactEditor } from 'slate-react'

/** Detect the Scale from the editor context.  */
export const useScalePath = (editor: TunaEditor, location?: Location | null) => {
  /** @todo Typescript requires a cast here because scale for some reason is unknown */
  const rootScale = isSong(editor.rootElement) ? (editor.rootElement.scale as Scale) : null
  const [scale, setScale] = useState<Scale | null | undefined>(rootScale)

  useEffect(() => {
    if (!location) {
      return
    }

    let scale = rootScale
    try {
      const path = Path.isPath(location) ? location : Range.isRange(location) ? location.anchor.path : location.path
      const parents = Node.ancestors(editor as never, path)
      for (const [parent] of parents) {
        if (isSection(parent) && parent.scale) {
          scale = parent.scale
          break
        }
      }
    } catch {}
    setScale(scale)
  }, [editor, rootScale, location])
  return scale
}

/** @deprecated use useScalePath instead */
export const useScale = (element?: CoreElement, sourcePath?: Path | null) => {
  const editor = useEditor()

  const [path, setPath] = useState<Path | undefined | null>(sourcePath)
  const scale = useScalePath(editor, path)

  useEffect(() => {
    if (!element || sourcePath) {
      return
    }
    const path = ReactEditor.findPath(editor as unknown as ReactEditor, element)
    setPath(path)
  }, [editor, element, sourcePath])

  return scale ?? null
}
