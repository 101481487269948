/** Context to register the available editors */

import type { Entity } from '@tunasong/schemas'
import type { TunaEditor, TunaPlugin } from '@tunasong/plugin-lib'
import React from 'react'
import type { FC } from 'react'
import type { ElementEditorProps } from './element-editor.js'
import type { EditorType } from '../editor-type.js'

export interface EditorContextProps extends Omit<ElementEditorProps, 'element' | 'editor'> {
  element: Entity
  plugins?: TunaPlugin[]
  onEditor?(editor: TunaEditor): void
}

export type EditorContextType = Partial<Record<EditorType, FC<EditorContextProps>>>

export const EditorsContext = React.createContext<EditorContextType | null>(null)
