import { useEffect, useRef } from 'react'

export type ScrollState = 'up' | 'down'

export const useScrollState = ({ onScrollChange }: { onScrollChange?: (scrollState: ScrollState) => void }) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const lastScrollTop = useRef(0)

  const scrollState = useRef<ScrollState>(null)

  useEffect(() => {
    const rootEl = scrollRef.current

    if (!rootEl) {
      return
    }
    const scrollHandler = () => {
      const currentScrollTop = rootEl.scrollTop

      const newScrollState = currentScrollTop > lastScrollTop.current ? 'down' : 'up'

      if (newScrollState !== scrollState.current) {
        if (onScrollChange) {
          onScrollChange(newScrollState)
        }
      }

      scrollState.current = newScrollState
      lastScrollTop.current = currentScrollTop
    }

    rootEl.addEventListener('scroll', scrollHandler)
    return () => {
      rootEl.removeEventListener('scroll', scrollHandler)
    }
  })
  return { scrollRef, scrollState }
}
