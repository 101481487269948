import type { CoreElement } from '@tunasong/schemas'
import { isCoreElement } from '@tunasong/schemas'

/** These are from Emoji-mart https://github.com/missive/emoji-mart */
export interface EmojiMartEmoji {
  id: string
  keywords: string[]
  name: string
  native: string
  shortcodes: string
  unified: string
}

export interface EmojiMartSearchResult {
  id: string
  keywords: string[]
  aliases: string[]
  name: string
  search: string

  skins: {
    native: string
    shortcodes: string
    unified: string
  }[]
  version: number
}

export interface Emoji extends CoreElement {
  type: 'emoji'
  /** Emoji Mart ID */
  emojiMartId: string
  /** The native character */
  native: string
}

export function isEmoji(element: unknown): element is Emoji {
  return Boolean(isCoreElement(element) && element.type === 'emoji' && 'native' in element)
}
