import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { AudioPosition } from '@tunasong/models'
import type { Audio, Media, Persisted, Video } from '@tunasong/schemas'
import type { FC } from 'react'
import { useState } from 'react'
import { PlayerControls } from './player-controls.js'

export interface CompactPlayerProps extends BoxProps {
  name?: string
  active?: Persisted<Audio | Video>
  entities: Persisted<Media>[]
  /** Capture keyboard events to control the player */
  keyboardControl?: boolean
  /** Open the media with the given index */
  onActivate(idx: number): void
}

export const CompactPlayer: FC<CompactPlayerProps> = props => {
  const { entities, active, name, keyboardControl = false, onActivate, ...restProps } = props

  const [ref, setRef] = useState<HTMLAudioElement | null>(null)

  const handleSeek = (pos: AudioPosition) => {
    if (!ref) {
      return
    }
    // eslint-disable-next-line react-compiler/react-compiler
    ref.currentTime = pos.seconds
  }

  const src = active?.storageUrls?.url

  return (
    <Box {...restProps}>
      <audio crossOrigin="anonymous" ref={setRef} controls={false} src={src} />
      <Box flex={1} />

      <PlayerControls
        sx={{ width: '100%' }}
        name={name ?? active?.name}
        el={ref}
        media={entities}
        active={active}
        keyboardControl={keyboardControl}
        onSeek={handleSeek}
        onSelect={onActivate}
      />
    </Box>
  )
}

export default CompactPlayer
