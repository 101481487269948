import Add from '@mui/icons-material/Add'
import { Box, IconButton, Typography } from '@mui/material'
import type { TypographyProps } from '@mui/material'
import classNames from 'classnames'
import type { FC, MouseEvent } from 'react'

export interface ActionHeaderProps extends TypographyProps {
  className?: string
  title: string
  onAction(event: MouseEvent): void
}

export const ActionHeader: FC<ActionHeaderProps> = props => {
  const { className, title, onAction, children, variant = 'subtitle1', ...restProps } = props

  return (
    <Box className={classNames(className)} display="flex" justifyContent="space-between" alignItems="center">
      <Typography display="inline" variant={variant} {...restProps}>
        {title}
      </Typography>
      {children}
      <IconButton onClick={onAction}>
        <Add />
      </IconButton>
    </Box>
  )
}
