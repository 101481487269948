import { Box, Button, ButtonGroup } from '@mui/material'
import type { SxProps, Theme } from '@mui/material'
import { EntityEditor } from '@tunasong/editor'
import type { EditorContextProps } from '@tunasong/editor'
import { Delete, Performance } from '@tunasong/icons'
import { isNote } from '@tunasong/models'
import type { Note, NoteType } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import { useEntityUpdate, useTrashEntity } from '@tunasong/redux'
import { isPersisted } from '@tunasong/schemas'
import { ConfirmDialog } from '@tunasong/ui-lib'
import { useCallback, useState } from 'react'

export interface NoteEditorProps extends EditorContextProps {
  controls?: boolean
}

const iconSx: SxProps<Theme> = {
  color: theme => theme.palette.action.focus,
  '& :hover': {
    color: 'action.active',
    cursor: 'pointer',
  },
} as const

export const NoteEditor = (props: NoteEditorProps) => {
  const { element, controls = false, ...restProps } = props
  const [showDelete, setShowDelete] = useState(false)
  const notePlugins = usePlugins('note')
  // Use upsert to create notes when they don't exist
  const updateEntity = useEntityUpdate<Note>({ debounceDelay: 1 * 1000, upsert: true })

  const handleType = useCallback(
    (noteType: NoteType) => () => {
      if (!isPersisted(element)) {
        return
      }
      updateEntity(element.id, { noteType })
    },
    [element, updateEntity]
  )

  const trash = useTrashEntity(element as never)
  const handleDelete = useCallback(() => {
    if (!(isNote(element) && isPersisted(element))) {
      return
    }
    setShowDelete(false)
    trash()
  }, [element, trash])

  const handleShowDelete = useCallback(() => setShowDelete(true), [])
  const handleHideDelete = useCallback(() => setShowDelete(false), [])

  if (!(isNote(element) && notePlugins)) {
    return null
  }

  const editor = (
    <EntityEditor<Note> {...restProps} element={element} upsert={true} plugins={notePlugins} gutter={false} />
  )

  const { noteType } = element

  return !controls ? (
    editor
  ) : (
    <Box flex={1} flexDirection="column">
      <ButtonGroup variant="text" size="small" aria-label="Note Type">
        <Button title="Performance Note" onClick={handleType('performance')}>
          <Performance sx={{ ...iconSx, color: noteType === 'performance' ? 'primary.main' : 'action.focus' }} />
        </Button>
        <Button title="Delete Note  " onClick={handleShowDelete}>
          <Delete sx={iconSx} />
        </Button>
      </ButtonGroup>
      <Box mt={2} />
      {editor}
      <ConfirmDialog
        open={showDelete}
        title="Delete note"
        text={`Really delete ${element.noteType} note?`}
        onCancel={handleHideDelete}
        onConfirm={handleDelete}
      />
    </Box>
  )
}

export default NoteEditor
