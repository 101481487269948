import { standardNoteNames } from '@tunasong/schemas'
import type { NoteName } from '@tunasong/schemas'
import {
  getNoteChromaticIndex,
  getNoteDegreeInfo,
  getNotesByChromaticIndex,
  isNoteDegreeAugmented,
  isNoteDegreeMinor,
  isNoteDiminished,
} from './note-lib.js'
import type { NoteDegree } from './note-lib.js'

export const getNotes = (key: NoteName, degree: NoteDegree) => {
  const info = getNoteDegreeInfo(degree)
  if (!info) {
    throw new Error(`Bah! ${key}, ${degree}, ${info}`)
  }
  const { chromaticDistance } = info
  const rootIndex = getNoteChromaticIndex(key)
  const targetIndex = (rootIndex + chromaticDistance + 12) % 12
  // deepcode ignore ExpectsString: false positive
  return getNotesByChromaticIndex(targetIndex)
}

/** Get the note for the specified NoteDegree in key */
export const getNote = (key: NoteName, degree: NoteDegree, exclude?: NoteName): NoteName => {
  if (degree === 'I') {
    return key
  }

  /* Filter out excluded prefix note names, of specified */
  // deepcode ignore ArrayMethodOnNonArray: false positive
  const notes = getNotes(key, degree).filter(n => (exclude ? !n.startsWith(exclude[0]) : true))

  /** Now pick the best note name based on the NoteDegree */
  if (notes.length > 1 && isNoteDegreeMinor(degree)) {
    return notes.filter(n => n.endsWith('b') || !n.endsWith('#'))[0]
  }
  if (notes.length > 1 && isNoteDegreeAugmented(degree)) {
    return notes.filter(n => n.endsWith('#') || !n.endsWith('b'))[0]
  }

  return notes[0]
}

/** @todo This should probably be moved to Scale - since this is the major scale */
export const getNotesForKey = (key: NoteName = 'C') => {
  /** We always start with the key - i.e., 'I' */
  const degrees = ['II', 'III', 'IV', 'V', 'VI', 'VII'] as NoteDegree[]
  const notes: NoteName[] = [key]
  for (const [idx, d] of degrees.entries()) {
    const prevNote = notes[idx]
    /** @todo support diminished - we ignore those for now */
    // deepcode ignore ArrayMethodOnNonArray: false positive
    const altNotes = getNotes(key, d).filter(n => !isNoteDiminished(n))
    if (altNotes.length === 1) {
      notes.push(altNotes[0])
      continue
    }
    notes.push(altNotes.filter(n => !n.startsWith(prevNote[0]))[0])
  }
  return notes
}

/** Note generation tools */
export const getRandomNote = (): NoteName => {
  const idx = Math.floor(Math.random() * 12)
  return standardNoteNames[idx]
}
