export interface CreateSongImagePromptProps {
  /** Title of song */
  title: string
  artistName?: string
  /** The full lyrics, or a snippet of the lyrics, e.g., the last verse */
  lyrics: string
}

export default ({ title, artistName = 'Unknown', lyrics }: CreateSongImagePromptProps) => {
  const prompt = `Describe an image that represents and illustrates the song ${title}${
    artistName ? ' by ' + artistName : ''
  }. Don't include any of the lyrics in the answer. Be short, maximum 50 words. The lyrics of the song are:

${lyrics}
`
  return prompt
}
