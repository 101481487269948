import type { BlockElement, FilterFunc, Line } from '@tunasong/models'
import { isBlock, isLine, logger } from '@tunasong/models'
import type { CoreElement } from '@tunasong/schemas'
import { isCoreElement } from '@tunasong/schemas'
import type { InsertNodeOperation, NodeEntry, Point, Span } from 'slate'
import { Element, Node, Operation, Path, Range, Text } from 'slate'
import { ReactEditor } from 'slate-react'
import { ElementApi } from '../plate-exports.js'
import type { TunaEditor } from '../plugin-types.js'
import { Editor, Transforms } from '../slate-typescript.js'

const currentBlockNode = (editor: TunaEditor, match: FilterFunc = isBlock): NodeEntry<BlockElement> | null => {
  const path = editor.selection?.focus.path
  if (!path) {
    return editor.cache?.currentBlockPath
      ? (nodeAt(editor, editor.cache.currentBlockPath) as NodeEntry<BlockElement>)
      : null
  }
  const [currentElement] = Editor.node(editor, path)
  const nodeEntry = match(currentElement) ? [currentElement, path] : Editor.above(editor, { match, at: path })
  editor.cache = editor.cache ?? {}
  editor.cache.currentBlockPath = (nodeEntry?.[1] as Path) ?? null
  return nodeEntry as NodeEntry<BlockElement>
}

const nodeAt = (editor: TunaEditor, path?: Path | null) => (path ? Editor.node(editor, path) : null)
const nodeAtFocus = (editor: TunaEditor) => nodeAt(editor, editor.selection?.focus.path)
const nodeAtAnchor = (editor: TunaEditor) => nodeAt(editor, editor.selection?.anchor.path)
const currentBlock = (editor: TunaEditor, match: FilterFunc = isBlock) => currentBlockNode(editor, match)?.[0]
const currentElement = (editor: TunaEditor) => currentBlockNode(editor, ElementApi.isElement)?.[0]

/** Return the pixel position of the current block */
const getClientPixelPosition = (editor: TunaEditor, node: Node): number => {
  const el = ReactEditor.toDOMNode(editor as unknown as ReactEditor, node)
  return el.getBoundingClientRect().left
}

/** Return the relative pixel offsets of the children */
const getChildrenPixelOffsets = (editor: TunaEditor, block = currentBlock(editor)): number[] => {
  if (!block) {
    return []
  }
  const blockLeft = getClientPixelPosition(editor, block)
  return block.children.map(c => getClientPixelPosition(editor, c) - blockLeft)
}

const beginningOfEmptyBlock = (editor: TunaEditor, match: FilterFunc = () => true): boolean => {
  const { selection } = editor
  const currentElement = currentBlock(editor, match)
  return Boolean(currentElement && selection && Range.isCollapsed(selection) && selection.anchor.offset === 0)
}

/** Check if we have children match without the need to handle an iterator */
const hasChildren = (editor: TunaEditor, at: Range | Point | Path | Span | undefined, match: FilterFunc = isBlock) => {
  const childChords = Editor.nodes(editor, { at, match }).next()
  return Boolean(!childChords.done)
}

const lastInsertedElementPath = (editor: TunaEditor, match: FilterFunc = isCoreElement) => {
  const op = (editor.operations as unknown[]).filter(
    o => Operation.isNodeOperation(o) && o.type === 'insert_node' && match(o.node)
  )[0] as InsertNodeOperation
  if (!Element.isElement(op?.node)) {
    return null
  }
  return op.path as Path
}

/**
 * @deprecated use setNodes directly instead
 * Update element will update element properties, but not replace the children */
const updateElement = <T extends CoreElement>(editor: TunaEditor, block: T, newProps: Partial<T>) => {
  if (newProps.children) {
    logger.warn(`updateElement does not support updating children (will be ignored)`)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...cleanProps } = newProps
  const at = ReactEditor.findPath(editor as unknown as ReactEditor, block)
  Transforms.setNodes(editor, { ...block, ...cleanProps }, { at })
}

/** Update element will replace the element, including the children */
const replaceElement = <T extends CoreElement>(editor: TunaEditor, block: T, newBlock: T) => {
  const at = ReactEditor.findPath(editor as unknown as ReactEditor, block)
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at })
    Transforms.insertNodes(editor, newBlock, { at })
  })
}

const deleteElement = (editor: TunaEditor, block: CoreElement) => {
  Transforms.delete(editor, { at: ReactEditor.findPath(editor as unknown as ReactEditor, block) })
}

/** Break top-level block and insert a new Line after the block */
const breakBlock = (editor: TunaEditor, direction = 'next', select = true) => {
  if (!editor.selection) {
    return
  }

  const selectionPath = Editor.path(editor, editor.selection)
  const insertPath =
    direction === 'previous'
      ? selectionPath[0] === 0
        ? [selectionPath[0]]
        : Path.previous([selectionPath[0]])
      : Path.next([selectionPath[0]])
  const line: Line = { type: 'line', children: [{ text: '' }] }
  Transforms.insertNodes(editor, line, {
    at: insertPath,
    select,
  })
}

const endOfLine = (editor: TunaEditor) => {
  /** Perhaps we should trigger suggestions from here instead? => issues with Sidebar */
  const node = nodeAt(editor, editor.selection?.anchor.path)
  if (!node) {
    return false
  }
  const parent = Editor.parent(editor, node[1])
  const lastChild = Node.last(editor as never, parent[1])

  const offset = editor.selection?.focus.offset ?? -1
  /** The text from the offset to the end */
  const restText =
    Text.isText(node[0]) && node[0].text !== '' ? node[0].text.substring(offset).replace(/\W/gi, '') : null

  /** We need to be the last child of the parent, and only whitespaces after the anchor */

  const isEndOfLine = restText === '' && node[0] === lastChild?.[0]
  return isEndOfLine
}

const currentText = (editor: TunaEditor, match: FilterFunc = isLine) => {
  const currentLine = Editor.above(editor, { match })
  if (!currentLine) {
    return { path: null, text: null }
  }
  const text = Array.from(Node.texts(currentLine[0]))
    .map(([node]) => node.text)
    .join(' ')

  return { text, path: currentLine[1] }
}

export const EditorQueries = {
  currentText,
  currentElement,
  currentBlock,
  currentBlockNode,
  nodeAt,
  nodeAtFocus,
  nodeAtAnchor,
  getClientPixelPosition,
  getChildrenPixelOffsets,
  beginningOfEmptyBlock,
  hasChildren,
  lastInsertedElementPath,
  updateElement,
  replaceElement,
  deleteElement,
  breakBlock,
  endOfLine,
}
