import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
import type { EntityOrElement, EntityType } from '@tunasong/schemas'
import ConvertMenuItem from './convert-action.js'

export const convertMenu =
  <TElement extends EntityOrElement>(to: EntityType[]) =>
  (props: RenderMenuItemProps<TElement>) => (
    <>
      {to.map(entityType => (
        <ConvertMenuItem key={entityType} {...props} to={entityType} />
      ))}
    </>
  )
