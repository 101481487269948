import { useCallback, useMemo, useState } from 'react'
import type { CSSProperties, FC } from 'react'
import { PulseProgressBox } from '../progress/index.js'

interface ImageProps {
  url?: string
  className?: string
  rotation?: number
}

export const Image: FC<ImageProps> = props => {
  const { url, className, rotation = 0 } = props

  const [loaded, setLoaded] = useState(false)

  const handleLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  const style: CSSProperties = useMemo(
    () =>
      className
        ? {
            transform: `rotate(${rotation}deg)`,
          }
        : {
            transform: `rotate(${rotation}deg)`,
            width: '100%',
            objectFit: 'cover',
          },
    [className, rotation]
  )
  return (
    <>
      {url && (
        <img
          className={className}
          onLoad={handleLoad}
          style={style}
          src={url}
          // crossOrigin="anonymous"
          contentEditable={false}
        />
      )}

      {!loaded ? <PulseProgressBox size="large" /> : null}
    </>
  )
}
