/** Register mime types from plugins in Redux */

import { getMimeTypeMapping, PluginContext } from '@tunasong/plugin-lib'
import { features } from '@tunasong/redux'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

/** @note must be run inside of a PluginContext, e.g., DIContext */
export const useRegisterMimeTypesFromPlugins = () => {
  const dispatch = useDispatch()
  const pluginRepository = useContext(PluginContext)

  useEffect(() => {
    if (!pluginRepository) {
      return
    }
    pluginRepository.getCollection('all').then(plugins => {
      const result = getMimeTypeMapping(plugins)
      dispatch(features.storage.actions.registerMimeTypeMappings(result))
    })
    return () => {
      dispatch(features.storage.actions.registerMimeTypeMappings(null))
    }
  }, [dispatch, pluginRepository])
}
