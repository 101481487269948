import {
  Box,
  Button,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { dayjs } from '@tunasong/models'
import { workflowsApi } from '@tunasong/redux'
import { useCallback } from 'react'
import type { FC } from 'react'

export interface JobsProps {}

export const Exports: FC<JobsProps> = props => {
  const {} = props
  const { currentData: exports = [], isLoading } = workflowsApi.useGetExportsQuery()
  const [doExport, exportStatus] = workflowsApi.useExportMutation()
  const handleExport = useCallback(() => doExport(), [doExport])

  const isRunning = exports[0]?.status === 'RUNNING'

  if (isLoading) {
    return <LinearProgress />
  }
  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleExport}
        disabled={exportStatus.isSuccess || exportStatus.isLoading || isRunning}
      >
        Export my data
      </Button>
      {isRunning ? <Typography variant="subtitle1">Export is running. This may take a while...</Typography> : null}
      <Divider>Exports</Divider>
      <List>
        {exports.map(e => (
          <ListItem>
            <ListItemText primary={dayjs(e.stopDate).format('YYYY-MM-DD')} secondary={e.status} />
            <ListItemSecondaryAction>
              <Button
                href={e.url}
                target="_blank"
                variant="outlined"
                color="primary"
                disabled={e.status !== 'SUCCEEDED' || !e.url}
              >
                Download
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default Exports
