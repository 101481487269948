import type { SongSection } from '@tunasong/models'
import { NoteLib } from '../note/index.js'
import { ScaleLib } from '../scale/index.js'
import { rhythmSummary } from './rhythm.js'

export const sectionSummary = ({ section, transpose = 0 }: { section: SongSection; transpose?: number }) => {
  const transposeLabel = NoteLib.transposeLabel(transpose)
  const scale = section.scale ? ScaleLib.transpose(section.scale, transpose) : undefined
  const scaleName = scale ? ScaleLib.getName(scale) + ' ' + transposeLabel : ''
  const rhythmName = section.rhythm ? rhythmSummary(section.rhythm) : ''
  return scaleName ? `${scaleName}${rhythmName}` : transposeLabel ? `Transposed ${transposeLabel}` : undefined
}
