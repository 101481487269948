import { createApi } from '@reduxjs/toolkit/query/react'
import type { Activity } from '@tunasong/models'
import type { Persisted } from '@tunasong/schemas'

import baseQuery from './base-query.js'

// Define a service using a base URL and expected endpoints
export const activitiesApi = createApi({
  reducerPath: 'activities-api',
  baseQuery,
  endpoints: builder => ({
    loadActivitiesForEntity: builder.query<Persisted<Activity>[], { entityId: string }>({
      query: ({ entityId }) => ({
        url: `activities/?entityId=${entityId}`,
      }),
      keepUnusedDataFor: 60,
    }),
    loadActivities: builder.query<Persisted<Activity>[], void>({
      query: () => ({
        url: `activities/`,
      }),
      keepUnusedDataFor: 60,
    }),
  }),
})
