import { getGlobalCommands, useGlobalAppContext, usePlugins } from '@tunasong/plugin-lib'
import type { Command } from '@tunasong/plugin-lib'
import { useUserConfig } from './config-service.hook.js'

/** Commands that are available globally */
export const useGlobalCommands = ({ text }: { text?: string }): Command[] => {
  const [maturity] = useUserConfig('featureMaturity')
  const plugins = usePlugins('all')
  const globalContext = useGlobalAppContext()

  const commands = plugins ? getGlobalCommands({ text, plugins, globalContext, maturity }) : []

  return commands
}
