import { alpha } from '@mui/material'
import type { TunaPlugin } from '@tunasong/plugin-lib'
import { Awareness, Y, getSharedType } from '@tunasong/sync-lib'
import type { SyncProvider } from '@tunasong/sync-lib'
import { useDelayedInvoke, useStableRef } from '@tunasong/ui-lib'
import { useCallback, useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { createYJSPlugin } from './yjs-create-plugin.js'

interface YjsPluginProps {
  doc: Y.Doc
  provider?: SyncProvider
  awareness: Awareness
  userId?: string
  color?: string // rgba
}

interface YjsPluginType {
  connected: boolean
  isSynced: boolean
  plugin: TunaPlugin | null
  onlineUsers: string[]
  toggleOnline(): void
}

export const useYjsPlugin = (props: YjsPluginProps): YjsPluginType => {
  const { doc, userId, color, provider, awareness } = props

  const [isSynced, setIsSynced] = useState(false)

  const { connected } = provider ?? {}

  invariant(doc, 'useYjsPlugin requires a Y.Doc')
  invariant(awareness, 'useYjsPlugin requires awareness to be set')

  useStableRef(awareness)

  const sharedTypeV2 = getSharedType(doc)

  // This should never change
  const [plugin] = useState(() => createYJSPlugin(sharedTypeV2, awareness))

  const toggleOnline = useCallback(() => {
    if (!provider) {
      return
    }
    if (connected) {
      provider.disconnect()
    } else {
      provider.connect()
    }
  }, [connected, provider])

  const debouncedAwarenessUpdate = useDelayedInvoke(10)
  const [onlineUsers, setOnlineUsers] = useState<string[]>([])
  useEffect(() => {
    if (!(color && provider && userId && awareness)) {
      return
    }

    const handleStatus = () => {
      // alert('Redraw on status', status)
    }

    awareness.setLocalState({
      data: {
        alphaColor: alpha(color, 0.4),
        color,
        userId,
      },
    })

    /** Users online */
    const handleAwarenessUpdate = () => {
      // Debounce this to avoid slowing down keypresses
      debouncedAwarenessUpdate(() => {
        const states = Array.from(awareness.states.values()) as { userName: string }[]
        setOnlineUsers(states.map(({ userName }) => userName))
      })
    }

    const handleSync = (isSynced: boolean) => setIsSynced(isSynced)

    awareness.on('update', handleAwarenessUpdate)
    provider.on('status', handleStatus)
    provider.on('synced', handleSync)

    provider.connect()

    return () => {
      awareness.off('update', handleAwarenessUpdate)
      provider.off('status', handleStatus)
      provider.off('synced', handleSync)
    }
  }, [awareness, color, debouncedAwarenessUpdate, provider, userId])

  const sharedTypeRef = useRef<unknown>(null)
  useEffect(() => {
    if (sharedTypeRef.current && sharedTypeRef.current !== sharedTypeV2) {
      throw new Error(`sharedType changed!`)
    }
    sharedTypeRef.current = sharedTypeV2
  }, [sharedTypeV2])

  return {
    connected: awareness ? Boolean(connected) : false,
    onlineUsers,
    isSynced,
    toggleOnline,
    plugin,
  }
}
