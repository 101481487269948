/* eslint-disable @typescript-eslint/member-ordering */
import type { DSPFeatureName, DSPFeatures } from '@tunasong/audio-dsp'
import { dspFeatures } from '@tunasong/audio-dsp'
import type { Decibel } from '@tunasong/models'
import type { AudioTrack } from '@tunasong/schemas'
import type { IAudioContext, IAudioNode } from 'standardized-audio-context'
import { AnalyserNode, GainNode, StereoPannerNode } from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import { DSPController } from './dsp-controller.js'
import type { IAudioController, IMixer } from './interfaces.js'

export type TrackType = 'track' | 'channel' | 'bus'

const DEFAULT_GAIN = 1.0

export abstract class AudioController<T extends AudioTrack = AudioTrack>
  extends EventTarget
  implements AudioTrack, IAudioController
{
  protected gainNode: GainNode<IAudioContext>
  protected pannerNode: StereoPannerNode<IAudioContext>
  protected preAnalyserNode: AnalyserNode<IAudioContext>
  protected postAnalyserNode: AnalyserNode<IAudioContext> | undefined

  protected isSolo = false
  protected isMuted = false
  protected isMonitored = false
  private state: 'running' | 'stopped'
  private nodeGain: number

  private dsp: DSPController | null = null
  private dspFeatures: DSPFeatures | null = null

  /** The subclasses will set input, output appropriately */
  protected abstract inputNode: IAudioNode<IAudioContext> | null
  protected abstract outputNode: IAudioNode<IAudioContext>

  constructor(
    protected mixer: IMixer,
    private source: T
  ) {
    super()
    this.state = 'running'

    /** This chain is common for channels and busses */
    this.gainNode = mixer.context.createGain()
    this.nodeGain = source.gain ? source.gain : DEFAULT_GAIN
    this.gainNode.gain.value = this.nodeGain

    this.pannerNode = mixer.context.createStereoPanner()
    this.pannerNode.pan.value = source.pan ?? 0.0
    this.gainNode.connect(this.pannerNode)

    this.preAnalyserNode = new AnalyserNode(mixer.context)
    this.pannerNode.connect(this.preAnalyserNode)
  }

  get active() {
    return this.state === 'running'
  }

  get type() {
    return this.source.type
  }

  get id() {
    return this.source.id
  }

  get name() {
    return this.source.name
  }

  get input() {
    return this.inputNode
  }

  /** Output node for the channel/bus - i.e., where the track audio is sent */
  get output() {
    return this.outputNode
  }

  get audioFeatures(): DSPFeatures | null {
    return this.dspFeatures
  }

  get monitor(): boolean {
    return this.isMonitored
  }
  set monitor(monitored: boolean) {
    this.isMonitored = monitored
  }

  get gain(): number {
    this.assertActive()
    return this.nodeGain
  }

  set gain(gain: number) {
    this.assertActive()
    if (gain < 0 || gain > 1.0) {
      throw new Error(`Gain must be between 0 and 1. Received: ${gain}`)
    }
    this.nodeGain = gain
    if (!this.muted) {
      this.gainNode.gain.value = gain
    }
  }

  get pan(): number {
    return this.pannerNode.pan.value
  }

  set pan(pan: number) {
    if (pan < -1.0 || pan > 1.0) {
      throw new Error(`Pan must be between -1 and 1. Received: ${pan}`)
    }
    this.pannerNode.pan.value = pan
  }

  get muted(): boolean {
    this.assertActive()

    return Boolean(this.isMuted)
  }

  set muted(muted: boolean) {
    this.assertActive()

    this.isMuted = muted
    const val = muted ? 0 : this.nodeGain
    this.gainNode.gain.value = val

    /** We cannot be muted and soloed */
    if (muted) {
      this.solo = false
    }
  }

  get solo() {
    return this.isSolo
  }

  set solo(solo: boolean) {
    /** We need to store the gain */
    this.isSolo = solo
    if (solo) {
      this.muted = false
    }
    this.mixer.updateSolo()
  }

  abstract get trackType(): TrackType

  enableDSP = () => {
    /** DSP Node - features are disabled by default also when dsp is enabled */
    if (!this.dsp) {
      this.dsp = new DSPController({ context: this.mixer.context, source: this, onFeatures: this.handleDSPFeatures })
    }
  }

  enableDSPFeatures = (enabled: boolean) => {
    /** Enable DSP Features for the channel */
    for (const feature of dspFeatures) {
      this.dsp?.enableFeature(feature, enabled)
    }
    if (!enabled) {
      this.dspFeatures = null
    }
  }
  enableDSPFeature = (feature: DSPFeatureName, enabled: boolean) => {
    this.enableDSP()
    invariant(this.dsp, 'DSP Controller must be initialized')
    this.dsp?.enableFeature(feature, enabled)
  }

  stop() {
    this.state = 'stopped'
  }

  /** Get the current decibels for the audio channel, either pre- or post compression */
  getDB(at: 'pre' | 'post' = 'pre'): Decibel {
    const analyser = at === 'pre' ? this.preAnalyserNode : this.postAnalyserNode

    if (!analyser) {
      throw new Error(`No analyser for ${at} for track ${this.name}`)
    }

    /** Adapted from https://stackoverflow.com/questions/44360301/web-audio-api-creating-a-peak-meter-with-analysernode */
    const sampleBuffer = new Float32Array(analyser.fftSize)
    analyser.getFloatTimeDomainData(sampleBuffer)

    // Compute average power over the interval.
    let sumOfSquares = 0
    for (const sample of sampleBuffer) {
      sumOfSquares += sample ** 2
    }
    const avg = 10 * Math.log10(sumOfSquares / sampleBuffer.length)

    // Compute peak instantaneous power over the interval.
    let peakInstantaneousPower = 0
    for (const sample of sampleBuffer) {
      const power = sample ** 2
      peakInstantaneousPower = Math.max(power, peakInstantaneousPower)
    }
    const peak = 10 * Math.log10(peakInstantaneousPower)
    return { avg, peak }
  }

  /** Sync gain to mixer solo value */
  syncSoloGain(mixerSolo: boolean) {
    if (this.isSolo || this.isMuted) {
      return
    }
    /** If mixerSolo is true, mute this non-soloed channel. Otherwise reset the gain. */
    this.gainNode.gain.value = mixerSolo ? 0 : this.nodeGain
  }

  connect(routeTo: IAudioNode<IAudioContext>) {
    this.output.connect(routeTo)
  }

  disconnect() {
    this.dsp?.disconnect()
    this.dsp = null

    this.output.disconnect()
  }

  /** Type safety for event listeners */
  addFeatureListener = <T extends DSPFeatureName | 'all'>(
    feature: T,
    callback: T extends 'all'
      ? (ev: CustomEvent<DSPFeatures>) => void
      : T extends DSPFeatureName
        ? (ev: CustomEvent<DSPFeatures[T]>) => void
        : never
  ): void => {
    super.addEventListener(feature, callback as EventListener)
  }
  removeFeatureListener = <T extends DSPFeatureName | 'all'>(
    feature: T,
    callback: T extends 'all'
      ? (ev: CustomEvent<DSPFeatures>) => void
      : T extends DSPFeatureName
        ? (ev: CustomEvent<DSPFeatures[T]>) => void
        : never
  ): void => {
    super.removeEventListener(feature, callback as EventListener)
  }

  private assertActive() {
    if (this.state !== 'running') {
      throw new Error(`Channel or Bus is not connected: ${this.source.id}`)
    }
  }
  private handleDSPFeatures = (features: DSPFeatures) => {
    this.dspFeatures = features

    /** Send one event per feature */
    // logger.debug(`Handling DSP features for ${this.name}`, features)

    // dispatch the 'all' event
    this.dispatchEvent(new CustomEvent<DSPFeatures>('all', { detail: features }))

    // dispatch individual events
    for (const [key, feature] of Object.entries(features)) {
      const featureType = key as DSPFeatureName
      const event = new CustomEvent<DSPFeatures[typeof featureType]>(featureType, { detail: feature })
      this.dispatchEvent(event)
    }
  }
}
