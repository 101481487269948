import invariant from 'tiny-invariant'
import * as Symbols from '../symbols.js'
import type { NoteInfo } from './note-info.js'
import type { NoteOctave } from './note.js'
import type { NoteType } from './types.js'
import type { NoteName, StandardNoteName, StandardNoteNameWithOctave } from '@tunasong/schemas'

/** See https://en.wikipedia.org/wiki/List_of_meantone_intervals */

export type NoteDegree =
  | 'I'
  | 'bbII'
  | 'II'
  | 'bII'
  | '#I'
  | 'II'
  | 'bbIII'
  | '#II'
  | 'bIII'
  | 'III'
  | '#III'
  | 'bIV'
  | 'IV'
  | 'bbV'
  | '#IV'
  | 'bV'
  | 'V'
  | 'bbVI'
  | '#V'
  | 'bVI'
  | 'VI'
  | 'bbVII'
  | '#VI'
  | 'bVII'
  | 'VII'
  | 'bVIII'
  | 'VIII'

const notes: { [degree in NoteDegree]: NoteInfo } = {
  I: {
    name: 'Unison',
    chromaticDistance: 0,
    type: 'major',
  },
  bbII: {
    name: 'Dimished second',
    chromaticDistance: 0,
    type: 'diminished',
  },
  '#I': {
    name: 'Chromatic semitone',
    chromaticDistance: 1,
    type: 'augmented',
  },
  bII: {
    name: 'Minor second',
    chromaticDistance: 1,
    type: 'minor',
  },
  II: {
    name: 'Whole tone',
    chromaticDistance: 2,
    type: 'major',
  },
  bbIII: {
    name: 'Diminished third',
    chromaticDistance: 2,
    type: 'diminished',
  },
  '#II': {
    name: 'Augmented second',
    chromaticDistance: 3,
    type: 'augmented',
  },
  bIII: {
    name: 'Minor third',
    chromaticDistance: 3,
    type: 'minor',
  },
  III: {
    name: 'Major third',
    chromaticDistance: 4,
    type: 'major',
  },
  bIV: {
    name: 'Diminished fourth',
    chromaticDistance: 4,
    type: 'diminished',
  },
  '#III': {
    name: 'Augmented third',
    chromaticDistance: 5,
    type: 'augmented',
  },
  IV: {
    name: 'Perfect fourth',
    chromaticDistance: 5,
    type: 'major',
  },
  bbV: {
    name: 'Diminished fifth',
    chromaticDistance: 5,
    type: 'diminished',
  },
  '#IV': {
    name: 'Augmented fourth',
    chromaticDistance: 6,
    type: 'augmented',
  },
  bV: {
    name: 'Diminished fifth',
    chromaticDistance: 6,
    type: 'diminished',
  },
  V: {
    name: 'Perfect fifth',
    chromaticDistance: 7,
    type: 'major',
  },
  bbVI: {
    name: 'Diminished sixth',
    chromaticDistance: 7,
    type: 'diminished',
  },
  '#V': {
    name: 'Augmented fifth',
    chromaticDistance: 8,
    type: 'augmented',
  },
  bVI: {
    name: 'Diminished sixth',
    chromaticDistance: 8,
    type: 'diminished',
  },
  VI: {
    name: 'Major sixth',
    chromaticDistance: 9,
    type: 'major',
  },
  bbVII: {
    name: 'Diminished seventh',
    chromaticDistance: 9,
    type: 'diminished',
  },
  '#VI': {
    name: 'Augmented sixth',
    chromaticDistance: 10,
    type: 'augmented',
  },
  bVII: {
    name: 'Minor seventh',
    chromaticDistance: 10,
    type: 'minor',
  },
  VII: {
    name: 'Major seventh',
    chromaticDistance: 11,
    type: 'major',
  },
  bVIII: {
    name: 'Diminished octave',
    chromaticDistance: 11,
    type: 'diminished',
  },
  VIII: {
    name: 'Octave',
    chromaticDistance: 0, // <= @todo should probably be 12
    type: 'major',
  },
}

export interface NoteChromaticInfo {
  /** The raw index in the chromatic scale from 0 to 11 */
  index: number
  /** The note type */
  type: NoteType
}

/** Lookup table for the relative chromatic positions of the different notes */
const noteIndexLookup: { [name in NoteName]: NoteChromaticInfo } = {
  C: { index: 0, type: 'major' },
  'B#': { index: 0, type: 'augmented' },
  Dbb: { index: 0, type: 'diminished' },
  'C#': { index: 1, type: 'augmented' },
  Db: { index: 1, type: 'minor' },
  D: { index: 2, type: 'major' },
  Ebb: { index: 2, type: 'diminished' },
  'D#': { index: 3, type: 'augmented' },
  Eb: { index: 3, type: 'minor' },
  E: { index: 4, type: 'major' },
  Fb: { index: 4, type: 'minor' } /** @todo is this diminished? */,
  F: { index: 5, type: 'major' },
  'E#': { index: 5, type: 'augmented' },
  'F#': {
    index: 6,
    type: 'augmented',
  } /** @todo This is augmented only in C, so we should look at the NoteDegree instead */,
  Gb: { index: 6, type: 'minor' },
  G: { index: 7, type: 'major' },
  Abb: { index: 7, type: 'diminished' },
  'G#': { index: 8, type: 'augmented' },
  Ab: { index: 8, type: 'minor' },
  A: { index: 9, type: 'major' },
  Bbb: { index: 9, type: 'diminished' },
  'A#': { index: 10, type: 'augmented' },
  Bb: { index: 10, type: 'minor' },
  B: { index: 11, type: 'major' },
  Cb: { index: 11, type: 'diminished' },
}

export const getNoteByIndex = (index: number) =>
  ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'][index] as StandardNoteName

export const getNotesByChromaticIndex = (index: number) =>
  Object.entries(noteIndexLookup)
    .filter(([, value]) => value && value.index === index)
    .map(([key]) => key) as NoteName[]

/** Return a single note by Chromatic index and type */
export const getNoteByChromaticIndex = (index: number, type: 'major' | 'diminished' | 'minor' | 'augmented') =>
  getNotesByChromaticIndex(index).filter(n => noteIndexLookup[n].type === type)[0]

export const getNoteChromaticIndex = (note: NoteName) => noteIndexLookup[note]?.index ?? -1
export const getNoteType = (name: NoteName) => noteIndexLookup[name].type
export const getNoteChromaticDistance = (from: NoteName, to: NoteName) =>
  (getNoteChromaticIndex(to) - getNoteChromaticIndex(from) + 12) % 12

export const isNoteAugmented = (name: NoteName) => name.endsWith('#')
export const isNoteDiminished = (name: NoteName) => name.endsWith('bb')
export const isNoteMinor = (name: NoteName) => name.endsWith('b') && !isNoteDiminished(name)

export const getNoteDegreeInfo = (degree: NoteDegree): NoteInfo => notes[degree]
export const isNoteDegreeAugmented = (name: NoteDegree) => name.startsWith('#')
export const isNoteDegreeMinor = (name: NoteDegree) => name.startsWith('b') && !isNoteDegreeDiminished(name)
export const isNoteDegreeDiminished = (name: NoteDegree) => name.startsWith('bb')

/**
 *
 * @param note
 * @param semiTones
 * @param exclude name of note to exclude in name - i.e., if exclude is 'G' then we will choose 'Ab' over 'G#'. Use when building scales.
 */
export const getNoteByChromaticOffset = (note: NoteName, semiTones: number, exclude?: NoteName): NoteName => {
  if (semiTones === 0) {
    return note
  }
  const index = getNoteChromaticIndex(note)
  const chromaticIndex = (index + semiTones + 12) % 12
  const nts = getNotesByChromaticIndex(chromaticIndex)
  return (exclude ? nts.filter(n => !n.startsWith(exclude[0])) : nts)[0]
}

export const transpose = getNoteByChromaticOffset

export const getPrevChromaticNotes = (note: NoteName) => {
  const index = (getNoteChromaticIndex(note) - 1 + 12) % 12
  return getNotesByChromaticIndex(index)
}

export const getNextChromaticNotes = (note: NoteName) => {
  const index = (getNoteChromaticIndex(note) + 1 + 12) % 12
  return getNotesByChromaticIndex(index)
}

/** Transpose variant with octave */
export const getNoteOctaveByChromaticOffset = (
  noteOctave: StandardNoteNameWithOctave,
  semiTones: number
): StandardNoteNameWithOctave => {
  if (semiTones === 0) {
    return noteOctave
  }
  const { note, octave } = getNoteAndOctave(noteOctave)

  const index = getNoteChromaticIndex(note)

  // This is a trick because index + semiTones can be negative. So we add a multiple of 12
  const newChromaticIndex = (index + semiTones + 1200) % 12

  const wholeOctaves = semiTones > 0 ? Math.floor(semiTones / 12) : Math.ceil(semiTones / 12)
  // If the foundNote is higher than the current note, we need to wrap the octave

  const foundNote = getNoteByIndex(newChromaticIndex)

  // If the next note is lower than the current note, we need to wrap the octave. We wrap +1 if semiTones > 0, -1 if semiTones < 0
  let wrapOctave = 0
  if (newChromaticIndex < index && semiTones > 0) {
    wrapOctave = 1
  }
  if (newChromaticIndex > index && semiTones < 0) {
    wrapOctave = -1
  }

  return `${foundNote}${octave + wholeOctaves + wrapOctave}` as StandardNoteNameWithOctave
}

export const prettifyNote = (note: NoteName | string) =>
  note.replace('#', Symbols.SHARP).replace('b', Symbols.FLAT) as NoteName

export const NOTE_DEGREE_NAMES = Object.keys(notes) as NoteDegree[]
export const NOTE_NAMES = Object.keys(noteIndexLookup) as NoteName[]

export const equals = (a: NoteName, b: NoteName) => getNoteChromaticIndex(a) === getNoteChromaticIndex(b)

export const sortFn = (a: NoteName, b: NoteName) => getNoteChromaticIndex(a) - getNoteChromaticIndex(b)

export const sort = (notes: NoteName[] = []) => notes.sort(sortFn)

export const uniqueFn = (v: NoteName, i: number, a: NoteName[]) => a.indexOf(v) === i

export const dedupe = (notes: NoteName[] = []) => [...new Set(notes)]

export const toNote = (note: NoteOctave | NoteName): NoteName => {
  invariant(note)
  /** Note degrees end with a number */
  let noteName = note
  if (note[note.length - 1].match(/\d/)) {
    noteName = note.slice(0, note.length - 1) as NoteName
  }

  return noteName as NoteName
}

export const transposeLabel = (semiTones: number) =>
  `${!semiTones || semiTones === 0 ? '' : semiTones < 0 ? '(' : '(+'}${semiTones !== 0 ? semiTones + ')' : ''}`

export { frequencyFromMIDINoteNumber, noteFromFrequency, centsOffFromPitch } from './note-frequencies.js'

/** Get the MIDI number for a note with octave */
export const getNoteMIDINumber = (noteWithOctave: StandardNoteNameWithOctave): number => {
  const { note, octave } = getNoteAndOctave(noteWithOctave)
  return 12 + 12 * octave + getNoteChromaticIndex(note)
}

export const getNoteAndOctave = (noteWithOctave: StandardNoteNameWithOctave) => {
  const note = noteWithOctave.slice(0, -1) as StandardNoteName
  const octave = parseInt(noteWithOctave.slice(-1), 10)
  return { note, octave }
}
