import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { ChordLib, ScaleLib, isChordVariant } from '@tunasong/music-lib'
import type { Chord } from '@tunasong/music-lib'
import { makeStyles } from '@tunasong/ui-lib'
import classNames from 'classnames'
import type { FC, MouseEvent } from 'react'
import { NoteDegree } from '../note/index.js'
import type { Scale } from '@tunasong/schemas'

export interface SimpleChordProps extends Omit<BoxProps, 'onClick'> {
  chord: Chord
  /** Transpose the display by a number of semitones ( + / - ) */
  transpose?: number
  /** Display transposition as part of the chord (+/-)transposition */
  showTranspose?: boolean
  scale?: Scale | null
  selected?: boolean
  focused?: boolean
  onClick?(chord: Chord): void
}

const useStyles = makeStyles()(theme => ({
  inScale: {
    color: theme.vars.palette.primary.main,
  },
  outOfScale: {
    color: theme.vars.palette.warning.light,
  },
  selected: {
    backgroundColor: theme.vars.palette.action.selected,
  },
  link: {
    textDecoration: 'underline',
    ':hover': {
      cursor: 'pointer',
    },
  },
}))

export const SimpleChord: FC<SimpleChordProps> = props => {
  const {
    chord,
    scale,
    showTranspose = false,
    transpose = 0,
    selected = false,
    focused = false,
    onClick,
    ...restProps
  } = props
  const { classes } = useStyles()

  const chordName = ChordLib.getName(chord, transpose)
  const inScale = scale ? ScaleLib.inScale(scale, chord) : true
  /** We don't know that the root is in the custom chord, so we cannot calculate the degree */
  const degree = isChordVariant(chord) && scale && inScale && ScaleLib.getChordDegree(scale, chord)
  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault()
    onClick?.(chord)
  }
  return (
    <Box
      sx={{ position: 'relative' }}
      className={classNames({
        [classes.selected]: selected && focused,
        [classes.outOfScale]: !inScale,
        [classes.inScale]: inScale,
        [classes.link]: Boolean(onClick),
      })}
      contentEditable={false}
      onClick={handleClick}
      {...restProps}
    >
      {chordName}
      {degree ? <NoteDegree degree={degree} /> : null}
      {showTranspose && transpose !== 0 ? ` (${transpose > 0 ? '+' : '-'}${transpose})` : null}
    </Box>
  )
}
