import type { AudioClip, Clip, EntityClip } from './audio-clip.js'
import { audioClipSchema, isAudioClip, isEntityClip } from './audio-clip.js'
import type { AudioComment } from './audio-comment.js'
import { audioCommentSchema } from './audio-comment.js'
import type { Track } from './audio-track.js'
import { isAudioTrack, trackSchema } from './audio-track.js'
import type { BandSpace } from './band-space.js'
import { bandSpaceSchema, isBandSpace } from './band-space.js'
import type { Attendee, Calendar, CalendarEvent } from './calendar.js'
import { calendarEventSchema, calendarSchema, isCalendar, isCalendarEvent } from './calendar.js'
import type { Effect, EffectType } from './effect.js'
import { effectSchema, EffectTypeValues, isEffect } from './effect.js'
import type { Media } from './media.js'
import { isMedia, mediaSchema } from './media.js'
import type { Midi, MidiCC, MidiProgramChange } from './midi.js'
import { isMidi, isMidiCC, isMidiProgramChange, midiSchema } from './midi.js'
import type { MusicXML } from './musicxml.js'
import { isMusicXML, musicXMLSchema } from './musicxml.js'
import type { ManyOfManyPollResponse, OneOfManyPollResponse, PollResponse } from './poll-response.js'
import {
  isManyOfManyPollResponse,
  isOneOfManyPollResponse,
  isPollResponse,
  manyOfManyPollResponseSchema,
  oneOfManyPollResponseSchema,
  pollResponseSchema,
} from './poll-response.js'
import type { ManyOfManyPoll, OneOfManyPoll, Poll, PollType } from './poll.js'
import { isManyOfManyPoll, isOneOfManyPoll, isPoll, pollSchema, pollTypes } from './poll.js'
import type { Setlist } from './setlist.js'
import { setlistSchema } from './setlist.js'
import type { Song } from './song.js'
import { isSong, songSchema } from './song.js'
import type { UserSettings, UserSettingsData } from './user-settings.js'
import { userSettingsSchema } from './user-settings.js'

const extensionSchemas = {
  /** we use non-passthrough for page for now, gradually moving the others over  */
  audioclip: audioClipSchema.passthrough(),
  audiocomment: audioCommentSchema.passthrough(),
  audiotrack: trackSchema.passthrough(),
  song: songSchema.passthrough(),
  midi: midiSchema,
  bandspace: bandSpaceSchema.passthrough(),
  calendar: calendarSchema.passthrough(),
  calendarevent: calendarEventSchema.passthrough(),
  musicxml: musicXMLSchema.passthrough(),
  usersettings: userSettingsSchema.passthrough(),
  effect: effectSchema,
  setlist: setlistSchema,
  pollresponse: pollResponseSchema,
  poll: pollSchema,
}

export const extensionEntityNames = Object.keys(
  extensionSchemas
) as unknown as readonly (keyof typeof extensionSchemas)[]

export {
  audioClipSchema,
  audioCommentSchema,
  bandSpaceSchema,
  calendarEventSchema,
  calendarSchema,
  EffectTypeValues,
  extensionSchemas,
  isAudioClip,
  isAudioTrack,
  isBandSpace,
  isCalendar,
  isCalendarEvent,
  isEffect,
  isEntityClip,
  isManyOfManyPoll,
  isManyOfManyPollResponse,
  isMedia,
  isMidi,
  isMidiCC,
  isMidiProgramChange,
  isMusicXML,
  isOneOfManyPoll,
  isOneOfManyPollResponse,
  isPoll,
  isPollResponse,
  isSong,
  manyOfManyPollResponseSchema,
  mediaSchema,
  midiSchema,
  musicXMLSchema,
  oneOfManyPollResponseSchema,
  pollResponseSchema,
  pollSchema,
  pollTypes,
  setlistSchema,
  songSchema,
  trackSchema,
  userSettingsSchema,
  type Attendee,
  type AudioClip,
  type AudioComment,
  type BandSpace,
  type Calendar,
  type CalendarEvent,
  type Clip,
  type Effect,
  type EffectType,
  type EntityClip,
  type ManyOfManyPoll,
  type ManyOfManyPollResponse,
  type Media,
  type Midi,
  type MidiCC,
  type MidiProgramChange,
  type MusicXML,
  type OneOfManyPoll,
  type OneOfManyPollResponse,
  type Poll,
  type PollResponse,
  type PollType,
  type Setlist,
  type Song,
  type Track,
  type UserSettings,
  type UserSettingsData,
}
