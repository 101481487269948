/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
 */

import { isBrowser } from '@tunasong/models'
import type { IAudioContext } from 'standardized-audio-context'

/**
 * @todo remove this when Typescript has defs.
 * @see https://github.com/microsoft/TypeScript/issues/33232
 */
declare global {
  interface MediaDevices {
    getDisplayMedia(constraints?: MediaStreamConstraints): Promise<MediaStream>
  }

  // if constraints config still lose some prop, you can define it by yourself also
  interface MediaTrackConstraintSet {
    displaySurface?: ConstrainDOMString
    logicalSurface?: ConstrainBoolean
  }
}

/**
  * dictionary MediaTrackSupportedConstraints {
             boolean width = true;
             boolean height = true;
             boolean aspectRatio = true;
             boolean frameRate = true;
             boolean facingMode = true;
             boolean volume = true;
             boolean sampleRate = true;
             boolean sampleSize = true;
             boolean echoCancellation = true;
             boolean latency = true;
             boolean channelCount = true;
             boolean deviceId = true;
             boolean groupId = true;
};
  */

/** MediaRecorder polyfill for Safari. Polyfill does not support SSR, so we need to check for window */
if (isBrowser()) {
  window.MediaRecorder = window.MediaRecorder ? window.MediaRecorder : require('audio-recorder-polyfill')
}

/**
 * @see https://blog.addpipe.com/audio-constraints-getusermedia/
 */
export const micDefaults: MediaTrackConstraints = {
  // sampleRate: defaultSampleRate,
  // sampleSize: 16,

  channelCount: 1,
  autoGainControl: { exact: true },
  noiseSuppression: { exact: false },
  /** not supported by safary */

  // echoCancellation: { exact: false },
}

export const micHeadset: MediaTrackConstraints = {
  // sampleRate: defaultSampleRate,
  echoCancellation: false,
  // noiseSuppression: false,
  autoGainControl: false,
}

/** Constraints for mobile with multiple cameras */
const userCamConstraints: MediaStreamConstraints = {
  audio: false,
  video: {
    facingMode: 'user',
  },
}

const webcamConstraints: MediaStreamConstraints = {
  audio: false, // We do not want an audio track
  video: {
    aspectRatio: {
      ideal: 1.333333, // 3:2 aspect is preferred
    },
  },
}

const environmentCamConstraints: MediaStreamConstraints = {
  audio: false,
  video: {
    facingMode: 'environment',
  },
}

const webcamWithAudioConstraints: MediaStreamConstraints = {
  ...webcamConstraints,
  ...micDefaults,
}

const screenShareConstraints: MediaStreamConstraints = {
  audio: false,
  video: {
    aspectRatio: {
      ideal: 1.333333, // 3:2 aspect is preferred
    },
  },
}

export const supportsFacingMode = () => Boolean(navigator.mediaDevices.getSupportedConstraints()['facingMode'])

export const getEnvironmentCamVideo = async () => navigator.mediaDevices.getUserMedia(environmentCamConstraints)

export const getWebcamWithAudio = async () => navigator.mediaDevices.getUserMedia(webcamWithAudioConstraints)
/** User-facing cam */
export const getUsercamVideo = async (): Promise<MediaStream> => navigator.mediaDevices.getUserMedia(userCamConstraints)
export const getWebcamVideo = async () => navigator.mediaDevices.getUserMedia(webcamConstraints)
export const getScreenShare = async () => navigator.mediaDevices.getDisplayMedia(screenShareConstraints)
export const getMicAudio = async (context: IAudioContext, deviceId: string | null = null): Promise<MediaStream> =>
  navigator.mediaDevices.getUserMedia({
    audio: {
      ...micDefaults,
      sampleRate: context.sampleRate,
      deviceId: deviceId ? { exact: deviceId } : undefined,
    },
  })

/** Convert a data URL to a Blob */
export const dataURLtoBlob = (dataurl: string) => {
  const arr = dataurl.split(',')
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const match = arr[0]!.match(/:(.*?);/)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mime = match![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}
