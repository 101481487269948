import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import type { CoreElement } from '@tunasong/schemas'
import type { FC } from 'react'

export interface BlockPropertiesProps {
  className?: string
  element: CoreElement
}

/** A Block. Extends Box, so all Box */
export const BlockProperties: FC<BlockPropertiesProps> = props => {
  const { element: block } = props

  const hideProperties = ['children', 'userId', 'acls']
  const properties = Object.entries(block)
    .filter(([name]) => block.hasOwnProperty(name) && !hideProperties.includes(name))
    .map(([name, value]) => ({
      name,
      value: typeof value === 'object' ? JSON.stringify(value) : (value as string).toString(),
    }))

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map(({ name, value }) => (
            <TableRow key={name}>
              <TableCell>{name}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
