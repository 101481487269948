import { logger } from '@tunasong/models'
import { useEntitiesById, useEntity } from '@tunasong/redux'
import type { Entity, EntityCommandSpec, EntityEvent, Persisted } from '@tunasong/schemas'
import { useMemo } from 'react'
import invariant from 'tiny-invariant'
import { useGlobalAppContext } from '../app-context.js'
import { useCustomCommands } from './custom-commands.hook.js'
import { usePlugin } from './editor-plugin.js'
import { usePlugins } from './editor-plugins.js'

export const useEntityCommands = (entity?: Persisted<Entity>) => {
  const plugin = usePlugin(entity)
  const globalContext = useGlobalAppContext()

  const entityCommands = plugin?.getEntityCommands ? plugin.getEntityCommands({ entity, globalContext }) : []
  return entityCommands
}

export const useAllEntityCommands = (entity?: Persisted<Entity>) => {
  const customCommands = useCustomCommands(entity)
  const entityCommands = useEntityCommands(entity)
  const commands = [...customCommands, ...entityCommands]
  return commands
}

export const useEntityCommandsForEvent = (targetEntity?: Persisted<Entity>, event?: EntityEvent) => {
  const plugins = usePlugins('all')

  const globalContext = useGlobalAppContext()

  const eventCommands =
    event && targetEntity?.eventCommands ? targetEntity.eventCommands[event] ?? [] : ([] as EntityCommandSpec[])

  const commandEntityIds = eventCommands.map(cmd => cmd.entityId)
  const { entities } = useEntitiesById(commandEntityIds)

  const commands = eventCommands
    .map(cmd => {
      const cmdEntity = entities?.find(e => e.id === cmd.entityId)
      const plugin = plugins?.find(p => p.node?.type === cmdEntity?.type)
      const pluginCommands = plugin?.getEntityCommands
        ? plugin.getEntityCommands({ entity: cmdEntity, targetEntity, globalContext }) ?? []
        : []
      const cmds = pluginCommands?.find(c => c.id === cmd.commandId)
      return cmds
    })
    .filter(Boolean)

  // We want to return commands when all eventCommands have been resolved.
  return commands.length === eventCommands.length ? commands : []
}

export const useEntityCommandFromSpec = (cmd?: EntityCommandSpec) => {
  const { entity } = useEntity(cmd?.entityId)
  const commands = useAllEntityCommands(entity)
  return commands.find(c => c.id === cmd?.commandId)
}

/** Get all the executable commands from the EntityCommandSpec list */
export const useEntityCommandsFromSpecs = (cmds?: EntityCommandSpec[]) => {
  const plugins = usePlugins('all')
  const globalContext = useGlobalAppContext()
  const entityIds = cmds?.map(c => c.entityId)
  const { entities } = useEntitiesById(entityIds)

  const commands = useMemo(() => {
    if (!cmds || !entities) {
      return []
    }
    return entities
      .flatMap(entity => {
        const plugin = plugins?.find(p => p.node?.type === entity.type)
        if (!plugin) {
          logger.error(`No plugin found for entity type: ${entity.type}, cannot execute commands.`)
          return null
        }
        if (!plugin.getEntityCommands) {
          logger.error(`No entity commands found for entity type: ${entity.type}, cannot execute commands.`)
          return null
        }
        const entityCommandSpecs = cmds.filter(c => c.entityId === entity.id)

        const entityCommands = plugin.getEntityCommands({ entity, globalContext })

        // we inject the parameters to the commands from the entityCommandSpecs
        const commandsWithParameters = entityCommandSpecs.map(cmdSpec => {
          const command = entityCommands.find(c => c.id === cmdSpec.commandId)
          invariant(command, `Command not found for entity ${entity.id} with commandId ${cmdSpec.commandId}`)
          const wrappedFn = command.cmd ? () => command.cmd?.(cmdSpec.commandParams) : null
          logger.debug(`Command ${command.id} with parameters  is being prepared.`, {
            commandParams: cmdSpec.commandParams,
          })
          return { ...command, cmd: wrappedFn }
        })

        return commandsWithParameters
      })
      .filter(Boolean)
  }, [cmds, entities, globalContext, plugins])

  return commands
}
