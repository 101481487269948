import { isEntity } from '@tunasong/schemas'
import type { Entity, EntityId } from '@tunasong/schemas'

export interface PlayList extends Entity {
  type: 'playlist'
  /** References to the ids of the media in the playlist */
  mediaIds: EntityId[]
}

export function isPlayList(node?: unknown): node is PlayList {
  return Boolean(isEntity(node) && node.type === 'playlist')
}
