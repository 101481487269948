import { isCoreElement } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export interface Heading extends CoreElement {
  type: 'heading'
  variant: HeadingVariant
}

export function isHeading(node?: unknown): node is Heading {
  return Boolean(isCoreElement(node) && node.type === 'heading')
}
