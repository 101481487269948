import type { IAudioContext, IAudioNode } from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import { getMicAudio } from '../../lib/index.js'
import type { AudioSource } from './audio-source.js'
import type { AudioSourceType } from '@tunasong/schemas'

type AquireInputState = 'initial' | 'pending' | 'started' | 'stopped'

/** Input channel that requires user media and provides an input IAudioNode */
export class InputSource implements AudioSource {
  type: AudioSourceType = 'mic'
  protected sourceNode: IAudioNode<IAudioContext> | null = null

  private state: AquireInputState = 'initial'
  private inputStream: MediaStream | null = null

  constructor(
    private context: IAudioContext,
    private device: MediaDeviceInfo
  ) {}

  get inputNode() {
    return this.sourceNode
  }

  get id() {
    return this.device.deviceId
  }
  get name() {
    return this.device.label
  }

  get inputDevice() {
    return this.device
  }

  start = async () => {
    invariant(this.device, 'device is not specified, cannot start')

    invariant(this.state !== 'pending', 'startDevice called when a previous start is pending')

    if (this.inputStream) {
      throw new Error('Using existing mic stream - this is bug')
    }
    // try {
    this.state = 'pending'

    /** The hook may want to close things down while this promise is pending */
    const stream = await getMicAudio(this.context, this.device.deviceId)

    /** Stop has occured while the promise resolved. So we need to stop tracks and exit */
    if (this.state !== 'pending') {
      this.stopTracks(stream)
      return null
    }
    this.inputStream = stream

    this.sourceNode = this.context.createMediaStreamSource(this.inputStream)

    this.state = 'started'

    return this.sourceNode
  }
  stop = async () => {
    /** We use a ref here to avoid that stop changes when micStream changes */
    const currentState = this.state

    /** Always set the state to stopped */
    this.state = 'stopped'

    if (currentState !== 'started') {
      /** No tracks have been acquired - we'll just return */
      return
    }

    invariant(this.inputStream, `stop called without a current micStream. Current state: ${this.state}`)
    invariant(this.sourceNode, `stop called without a current recording source. `)

    this.state = 'stopped'

    this.stopTracks(this.inputStream)

    this.sourceNode.disconnect()

    this.inputStream = null

    this.sourceNode = null
  }

  private stopTracks = (stream: MediaStream) => {
    const tracks = stream.getTracks()
    for (const track of tracks) {
      track.stop()
    }
  }
}
