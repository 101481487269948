import type { DefaultGlobalContext, PluginConfigSet, PluginRepository, TunaPlugin } from '@tunasong/plugin-lib'
import type { EntityOrElement } from '@tunasong/schemas'
import { configurePlugins } from './plugin-collection.js'
import { pluginSets } from './plugins.js'

/** We want to dynamically load plugins when used */

export class Repository implements PluginRepository {
  pluginCollectionPromises: Partial<Record<string, Promise<TunaPlugin<EntityOrElement, DefaultGlobalContext>[]>>> = {}

  getCollection = async (type: PluginConfigSet) => {
    if (this.pluginCollectionPromises[type]) {
      return this.pluginCollectionPromises[type]
    }

    const pluginsPromise = configurePlugins(pluginSets[type])
    this.pluginCollectionPromises[type] = pluginsPromise

    return pluginsPromise
  }
}
