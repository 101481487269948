import { FindGuitarChord } from '@tunasong/music-ui'
import { ButtonDialog, VBox } from '@tunasong/ui-lib'
import type { SidebarComponent } from '@tunasong/ui-lib'
import React from 'react'
import type { FC } from 'react'

export const SidebarGuitar: FC<SidebarComponent> = ({}) => (
  <VBox sx={{ overflow: 'hidden' }}>
    <ButtonDialog title="Fretboard finder" maxWidth="lg" fullWidth>
      <FindGuitarChord />
    </ButtonDialog>
  </VBox>
)
