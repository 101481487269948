/** Connected yDoc */

import { YjsEditor } from '@tunasong/sync-lib'
import { useEffect } from 'react'
import type { TunaEditor } from '../plugin-types.js'

/** Connect YDoc to the editor */
export const useYDocConnect = (editor: TunaEditor | null) => {
  // Connect editor in useEffect to comply with concurrent mode requirements.
  useEffect(() => {
    if (!editor) {
      return
    }
    if (YjsEditor.isYjsEditor(editor) === false) {
      throw new Error('useYDocConnected: editor must be a YjsEditor')
    }
    YjsEditor.connect(editor as unknown as YjsEditor)
    return () => YjsEditor.disconnect(editor as unknown as YjsEditor)
  }, [editor])
}
