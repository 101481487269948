import { isOnline, logger } from '@tunasong/models'
import type { WSMessage } from '@tunasong/models'
import { features, OAuth, useSelector, useThunkDispatch } from '@tunasong/redux'
import { useEffect, useRef, useState } from 'react'

export interface WebSocketProps {
  baseUrl?: string
  /** Reconnect even on normal close (i.e., return code 1000) */
  persistent?: boolean
  /** Allow non-authenticated users. @default false */
  allowPublic?: boolean
}

const WEBSOCKET_NORMAL_CLOSE = 1000

export const useCreateWebsocket = (props: WebSocketProps = {}) => {
  const { allowPublic = false, baseUrl = 'wss://ws.api.tunasong.com', persistent = true } = props
  const [webSocket, setWebsocket] = useState<WebSocket | null>(null)
  /** Connection number - used to trigger reconnects in hook */
  const [connId, setConnId] = useState(1)

  /** Use a ref to avoid re-render (and closing ws) when retryWait changes */
  const retryWait = useRef(2000)

  const dispatch = useThunkDispatch()

  const authData = useSelector(state => state.user.authData)
  const token = authData?.idToken.jwtToken
  const tokenExpired = OAuth.isSessionExpired(authData)

  const [online, setOnline] = useState(isOnline())
  /** Check online status every 10 seconds */
  useEffect(() => {
    const interval = setInterval(() => {
      setOnline(isOnline())
    }, 10 * 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const t = token ?? (allowPublic ? 'public' : undefined)
    if (!t || tokenExpired) {
      return
    }
    if (!online) {
      logger.info('Websocket: Offline, not attempting connect')
      return
    }

    const url = `${baseUrl}?token=${t}`
    const ws = new WebSocket(url)

    const openHandler = () => {
      if (ws.readyState !== 1) {
        throw new Error(`Websocket failed to open`)
      }
      logger.debug(`*** Websocket connected ***`)
      setWebsocket(ws)

      /** Ping handler to avoid AWS disconnect */
      const pingMsg: WSMessage<unknown> = {
        action: 'ping',
        data: {},
      }
      /** ping interval is 5 minutes */
      const pingInterval = 5 * 60 * 1000
      const interval = setInterval(() => {
        if (ws.readyState === 1) {
          ws.send(JSON.stringify(pingMsg))
        }
      }, pingInterval)
      return () => clearInterval(interval)
    }

    const closeHandler = (ev: CloseEvent) => {
      logger.info('*** Websocket closed.', ev)
      setWebsocket(null)
      /**
       * See close codes here https://tools.ietf.org/html/rfc6455#section-7.4.1
       * 1000 is "normal close", which means we should not reconnect
       * 1006 is close before connected, which can happen on startup when token is not set
       */
      if (!persistent && (ev.code === 1000 || ev.code === 1006)) {
        return
      }
      /** Since this was NOT triggered by our useEffect hook we re-connect */
      logger.info(`*** Websocket reconnecting in ${retryWait.current} ms...`)

      setTimeout(() => setConnId(prev => prev + 1), retryWait.current)
      /** Update the retry wait time. Increase expontially, cap at 60 seconds. */
      retryWait.current = Math.min(retryWait.current * 2, 60000)
    }

    const errorHandler = (ev: Event) => {
      logger.error('*** Websocket error', ev)
      setWebsocket(null)
      if (!token) {
        return
      }
      /** The main cause of this is expired non-public token. We'll refresh the session and try again  */
      dispatch(features.user.actions.refreshSession())
    }

    ws.addEventListener('open', openHandler)
    ws.addEventListener('close', closeHandler)
    ws.addEventListener('error', errorHandler)

    return () => {
      // logger.log('Websocket hook -  removing event listeners for connection', connId, url)
      ws.removeEventListener('open', openHandler)
      ws.removeEventListener('close', closeHandler)
      ws.removeEventListener('error', errorHandler)
      ws.close(WEBSOCKET_NORMAL_CLOSE)
    }
    /** @note we use connId here to trigger a re-connect. connId is only set in the close handler */
  }, [allowPublic, baseUrl, connId, token, persistent, dispatch, tokenExpired, online])

  return webSocket
}
