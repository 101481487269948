import { Typography } from '@mui/material'
import type { Theme } from '@mui/material'
import { MetronomeButton } from '@tunasong/audio-ui'
import { makeStyles } from '@tunasong/ui-lib'
import { meterToString } from '@tunasong/models'
import type { Rhythm } from '@tunasong/models'
import classNames from 'classnames'
import React from 'react'
import type { FC } from 'react'

export interface RhythmProps {
  className?: string
  rhythm?: Rhythm
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  item: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const RhythmComponent: FC<RhythmProps> = props => {
  const { className, rhythm } = props
  const { classes } = useStyles()
  if (!rhythm) {
    return null
  }

  return (
    <div className={classNames(className, classes.root)}>
      <MetronomeButton className={classes.item} rhythm={rhythm} />
      <Typography className={classes.item} variant="body2" color="inherit">
        {rhythm.tempo} BPM
      </Typography>
      {rhythm.meter && (
        <Typography className={classes.item} variant="body2" color="inherit">
          {meterToString(rhythm.meter)}
        </Typography>
      )}
    </div>
  )
}

export default RhythmComponent
