import { InputBase, Typography, useTheme } from '@mui/material'
import type { InputBaseProps } from '@mui/material'
import { useEntityUpdate } from '@tunasong/redux'
import type { Persisted } from '@tunasong/schemas'
import type { Entity } from '@tunasong/schemas'
import React, { useCallback } from 'react'
import type { FC } from 'react'
import { VBox } from '../layout/vertical-box.js'
import { disableAutocomplete } from '../util/disable-autocomplete.js'

export interface EntityTitleProps extends InputBaseProps {
  entity?: Persisted<Entity>
}

const getFontSize = (entity: Persisted<Entity>) => {
  const name = entity?.name ?? ''
  if (name.length > 20) {
    return '1.5rem'
  }
  if (name.length > 10) {
    return '2rem'
  }
  return '2.5rem'
}

export const EntityTitle: FC<EntityTitleProps> = props => {
  const { entity, ...inputProps } = props
  const updateEntity = useEntityUpdate({ debounceDelay: 3 * 1000 })
  const theme = useTheme()

  const handleTitle: React.FocusEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (!entity) {
        return
      }
      updateEntity(entity.id, { name: e.target.value })
    },
    [entity, updateEntity]
  )
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      e.currentTarget.blur()
    }
  }, [])

  if (!entity) {
    return null
  }

  return (
    <VBox sx={{ alignItems: 'center' }}>
      <InputBase
        key={entity.id}
        sx={{ ...theme.typography.h1, textAlign: 'center', ...disableAutocomplete, mb: 0 }}
        placeholder={'Untitled'}
        inputProps={{ style: { fontSize: getFontSize(entity), textAlign: 'center' } }}
        defaultValue={entity.name ?? ''}
        fullWidth
        onChange={handleTitle}
        autoFocus={!entity.name}
        autoCorrect="off"
        onKeyDown={handleKeyDown}
        {...inputProps}
      />
      {entity.description ? <Typography variant="caption">{entity.description}</Typography> : null}
    </VBox>
  )
}

export default EntityTitle
