/** Deep-clone an element. If the element has ids, new ones will be created */

import { shortUuid } from '@tunasong/models'
import { Element } from 'slate'
import type { BaseElement } from 'slate'

const recurseChildren = <T extends BaseElement>(node: T, cb: (node: BaseElement) => void) => {
  cb(node)
  for (const child of node.children ?? []) {
    if (Element.isElement(child)) {
      cb(child)
      recurseChildren(child, cb)
    }
  }
  return node
}

export const cloneElement = <T extends BaseElement>(element: T): T => {
  const clone = JSON.parse(JSON.stringify(element)) as T

  return recurseChildren(clone, node => {
    /** Update ID for nodes that have ID set */
    if ('id' in node) {
      node.id = shortUuid()
    }
  })
}
