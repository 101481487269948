import { Alert, Button } from '@mui/material'
import type { AlertProps } from '@mui/material'

import { entitiesApi, features, useEntity, useEntityUpdate, useThunkDispatch } from '@tunasong/redux'
import { isPersisted } from '@tunasong/schemas'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import ConfirmDialog from '../dialog/confirm-dialog.js'
import { useNavigateToEntity } from '../navigation/navigate.js'
export interface TrashHeaderProps extends AlertProps {
  className?: string
  entity?: Persisted<Entity>
}

export const TrashHeader: FC<TrashHeaderProps> = props => {
  const { className, entity, ...alertProps } = props
  const dispatch = useThunkDispatch()
  const { entity: parent } = useEntity(entity?.parentId)
  const navigate = useNavigateToEntity()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const updateEntity = useEntityUpdate()

  const handleShowDelete = () => setShowDeleteDialog(true)
  const handleHideDelete = () => setShowDeleteDialog(false)

  const [deleteEntity, deleteEntityResult] = entitiesApi.useDeleteEntityMutation()

  const handleDelete = async () => {
    if (!entity) {
      return
    }
    await deleteEntity({ entity }).unwrap()
    /** Navigate to the parent (if it exists) */
    if (parent) {
      navigate(parent)
    }

    setShowDeleteDialog(false)
  }

  useEffect(() => {
    if (!deleteEntityResult.isError) {
      return
    }
    dispatch(
      features.notifications.actions.setAlert({
        message: `Failed to delete entity: ${deleteEntityResult.originalArgs?.entity.id}`,
        severity: 'error',
      })
    )
  }, [deleteEntityResult.isError, deleteEntityResult, dispatch])

  const handleRestore = useCallback(() => {
    if (!isPersisted(entity)) {
      return
    }
    updateEntity(entity.id, { trash: false })
  }, [entity, updateEntity])

  if (!entity?.trash) {
    return null
  }

  return (
    <>
      <Alert
        className={className}
        severity="error"
        action={
          <>
            <Button onClick={handleRestore}>Restore</Button>
            <Button onClick={handleShowDelete}>Delete Permanently</Button>
          </>
        }
        {...alertProps}
      >
        This entity is in the Trash
      </Alert>
      <ConfirmDialog
        open={showDeleteDialog}
        text={`Really permanently delete ${entity.type}: ${entity.name}? This action is non-reversable.`}
        onCancel={handleHideDelete}
        onConfirm={handleDelete}
      />
    </>
  )
}

export default TrashHeader
