import { ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from '@mui/material'
import { Download } from '@tunasong/icons'
import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
import { isEntity } from '@tunasong/schemas'
import type { FC } from 'react'

export interface DownloadMenuItemProps extends RenderMenuItemProps {}

export const DownloadAction: FC<DownloadMenuItemProps> = props => {
  const { element, onClose } = props

  const url = isEntity(element) ? element.storageUrls?.url : undefined

  /**
   * @note that the file name does not work unless it's same origin - i.e., not during dev
   * download only works for same-origin URLs, or the blob: and data: schemes.
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
   */
  return (
    <MuiMenuItem
      href={url}
      target="_blank"
      disabled={!url}
      download={element.name ?? true}
      component={'a'}
      onClick={e => {
        e.stopPropagation()
        onClose?.()
      }}
    >
      <ListItemIcon>
        <Download />
      </ListItemIcon>
      <ListItemText>Download</ListItemText>
    </MuiMenuItem>
  )
}

export default DownloadAction
