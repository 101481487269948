import { Typography } from '@mui/material'
import { NoteLib } from '@tunasong/music-lib'
import { HBox } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { AudioController } from '../engine/index.js'
import type { NoteName } from '@tunasong/schemas'

export interface FFTNotesProps {
  audioController: AudioController
  // Maximum number of notes to display
  maxNotes?: number
  // The negative dB threshold to consider a note
  dbThreshold?: number

  onNotes?: (notes: { note: NoteName; octave: number; hz: number }[]) => void
}

/** Performant real-time view of FFT frequencies mapped to notes.  */
export const FFTNotesView: FC<FFTNotesProps> = props => {
  const { audioController, maxNotes = 10, onNotes, dbThreshold = -50 } = props

  const [topNotes, setTopNotes] = useState<{ note: string; octave: number; hz: number }[]>([])

  const handleFFT = useCallback(
    (ev: CustomEvent<Float32Array | null>) => {
      /** If the chroma processor is not enabled we can get a null message here if other processors */
      const fft = ev.detail

      // Clear opacity if no chroma
      if (!fft) {
        setTopNotes([])
        return
      }
      /** @todo we need to know the frequency width of each band */
      const bandHz = 44100 / fft.length

      // Find the top notes, i.e., the maxNotes highest values in the FFT
      /** @todo perhaps set the Hz to the middle of the band? */
      const vals = Array.from(fft)
        .map((val, idx) => (val > dbThreshold ? { val, hz: (idx + 1) * bandHz } : null))
        .filter(Boolean)

      const topNotes: Partial<Record<NoteName, { note: NoteName; octave: number; hz: number; val: number }>> = {}

      for (const note of vals) {
        const { note: noteName, octave } = NoteLib.noteFromFrequency(note.hz)
        const existingNote = topNotes[noteName]
        if (!existingNote) {
          topNotes[noteName] = { note: noteName, octave, hz: note.hz, val: note.val }
          continue
        }
        if (existingNote.val > note.val) {
          continue
        }
        topNotes[noteName] = { note: noteName, octave, hz: note.hz, val: note.val }
      }

      const sorted = [...Object.values(topNotes)].sort((a, b) => b.val - a.val)
      const topVals = sorted.slice(0, maxNotes)

      setTopNotes(topVals)
      if (onNotes) {
        onNotes(topVals)
      }
    },
    [dbThreshold, maxNotes, onNotes]
  )

  useEffect(() => {
    audioController.enableDSPFeature('fft', true)
    audioController.addFeatureListener('fft', handleFFT)

    return () => {
      /** @todo what if others require chroma? */
      /** @todo perhaps we should automatically check if we have  */
      audioController.enableDSPFeature('fft', false)
      audioController.removeFeatureListener('fft', handleFFT)
    }
  }, [audioController, handleFFT])

  return (
    <HBox justifyContent="space-between">
      {topNotes.map(({ note, hz, octave }, idx) => (
        <Typography key={idx}>
          {note}
          {octave} / {hz.toFixed(2)} Hz
        </Typography>
      ))}
    </HBox>
  )
}

export default FFTNotesView
