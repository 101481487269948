import type { OpenAI } from 'openai'
import type { ChatMessage } from '@tunasong/schemas'

export const mergeChunks = <T extends Omit<ChatMessage, 'content'> & { content: string | null }>({
  message,
  chunks,
}: {
  message: T
  chunks: OpenAI.Chat.ChatCompletionChunk[]
}): ChatMessage => {
  let merged = {
    ...message,
  }
  for (const chunk of chunks) {
    const chunkDelta = chunk.choices[0].delta
    let content: string | null = null
    if (merged.content !== null && chunkDelta.content) {
      content = merged.content + chunkDelta.content
    } else if (merged.content === null && chunkDelta.content) {
      content = chunkDelta.content
    } else {
      content = merged.content
    }

    merged = {
      ...merged,
      ...(chunkDelta as Partial<ChatMessage>),
      content,
      function_call:
        chunkDelta.function_call || merged.function_call
          ? {
              ...merged.function_call,
              ...chunkDelta.function_call,
              name: chunkDelta.function_call?.name ?? merged.function_call?.name ?? '',
              arguments: (merged.function_call?.arguments ?? '') + (chunkDelta.function_call?.arguments ?? ''),
            }
          : undefined,
    }
  }
  return merged as ChatMessage
}
