import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import type { AudioPeaks } from '@tunasong/models'
import { useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { useAudioEngine, useTransport } from '../../hooks/index.js'
import type { Recorder } from '../../recorder/index.js'
import { Wave } from '../../waveform/wave.js'
import { useStyles } from './clip.styles.js'

/** Dynamic clip that is used when recording to gradually expand */

export interface RecordingClipProps extends BoxProps {
  className?: string
  recorder?: Recorder | null
  pixelsPerSecond: number
}

export const RecordingClip: FC<RecordingClipProps> = props => {
  const { className, recorder, pixelsPerSecond, ...restProps } = props
  const { classes } = useStyles()
  const { transport } = useTransport()
  const engine = useAudioEngine()
  const ref = useRef<HTMLDivElement | null>(null)
  const [peaks, setPeaks] = useState<AudioPeaks>()

  /** @todo how to get the start time */
  const clipStart = recorder?.startTransportTime ?? 0

  const recording = recorder?.state === 'recording'

  useEffect(() => {
    const id = transport.scheduleRepeat(time => {
      engine.draw.schedule(() => {
        const clipStartCtx = recorder?.startContextTime ?? 0
        const width = pixelsPerSecond * time - clipStartCtx * pixelsPerSecond
        if (ref.current) {
          ref.current.style.width = `${width}px`
        }
      }, time)
    }, 0.5)
    return () => {
      transport.clear(id)
    }
  }, [engine.draw, pixelsPerSecond, recorder, transport, transport.state])

  /** Redraw the envelope a little less aggressively */
  useEffect(() => {
    if (!(recording && recorder)) {
      return
    }
    let lastPeakIdx = 0
    const id = transport.scheduleRepeat(time => {
      engine.draw.schedule(() => {
        if (!recorder.rawBuffers) {
          return
        }
        /** Get the new data, average it and store it  */
        const data = recorder.rawBuffers[0] ?? []
        const newData = data.subarray(lastPeakIdx, data.length)
        const avg = newData.reduce((partial, val) => partial + Math.abs(val), 0) / newData.length
        const peak = Number.isFinite(avg) ? avg : 0

        setPeaks(peaks => ({
          type: 'peak',
          length: (peaks?.data?.length ?? 0) + 1,
          channels: 1,
          data: [...(peaks?.data ?? []), peak],
        }))

        lastPeakIdx = data.length
      }, time)
    }, 0.1)

    return () => {
      transport.clear(id)
    }
  }, [engine.draw, pixelsPerSecond, recorder, recording, transport, transport.state])

  const clipStyle = {
    left: pixelsPerSecond * clipStart,
  }

  if (!recording) {
    return null
  }

  return (
    <Box {...restProps} className={className} style={clipStyle} ref={ref}>
      {peaks ? <Wave mode="fixed" peaks={peaks} className={classes.recordingWav} /> : null}
    </Box>
  )
}

export default RecordingClip
