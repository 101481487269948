/** Calculate the CSS descriptor of the computed font for an element. */
export const getComputedFont = (el: HTMLElement) => {
  const compStyles = window.getComputedStyle(el)
  return compStyles.getPropertyValue('font')
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */

// re-use canvas object for better performance
let canvas: HTMLCanvasElement
export const getTextWidth = (text: string, font: string) => {
  canvas = canvas ?? document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    throw new Error(`Cannot create Canvas context`)
  }
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}
