import React from 'react'
import type { FC } from 'react'
import { Collapsable } from './collapsable.js'
import { CoreBlock } from './core-block.js'
import type { CoreBlockProps } from './core-block.js'
import { DndBlock } from './dnd-block.js'

export interface BlockProps extends Omit<CoreBlockProps, 'dragRef' | 'blockRef' | 'dropLine' | 'isDragging'> {
  draggable?: boolean
  collapsable?: boolean
}

const BlockFragment = ({ children }: { children: React.ReactNode; title?: string }) => (
  <React.Fragment>{children}</React.Fragment>
)

export const Block: FC<BlockProps> = React.memo(props => {
  const { draggable = true, collapsable = false, title, ...restProps } = props

  const Container = collapsable ? Collapsable : BlockFragment

  return (
    <Container title={title}>
      {draggable ? <DndBlock {...restProps} title={title} /> : <CoreBlock {...restProps} title={title} />}
    </Container>
  )
})
