import type { Alert } from '@tunasong/models'
import { features, useThunkDispatch } from '@tunasong/redux'
import { useCallback } from 'react'

/** @todo should be moved to notifications slice */
export const useAlert = () => {
  const dispatch = useThunkDispatch()

  const clearAlert = useCallback(() => dispatch(features.notifications.actions.clearAlert()), [dispatch])
  const alert = useCallback((alert: Alert) => dispatch(features.notifications.actions.setAlert(alert)), [dispatch])

  return {
    alert,
    clearAlert,
  }
}
