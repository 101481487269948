import { StyledEngineProvider, useColorScheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import AudioContainer, { AudioEngine } from '@tunasong/audio-ui'
import { logger, shortUuid } from '@tunasong/models'
import { useRegisterWebPush } from '@tunasong/notifications'
import { GlobalAppContext } from '@tunasong/plugin-lib'
import { ConfigService, useEntityGraph, useStore, useThunkDispatch } from '@tunasong/redux'
import { EntityActionsMenu, ErrorGeneral, ReleaseNotesDialog } from '@tunasong/tunasong-ui'
import type { UIExtensions } from '@tunasong/ui-lib'
import {
  Alert,
  FullPageProgress,
  TunaTheme,
  UIExtensionsContext,
  UploadContext,
  UploadManager,
  useDeviceInfo,
  useFormDialog,
  useGlobalFormDialog,
  useIsBusy,
  useNavigateToEntity,
} from '@tunasong/ui-lib'
import { WebSocketContext, useReceiveRedux } from '@tunasong/ws'
import React, { useEffect, useMemo, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import type { ZodSchema } from 'zod'
import { useSentry } from './3rdparty/sentry.js'
import TunaGlobalStyles from './app-global-styles.js'
import { AuthGuard } from './auth/index.js'
import { DIContainer } from './di/di-container.js'
import { FullPageLayout } from './di/layouts/full-page.js'
import { useGlobalHotkeys } from './hooks/global-hotkeys.js'
import { useSWSync } from './sw/sw-sync.js'
import { useCreateWebsocket } from './ws/create-websocket.hook.js'

export interface AppProps {
  children?: React.ReactNode
  component: React.FunctionComponent<Record<string, unknown>>
  /** The route requires an authenticated user*/
  auth?: boolean
  /** The route requires the Audio mixer set up. @default false */
  audio?: boolean
  /** Use the specified theme. @default is to use the user's theme */
  theme?: 'light' | 'dark'
  audioEngine: AudioEngine
  configService: ConfigService
}

export const AppContainer: React.FC<AppProps> = ({
  auth = true,
  audio: audioEnabled = false,
  component: Component,
  theme: providedTheme,
  audioEngine,
  configService,
  children,
  ...restProps
}) => {
  /** Global styles */

  const appContainerId = useRef(shortUuid())

  useEffect(() => {
    const containerId = appContainerId.current
    logger.debug(`AppContainer ${containerId} mounted`)
    return () => {
      logger.debug(`AppContainer ${containerId} unmounted`)
    }
  }, [])

  const isBusy = useIsBusy()

  const { styles: sentryStyles } = useSentry()

  const { isIPad, isMobile } = useDeviceInfo()

  /** Receive messages from Service Worker */
  useSWSync()

  const { globalHotkeyModals } = useGlobalHotkeys()

  /** Request notifications */
  useRegisterWebPush()

  /** Websocket */
  const webSocket = useCreateWebsocket()

  /** Handle redux messages over the websocket */
  useReceiveRedux(webSocket)

  // App specific global App context
  const { getState, subscribe } = useStore()
  const dispatch = useThunkDispatch()
  const navigateToEntity = useNavigateToEntity()
  const prompt = useFormDialog()
  const graph = useEntityGraph()

  // Upload manager
  const uploadManager = useMemo(
    () => new UploadManager({ dbName: 'entity-uploads', dispatch, getState }),
    [dispatch, getState]
  )

  const AuthContainer = auth ? AuthGuard : React.Fragment
  const Audio = audioEnabled ? AudioContainer : React.Fragment

  const { mode, systemMode } = useColorScheme()
  const theme = providedTheme ? providedTheme : mode === 'system' ? systemMode : mode

  const globalAppContext = {
    engine: audioEngine,
    configService,
    /** UIContext extensions for app. These can use components in app, and be surfaced in the standard components. */
    uiExtensions: {
      entityList: {
        ItemMenu: EntityActionsMenu,
      },
    } satisfies UIExtensions,
    getState,
    dispatch,
    subscribe,
    navigateToEntity,
    graph,
    prompt: <T extends Record<string, unknown>>({ schema, title }: { schema: ZodSchema<T>; title: string }) =>
      prompt({
        schema,
        dialogProps: {
          title,
        },
      }),
  }

  const { formDialog } = useGlobalFormDialog()

  const { dndBackend, dndOptions } = useMemo(() => {
    if (isMobile || isIPad) {
      return {
        dndBackend: TouchBackend,
        dndOptions: {
          enableMouseEvents: true,
          delayTouchStart: 200,
        },
      }
    }
    return { dndBackend: HTML5Backend, options: {} }
  }, [isIPad, isMobile])

  return (
    <Sentry.ErrorBoundary fallback={ErrorGeneral}>
      <GlobalAppContext.Provider value={globalAppContext}>
        <UploadContext.Provider value={uploadManager}>
          <StyledEngineProvider injectFirst>
            {sentryStyles}
            <UIExtensionsContext.Provider value={globalAppContext?.uiExtensions ?? null}>
              {/* @todo make adapter locale configurable */}
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={navigator.language}>
                <TunaTheme theme={theme}>
                  <TunaGlobalStyles />
                  <CssBaseline />

                  <FullPageLayout>
                    <WebSocketContext.Provider value={webSocket}>
                      <DIContainer>
                        <DndProvider backend={dndBackend} options={dndOptions}>
                          <FullPageProgress show={isBusy} />
                          <AuthContainer>
                            <ReleaseNotesDialog />
                            <Alert anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} />
                            <Audio engine={globalAppContext?.engine ?? null}>
                              <Component {...restProps} />
                              {/* Prompt / form dialog */}
                              {formDialog}
                              {globalHotkeyModals}
                              {children}
                            </Audio>
                          </AuthContainer>
                        </DndProvider>
                      </DIContainer>
                    </WebSocketContext.Provider>
                  </FullPageLayout>
                </TunaTheme>
              </LocalizationProvider>
            </UIExtensionsContext.Provider>
          </StyledEngineProvider>
        </UploadContext.Provider>
      </GlobalAppContext.Provider>
    </Sentry.ErrorBoundary>
  )
}

export default AppContainer
