import type { Shortcut } from './shortcut.js'

const shortcutActions = [
  'Search',
  'ShowHelp',
  'Suggestions',
  'Save',
  'SoftBreak',
  'ExitBreak',
  'Comments',
  'Link',
  'TogglePool',
  'RunCommand',
] as const
type ShortCutType = (typeof shortcutActions)[number]

export const shortcuts: Record<ShortCutType, Shortcut> = {
  RunCommand: {
    description: 'Run Command',
    hotkey: 'mod+k',
  },

  Comments: {
    description: 'Comments',
    hotkey: 'shift+mod+m',
  },
  Search: {
    description: 'Search',
    hotkey: 'mod+f',
  },
  SoftBreak: {
    description: 'Soft Break',
    hotkey: 'shift+enter',
  },

  ExitBreak: {
    description: 'Exit Block',
    hotkey: 'mod+enter',
  },
  ShowHelp: {
    description: 'Show Help',
    hotkey: 'mod+?',
  },

  Suggestions: {
    description: 'Suggestions',
    hotkey: 'ctrl+space',
  },

  Save: {
    description: 'Save',
    hotkey: 'mod+s',
  },
  Link: {
    description: 'Link',
    hotkey: 'mod+k',
  },
  TogglePool: {
    description: 'Show/Hide Pool',
    hotkey: 'mod+p',
  },
}

export default shortcuts
