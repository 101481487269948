/** Hook to handle the signaling for a room */
import { logger } from '@tunasong/models'
import type { UserInfo, WSMessage } from '@tunasong/models'
import { features, useStore, useThunkDispatch } from '@tunasong/redux'
import type { WSReduxType } from '@tunasong/redux'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useCallback, useContext, useEffect, useRef } from 'react'
import invariant from 'tiny-invariant'
import { WebSocketContext } from './websocket-context.js'

/**
  Handle presence signaling
*/

interface UserInfoWithClient extends UserInfo {
  clientId: string
}

interface PresenceMessage extends UserInfoWithClient {
  entityId: string
}

export const usePresenceSignaling = (entity?: Persisted<Entity>) => {
  const socket = useContext(WebSocketContext)
  const dispatch = useThunkDispatch()
  const queue = useRef<WSMessage[]>([])
  /** @note use use getState to avoid re-render (and resubscribe to Websocket events) */
  const { getState } = useStore()
  const currentEntityId = entity?.id ?? null

  // const presence = entity?.id ? state.presence.activeUsers[entity.id] : null
  const getPresence = useCallback(() => {
    const state = getState()
    return {
      userId: state.user.userId,
      name: state.user.name ?? 'A Girl Has No Name',
      clientId: state.presence.clientId,
      entityId: state.presence.activeEntityId,
      activeUsers: state.presence.activeClients,
    }
  }, [getState])

  const sendMessage = useCallback(
    (wsMsg: WSMessage) => {
      queue.current.push(wsMsg)

      /** Empty queue if the socket is up */
      if (!socket) {
        logger.debug('usePresenceSignaling: sendMessage called without a socket, queueing message')
        return
      }
      let msg: WSMessage | undefined
      while (socket.readyState === socket.OPEN && (msg = queue.current.pop())) {
        socket.send(JSON.stringify(msg))
      }
    },
    [socket]
  )

  const sendEnterMessage = useCallback(
    ({ entityId, userId, name, clientId }: PresenceMessage) =>
      sendMessage({
        action: 'webrtc',
        data: features.presence.actions.enterEntity({
          senderClientId: clientId,
          entityId,
          userInfo: {
            userId,
            name,
          },
        }),
      }),
    [sendMessage]
  )

  const sendLeaveMessage = useCallback(
    ({ entityId, userId, name, clientId }: PresenceMessage) => {
      sendMessage({
        action: 'webrtc',
        data: features.presence.actions.leaveEntity({
          senderClientId: clientId,
          entityId,
          userInfo: {
            userId,
            name,
          },
        }),
      })
    },
    [sendMessage]
  )

  /**
   * Handle presence messages from peers
   */
  const handlePresence = useCallback(
    ({ data: payload }: MessageEvent) => {
      const wsMsg = JSON.parse(payload) as WSMessage<WSReduxType>
      /** @todo Presence is a 'webrtc' type event for legacy reasons */
      if (wsMsg.action !== 'webrtc') {
        return
      }

      const { clientId } = getPresence()

      const msg = wsMsg.data

      if (features.presence.actions.enterEntity.match(msg)) {
        // dispatch(
        //   features.notifications.actions.setAlert({
        //     title: 'Presence',
        //     severity: 'success',
        //     message: `${msg.payload.userInfo.nickName} just entered this space`,
        //     duration: 3000,
        //   })
        // )
      } else if (
        features.presence.actions.setActiveClientsForEntity.match(msg) &&
        msg.payload.activeClients.length > 0
      ) {
        // dispatch(
        //   features.notifications.actions.setAlert({
        //     title: 'Presence',
        //     severity: 'success',
        //     message: `Active in space: ${msg.payload.activeClients.map(c => c.userInfo.nickName).join(', ')}`,
        //     duration: 3000,
        //   })
        // )
      } else if (features.presence.actions.leaveEntity.match(msg)) {
        invariant(
          msg.payload.senderClientId !== clientId,
          `handlePresence leaveEntity: cannot receive message from myself. Ouch.`
        )

        /** too chatty - for now we use enter notifications only */
        // if (msg.payload.entityId === entityId) {
        //   dispatch(
        //     features.notifications.actions.setAlert({
        //       severity: 'info',
        //       message: `${msg.payload.userInfo?.nickName} has left`,
        //     })
        //   )
        // }
      }

      /** Simply dispatch the presence event to the Redux store */
      dispatch(msg)
    },
    [dispatch, getPresence]
  )

  /** Send presence information to peers when entityId changes */
  useEffect(() => {
    /** We trigger this on entity changes */

    dispatch(features.presence.actions.setActiveEntity({ entityId: currentEntityId }))

    if (!(socket && currentEntityId)) {
      return
    }

    const { userId, name, clientId } = getPresence()
    sendEnterMessage({ userId, name, clientId, entityId: currentEntityId })

    /** Leave room on exit */
    return () => {
      sendLeaveMessage({ entityId: currentEntityId, userId, name, clientId })
    }
  }, [dispatch, currentEntityId, getPresence, sendEnterMessage, sendLeaveMessage, socket])

  /** Handler functions for websocket */
  const handleConnect = useCallback(() => {
    const { userId, name, clientId, entityId } = getPresence()
    if (!(entityId && userId)) {
      logger.warn('No entity or user ID, cannot send presence message')
      return
    }
    sendEnterMessage({ entityId, userId, name, clientId })
  }, [getPresence, sendEnterMessage])

  const handleLeave = useCallback(() => {
    const { userId, name, clientId, entityId } = getPresence()
    invariant(entityId && userId, 'No entity or user ID, cannot send presence message')
    sendLeaveMessage({ entityId, userId, name, clientId })
  }, [getPresence, sendLeaveMessage])

  // Listen to events on the Websocket
  useEffect(() => {
    if (!socket) {
      return
    }
    /** @note to avoid re-subscribing every time the entity changes we don't depend on anything other than the socket here */
    logger.debug(`*** Presence signaling init at ${new Date().toISOString()}`)

    socket.addEventListener('message', handlePresence)
    socket.addEventListener('open', handleConnect)
    socket.addEventListener('close', handleLeave)

    return () => {
      logger.debug(`*** Presence signaling shutdown at ${new Date().toISOString()}`)
      socket.removeEventListener('message', handlePresence)
      socket.removeEventListener('open', handleConnect)
      socket.removeEventListener('close', handleLeave)
    }
  }, [socket, handleConnect, handlePresence, handleLeave])
}
