import { isLine } from '@tunasong/models'
import type { TunaElement } from '@tunasong/models'
import type { Chord } from '@tunasong/music-lib'
import { Editor } from '@tunasong/plugin-lib'
import type { TunaEditor } from '@tunasong/plugin-lib'
import { isCoreElement } from '@tunasong/schemas'
import { Location, Path, Point, Range, Span } from 'slate'
import type { NodeEntry } from 'slate'
import type { PositionMode } from './position.js'
import { RangeQueries } from './range.js'
import { SectionQueries } from './section.js'

/** Use the actual node value to allow object comparisons. We need this to effectively not re-render chords */
const mapToChords = (nodes: Iterable<NodeEntry>) => {
  const chrd: Chord[] = []
  for (const [node] of nodes) {
    chrd.push(node as TunaElement<Chord>)
  }
  return chrd
}

const isChord = (m: unknown) => isCoreElement(m) && m.type === 'chord'

/** A line with a chord */
const isChordLine = (editor: TunaEditor) => {
  const line = Editor.above(editor, { match: isLine })
  if (!line) {
    return false
  }
  if (Editor.isEmpty(editor, line[0])) {
    return true
  }
  const at = {
    anchor: Editor.start(editor, line[1]),
    focus: Editor.end(editor, line[1]),
  }
  return find(editor, { at }).length > 0
}

/** Find chords in  Range specified by at. If Range is not specified, search the complete document. */
const find = (editor: TunaEditor, props: { at?: Location | Span } = {}) => {
  const { at = RangeQueries.all(editor) } = props
  const nodes = Editor.nodes(editor, {
    at,
    match: isChord,
  })
  return mapToChords(nodes)
}

const chords = (editor: TunaEditor, props: { mode?: PositionMode } = {}) => {
  if (!editor.selection) {
    return []
  }
  let range: Range | Path | Point | undefined

  const { mode = 'current' } = props
  switch (mode) {
    case 'selected':
      range = editor.selection
      break

    /** Current is a dynamic range that defaults to the current section */
    case 'current':
      range = SectionQueries.currentRange(editor)
      break

    case 'previous':
      /** Find first text before anchor */
      const p = Editor.previous(editor)
      range = {
        anchor: p ? Editor.end(editor, p[1]) : editor.selection.anchor,
        focus: Editor.start(editor, []),
      }
      break
    case 'next':
      const n = Editor.next(editor)
      range = {
        anchor: n ? Editor.start(editor, n[1]) : editor.selection.anchor,
        focus: Editor.end(editor, []),
      }
  }
  const nodes = Editor.nodes(editor, { at: range, match: isChord })
  return mapToChords(nodes)
}

export const chord = (editor: TunaEditor, props: { mode?: PositionMode } = {}) => {
  const { mode = 'current' } = props
  const c = chords(editor, props)
  switch (mode) {
    case 'current':
    case 'next':
      return c[0]
    case 'previous':
      /** Need to return the last chord */
      return c.length > 0 ? c[c.length - 1] : undefined
  }
  return undefined
}

export const ChordQueries = {
  find,
  chord,
  chords,
  isChordLine,
}
