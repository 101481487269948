import { capitalize } from '@tunasong/models'
import type { FilterFunc } from '@tunasong/models'
import { isCoreElement, isInternalEntity } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { dispatchRootEvent } from '../events.js'
import { EditorQueries } from '../queries/editor-queries.js'
import type { TunaEditor } from '../plugin-types.js'
import type { ShowBlockPropertiesEvent } from '../hooks/editor-message.js'

interface ShowPropertiesCommand {
  editor: TunaEditor
  nodeFilter?: FilterFunc
  title?: string
}

export const showPropertiesCommand = ({ title, editor, nodeFilter }: ShowPropertiesCommand) => {
  if (!nodeFilter) {
    nodeFilter = e => isCoreElement(e) && !isInternalEntity(e)
  }
  const nodeEntry = EditorQueries.currentBlockNode(editor, nodeFilter)
  if (!nodeEntry) {
    return null
  }

  const el = nodeEntry[0]
  invariant(isCoreElement(el), 'nodeEntry[0] should be defined')

  const data: Partial<ShowBlockPropertiesEvent> = {
    title: title ?? capitalize(el.type),
  }
  return () => {
    dispatchRootEvent(editor, nodeEntry, 'show-block-properties', data)
    return editor
  }
}
