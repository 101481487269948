import { useTheme } from '@mui/material'
import { makeStyles } from '@tunasong/ui-lib'
import { ChordLib, GuitarLib, isChordVariant, NoteLib, ScaleLib } from '@tunasong/music-lib'
import type { Chord } from '@tunasong/music-lib'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import type { FC } from 'react'
import { defaultHeight, defaultWidth, draw } from './guitar-chord-draw.js'
import type { Scale } from '@tunasong/schemas'

export interface ChordProps {
  className?: string
  chord?: Chord
  /** If specified, the in/out scale notes will be marked */
  scale?: Scale | null
}

const useStyles = makeStyles()(() => ({
  root: {
    flex: 1,
    width: defaultWidth,
    height: defaultHeight,
  },
}))

/**
 * Layout
 *
 *  Chord name
 *  --padding--
 *  x   o    x
 *  ============ (nut)
 *    o          (fingering)
 *  ------------ (fret)
 *        o      (fingering)
 *  ------------ (fret)
 *
 *    2   3       (fingering fret number)
 */

export const GuitarChord: FC<ChordProps> = props => {
  const { className, chord, scale } = props
  const { classes } = useStyles()
  const theme = useTheme()
  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  const {
    name = 'Unknown',
    fingering = [],
    scaleDegree,
  } = useMemo(() => {
    if (!chord) {
      return {}
    }
    /** A guitar chord can also be a Chord */
    const scaleDegree = scale && isChordVariant(chord) ? ScaleLib.getChordDegree(scale, chord) : undefined
    const name = ChordLib.getName(chord)
    const fingering = isChordVariant(chord)
      ? GuitarLib.getFingering(chord)
      : GuitarLib.isGuitarChord(chord)
        ? chord.fingering
        : null

    return { name, fingering: GuitarLib.getFingeringWithMetadata(fingering ?? [], scale), scaleDegree }
  }, [chord, scale])

  const type = isChordVariant(chord) ? chord?.variant ?? '' : ''

  const label = useMemo(() => (name ? NoteLib.prettifyNote(name) : 'Custom'), [name])

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    draw({ canvas: canvasRef.current, fingering, degree: scaleDegree, label, type, theme })
  }, [fingering, label, scaleDegree, theme, type])

  return <canvas className={classNames(className, classes.root)} ref={canvasRef} />
}

export default GuitarChord
