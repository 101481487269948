import jwtDecode from 'jwt-decode'
import type { JwtPayload } from 'jwt-decode'
import type { AuthData } from './auth-data.js'

export interface DecodeParams {
  idToken: string
  accessToken: string
  refreshToken: string
  expiresIn: number
}

export const decodeOAuthData = ({ idToken, accessToken, refreshToken, expiresIn }: DecodeParams): AuthData => {
  const data: AuthData = {
    idToken: {
      jwtToken: idToken,
      payload: jwtDecode<JwtPayload>(idToken),
    },
    accessToken: {
      jwtToken: accessToken,
    },
    refreshToken: {
      jwtToken: refreshToken,
    },
    expires: Date.now() + expiresIn * 1000,
  }

  return data
}
