import { entitySort } from '@tunasong/models'
import type { FilterFunc, SortOptions } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'

export const useEntitySort = <T extends Entity>(
  entities: Persisted<T>[],
  /** If false, do not sort */
  sortOptions: SortOptions | false,
  filter?: FilterFunc
) => {
  if (sortOptions === false) {
    return entities
  }
  const sortedEntities = entitySort({
    entities,
    sortOptions,
    filter,
  })

  return sortedEntities as Persisted<T>[]
}
