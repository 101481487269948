import { Box, Fab, Stack, Typography } from '@mui/material'
import type { StackProps } from '@mui/material'
import type { VirtualElement } from '@popperjs/core'
import { Next, Play as PlayIcon, Prev, Stop as StopIcon } from '@tunasong/icons'
import type { AudioPosition } from '@tunasong/models'
import type { Media, Persisted } from '@tunasong/schemas'
import { Popup, useHotkey } from '@tunasong/ui-lib'
import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useTimelineInteraction } from './hooks/index.js'
import { trackMouse } from './lib/index.js'
import { usePlayerControl } from './player-control.hook.js'
import { PlayerProgress } from './progress.js'

export interface PlayerControlsProps extends Omit<StackProps, 'onSelect' | 'ref'> {
  el: HTMLAudioElement | null
  // Name of the audio channel
  name?: string
  media: Persisted<Media>[]
  active?: Persisted<Media>
  title?: string
  /** Capture keyboard events to control start/stop, next, prev */
  keyboardControl?: boolean
  onSelect(idx: number): void
  onSeek?(pos: AudioPosition): void
}

export const PlayerControls: FC<PlayerControlsProps> = props => {
  const { el, name, media, active, title, onSeek, onSelect, keyboardControl = false, ...restProps } = props

  const { next, prev, togglePlay, playing, progressLabel } = usePlayerControl({
    el,
    name,
    media,
    active,
    onSelect,
  })

  const [popupEl, setPopupEl] = useState<VirtualElement | null>(null)

  /** Track user interaction */
  const { position, pagePosition, timelineRef } = useTimelineInteraction({
    duration: el?.duration,
    onSeek,
  })

  useEffect(() => {
    setPopupEl(pagePosition ? trackMouse(pagePosition) : null)
  }, [pagePosition])

  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useHotkey({ hotkey: 'ArrowLeft', handler: prev, disabled: !keyboardControl, el: ref })
  useHotkey({ hotkey: 'ArrowRight', handler: next, disabled: !keyboardControl, el: ref })
  useHotkey({ hotkey: 'Space', handler: togglePlay, disabled: !keyboardControl, el: ref })

  if (!el) {
    return null
  }

  return (
    <>
      <Stack
        {...restProps}
        ref={setRef}
        spacing={2}
        sx={{ ...restProps.sx, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Stack direction="row" spacing={2}>
          <Fab disabled={!prev} size={'medium'} title={title} onClick={prev}>
            <Prev />
          </Fab>
          <Fab size={'medium'} title={title} disabled={!active} onClick={togglePlay} color="secondary">
            {playing ? <StopIcon /> : <PlayIcon />}
          </Fab>
          <Fab disabled={!next} size={'medium'} title={title} onClick={next}>
            <Next color="inherit" />
          </Fab>
        </Stack>

        <PlayerProgress ref={timelineRef} el={el} />

        <Box display="flex" flexDirection="row" flex={1} mt={1}>
          <Typography variant="caption">{active?.name}</Typography>
          <Box ml={1} />
          <Typography variant="caption">{progressLabel}</Typography>
        </Box>
      </Stack>
      <Popup open={Boolean(popupEl)} anchorEl={popupEl} placement="right-end">
        <Typography variant="caption">{position?.label}</Typography>
      </Popup>
    </>
  )
}

export default PlayerControls
