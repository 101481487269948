import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AudioPeaks } from '@tunasong/models'

export interface Player {
  url?: string
  name?: string
  isPlaying: boolean
}

export interface AudioState {
  player: Player
  /** Audio Peaks indexed by the path */
  peaks: Record<string, AudioPeaks>
}

const initialState: AudioState = {
  player: {
    isPlaying: false,
  },
  peaks: {},
}

export const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setPlayer: (state: AudioState, action: PayloadAction<Partial<Player>>) => {
      state.player = { ...state.player, ...action.payload }
    },
    setPeaks: (state: AudioState, action: PayloadAction<{ path: string; peaks: AudioPeaks }>) => {
      const { path, peaks } = action.payload
      state.peaks[path] = peaks
    },
  },
})
