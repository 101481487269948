import type { UserConfig } from '@tunasong/manifest'
import { useGlobalAppContext } from '@tunasong/plugin-lib'
import { ConfigService, useProfile, useSelector, useStore, useThunkDispatch } from '@tunasong/redux'
import type { UserConfigService } from '@tunasong/redux'
import { useEffect } from 'react'

/**
 * Async user configuration service. This loads configuration async, so clients that must
 * have updated information should use this and wait for the promise to resolve.
 *
 * Others can use the synchronous version(s) that look like useState() hooks.
 */

export const useAsyncUserConfigService = (): UserConfigService => {
  /** Currently we use the profile to store configuration */
  const { profile } = useProfile()
  const dispatch = useThunkDispatch()
  const { getState, subscribe } = useStore()
  const { configService } = useGlobalAppContext()

  // This is a bit of an initialization hack
  useEffect(() => {
    const service = configService as ConfigService
    if (service.ready || !profile) {
      return
    }
    service.init({ dispatch, getState, subscribe, profile })
  }, [configService, dispatch, getState, profile, subscribe])

  return configService
}

/** Use function overloads to resolve the generics (thanks co-pilot) */
export function useUserConfig<T extends keyof UserConfig>(
  key: T
): [config: UserConfig[T], updateConfig: (value: UserConfig[T]) => void, hasLoaded: boolean]

export function useUserConfig<T extends keyof UserConfig | Partial<UserConfig>>(): [
  config: UserConfig,
  updateConfig: (keyOrValue: T, value?: T extends keyof UserConfig ? UserConfig[T] : never) => void,
  hasLoaded: boolean,
]

export function useUserConfig<T extends keyof UserConfig>(key?: T) {
  const userConfig = useSelector(state => state.config.userConfig)
  const hasLoaded = useSelector(state => state.config.userConfigLoaded)
  const { updateUserConfig, updateUserConfigByKey } = useAsyncUserConfigService()

  if (key) {
    const value = userConfig[key]
    // Explicitly assert the return type for the specific key case
    const updateConfig = (value: UserConfig[T]) => updateUserConfigByKey(key, value)
    return [value, updateConfig, hasLoaded]
  }
  // For the general case, we might need to adjust based on your UserConfig type structure
  // Assuming updateUserConfig can be used directly if no key is provided
  // Explicitly assert the return type for the general case
  return [userConfig, updateUserConfig, hasLoaded]
}
