import type { SidebarComponent } from '@tunasong/ui-lib'
import { useEditor } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import { DebugInfo } from './debug-info.js'

export const SidebarDebug: FC<SidebarComponent> = ({}) => {
  const editor = useEditor({ allowNull: true })

  return editor ? <DebugInfo editor={editor} /> : null
}
