import type { SearchRequest } from '@tunasong/schemas'
import type { QueryProps } from './query-props.js'
/** Search for single ID, and return everything including embeddings */
const query = ({ query, size = 100 }: QueryProps): SearchRequest | undefined =>
  query
    ? {
        index: 'entities',
        size,
        from: 0,
        body: {
          query: {
            term: {
              _id: query,
            },
          },
        },
      }
    : undefined

export default query
