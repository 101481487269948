import type { Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'

import { useMemo } from 'react'
import { entitiesApi } from '../api/entities.js'

/** Load entity, partially loading children if those have not been loaded */
export const useEntitiesById = <T extends Entity = Persisted<Entity>>(ids: string[] = []) => {
  const sortedIds = useMemo(
    () =>
      ids
        .filter(id => id !== 'ROOT')
        .filter(Boolean)
        // Sort to ensure that we don't refetch the same entities in a different order
        .sort(),
    [ids]
  )
  const {
    currentData: entities,
    isLoading,
    isSuccess,
    error,
    isError,
  } = entitiesApi.useLoadEntitiesQuery({ ids: sortedIds }, { skip: !sortedIds || sortedIds.length === 0 })

  /** Some components require the entities in the specified order */
  const orderedEntities = useMemo(
    () => ids.map(id => entities?.find(entity => entity.id === id)).filter(Boolean) as unknown as T[],
    [entities, ids]
  )

  return { entities: orderedEntities, isError, error, isLoading, isSuccess: isSuccess || sortedIds.length === 0 }
}
