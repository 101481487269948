import { logger } from '@tunasong/models'
import type { AudioPeaks } from '@tunasong/models'
import { useEffect, useState } from 'react'
import { AudioPlayer } from '../instruments/audio-player.js'

export const getPeaks = async (url: string): Promise<AudioPeaks> =>
  new Promise<AudioPeaks>(resolve => {
    const p = new AudioPlayer(url, () => {
      const peaks = p.renderPeaks(200)
      resolve({
        type: 'peak',
        data: peaks,
        channels: 2,
        length: p.getChannelData(0).length,
        sample_rate: 44100,
      })

      p.disconnect()
      p.dispose()
    })
  })

/**
 * Render peaks from the source audio
 *
 * @note the complete audio is downloaded to generate the peaks
 */
export const useRenderPeaks = (url: string | null = null) => {
  const [peaks, setPeaks] = useState<AudioPeaks>()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (peaks || !url) {
      return
    }
    setLoading(true)

    getPeaks(url)
      .then(renderedPeaks => {
        setLoading(false)
        setPeaks(renderedPeaks)
      })
      .catch(err => {
        logger.error(`Failed to load peaks ${url}: `, err)
        setPeaks(undefined)
      })
  }, [peaks, url])

  return { peaks, loading }
}
