import type { Theme } from '@mui/material'
import { NoteLib } from '@tunasong/music-lib'
import type { ComplementaryScales } from '@tunasong/music-lib'

export const drawCircleOfFifths = ({
  ctx,
  theme,
  height,
  width,
  circleOfFifths,
}: {
  ctx: CanvasRenderingContext2D
  theme: Theme
  height: number
  width: number
  circleOfFifths: ComplementaryScales[]
}) => {
  const radius = width / 2
  const innerRadius = width / 3.5
  const centerX = width / 2
  const centerY = height / 2

  const drawRadialLabel = (
    label: string,
    fontSize: number,
    angle: number,
    r: number,
    color: string = theme.vars.palette.grey[50]
  ) => {
    const cos = Math.cos(angle)
    const sin = Math.sin(angle)

    ctx.font = `${fontSize}px`

    const labelSize = ctx.measureText(label)

    const offsetX1 = cos * r - labelSize.width / 2
    const offsetY1 = sin * r + fontSize / 2

    ctx.fillStyle = color
    ctx.fillText(label, centerX + offsetX1, centerY + offsetY1)
  }

  // Pie chart - http://www.codeblocq.com/2016/04/Create-a-Pie-Chart-with-HTML5-canvas/
  // Hover effects - https://stackoverflow.com/questions/29300280/update-html5-canvas-rectangle-on-hover
  /** Outer pie */
  ctx.beginPath()
  ctx.fillStyle = theme.vars.palette.secondary.main
  ctx.arc(centerX, centerY, radius, 0, Math.PI * 2, true) // Outer circle
  ctx.fill()

  /** Inner circle */
  ctx.beginPath()
  ctx.fillStyle = ctx.fillStyle = theme.vars.palette.secondary.light
  ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2, true)
  ctx.fill()

  const rad = (2 * Math.PI) / 12
  for (let i = 1; i <= 12; i++) {
    /** Start "at the top" */
    const keys = circleOfFifths[(i + 3 - 1) % 12]
    /** The slices - we center them */
    const startAngle = (i - 1) * rad - rad / 2
    const endAngle = startAngle + rad
    const midAngle = startAngle + (endAngle - startAngle) / 2

    ctx.beginPath()
    ctx.moveTo(centerX, centerY)

    ctx.fillStyle = theme.vars.palette.secondary.light
    ctx.strokeStyle = theme.vars.palette.background.paper
    ctx.arc(centerX, centerY, radius, startAngle, endAngle, false)
    ctx.lineTo(centerX, centerY)

    const largeFont = width / 15
    const smallFont = width / 25

    const majorTextRad = innerRadius + (radius - innerRadius) / 2
    const minorTextRad = innerRadius - innerRadius / 4
    const degreeTextRad = radius - smallFont
    ctx.stroke()

    const textColor = theme.vars.palette.grey[50]
    drawRadialLabel(NoteLib.prettifyNote(keys.major), largeFont, midAngle, majorTextRad, textColor)
    drawRadialLabel(NoteLib.prettifyNote(`${keys.minor}m`), smallFont, midAngle, minorTextRad, textColor)
    if (keys.degree) {
      drawRadialLabel(NoteLib.prettifyNote(keys.degree), smallFont, midAngle, degreeTextRad, textColor)
    }

    ctx.stroke()
  }
}
