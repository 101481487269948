import * as colors from '@mui/material/colors/index.js'
import { Question } from '@tunasong/icons'
import type { SvgIconComponent } from '@tunasong/icons'
import manifest from '@tunasong/manifest'
import { logger } from '@tunasong/models'
import type { CoreElement, ElementType, EntityType } from '@tunasong/schemas'
import type { Entity } from '@tunasong/schemas'
import type { TunaPlugin, TunaPluginRenderProps } from '../plugin-types.js'
import type { JSX } from 'react'

export const getPluginByType = (type: string, plugins: TunaPlugin[] | null) =>
  type ? plugins?.find(p => p?.node?.type === type) : undefined

export const getPlugin = (element: Pick<CoreElement, 'type'>, plugins: TunaPlugin[] | null) =>
  getPluginByType(element?.type, plugins)

export const getEntityIcon = (element: Pick<CoreElement, 'type'>, plugins: TunaPlugin[] | null) => {
  const plugin = getPluginByType(element.type, plugins)
  return plugin?.icon ?? Question
}

export interface ElementMedia {
  icon: SvgIconComponent
  materialColor: string // Valid material-ui color name, e.g., red or teal
  colorCode: string // Valid CSS color code, e.g., #FF0000
}

/** Load entity, partially loading children if those have not been loaded */
export const getElementMedia = (type: string | undefined, plugins: TunaPlugin[] | null) => {
  const plugin = type ? getPluginByType(type, plugins) : undefined
  return getElementMediaForPlugin(plugin)
}

export const getElementMediaForPlugin = (plugin?: TunaPlugin) => {
  const materialColor = plugin?.color ? plugin.color : 'grey'
  const icon = plugin?.icon ? plugin.icon : Question
  const openIcon = plugin?.openIcon ? plugin.openIcon : icon

  // We use the 500 color for the icon
  const colorCode = colors[materialColor][500]

  return {
    materialColor,
    colorCode,
    icon,
    openIcon,
  }
}

export const renderToolbar = (props: TunaPluginRenderProps, plugins: TunaPlugin[] | null) =>
  plugins
    ?.map(p => (p.components?.Toolbar ? <p.components.Toolbar key={p.key} {...props} /> : null))
    .filter(Boolean) as JSX.Element[]

export const getAcceptedMimeTypes = (plugins: TunaPlugin[] | null) => {
  const mimeTypes: Record<string, string[]> = {}

  for (const { mimeTypes: pluginMimeTypes } of plugins ?? []) {
    if (!pluginMimeTypes) {
      continue
    }
    /**  */
    for (const [key, value] of Object.entries(pluginMimeTypes)) {
      if (!mimeTypes[key]) {
        mimeTypes[key] = value
      } else {
        mimeTypes[key] = [...mimeTypes[key], ...value]
      }
    }
  }
  return mimeTypes
}

export const getImageMimeTypes = (plugins: TunaPlugin[] | null) => {
  const mimeTypes = getAcceptedMimeTypes(plugins)
  return {
    'image/*': mimeTypes['image/*'] ?? [],
  }
}

/** Return the first matching mimeType from the plugins. Hence, order of plugins matter */
export const getEntityTypeFromMimeType = (mimeType: string, plugins: TunaPlugin[] | null) => {
  const pluginsWithType = (plugins ?? [])?.filter(p => Boolean(p.node?.type))
  for (const { node, mimeTypes: pluginMimeTypes } of pluginsWithType) {
    const pluginEntityType = node?.type
    if (!(pluginMimeTypes && pluginEntityType)) {
      continue
    }
    for (const key of Object.keys(pluginMimeTypes)) {
      // We have mimeTypes that includes wildcards, e.g., image/*, video/*, etc.
      // we match a fully specified mimeType first, then a wildcard
      const [type, subtype] = mimeType.split('/')
      const [pluginType, pluginSubType] = key.split('/')
      if (type !== pluginType) {
        continue
      }
      // pluginSubType can be a wildcard, so we need to check that
      if (pluginSubType === '*' || pluginSubType === subtype) {
        return pluginEntityType as EntityType
      }
    }
  }
  return undefined
}

type EntityTypeToMimeType = {
  [key in EntityType]: string
}

export const getMimeTypeMapping = (plugins: TunaPlugin[]) => {
  const mimeTypeAccept: Record<string, string[]> = {}
  const mimeTypeToEntityType: Record<string, EntityType> = {}
  const entityTypeToMimeType: EntityTypeToMimeType = {} as EntityTypeToMimeType
  const extensionToEntityType: Record<string, EntityType> = {}

  for (const { node, mimeTypes = {} } of plugins) {
    const type = node?.type as EntityType
    if (!(mimeTypes && type)) {
      continue
    }
    for (const [mimeType, extensions] of Object.entries(mimeTypes)) {
      if (mimeTypeAccept[mimeType]) {
        logger.warn(
          `Mime type ${mimeType} already registered, will overwrite from plugin ${type}.`,
          mimeTypeAccept[mimeType],
          extensions
        )
      }
      mimeTypeAccept[mimeType] = extensions
      mimeTypeToEntityType[mimeType] = type
      entityTypeToMimeType[type] = mimeType
      for (const ext of extensions) {
        extensionToEntityType[ext] = type
      }
    }
  }
  return { mimeTypeAccept, mimeTypeToEntityType, entityTypeToMimeType, extensionToEntityType }
}

export const getMimeTypeMappingFromManifest = () => {
  const entityTypeToMimeType = manifest.entityTypeMimeExtensions
  const mimeTypeAccept: Record<string, string[]> = {}
  const mimeTypeToEntityType: Record<string, EntityType> = {}
  const extensionToEntityType: Record<string, EntityType> = {}

  for (const [eType, mimeTypes] of Object.entries(entityTypeToMimeType)) {
    const type = eType as EntityType
    if (!(mimeTypes && type)) {
      continue
    }
    for (const [mimeType, extensions] of Object.entries(mimeTypes)) {
      if (mimeTypeAccept[mimeType]) {
        logger.warn(
          `Mime type ${mimeType} already registered, will overwrite from plugin ${eType}.`,
          mimeTypeAccept[mimeType],
          extensions
        )
      }
      mimeTypeAccept[mimeType] = extensions
      mimeTypeToEntityType[mimeType] = type
      for (const ext of extensions) {
        extensionToEntityType[ext] = type
      }
    }
  }
  return { mimeTypeAccept, mimeTypeToEntityType, entityTypeToMimeType, extensionToEntityType }
}

export const sortPlugins = (a: TunaPlugin, b: TunaPlugin) => {
  const aVal = a.description ?? a.node?.type
  const bVal = b.description ?? b.node?.type

  if (aVal && bVal && aVal > bVal) {
    return 1
  } else if (a.node?.type === b.node?.type) {
    return 0
  }
  return -1
}

export const getActiveView = (element: CoreElement) => element.options?.activeView ?? 'default'

export const getContentViews = (plugins: TunaPlugin[], type: ElementType) => {
  const plugin = plugins.find(p => p.node?.type === type)
  return typeof plugin?.components?.Content === 'object' ? plugin?.components.Content : {}
}

export const isAllowedChildEntity = ({
  parent,
  entity,
  plugins,
}: {
  parent: Pick<Entity, 'type'>
  entity: Pick<Entity, 'type'>
  plugins: TunaPlugin[]
}) => {
  const parentPlugin = getPlugin(parent, plugins)
  const allowChildrenTypes = parentPlugin?.allowChildrenTypes
  if (!allowChildrenTypes) {
    return false
  }
  if (allowChildrenTypes === true) {
    return true
  }
  return allowChildrenTypes.includes(entity.type)
}
