import type { Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import { useCallback } from 'react'
import { useSearchParams } from '../navigation/navigate.js'

export const useCurrentEntity = ({
  entities = [],
  replaceHistory = false,
}: {
  entities?: Persisted<Entity>[]
  replaceHistory?: boolean
} = {}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentId = searchParams.get('current') ?? entities?.[0]?.id

  const setCurrentId = useCallback(
    (entityId: string) => {
      setSearchParams(
        params => ({
          ...Object.fromEntries(params.entries()),
          current: entityId,
        }),
        {
          replace: replaceHistory,
        }
      )
    },
    [replaceHistory, setSearchParams]
  )

  return { currentId, setCurrentId }
}
