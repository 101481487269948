import { useEffect } from 'react'
import { useTransport } from './transport.js'

/**
 * useAudioTransportControl hook will control the transport with an Media Element. The MediaElement is the
 * master, so this is not going to be sample accurate. Don't use for applications where accurate timing
 * is important, but rather for cases when you just want a transport with your single audio player.
 *
 * useAudioTransportControl does not listen to Transport events.
 */

export const useAudioTransportControl = (el: HTMLAudioElement | null) => {
  const { transport } = useTransport()

  useEffect(() => {
    if (!(el && transport)) {
      return
    }
    const handlePlay = () => {
      // eslint-disable-next-line react-compiler/react-compiler
      transport.seconds = el.currentTime
      transport.start()
    }
    const handlePause = () => transport.pause()
    const handleStop = () => transport.stop()
    const handleSeeked = () => (transport.seconds = el.currentTime)

    el.addEventListener('play', handlePlay)
    el.addEventListener('pause', handlePause)
    el.addEventListener('seeked', handleSeeked)
    el.addEventListener('ended', handleStop)
    return () => {
      el.removeEventListener('play', handlePlay)
      el.removeEventListener('pause', handlePause)
      el.removeEventListener('ended', handleStop)
      el.removeEventListener('seeked', handleSeeked)
    }
  }, [el, transport])
}
