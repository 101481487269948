import type { BoxProps } from '@mui/material'
import type { FC } from 'react'
import WaveCanvas from '../waveform/wave-canvas.js'
import { useFFT } from './fft.hook.js'

export interface FFTProps extends Omit<BoxProps, 'position' | 'ref'> {}

/** Show an FFT view, using the Wave visualizer */
export const FFT: FC<FFTProps> = props => {
  const { ...restProps } = props
  const peaks = useFFT({ fftSize: 512 })

  return <WaveCanvas {...restProps} waveType="monitor" mode="fixed" peaks={peaks} />
}
