import { isPlainObject } from './is-plain-object.js'

/** Adapted from https://blog.devgenius.io/how-to-deep-merge-javascript-objects-12a7235f5573 */
export const deepMerge = <T extends unknown>(target: T, ...sources: unknown[]): T => {
  if (!sources.length) {
    return target
  }
  const source = sources.shift()
  if (isPlainObject(target) && isPlainObject(source)) {
    for (const key in source) {
      if (isPlainObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {},
          })
        }
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, {
          [key]: source[key],
        })
      }
    }
  }
  return deepMerge(target, ...sources)
}
