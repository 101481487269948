import classNames from 'classnames'
import React, { useCallback } from 'react'
import type { FC } from 'react'
import ReactDraggable from 'react-draggable'
import { makeStyles } from '../styles.js'

export interface DraggableProps {
  className?: string
  children: React.ReactNode
}

const useStyles = makeStyles()(() => ({
  root: {},
  container: {
    display: 'flex',
  },
  handle: {
    cursor: 'grab',
    display: 'flex',
    flex: 1,
  },
}))

export const Draggable: FC<DraggableProps> = props => {
  const { className, children } = props
  const { classes } = useStyles()
  const handleMouse = useCallback((ev: globalThis.MouseEvent) => ev.preventDefault(), [])

  return (
    <ReactDraggable handle="div" onMouseDown={handleMouse}>
      <div className={classNames(className, classes.root, classes.container)}>
        <div className={classes.handle}>{children}</div>
      </div>
    </ReactDraggable>
  )
}

export default Draggable
