import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { ExpandLess, ExpandMore } from '@tunasong/icons'
import { useState } from 'react'
import type { FC, MouseEvent } from 'react'
import { useDimensions } from '../layout/dimensions.js'

interface MenuButtonItem {
  Icon: FC
  name: string
}

export interface MenuButtonSelector<T extends MenuButtonItem> extends Omit<BoxProps, 'onChange'> {
  items: T[]
  selected?: T
  onChange(item: T): void
}

export const MenuButtonSelector = <T extends MenuButtonItem>(props: MenuButtonSelector<T>) => {
  const { items, selected, onChange } = props

  const { isSmallOrSmaller } = useDimensions()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleDropdownClick = (ev: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl => (anchorEl ? null : ev.currentTarget))
  }

  return (
    <>
      <Button
        sx={{
          textTransform: 'none',
        }}
        onClick={handleDropdownClick}
        startIcon={selected?.Icon && <selected.Icon />}
        endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
      >
        {isSmallOrSmaller ? '' : selected?.name}
      </Button>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {items.map(item => (
          <MenuItem
            key={item.name}
            disabled={item?.name === selected?.name}
            onClick={() => {
              onChange(item)
              setAnchorEl(null)
            }}
          >
            <ListItemIcon>{<item.Icon />}</ListItemIcon>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MenuButtonSelector
