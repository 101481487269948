import { Button } from '@mui/material'
import type { ButtonProps } from '@mui/material'
import React, { useCallback, useState } from 'react'
import type { FC } from 'react'
import { Dialog } from './dialog.js'
import type { CDialogProps } from './dialog.js'

export interface ButtonDialogProps extends Omit<CDialogProps, 'open' | 'onClose' | 'onOpen'> {
  buttonProps?: Partial<ButtonProps>
}

export const ButtonDialog: FC<ButtonDialogProps> = props => {
  const [open, setOpen] = useState(false)
  const { title, buttonProps, children, ...dialogProps } = props
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  return (
    <>
      <Button {...buttonProps} title={title} onClick={handleOpen}>
        {title}
      </Button>
      <Dialog {...dialogProps} title={title} open={open} onClose={handleClose}>
        {children}
      </Dialog>
    </>
  )
}

export default ButtonDialog
