import { getEditorCommands, useEditor, useGlobalAppContext } from '@tunasong/plugin-lib'
import type { Command } from '@tunasong/plugin-lib'
import type { CoreElement, Maturity } from '@tunasong/schemas'
import type { NodeEntry } from 'slate'
import { useUserConfig } from './config-service.hook.js'

export const useEditorCommands = ({
  nodeEntry,
  text = '',
  maturity = 'stable',
}: {
  text?: string
  nodeEntry?: NodeEntry<CoreElement>
  maturity?: Maturity
}): Command[] => {
  const [userConfig] = useUserConfig()

  // Generate command specs from the custom commands
  const globalContext = useGlobalAppContext()
  const editor = useEditor({ allowNull: true })

  const plugins = editor?.pluginList
  const commands =
    editor && plugins && userConfig
      ? getEditorCommands({ nodeEntry, plugins, text, globalContext, editor, maturity, userConfig })
      : []

  return commands
}
