import type { Entity, Persisted } from '@tunasong/schemas'
import { entitiesApi } from '../api/entities.js'
import { skipToken } from '@reduxjs/toolkit/query'

/** Load entity, partially loading children if those have not been loaded */
export const useEntity = <T extends Entity = Entity>(id?: string | null) => {
  const skip = !id || id === 'ROOT'

  const {
    currentData: entity,
    data: previousEntity,
    ...restProps
  } = entitiesApi.useLoadEntityQuery(skip ? skipToken : { id }, {})

  return { entity: id ? (entity as Persisted<T>) : undefined, previousEntity, ...restProps }
}
