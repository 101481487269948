import { isCollabEntity } from '@tunasong/models'
import { skipToken, storageApi } from '@tunasong/redux'
import type { Entity } from '@tunasong/schemas'
import { Y, getElementValue, getSlateYDocVersion } from '@tunasong/sync-lib'
import { toUint8Array } from 'js-base64'
import { useEffect, useMemo, useState } from 'react'

/** Create a collaborative YDoc from the specified entity */
export const useYDoc = <T extends Entity = Entity>(entity?: T) => {
  const isCollab = isCollabEntity(entity)
  const { id, yDoc: entityYDoc, storageUrls } = entity ?? {}

  const useS3 = Boolean(storageUrls)

  // Legacy support for yDoc as string inside the entity
  const entityYDocAsUpdates = useMemo(() => (entityYDoc ? toUint8Array(entityYDoc) : undefined), [entityYDoc])

  // New support for yDoc in S3 storage
  const { data: yDocS3, ...restProps } = storageApi.useGetAuthorizedStorageFileQuery(
    storageUrls ? { storageUrls } : skipToken
  )

  const docUpdates = useS3 ? yDocS3 : entityYDocAsUpdates

  const [doc, setDoc] = useState<Y.Doc | undefined>(undefined)
  const value = useMemo(() => (doc ? getElementValue(doc) : null), [doc])
  const element = useMemo(() => (value ? ({ ...entity, children: value } as never as T) : null), [entity, value])
  const { v1, v2 } = useMemo(() => (doc ? getSlateYDocVersion(doc) : { v1: false, v2: false }), [doc])

  /** If yDoc is set we apply the changes to the local doc */
  useEffect(() => {
    if (!id || !isCollab || !docUpdates) {
      return
    }

    /** Has ID changed? */
    if (id === doc?.guid) {
      return
    }
    // Doc is either null or not the right ID
    if (doc) {
      doc.destroy()
    }
    const newDoc = new Y.Doc({ guid: id })
    Y.applyUpdate(newDoc, docUpdates)
    setDoc(newDoc)
  }, [doc, docUpdates, id, isCollab])

  /** Ensure we never return a doc for the wrong ID */
  return { doc, element, value, v1, v2, ...restProps }
}
