import { CircularProgress, IconButton, InputBase } from '@mui/material'
import type { InputBaseProps } from '@mui/material'
import { Controls, Search as SearchIcon } from '@tunasong/icons'
import { Dialog, HBox, TagList, disableAutocomplete } from '@tunasong/ui-lib'
import cn from 'classnames'
import { useMemo, useState } from 'react'
import type { ChangeEvent, FC } from 'react'
import SearchFilter from './search-filter.js'
import type { SearchFilter as SearchFilterType } from '@tunasong/schemas'

export interface SearchInputProps extends InputBaseProps {
  classes: Record<string, string>
  query: string
  loading: boolean
  placeholder: string
  fullWidth?: boolean
  filter?: SearchFilterType
  /** Show the filter button */
  onChangeFilter?(filter: SearchFilterType): void
  onChange(ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
}

export const SearchInput: FC<SearchInputProps> = props => {
  const { classes, fullWidth, placeholder, query, loading, filter, onChangeFilter, onChange, ...restProps } = props

  const [showFilter, setShowFilter] = useState(false)

  const tags = useMemo(
    () => [filter?.tags ?? [], filter?.types?.map(type => `type:${type}`)].filter(Boolean).flat(),
    [filter]
  )

  return (
    <>
      <InputBase
        {...restProps}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        fullWidth={fullWidth}
        value={query}
        // inputRef={inputRef}
        startAdornment={
          <div className={cn(classes.searchIcon)}>
            <SearchIcon />
          </div>
        }
        endAdornment={
          <>
            <TagList edit={false} tags={tags} />
            {loading ? (
              <HBox sx={{ alignItems: 'center', mr: 1 }}>
                <CircularProgress color="inherit" size={20} />
              </HBox>
            ) : null}
            {Boolean(onChangeFilter) ? (
              <IconButton onClick={() => setShowFilter(true)}>
                <Controls />
              </IconButton>
            ) : null}
          </>
        }
        onChange={onChange}
        placeholder={placeholder}
        inputProps={disableAutocomplete}
        {...restProps}
      />
      {onChangeFilter ? (
        <Dialog fullWidth maxWidth="xs" open={showFilter} onClose={() => setShowFilter(false)} title="Filter">
          <SearchFilter filter={filter ?? {}} onChange={onChangeFilter} />
        </Dialog>
      ) : null}
    </>
  )
}

export default SearchInput
