import { Popper, Typography } from '@mui/material'
import { formatNumber } from '@tunasong/models'
import { makeStyles, useDragMove } from '@tunasong/ui-lib'
import cn from 'classnames'
import { useCallback, useState } from 'react'
import type { FC } from 'react'
import { getPercentageDB } from '../../lib/index.js'

const useStyles = makeStyles<ChannelVolumeProps>()((theme, props) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  volContainer: {
    flex: 1,
    backgroundColor: theme.vars.palette.action.hover,
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
    height: theme.spacing(0.5),
  },
  volBar: {
    width: `${Math.min(Math.min(props.volume * 100, 100), 100)}%`,
    backgroundColor: theme.vars.palette.primary.light,
    height: theme.spacing(0.5),
  },
}))

export interface ChannelVolumeProps {
  className?: string
  volume: number
  onChangeVolume(volume: number): void
}

export const ChannelVolume: FC<ChannelVolumeProps> = props => {
  const { className, volume, onChangeVolume } = props
  const { classes } = useStyles(props)

  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  /** Dragging the volume bar? */

  const handleVolume = useCallback(
    (ev: MouseEvent) => {
      if (!ref) {
        return
      }
      ev.preventDefault()
      const box = ref.getBoundingClientRect()
      const percentage = Math.max(Math.min(((ev.clientX - box.left) / box.width) * 100, 100), 0)
      onChangeVolume(percentage / 100)
    },
    [onChangeVolume, ref]
  )
  const [showDB, setShowDB] = useState(false)
  const handleStart = useCallback(
    (ev: MouseEvent) => {
      handleVolume(ev)
      setShowDB(true)
    },
    [handleVolume]
  )

  const handleStop = useCallback(
    (ev: MouseEvent) => {
      handleVolume(ev)
      setShowDB(false)
    },
    [handleVolume]
  )

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  useDragMove({
    el: ref,
    onMove: handleVolume,
    onStart: handleStart,
    onStop: handleStop,
  })

  const db = `${formatNumber(getPercentageDB({ value: volume * 100 }), { digits: 1 })}dB`

  return (
    <div className={cn(className, classes.root)} title={db} ref={setAnchorEl}>
      <div className={classes.volContainer} ref={setRef}>
        <div className={classes.volBar} />
      </div>
      <Popper open={showDB} anchorEl={anchorEl} placement="top">
        <Typography variant="caption">{db}</Typography>
      </Popper>
    </div>
  )
}

export default ChannelVolume
