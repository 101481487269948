import type { UserConfig } from '@tunasong/manifest'
import { logger } from '@tunasong/models'
import type { UserConfigService } from '@tunasong/redux'
import { isMidiCC, isMidiProgramChange } from '@tunasong/schemas'
import type { Midi } from '@tunasong/schemas'
import { EventEmitter } from '@tunasong/ui-lib'
import { WebMidi, OutputChannel as WebMidiOutputChannel } from 'webmidi'
import { BluetoothMidi } from './bt-midi/bt-midi.js'
import type { MidiInput, MidiOutput } from './midi-types.js'

export interface OutputChannel extends Pick<WebMidiOutputChannel, 'send' | 'sendProgramChange' | 'sendControlChange'> {}

export class MidiControl extends EventEmitter<'iochanged'> {
  btMidi: BluetoothMidi | null = null
  connected = false

  inputs: MidiInput[] = []
  outputs: MidiOutput[] = []
  configService: UserConfigService

  initialized = false
  unsubscribeFromChanges: (() => void) | null = null

  constructor({ configService }: { configService: UserConfigService }) {
    super()
    this.configService = configService

    this.unsubscribeFromChanges = this.configService.subscribeToChanges(userConfig => {
      this.initializeMidi(userConfig)
    })
  }

  /** Handle both BT MIDI and regular midi */
  sendProgramChange(program: number, options?: { channels?: number[] | number; time?: string | number }) {
    // Send to all outputs
    logger.debug('Sending Program Change: ', program, options, this.outputs)
    for (const output of this.outputs) {
      output.sendProgramChange(program, options)
    }
  }

  /** MIDI commands are MIDI entities */
  sendMidiCommands({ output, commands }: { output?: MidiOutput; commands: Midi[] }) {
    const outputs = output ? [output] : this.outputs

    for (const output of outputs) {
      for (const cmd of commands) {
        if (isMidiProgramChange(cmd)) {
          if (typeof cmd.patchBank === 'number') {
            // Send patch bank first - that is CC 0
            logger.debug('Sending MIDI Bank select (CC: 0): ', cmd.patchBank)
            output.sendControlChange(0, cmd.patchBank, { channels: cmd.channel })
          }
          if (typeof cmd.patchBankLsb === 'number') {
            // Send patch bank first - that is CC 0
            logger.debug('Sending MIDI Bank select LSB (CC: 32): ', cmd.patchBank)
            output.sendControlChange(32, cmd.patchBankLsb, { channels: cmd.channel })
          }
          logger.debug('Sending MIDI Program Change: ', cmd, output.name)
          output.sendProgramChange(cmd.program, { channels: cmd.channel })
        } else if (isMidiCC(cmd)) {
          logger.debug('Sending MIDI CC: ', cmd, output.name, { channels: cmd.channel })
          output.sendControlChange(cmd.cc, cmd.value)
        } else {
          logger.error('Unsupported MIDI command: ', cmd)
        }
      }
    }
  }
  sendMidiCommand({ output, command }: { output?: MidiOutput; command: Midi }) {
    this.sendMidiCommands({ output, commands: [command] })
  }

  destroy() {
    if (this.unsubscribeFromChanges) {
      this.unsubscribeFromChanges()
    }
    WebMidi.disable()
    this.inputs = []
    this.outputs = []

    this.btMidi?.destroy()
    super.destroy()
  }

  handleConnected = () => {
    this.connected = true

    this.inputs = [...(this.btMidi ? this.btMidi.inputs : []), ...WebMidi.inputs]
    this.outputs = [...(this.btMidi ? this.btMidi.outputs : []), ...WebMidi.outputs]

    logger.debug('MIDI connected. Inputs: ', this.inputs, ', Outputs: ', this.outputs)

    this.emit('iochanged')
  }

  private initializeMidi = async (config: UserConfig) => {
    const midiConfig = config.plugins.midi

    if (!midiConfig?.midi) {
      this.inputs = []
      this.outputs = []
      this.btMidi?.destroy()
      this.btMidi = null
      WebMidi.disable()
      this.initialized = false
      return
    }

    if (this.initialized) {
      return
    }

    if (midiConfig?.btMidi) {
      this.btMidi =
        this.btMidi ??
        new BluetoothMidi({
          onConnectionStatus: this.handleConnected,
          midiAutoConnect: Boolean(config.plugins.midi?.btMidiAutoConnect),
        })
    } else {
      this.btMidi?.destroy()
      this.btMidi = null
    }

    WebMidi.enable()
      .then(() => {
        this.handleConnected()
      })
      .catch(e => {
        logger.error("Couldn't enable MIDI. Please check your browser settings.", e)
      })

    this.initialized = true
  }
}
