import { shortUuid } from '@tunasong/models'
import type { CoreElement, TunaDecendant } from '@tunasong/schemas'
import type { Ancestor as TAncestor, TElement, TNode } from '@udecode/slate'
import type { MaximizeMode, RangeMode } from 'slate'
import { Location, Node, Path, Editor as SlateEditor, Transforms as SlateTransforms } from 'slate'
import type {
  Ancestor,
  BaseElement,
  BaseRange,
  EditorAboveOptions,
  EditorAfterOptions,
  EditorBeforeOptions,
  EditorDirectedDeletionOptions,
  EditorNextOptions,
  EditorNodeOptions,
  EditorNodesOptions,
  EditorNormalizeOptions,
  EditorParentOptions,
  EditorPathOptions,
  EditorPreviousOptions,
  EditorStringOptions,
  NodeEntry,
  NodeMatch,
  PropsCompare,
  PropsMerge,
} from 'slate'
import type { TextInsertTextOptions } from 'slate/dist/interfaces/transforms/text.js'
import invariant from 'tiny-invariant'
import type { TunaEditor } from './plugin-types.js'

/** Temporary helpers for Typescript until Slate gets proper support */

export const Editor = {
  deleteForward: (editor: TunaEditor, opts?: EditorDirectedDeletionOptions) =>
    SlateEditor.deleteForward(editor as never, opts),
  deleteBackward: (editor: TunaEditor, opts?: EditorDirectedDeletionOptions) =>
    SlateEditor.deleteBackward(editor as never, opts),
  path: (editor: TunaEditor, at: Location, opts?: EditorPathOptions) => SlateEditor.path(editor as never, at, opts),
  range: (editor: TunaEditor, at: Location, to?: Location) => SlateEditor.range(editor as never, at, to),
  normalize: (editor: TunaEditor, opts?: EditorNormalizeOptions) => SlateEditor.normalize(editor as never, opts),
  insertNode: (editor: TunaEditor, node: TNode) => SlateEditor.insertNode(editor as never, node),
  parent: <T extends EditorParentOptions>(editor: TunaEditor, at: Location, opts?: T) =>
    SlateEditor.parent(editor as never, at, opts),
  start: (editor: TunaEditor, at: Location) => SlateEditor.start(editor as never, at),
  end: (editor: TunaEditor, at: Location) => SlateEditor.end(editor as never, at),
  node: (editor: TunaEditor, at: Location, opts?: EditorNodeOptions) =>
    SlateEditor.node(editor as never, at, opts) as NodeEntry<TNode>,
  nodes: <T extends TunaDecendant>(editor: TunaEditor, opts?: EditorNodesOptions<T>) =>
    SlateEditor.nodes<T>(editor as never, opts),
  withoutNormalizing: (editor: TunaEditor, fn: () => void) => SlateEditor.withoutNormalizing(editor as never, fn),
  above: <T extends CoreElement>(editor: TunaEditor, opts?: EditorAboveOptions<T>) =>
    SlateEditor.above(editor as never, opts),
  // eslint-disable-next-line id-blacklist
  string: (editor: TunaEditor, at: Location, opts?: EditorStringOptions) =>
    SlateEditor.string(editor as never, at, opts),
  after: (editor: TunaEditor, at: Location, opts?: EditorAfterOptions) => SlateEditor.after(editor as never, at, opts),
  before: (editor: TunaEditor, at: Location, opts?: EditorBeforeOptions) =>
    SlateEditor.before(editor as never, at, opts),

  next: <T extends TunaDecendant>(editor: TunaEditor, opts?: EditorNextOptions<T>) =>
    SlateEditor.next(editor as never, opts) as NodeEntry<TAncestor> | undefined,
  previous: (editor: TunaEditor, opts?: EditorPreviousOptions<Ancestor>) =>
    SlateEditor.previous(editor as never, opts) as NodeEntry<TAncestor> | undefined,
  insertText: (editor: TunaEditor, text: string) => SlateEditor.insertText(editor as never, text),
  isEmpty: (editor: TunaEditor, element: BaseElement) => SlateEditor.isEmpty(editor as never, element),
  isVoid: (editor: TunaEditor, element: BaseElement) => SlateEditor.isVoid(editor as never, element),
}

export const Transforms = {
  // custom helpers

  /** Insert node async, displaying a placeholder while the promise settles. */
  insertNodesAsync: async (
    editor: TunaEditor,
    finalNodesFn: () => Promise<TElement | TNode | (TElement | TNode)[] | null>,
    options: { temporaryNode?: TElement } = {}
  ) => {
    const { temporaryNode = { type: 'pulse', children: [{ text: '' }] } } = options
    const id = shortUuid()
    SlateTransforms.insertNodes(
      editor as never,
      {
        ...temporaryNode,
        id,
      } as TElement
    )

    const insertedEntry = Array.from(
      Editor.nodes(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
        match: node => 'id' in node && node.id === id,
      })
    )[0]
    invariant(insertedEntry, 'Expected to find a pending link block')
    const [, path] = insertedEntry
    try {
      const finalNode = await finalNodesFn()
      Editor.withoutNormalizing(editor, () => {
        SlateTransforms.delete(editor as never, { at: path })
        if (finalNode) {
          SlateTransforms.insertNodes(editor as never, finalNode, {
            at: path,
            select: true,
          })
        }
      })
    } catch (e) {
      SlateTransforms.delete(editor as never, { at: path })
    }
  },

  // slate helpers (with types)
  moveNodes: <T extends TNode>(
    editor: TunaEditor,
    options: {
      at?: Location
      match?: NodeMatch<T>
      mode?: string
      to: Path
      voids?: boolean
    }
  ) => SlateTransforms.moveNodes(editor as never, options as never),
  liftNodes: <T extends TNode>(
    editor: TunaEditor,
    options: {
      at?: Location
      match?: NodeMatch<T>
      mode?: MaximizeMode
      voids?: boolean
    }
  ) => SlateTransforms.liftNodes(editor as never, options),
  select: (editor: TunaEditor, target: Location) => SlateTransforms.select(editor as never, target),
  removeNodes: <
    S extends {
      at?: Location | undefined
      match?: NodeMatch<T> | undefined
      mode?: RangeMode | undefined
      hanging?: boolean | undefined
      voids?: boolean | undefined
    },
    T extends TNode,
  >(
    editor: TunaEditor,
    opts?: S
  ) => SlateTransforms.removeNodes<T>(editor as never, opts),
  setNodes: <
    T extends {
      at?: Location | undefined
      match?: NodeMatch<TNode> | undefined
      mode?: MaximizeMode | undefined
      hanging?: boolean | undefined
      split?: boolean | undefined
      voids?: boolean | undefined
      compare?: PropsCompare | undefined
      merge?: PropsMerge | undefined
    },
  >(
    editor: TunaEditor,
    props: Partial<TNode>,
    opts?: T
  ) => SlateTransforms.setNodes(editor as never, props, opts),
  insertNodes: <
    T extends {
      at?: Location | undefined
      match?: NodeMatch<Node> | undefined
      mode?: RangeMode | undefined
      hanging?: boolean | undefined
      select?: boolean | undefined
      voids?: boolean | undefined
    },
  >(
    editor: TunaEditor,
    nodes: TunaDecendant | TunaDecendant[],
    opts?: T
  ) => SlateTransforms.insertNodes(editor as never, nodes, opts),
  move: (
    editor: TunaEditor,
    opts?: {
      distance?: number
      unit?: string
      reverse?: boolean
      edge?: 'anchor' | 'focus'
    }
  ) => SlateTransforms.move(editor as never, opts as never),
  mergeNodes: <
    T extends {
      at?: Location | undefined
      match?: NodeMatch<Node> | undefined
      mode?: RangeMode | undefined
      hanging?: boolean | undefined
      voids?: boolean | undefined
    },
  >(
    editor: TunaEditor,
    options?: T
  ) => SlateTransforms.mergeNodes(editor as never, options),
  delete: (
    editor: TunaEditor,
    options?: {
      at?: Location
      distance?: number
      unit?: string
      reverse?: boolean
      hanging?: boolean
      voids?: boolean
    }
  ) => SlateTransforms.delete(editor as never, options as never),
  setSelection: (editor: TunaEditor, props: Partial<BaseRange>) => SlateTransforms.setSelection(editor as never, props),
  wrapNodes: <
    S extends {
      at?: Location | undefined
      match?: NodeMatch<T> | undefined
      mode?: MaximizeMode | undefined
      split?: boolean | undefined
      voids?: boolean | undefined
    },
    T extends TNode,
  >(
    editor: TunaEditor,
    el: BaseElement,
    props?: S
  ) => SlateTransforms.wrapNodes<T>(editor as never, el, props),
  unwrapNodes: <
    S extends {
      at?: Location | undefined
      match?: NodeMatch<T> | undefined
      mode?: MaximizeMode | undefined
      split?: boolean | undefined
      voids?: boolean | undefined
    },
    T extends TNode,
  >(
    editor: TunaEditor,
    props?: S
  ) => SlateTransforms.unwrapNodes<T>(editor as never, props),
  insertText: <S extends TextInsertTextOptions | undefined>(editor: TunaEditor, text: string, props?: S) =>
    SlateTransforms.insertText(editor as never, text, props),
}

export type { Descendant as TDescendant, TElement, TNode, TText } from '@udecode/slate'

export type { NodeEntry } from 'slate'
