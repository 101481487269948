import type { FC } from 'react'
import { EntityList } from '../entity/entity-list.js'
import type { EntityListProps } from '../entity/entity-list.js'
import { useFavorites } from './favorites.hook.js'

interface FavoritesListProps extends Omit<EntityListProps, 'entities'> {}

export const FavoritesList: FC<FavoritesListProps> = props => {
  const { ...entityListProps } = props
  const { favorites } = useFavorites()
  return (
    <EntityList
      size="large"
      showBreadcrumbs={true}
      entities={favorites}
      controls={false}
      actions={false}
      {...entityListProps}
    />
  )
}

export default FavoritesList
