import type { ACL } from '@tunasong/schemas'

export type AccessControlled<T> = T & {
  acls?: ACL[]
}

export type Owned<T = unknown> = AccessControlled<T> & {
  userId: string
}

export function isAccessControlled<T>(item: T): item is AccessControlled<T> {
  return Boolean(Array.isArray((item as AccessControlled<T>)?.acls))
}

export function isOwned<T>(item?: T): item is Owned<T> {
  return Boolean((item as Owned<T>)?.userId)
}
