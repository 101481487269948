import { Breadcrumbs, Link, Typography } from '@mui/material'
import type { BreadcrumbsProps, LinkProps, TypographyVariant } from '@mui/material'
import { Home } from '@tunasong/icons'
import type { NavigationEntityWithType } from '@tunasong/models'
import { getElementMedia, usePlugins } from '@tunasong/plugin-lib'
import cn from 'classnames'
import React, { Fragment, useMemo, useState } from 'react'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router'
import { getEntityPath } from '../entity/entity-link-util.js'
import { useContainerSize } from '../hooks/index.js'
import { makeStyles } from '../styles.js'

const useStyles = makeStyles()(theme => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  iconLarge: {
    marginRight: theme.spacing(0.5),
    width: 24,
    height: 24,
  },
  path: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(),
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    fontWeight: 300,
    textDecoration: 'none',
  },
}))

export interface TunaBreadcrumbsProps extends BreadcrumbsProps {
  className?: string
  parentChain: NavigationEntityWithType[]
  variant?: TypographyVariant
  icon?: boolean
  /** Show the Home icon. @default true */
  showHome?: boolean
  /** Show the leaf - i.e., the entity */
  showLeaf?: boolean
  /** Make the links active, i.e., clickable */
  activeLinks?: boolean
  back?: boolean
}

export const TunaBreadcrumbs: FC<TunaBreadcrumbsProps> = React.memo(props => {
  const {
    className,
    parentChain = [],
    activeLinks = true,
    variant = 'subtitle1',
    icon = true,
    showHome = true,
    showLeaf = true,
    maxItems = -1,
  } = props
  const { classes } = useStyles()
  const plugins = usePlugins('all')

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const size = useContainerSize(anchorEl)

  const linkProps = useMemo<Pick<LinkProps, 'variant' | 'color'> & { className: string }>(
    () => ({
      variant,
      color: 'inherit',
      className: cn(classes.link, classes.path),
    }),
    [classes.link, classes.path, variant]
  )

  const path = useMemo(() => {
    const p = [...parentChain]
    const trimmed = showLeaf ? p : p.slice(0, p.length - 1)
    return trimmed
  }, [parentChain, showLeaf])

  const links = useMemo(
    () =>
      path.map((e, idx) => {
        const { colorCode, icon: Icon } = getElementMedia(e.type, plugins)
        const content = (
          <Fragment>
            {icon ? <Icon className={classes.icon} sx={{ color: colorCode }} /> : null}
            {e.name ? e.name : 'Untitled'}
          </Fragment>
        )
        return activeLinks ? (
          <Link key={idx} component={RouterLink} {...linkProps} to={getEntityPath({ entity: e })} sx={{ fontSize: 14 }}>
            {content}
          </Link>
        ) : (
          <Typography {...linkProps} className={classes.path} key={idx}>
            {content}
          </Typography>
        )
      }),
    [activeLinks, classes.icon, classes.path, icon, linkProps, path, plugins]
  )

  const pathLen = path.length + (showHome ? 1 : 0)
  const maxTotalItems = size && maxItems === -1 ? Math.max(Math.ceil(size.width / 175), 2) : 3
  const itemsBeforeCollapse = maxTotalItems < pathLen + 1 ? 1 : undefined
  const itemsAfterCollapse = maxTotalItems < pathLen ? Math.max(1, maxTotalItems - 2) : undefined

  return (
    <Breadcrumbs
      ref={setAnchorEl}
      className={className}
      aria-label="breadcrumb"
      itemsBeforeCollapse={itemsBeforeCollapse}
      itemsAfterCollapse={itemsAfterCollapse}
      maxItems={maxTotalItems}
      sx={{ margin: 0, ...props.sx }}
    >
      {showHome ? (
        <Link
          sx={{ marginRight: '0px !important' }}
          component={RouterLink}
          {...linkProps}
          to="/app"
          title="Home link"
          aria-label="Home link"
        >
          {icon ? <Home sx={{ marginRight: '0px !important' }} color="inherit" className={classes.iconLarge} /> : null}
        </Link>
      ) : null}
      {links}
    </Breadcrumbs>
  )
})
