import { isLine } from '@tunasong/models'
import { Editor, Transforms, normalizeElement } from '@tunasong/plugin-lib'
import type { TunaEditor } from '@tunasong/plugin-lib'
import type { CoreElement } from '@tunasong/schemas'

export const insertBlockSafe = (editor: TunaEditor, nodes: CoreElement | CoreElement[], select = false) => {
  const at = Editor.above(editor, { match: n => isLine(n) })
  const path = at ? at[1] : editor.selection?.focus

  const safeNodes = Array.isArray(nodes) ? nodes.map(n => normalizeElement(n)) : normalizeElement(nodes)

  Transforms.insertNodes(editor, safeNodes, {
    at: path,
    select,
  })
}
