import { logger, shortUuid } from '@tunasong/models'
import type { SortOptions } from '@tunasong/models'
import { entitiesApi, useChildren, useEntity, useEntityUpdate } from '@tunasong/redux'
import { isAudioTrack } from '@tunasong/schemas'
import type { Persisted, Track } from '@tunasong/schemas'
import { hashColorName, useEntitySort } from '@tunasong/ui-lib'
import { useCallback, useRef } from 'react'
import { ChannelController } from '../../engine/index.js'
import { useMixer } from '../../hooks/index.js'
import { createClip } from '../clips/index.js'
import { useUploadTrackClips } from './upload-clips.js'

export interface TrackComponent {
  track: Track
}
export interface DAWProps {
  parentId: string
}

const sortOpts: SortOptions = {
  sortBy: 'createdAt',
  order: 'asc',
}

interface Times {
  contextTime: number
  transportTime: number
}

export const useDAW = (props: DAWProps) => {
  const { parentId } = props
  const mixer = useMixer()
  const recordStart = useRef(0)

  const { entities: unsortedTracks } = useChildren<Track>({ parentId, filter: isAudioTrack })
  const tracks = useEntitySort(unsortedTracks, sortOpts)

  /** @todo perhaps the clips should be their own entities */
  const uploadClips = useUploadTrackClips(parentId)

  const [deleteEntity] = entitiesApi.useDeleteEntityMutation()
  const [createEntity] = entitiesApi.useCreateEntityMutation()
  const { entity: parent } = useEntity(parentId)
  const updateEntity = useEntityUpdate()

  const hasArmedTracks = (mixer?.channels.filter(c => c.armed) ?? []).length > 0

  /** Channels we are currently recording */
  const recCh = useRef<ChannelController[] | null>(null)

  const onUpdateTrack = useCallback(
    async (track: Persisted<Track>) => {
      updateEntity(track.id, track)
      await uploadClips(track)
    },
    [updateEntity, uploadClips]
  )

  const onStartRecord = useCallback(
    async ({ contextTime, transportTime }: Times) => {
      recordStart.current = transportTime
      logger.debug(`Record starting at: ${contextTime} (${transportTime})`)
      recCh.current = mixer?.channels.filter(c => c.armed) || []
      recCh.current.forEach(c => c.recorder?.start(contextTime, transportTime))
    },
    [mixer]
  )
  const onStopRecord = useCallback(
    async ({ contextTime, transportTime }: Times) => {
      logger.debug(`Record stopped at: ${contextTime} (${transportTime})`)
      if (!recCh.current) {
        return
      }
      const recordingChannels = recCh.current
      /** Get the recordings */
      for (const c of recordingChannels) {
        if (!c.recorder) {
          continue
        }
        c.recorder.stop(contextTime)
        /** @todo time may be after now, so we must defer getting the recordings */
        // const rec = c.recorder?.getRecording()
        const clip = createClip(c.recorder, recordStart.current, transportTime)
        if (!clip) {
          throw new Error(`Cannot create clip from recording: ${c.name}`)
        }
        /** Update the corresponding track */
        const track = tracks.find(t => t.id === c.id)
        if (!track) {
          throw new Error(`Cannot find track: ${c.id}`)
        }

        const clips = [...(track.clips ?? []), clip]
        logger.debug(
          `Recording for channel  ${c.name} starting: ${recordStart.current} - ${transportTime}`,
          track,
          clip
        )
        onUpdateTrack({ ...track, clips })
      }
      recordStart.current = 0
      recCh.current = null
    },
    [onUpdateTrack, tracks]
  )

  const onCreateTrack = useCallback(async () => {
    const id = shortUuid()
    const track: Track = {
      id,
      parentId,
      type: 'audiotrack',
      name: 'Untitled',
      color: hashColorName(id),
      gain: 1.0,
      pan: 0,
    }
    const entity = await createEntity({ entity: track, parent: parent ?? null }).unwrap()

    return entity
  }, [createEntity, parent, parentId])

  const onDeleteTrack = useCallback(
    async (track: Persisted<Track>) => {
      await deleteEntity({ entity: track }).unwrap()
    },
    [deleteEntity]
  )

  return { tracks, hasArmedTracks, onUpdateTrack, onDeleteTrack, onCreateTrack, onStartRecord, onStopRecord }
}

export default useDAW
