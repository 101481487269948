import { Autocomplete, TextField } from '@mui/material'
import type { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material'
import { entityTypeNames } from '@tunasong/schemas'
import type { EntityType } from '@tunasong/schemas'
import classNames from 'classnames'
import { useCallback } from 'react'
import type { FC, SyntheticEvent } from 'react'
import { makeStyles } from '../styles.js'

const useStyles = makeStyles()(() => ({
  root: {
    minWidth: 100,
  },
  inputContainer: {},
}))

export interface EntityFilterProps extends Pick<AutocompleteProps<string, true, false, false>, 'componentsProps'> {
  className?: string
  types?: EntityType[]
  defaultValue?: EntityType[]
  onChange?(event: SyntheticEvent<Element, Event>, types: EntityType[]): void
}

export const EntityFilter: FC<EntityFilterProps> = props => {
  const { className, defaultValue = [], onChange, types = entityTypeNames, ...restProps } = props
  const { classes } = useStyles()
  const handleChange = useCallback(
    (ev: SyntheticEvent<Element, Event>, value: EntityType[]) => {
      if (!onChange) {
        return
      }

      onChange(ev, value)
    },
    [onChange]
  )
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      className={classes.inputContainer}
      {...params}
      sx={{ minWidth: 200 }}
      variant="standard"
      // @todo endAdornment screws up the CSS, so we null it here for now
      InputProps={{ ...params.InputProps, endAdornment: null }}
      placeholder="Filter..."
    />
  )

  return (
    <Autocomplete
      className={classNames(className, classes.root)}
      multiple
      size="small"
      options={types}
      defaultValue={defaultValue}
      onChange={handleChange}
      renderInput={renderInput}
      {...restProps}
    />
  )
}

export default EntityFilter
