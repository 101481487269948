import { Button, DialogActions } from '@mui/material'
import { AutoForm } from '@tunasong/autoform'
import type { DefaultValues } from '@tunasong/autoform'
import { useCallback, useState } from 'react'
import type { ZodSchema } from 'zod'
import { Dialog } from './dialog.js'
import type { CDialogProps } from './dialog.js'

interface FormDialogProps<T> {
  dialogProps: Omit<CDialogProps, 'onClose' | 'open'>
  open: boolean
  schema: ZodSchema<T>
  defaultValues?: DefaultValues<T>
  onChange(value: T): void
  onClose(success: boolean): void
}

export const FormDialog = <T extends Record<string, unknown> = Record<string, unknown>>({
  open,
  schema,
  dialogProps,
  defaultValues,
  onChange,
  onClose,
}: FormDialogProps<T>) => {
  const [valid, setValid] = useState(false)
  const handleChange = useCallback(
    (val: T) => {
      setValid(true)
      onChange(val)
    },
    [onChange]
  )
  const handleClose = useCallback(
    (success: boolean) => () => {
      onClose(success)
    },
    [onClose]
  )

  const handleError = useCallback(() => {
    setValid(false)
  }, [])
  return (
    <Dialog open={open} onClose={handleClose(false)} showClose={false} showOK={false} {...dialogProps}>
      <AutoForm defaultValues={defaultValues} schema={schema} onSubmit={handleChange} onError={handleError} />

      <DialogActions>
        <Button disabled={!valid} onClick={handleClose(true)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
