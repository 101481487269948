/** Decorate an entity with the parent ACL(s) */

import type { ACL, Entity, Persisted } from '@tunasong/schemas'
import { logger } from '../logger.js'
import { canWrite } from './acl-access.js'
import { isAccessControlled, isOwned } from './owned.js'
import type { AccessControlled } from './owned.js'

interface Decorate<T extends Entity> {
  entity: T
  parent?: Persisted<Entity> | null
  isPrivate?: boolean
}
export const decorateParentAndACLs = <T extends Entity>({
  entity,
  parent,
  isPrivate = false,
}: Decorate<T>): AccessControlled<T> => {
  const entityAcls: ACL[] = isAccessControlled(entity) && Array.isArray(entity.acls) ? entity.acls : []
  const parentAcls: ACL[] = parent?.acls && Array.isArray(parent?.acls) ? parent.acls : []

  const acls = [...parentAcls, ...entityAcls].filter(acl =>
    isPrivate ? isOwned(entity) && acl.principal === entity?.userId : true
  )

  /** Ensure that the entity owner is given full permissions */
  if (isOwned(entity) && !canWrite(entity.userId, acls)) {
    acls.push({ principal: entity.userId, permission: 'WRITE' })
  }

  /** Ensure that the owner of the parent is also given full permissions, unless this is a private entity */
  if (!isPrivate && parent?.userId && !canWrite(parent.userId, acls)) {
    acls.push({ principal: parent.userId, permission: 'WRITE' })
  }
  logger.debug('Decorated ACLs', { acls })
  return {
    ...entity,
    parentId: parent?.id ?? entity.parentId,
    acls,
  }
}
