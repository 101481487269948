/**
 * We need the plugin object to stay constant, we only change the decorate function
 *
 * @note we need to use this in offline mode as well to receive changes to Doc (provided by withYjs)
 */
import type { TunaPlugin } from '@tunasong/plugin-lib'
import type { Y } from '@tunasong/sync-lib'
import { withCursors, withYHistory, withYjs } from '@tunasong/sync-lib'
import { useDecorateRemoteCursorsV2 } from '@tunasong/sync-lib/react'
import { createPlatePlugin, usePlateActions } from '@udecode/plate-core/react'
import type { PlatePluginConfig } from '@udecode/plate-core/react'
import { useEffect } from 'react'
import type { Awareness } from 'y-protocols/awareness.js'
import { CaretLeaf } from './caret-leaf.js'
import { YJS_DEFAULT_LOCAL_ORIGIN } from './local-origin.js'

/**
 * @todo I would like to rewrite this to use the Plate config settings for awareness and sharedTypeV2
 * However, currently the YJS slate lib requires the sharedTypeV2 and awareness to be passed in when
 * extending the editor, and awareness changes.
 */
const yjsPluginConfig = (sharedTypeV2: Y.XmlText, awareness: Awareness): TunaPlugin => ({
  key: 'collab-yjs',
  description: 'Collaborative Editor Plugin',
  RenderLeaf: CaretLeaf,
  extendEditor: ({ editor }) => {
    /**
     * synchronizeValue should be called synchronizeInitialValue, since it deals with whether to
     * initially set the editor value to the YJS value. We handle that ourselves, since the value
     * here may be invalid and we want to normalize it before setting it in the editor.
     *
     * if we set it to false, we're getting other classes of errors...
     */
    const trackedOrigins = new Set([YJS_DEFAULT_LOCAL_ORIGIN])
    const yjsEditor = withYHistory(
      /** withCursors sets awareness on the editor, @see https://github.com/BitPhinix/slate-yjs/blob/69f3e0eaecc0b81049dc57e70403df60e7a10163/packages/core/src/plugins/withCursors.ts#L175 */
      withCursors(
        withYjs(editor, sharedTypeV2, {
          localOrigin: YJS_DEFAULT_LOCAL_ORIGIN,
        }),
        awareness
      ),
      {
        trackedOrigins,
      }
    )
    /** @todo factor out the online and offline bits here */
    return yjsEditor
  },
  /** @see https://github.com/udecode/plate/pull/1304 */
  useHooks: ({ editor }) => {
    const decorate = useDecorateRemoteCursorsV2()
    // defer to avoid delays in typing
    const plateDecorate = usePlateActions(editor.id).decorate({ delay: 10 })
    useEffect(() => {
      plateDecorate(decorate)
    })
  },
})

export const createYJSPlugin = (sharedTypeV2: Y.XmlText, awareness: Awareness) =>
  createPlatePlugin(yjsPluginConfig(sharedTypeV2, awareness) as unknown as PlatePluginConfig) as unknown as TunaPlugin
