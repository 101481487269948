import type { SelectChangeEvent } from '@mui/material'
import { EnumSelect } from '@tunasong/ui-lib'
import { GuitarBrand, GuitarPickups, GuitarType } from '@tunasong/schemas'
import type { Guitar } from '@tunasong/schemas'
import { useCallback } from 'react'
import type { FC } from 'react'
import type { InstrumentEditorProps } from '../instrument-editor.props.js'

const GuitarEditor: FC<InstrumentEditorProps> = props => {
  const { instrument, onChange } = props

  const { brand = '', variant = '', pickups = '' } = instrument as Guitar

  const handleFieldChange = useCallback(
    (field: keyof Guitar) => (event: SelectChangeEvent<unknown>) => {
      if (onChange) {
        onChange({ ...instrument, [field]: event.target.value })
      }
    },
    [onChange, instrument]
  )

  return (
    <>
      <EnumSelect
        title="Type"
        variant="standard"
        values={GuitarType}
        value={variant}
        onChange={handleFieldChange('variant')}
      />
      <EnumSelect
        variant="standard"
        title="Brand"
        values={GuitarBrand}
        value={brand}
        onChange={handleFieldChange('brand')}
      />
      {variant === GuitarType.Electric && (
        <>
          <EnumSelect
            variant="standard"
            title="Pickups"
            values={GuitarPickups}
            value={pickups}
            onChange={handleFieldChange('pickups')}
          />
        </>
      )}
    </>
  )
}

export default GuitarEditor
