import { shortUuid } from '@tunasong/models'
import type { Profile } from '@tunasong/schemas'
import { hashColor, hashColorName } from '../color/hash-color.js'

/** Get the profile for userId id. If not specified, get the profile for the currently logged in user. */
export const getProfileColor = (profile?: Partial<Profile> | null) => {
  const colorName = profile?.color ? profile?.color : hashColorName(profile?.userId ?? shortUuid())

  return {
    color: hashColor(colorName)[500],
    colorName,
  }
}
