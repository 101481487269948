import type { ListItemProps } from '@mui/material'
import { Box, Divider, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { Piano, Tuner } from '@tunasong/icons'
import type { Chord } from '@tunasong/music-lib'
import { Dialog } from '@tunasong/ui-lib'
import type { MouseEvent } from 'react'
import { useState } from 'react'
import { KeyboardChord } from './keyboard-chord.js'
import type { Scale } from '@tunasong/schemas'
import { ScaleSelect } from '../scale/scale-select.js'
import { SimpleChord } from '../chord/simple-chord.js'

interface KeyboardMenuItemProps extends Omit<ListItemProps, 'button'> {
  onClick?: () => void
}

export const useKeyboardMenuItem = (props: KeyboardMenuItemProps = {}) => {
  const { onClick, ...restProps } = props

  const [scale, setScale] = useState<Scale | null>({ root: 'C', type: 'major' })
  const [chords, setChords] = useState<Chord[]>([])

  const [show, setShow] = useState(false)

  const handleShow = (ev: MouseEvent) => {
    ev.preventDefault()
    setShow(true)
    if (onClick) {
      onClick()
    }
  }

  const handleHide = () => {
    setShow(false)
  }

  return {
    dialog: (
      <Dialog open={show} onClose={handleHide} title="Chord Finder" fullWidth={true} maxWidth="md">
        <ScaleSelect value={scale} onChange={setScale} />
        <Divider sx={{ my: 2 }} />
        <KeyboardChord scale={scale} showLabelOnActiveOnly={true} largeKeyboard={true} onChordChange={setChords} />

        <Divider sx={{ my: 2 }}>
          <Typography variant="caption">Identified Chords</Typography>
        </Divider>
        <Box sx={{ minHeight: 50 }}>
          {chords.map((c, idx) => (
            <SimpleChord sx={{ fontSize: '2em' }} key={idx} chord={c} />
          ))}
        </Box>
      </Dialog>
    ),
    menuItem: (
      <MenuItem title="Tuner" color="inherit" onClick={handleShow} {...restProps}>
        <ListItemIcon>
          <Piano />
        </ListItemIcon>
        Chord Finder
      </MenuItem>
    ),
  }
}
