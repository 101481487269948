import { MenuList } from '@mui/material'
import type { Media, Persisted } from '@tunasong/schemas'
import { useState } from 'react'
import type { FC } from 'react'
import { useAudioPlayer } from '../hooks/audio-player.js'
import { usePlayerControl } from '../player-control.hook.js'
import { PlayListItem } from './playlist-list-item.js'

export interface CompactPlaylistProps {
  clips: Persisted<Media>[]
}

export const CompactPlaylist: FC<CompactPlaylistProps> = props => {
  const { clips } = props
  const [ref, setRef] = useState<HTMLAudioElement | null>(null)
  const [active, setActive] = useState<Persisted<Media>>()
  const { audioProps } = useAudioPlayer({ name: active?.name, el: ref })
  const { playing, play, pause, progressLabel } = usePlayerControl({
    el: ref,
    media: clips,
    active,
    onSelect: num => setActive(clips[num]),
  })

  const handleActivate = (entity: Persisted<Media>) => () => {
    if (entity !== active) {
      setActive(entity)
      play()
      return
    }
    if (playing) {
      pause()
      return
    }
    play()
  }

  // Sort clips by updated date by ISO string
  const sortedClips = clips
    .sort((a, b) => {
      const aDate = new Date(a.updatedAt).getTime()
      const bDate = new Date(b.updatedAt).getTime()
      return bDate - aDate
    })
    // Filter out duplicates in sortedClips
    .filter((clip, index, self) => self.findIndex(t => t.id === clip.id) === index)

  return (
    <>
      <MenuList sx={{ width: '100%' }}>
        {sortedClips.map(entity => (
          <PlayListItem
            sx={{
              cursor: 'pointer',
            }}
            key={entity.id}
            entity={entity}
            showUpdated
            showPlayButton
            playing={playing && active === entity}
            progressLabel={progressLabel}
            onPlayClicked={handleActivate(entity)}
          />
        ))}
      </MenuList>
      <audio {...audioProps} ref={setRef} src={active?.storageUrls?.url} />
    </>
  )
}

export default CompactPlaylist
