import { isMention, shortUuid } from '@tunasong/models'
import type { Owned } from '@tunasong/models'
import { entitiesApi, useChildrenIncremental, useEntityUpdate, useSelector } from '@tunasong/redux'
import { chatSchema, isComment, isPersisted, persistedSchema } from '@tunasong/schemas'
import type { Chat, Comment, Entity, EntityOrElement, Persisted } from '@tunasong/schemas'
import { useCurrentUser } from '@tunasong/ui-lib'
import { Node } from 'slate'
import invariant from 'tiny-invariant'

export const useChat = <T extends EntityOrElement>({
  element,
  pageSize = 25,
}: {
  element?: T | null
  pageSize?: number
}) => {
  const { userId } = useCurrentUser()

  const presence = useSelector(state => (element?.id ? state.presence.activeClients[element.id] : null))
  const activeUsers = presence ? Object.values(presence) : []
  const [createEntity] = entitiesApi.useCreateEntityMutation()
  const updateEntity = useEntityUpdate<Chat>({ debounceDelay: 0 })

  const externalItems = useSelector(state => state.entities.receivedEntities)

  const {
    entities: incrementalEntities = [],
    isFetching,
    isLoading,
    addOptimistic,
    getNext,
  } = useChildrenIncremental<Persisted<Comment>>({
    pageSize,
    includeSys: true,
    parentId: element?.id,
    filter: 'comment',
  })

  const comments: Persisted<Comment>[] = [
    ...incrementalEntities,
    ...(externalItems.filter(
      e => element?.id && e.parentId && e.parentId === element.id && isComment(e)
    ) as Persisted<Comment>[]),
  ]

  const create = async (parent: Persisted<Entity>) => {
    /** @todo we use AUTHENTICATED ACL here */
    const chat: Chat & Partial<Owned> = {
      type: 'chat',
      userId,
      parentId: parent.id,
      /** @todo previously for public chats in video rooms */
      // acls: [{ principal: PrincipalType.AUTHENTICATED, permission: 'READ' }],
    }
    const result = createEntity({ entity: chat, parent }).unwrap()
    return chatSchema.merge(persistedSchema).parse(result)
  }

  const addComment = async (comment: Comment) => {
    invariant(userId && element?.id, `Cannot addComment: userId: ${userId}, id: ${element?.id}`)

    /** @todo the ACLs should not really be set here */
    const cc = {
      /** Set the ID to get immediate response in the UI */
      id: shortUuid(),
      // @todo react-compiler makes this crash without the ? operator
      parentId: element?.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      ...comment,
      // acls: [{ principal: PrincipalType.AUTHENTICATED, permission: 'READ' }],
    }
    addOptimistic(cc)
    return createEntity({ entity: cc, parent: element as never }).unwrap() as Promise<Persisted<Comment>>
  }

  const resolveComment = (c: Comment) => {
    if (!(element && isPersisted(c))) {
      throw new Error(`Unable to update comment when element is null or comment.id is null`)
    }

    return updateEntity(c.id, { resolved: true })
  }

  const sortedComments = comments?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
  const activeComments = sortedComments?.filter((e: Comment) => Boolean(!e.resolved))
  const resolvedComments = sortedComments?.filter(e => Boolean(e.resolved))

  const isMentioned = activeComments
    ? Boolean(
        activeComments.find(comment => {
          for (const [node] of Node.descendants(comment)) {
            if (isMention(node) && node.userId === userId) {
              return true
            }
          }
          return false
        })
      )
    : false

  return {
    comments,
    getNext,
    isMentioned,
    activeComments,
    resolvedComments,
    activeUsers,
    create,
    addComment,
    resolveComment,
    isLoading,
    isFetching,
  }
}
