import { Box, Slider, ToggleButton, Typography } from '@mui/material'
import type { SliderProps } from '@mui/material'
import { FLAT } from '@tunasong/music-lib'
import React, { useCallback } from 'react'
import type { FC, MouseEventHandler } from 'react'

export interface TransposeProps extends Omit<SliderProps, 'onChange'> {
  value?: number
  onChange(event: unknown, semiTones: number): void
}

export const Transpose: FC<TransposeProps> = ({ value = 0, onChange, ...restProps }) => {
  const handleChange = useCallback(
    (event: Event, value: number | number[]) => onChange(event, value as number),
    [onChange]
  )
  const handleClick = useCallback(
    (semiTones: number): MouseEventHandler<HTMLElement> =>
      (ev: React.SyntheticEvent) =>
        onChange(ev, semiTones),
    [onChange]
  )
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
      <Typography variant="subtitle1" display={'block'} sx={{ pb: 2 }}>
        Transpose
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* @see http://www.musikkteori.net/2011/05/23/laer-hvordan-du-transponerer-musikk-til-ulike-instrumenter/ */}
        <ToggleButton selected={value === 0} value="C" onClick={handleClick(0)}>
          C tuning
        </ToggleButton>
        <ToggleButton selected={value === 2} value="Bb" onClick={handleClick(2)}>
          B{FLAT} tuning
        </ToggleButton>
        <ToggleButton selected={value === 9} value="Eb" onClick={handleClick(9)}>
          E{FLAT} tuning
        </ToggleButton>
        <ToggleButton selected={value === 7} value="F" onClick={handleClick(7)}>
          F tuning
        </ToggleButton>
      </Box>
      <Box sx={{ padding: 1 }}>
        {/* Need the box container because marks overflows */}
        <Slider
          {...restProps}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={-11}
          max={11}
          value={value}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}
