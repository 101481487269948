import { useContext, useEffect, useRef, useState } from 'react'
import { PluginContext } from './plugin-context.js'
import type { PluginConfigSet } from './plugin-context.js'
import type { TunaPlugin } from '../plugin-types.js'

export const usePlugins = (type: PluginConfigSet | null = 'all') => {
  const repo = useContext(PluginContext)

  const [pluginCollection, setCollection] = useState<TunaPlugin[] | null>(null)
  const isLoading = useRef(false)

  useEffect(() => {
    if (!(repo && type) || pluginCollection || isLoading.current) {
      return
    }
    isLoading.current = true
    repo
      .getCollection(type)
      .then(coll => setCollection(coll.flatMap(plugin => [plugin, ...(plugin.plugins ?? [])])))
      .finally(() => {
        isLoading.current = false
      })
  }, [pluginCollection, repo, type])

  return pluginCollection
}
