import { Dialog, DialogContent, InputBase, Typography } from '@mui/material'

import React, { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { createHotkey } from './create-hotkey.js'
import { makeLabel } from './make-label.js'
import { useDeviceInfo } from '../browser/index.js'

export interface RecordHotkeyProps {
  open: boolean
  onChange: (hotkey: string) => void
  onClose(): void
}

export const RecordHotkey: FC<RecordHotkeyProps> = ({ open, onChange, onClose }) => {
  const [key, setKey] = useState<string | null>()
  const [ref, setRef] = useState<HTMLInputElement>()
  const { os } = useDeviceInfo()

  const handleKey = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (e.key === 'Enter' && key) {
        onChange(key)
      }
      if (e.key === 'Escape') {
        onClose()
      }

      const keyValue = createHotkey(e, os)

      setKey(keyValue)
    },
    [key, onChange, onClose, os]
  )
  useEffect(() => {
    // <StrictMode> breaks the autofocus since it's mounting twice
    if (ref) {
      ref.focus()
    }
  })

  const value = key !== null ? makeLabel(key).toUpperCase() : '<Reserved>'

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent>
        <Typography variant="subtitle1">Record Hotkey and hit Enter (Esc to abort)</Typography>

        <InputBase inputRef={setRef} autoFocus={true} sx={{ flex: 1, p: 2 }} onKeyDown={handleKey} value={value} />
      </DialogContent>
    </Dialog>
  )
}

export default RecordHotkey
