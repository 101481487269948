import type { BoxProps } from '@mui/material'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { GuitarLib, NoteLib } from '@tunasong/music-lib'
import type { Scale } from '@tunasong/schemas'
import { ButtonLink } from '@tunasong/ui-lib'
import { Guitar } from '@tunasong/weblature'
import type { ChangeEvent, FC } from 'react'
import { useMemo, useState } from 'react'
import { Note } from '../../note/note.js'
import Fretboard from '../visualization/fretboard.js'
import GuitarChord from '../visualization/guitar-chord.js'

interface FindChordProps extends BoxProps {
  defaultChord?: GuitarLib.GuitarChord
  scale?: Scale | null
  onSelected?(chord: GuitarLib.GuitarChord): void
}

const allOpen = [1, 2, 3, 4, 5, 6].map(
  str =>
    ({
      str,
      fret: GuitarLib.OPEN_STRING,
    }) as GuitarLib.Fingering
)

export const FindGuitarChord: FC<FindChordProps> = props => {
  const { defaultChord, scale, onSelected, ...restProps } = props

  const [chord, setChord] = useState<GuitarLib.GuitarChord | undefined>(defaultChord)
  const [name, setName] = useState<string>(defaultChord?.name ?? 'Custom')
  const fingering = GuitarLib.getFingeringWithMetadata(chord?.fingering ?? allOpen)

  const guitar = useMemo(() => new Guitar(), [])
  const frets = GuitarLib.getFretsFromFingering(fingering)

  /** Uses 1 for low E, we use 6 for low E */
  const rawChordNames = guitar.getChordNames([...frets].reverse()).sort((a, b) => a.length - b.length)

  /** Map to known chords */
  // const chords = rawChordNames.map(name => ChordLib.fromName(name)).filter(Boolean) as Chord[]

  const chordNotes = chord?.fingering ? GuitarLib.getNotesFromFingering(chord.fingering) : []

  const handleFingering = (n: GuitarLib.Fingering) => {
    const e = fingering.find(f => f.str === n.str)

    /** If existing and new have the same fret, we toggle open */
    let newFret: GuitarLib.Fingering
    if (e?.fret === n.fret) {
      newFret = {
        str: n.str,
        fret: GuitarLib.OPEN_STRING,
      }
    } else {
      newFret = n
    }

    const newFingering = [...fingering.filter(f => f.str !== newFret.str), newFret]

    const chord = {
      name,
      /** We don't want to return the metadata here */
      fingering: GuitarLib.removeMetadata(newFingering),
      notes: chordNotes,
    }
    setChord(chord)

    if (onSelected) {
      onSelected(chord)
    }
  }

  const handleName = (name: string) => {
    setName(name)
    if (onSelected && chord) {
      onSelected({ ...chord, name })
    }
  }
  const handleChordName = (name: string) => () => handleName(name)
  const handleNameText = (ev: ChangeEvent<HTMLInputElement>) => handleName(ev.target.value)

  const fingeringWithMetadata = GuitarLib.getFingeringWithMetadata(fingering, scale)

  return (
    <Box {...restProps}>
      <Fretboard fingerings={fingeringWithMetadata} onSelectFingering={handleFingering} />

      <Grid container sx={{ minHeight: 200 }}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Name</Typography>
          <TextField value={name} onChange={handleNameText} />

          <Typography sx={{ mt: 2 }} variant="subtitle1">
            Notes in Chord
          </Typography>
          {chordNotes
            ?.sort(NoteLib.sortFn)
            .filter(NoteLib.uniqueFn)
            .map((n, idx) => <Note sx={{ m: 1 }} note={n} key={idx} scale={scale} />)}

          <Typography sx={{ mt: 2 }} variant="subtitle1">
            Alternative Chord names
          </Typography>
          {rawChordNames.map(n => (
            <ButtonLink variant="caption" sx={{ pr: 1 }} key={n} onClick={handleChordName(n)}>
              {n}
            </ButtonLink>
          ))}
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">Diagram</Typography>
          <GuitarChord chord={chord} scale={scale} />
        </Grid>
      </Grid>
    </Box>
  )
}
