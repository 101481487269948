import { MEDIA_ELEMENTS } from '@tunasong/schemas'
import type { EntityType, MediaType } from '@tunasong/schemas'

/**
 * This huge hack splits the mimetype, and tries to find the entity type from it
 * e.g.,
 *  audio/midi => audio
 * image/png => image
 * application/pdf => pdf
 *
 * @todo we should use the available mimeTypes in the plugins instead
 * @deprecated
 */
export const getEntityTypeFromMime = (mimeString: string): EntityType | undefined => {
  const [mime, subMime] = mimeString.split('/')
  const media = MEDIA_ELEMENTS
  if (media.includes(mime as never)) {
    return mime as MediaType
  }
  if (media.includes(subMime as never)) {
    return subMime as MediaType
  }
  return undefined
}
