import { colors } from '@mui/material'
import type { Color } from '@mui/material'
import type { ColorName } from '@tunasong/schemas'
import { colorNames } from './color.js'

const stringToHash = (str: string) => {
  let hash = 0

  if (str.length === 0) {
    return hash
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char
    // eslint-disable-next-line no-bitwise
    hash = hash & hash
  }

  return hash
}

export const hashColorName = (data: string) => {
  const hash = Math.abs(stringToHash(data))
  const color = colorNames[hash % colorNames.length]
  return color
}

export const hashColor = (name: ColorName): Color => colors[name]
