/** The graph hook will provide the required functionality to update entities  */

import type { Edge, Persisted, Profile } from '@tunasong/schemas'
import { useCallback } from 'react'
import { entitiesApi } from '../api/entities.js'
import { profilesApi } from '../api/profiles.js'
import { storageApi } from '../api/storage.js'
import { useEntitiesById } from './entities-by-id.js'
import { useEntityUpdate } from './entity-update.js'
import { useThunkDispatch } from './thunk-dispatch.hook.js'

export const useEntityGraph = ({
  debounceDelay = 1000,
  upsert = false,
}: { debounceDelay?: number; upsert?: boolean } = {}) => {
  const dispatch = useThunkDispatch()
  const updateEntity = useEntityUpdate({ debounceDelay, upsert })
  const getEntitiesById = useEntitiesById()

  const [updateProfileMutation] = profilesApi.useUpdateProfileMutation()
  const updateProfile = useCallback(
    async ({ id, profile }: { id: string; profile: Partial<Profile> }): Promise<Persisted<Profile>> =>
      updateProfileMutation({
        id,
        profile,
      }).unwrap(),
    [updateProfileMutation]
  )

  const [loadEntity] = entitiesApi.useLazyLoadEntityQuery()
  const [createEntity, createEntityResult] = entitiesApi.useCreateEntityMutation()
  const [loadFile] = storageApi.useLazyGetAuthorizedStorageFileQuery()

  /**
   * @see https://redux-toolkit.js.org/rtk-query/usage/prefetching

   */
  const prefetchEntity = entitiesApi.usePrefetch('loadEntity')
  const prefetchEntityById = useCallback(
    async (id?: string, ifOlderThanSeconds = 60) => {
      const skip = !id || id === 'ROOT'
      if (skip) {
        return
      }
      prefetchEntity({ id }, { ifOlderThan: ifOlderThanSeconds })
    },
    [prefetchEntity]
  )

  /**
   * Load the entity and its storage file if it is a yDoc.
   */
  const getEntityById = useCallback(
    async (id?: string) => {
      const skip = !id || id === 'ROOT'
      if (skip) {
        return
      }
      // @note important to use `true` as the second argument to avoid always triggering a network request if we have the data in the cache
      const loadResult = await loadEntity({ id }, true)
      if (loadResult.data) {
        const { storage, storageUrls } = loadResult.data
        if (storage?.docType === 'yDoc' && storageUrls) {
          loadFile({ storageUrls }, true)
        }
      }
      return loadResult
    },
    [loadEntity, loadFile]
  )

  const getBacklinks = useCallback(
    async (entityId: string): Promise<Edge[]> =>
      dispatch(entitiesApi.endpoints.loadEntityBacklinks.initiate({ id: entityId })).unwrap(),
    [dispatch]
  )

  return {
    createEntity,
    isCreatingEntity: createEntityResult.isLoading,
    hasCreatedEntity: createEntityResult.isSuccess,
    createEntityResult,
    updateEntity,
    getEntitiesById,
    prefetchEntityById,
    getEntityById,
    getBacklinks,
    updateProfile,
  }
}
