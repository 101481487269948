import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { colorNames } from '../../lib/color.js'

export const profileSchema = baseEntitySchema.extend({
  id: z.string(),
  type: z.literal('profile'),
  userId: z.string(),
  email: z.string(),
  name: z.string().optional(),
  /** URL of a picture for the user. Child images does take precedence, and is handled by the profiles API. */
  picture: z.union([z.string(), z.null()]).optional(),
  /** Profile color */
  color: z.enum(colorNames).optional(),

  /** Subscriber ID */
  sub: z.string().optional(),

  /** Storage quota used in number of bytes */
  quotaUsed: z.number().default(0),

  /** AI tokens used */
  aiTokensUsed: z.number().default(0),

  /**
   * User configuration. useUserConfig() should be used to access this.
   */
  config: z.record(z.unknown()).optional(),
})

export type Profile = z.infer<typeof profileSchema>

export function isProfile(node: unknown): node is Profile {
  return profileSchema.safeParse(node).success
}

/** Server schema with fields that the client are not allowed to change */
export const profileServerSchema = profileSchema.extend({
  limits: z
    .object({
      /** Maximum number of bytes allowed under the current plan. @default 1GB */
      quota: z.number().default(1024 * 1024 * 1024),
    })
    .default({}),
})
export type ProfileServerSchema = z.infer<typeof profileServerSchema>

export const publicProfileSchema = profileSchema.pick({
  id: true,
  type: true,
  userId: true,
  name: true,
  email: true,
  picture: true,
  color: true,
})
export type PublicProfile = z.infer<typeof publicProfileSchema>

export default profileSchema
