import { logger } from '@tunasong/models'
import type { NoteOctave } from '@tunasong/music-lib'
import type { Midi } from '@tunasong/schemas'
import { useAlert } from '@tunasong/ui-lib'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Enumerations } from 'webmidi'
import type { MessageEvent, NoteMessageEvent } from 'webmidi'
import type { MidiNoteEvent } from '../events/midi-event.js'
import { useAudioEngine } from '../hooks/index.js'
import { MidiFactory } from '../midi/midi-entity-factory.js'
import { getActiveNotes } from './active-notes.js'

interface UseMidi {
  /** Channel to listen / send to. If not specified, use all channels. */
  channel?: number

  onNoteEvent?(msg: MidiNoteEvent): void
  onMidiEvent?(msg: MessageEvent): void
}
export const useMidi = ({ channel, onNoteEvent, onMidiEvent }: UseMidi = {}) => {
  const { midi } = useAudioEngine()
  const [activeNotes, setActiveNotes] = useState<NoteOctave[]>([])

  const { alert } = useAlert()

  // We keep inputs and outputs as state here to get reactivity in React
  const [inputs, setInputs] = useState(midi?.inputs ?? [])
  const [outputs, setOutputs] = useState(midi?.outputs ?? [])
  useEffect(() => {
    if (!midi) {
      return
    }
    const handleIOChange = () => {
      setInputs(midi.inputs ?? [])
      setOutputs(midi.outputs ?? [])
      if (midi.inputs.length > 0 || midi.outputs.length > 0) {
        alert({
          message: `MIDI I/O changed. Inputs: ${midi.inputs.map(i => i.name).join(', ')}. Outputs: ${midi.outputs.map(i => i.name).join(', ')}`,
          severity: 'info',
        })
      }
    }
    midi.addListener('iochanged', handleIOChange)
    return () => {
      midi.removeListener('iochanged', handleIOChange)
    }
  })

  /** @todo filter on channel */
  const handleNoteEvent = useCallback(
    (event: NoteMessageEvent) => {
      logger.debug('useMidi: onEvent', event)
      /** Keep track of active notes to e.g., guide a keyboard display */
      setActiveNotes(currentNotes => getActiveNotes({ currentNotes, event }))
      if (onNoteEvent) {
        onNoteEvent(event as MidiNoteEvent)
      }
    },
    [onNoteEvent]
  )
  const handleMidiMessage = useCallback(
    (event: MessageEvent) => {
      onMidiEvent?.(event)
    },
    [onMidiEvent]
  )

  const sendMidiCommands = useCallback(
    (commands: Midi[]) => {
      logger.debug('Sending MIDI Commands: ', commands)
      midi?.sendMidiCommands({ commands })
    },
    [midi]
  )

  const sendProgramChange = useCallback(
    ({ program, patchBank, patchBankLsb }: { program: number; patchBank?: number; patchBankLsb?: number }) => {
      midi?.sendMidiCommand({ command: MidiFactory.programChange({ program, patchBank, patchBankLsb, channel }) })
    },
    [channel, midi]
  )
  const sendCC = useCallback(
    ({ cc, value }: { cc: number; value: number }) => {
      logger.debug('Sending MIDI CC Command: ', { cc, value })

      midi?.sendMidiCommand({ command: MidiFactory.cc({ cc, value, channel }) })
    },
    [channel, midi]
  )

  const counter = useRef(0)

  /** Set up on MIDI connected */
  useEffect(() => {
    if (!inputs) {
      return
    }
    counter.current += 1
    if (counter.current > 5) {
      logger.warn('useMidi: more than 5 attempts to set up MIDI - do you have unstable references?')
      return
    }
    for (const input of inputs) {
      logger.debug('useMidi: setting up input', input)
      input.addListener('midimessage', handleMidiMessage)
      for (const channel of Enumerations.MIDI_CHANNEL_NUMBERS) {
        input.channels[channel].addListener('noteon', handleNoteEvent)
        input.channels[channel].addListener('noteoff', handleNoteEvent)
      }
    }
    return () => {
      for (const input of inputs) {
        input.removeListener('midimessage', handleMidiMessage)
        for (const channel of Enumerations.MIDI_CHANNEL_NUMBERS) {
          input.channels[channel].removeListener('noteon', handleNoteEvent)
          input.channels[channel].removeListener('noteoff', handleNoteEvent)
        }
      }
    }
  }, [channel, handleMidiMessage, handleNoteEvent, inputs])

  return {
    sendMidiCommands,
    sendProgramChange,
    sendCC,
    inputs,
    outputs,
    activeNotes,
  }
}
