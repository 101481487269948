import { Range } from 'slate'
import { Editor } from '@tunasong/plugin-lib'
import type { TunaEditor } from '@tunasong/plugin-lib'

/** Select the full document */
const all = (editor: TunaEditor): Range | undefined => {
  if (editor.children?.length === 0) {
    return undefined
  }
  return {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  }
}

export const RangeQueries = {
  all,
}
