import { Box, Chip, InputBase } from '@mui/material'
import type { Theme } from '@mui/material'
import classNames from 'classnames'
import { useState } from 'react'
import type { ChangeEvent, FC, KeyboardEvent } from 'react'
import { hashColorName, useBorderColors } from '../color/index.js'
import { useCommonStyles } from '../hooks/index.js'
import { makeStyles } from '../styles.js'
import { disableAutocomplete } from '../util/index.js'

export interface TagListProps {
  className?: string
  tags?: string[]
  fullWidth?: boolean
  placeholder?: string
  variant?: 'outlined' | 'filled'
  size?: 'small' | 'medium'
  edit?: boolean
  onChange?(tags: string[]): void
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    // flexDirection: 'column',
    fontSize: theme.typography.fontSize * 0.8,
  },
  tags: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tag: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
}))

export const TagList: FC<TagListProps> = props => {
  const {
    className,
    tags: defaultTags = [],
    size = 'medium',
    onChange,
    variant = 'outlined',
    fullWidth = true,
    placeholder = 'Add tag...',
  } = props
  const { classes } = useStyles()
  const [name, setName] = useState('')

  const { classes: colorClasses } = useBorderColors()
  const { classes: cc } = useCommonStyles()
  const tags = defaultTags ?? []

  const handleDelete = (tag: string) => () => onChange && onChange(tags.filter(el => el !== tag))
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => setName(ev.target.value)
  const handleReturn = (ev: KeyboardEvent<HTMLInputElement>) => {
    const val: string = ev.currentTarget.value
    if (ev.key === 'Enter' && val) {
      setName('')
      if (tags.includes(val)) {
        return
      }
      if (onChange) {
        onChange([...tags, val.toLowerCase()])
      }
    }
  }

  return (
    <Box className={classNames(className, classes.root, classes.tags)}>
      {tags.map(c => (
        <Chip
          className={classNames(classes.tag, { [colorClasses[hashColorName(c)]]: true })}
          key={c}
          label={c}
          size={size}
          variant={variant}
          onDelete={onChange ? handleDelete(c) : undefined}
        />
      ))}
      {onChange && (
        <InputBase
          id="tag-list"
          className={cc.controlText}
          fullWidth={fullWidth}
          value={name}
          placeholder={placeholder}
          onKeyUp={handleReturn}
          onChange={handleChange}
          inputProps={disableAutocomplete}
        />
      )}
    </Box>
  )
}

export default TagList
