import { logger } from '@tunasong/models'
import type { AudioChannel } from '@tunasong/models'
import type { AudioTrack } from '@tunasong/schemas'
import type { IAudioContext, IAudioNode } from 'standardized-audio-context'
import invariant from 'tiny-invariant'
import type { Recorder } from '../recorder/recorder.js'
import { WavRecorder } from '../recorder/wav-recorder.js'
import { AudioController } from './audio-controller.js'
import type { TrackType } from './audio-controller.js'
import type { IMixer } from './interfaces.js'

export class ChannelController extends AudioController<AudioTrack> implements AudioChannel {
  protected outputNode: IAudioNode<IAudioContext>
  private wavRecorder: WavRecorder | null = null
  private isArmed = false

  constructor(
    protected mixer: IMixer,
    private audioSource: AudioChannel
  ) {
    super(mixer, audioSource)

    /** Connect the input to the gain node */
    if (this.input) {
      this.setupInput()
    }

    this.outputNode = this.pannerNode
  }

  get inputNode(): IAudioNode<IAudioContext> | null {
    return this.audioSource.inputNode
  }

  get trackType(): TrackType {
    return 'track'
  }

  get recorder(): Recorder | null {
    return this.wavRecorder
  }

  get monitor(): boolean {
    return this.isMonitored
  }

  set monitor(monitored: boolean) {
    if (monitored === this.isMonitored) {
      return
    }
    this.isMonitored = monitored
    const { start, stop } = this.audioSource
    if (monitored && start) {
      start().then(() => this.setupInput())
    } else if (!monitored && stop) {
      stop()
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get armed() {
    return this.isArmed
  }

  set armed(armed: boolean) {
    this.isArmed = armed

    /** If this is an input channel, we acquire the device from the user */

    if (armed && !this.wavRecorder) {
      /** @todo more flexible routing here */
      const source = this.mixer.getBus('mic')
      this.wavRecorder = new WavRecorder(this.mixer, source)
      logger.debug(`Armed track with source`, this, source)
      return
    }
    if (!armed) {
      /** Stop recording (if ongoing) */
      this.recorder?.stop()
    }
  }

  setMonitor(monitor: boolean) {
    this.monitor = monitor
  }

  /** Basic routing for input */
  setupInput = () => {
    invariant(this.input, 'input node must be defined to route the channel')
    this.input.connect(this.gainNode)

    /** @todo how to avoid adding multiple effects here? */
    const effects = this.audioSource.effects ?? []
    this.addEffects(this.input, effects as never)
  }

  addEffects = (node: IAudioNode<IAudioContext>, effects: IAudioNode<IAudioContext>[] = []) => {
    /** The effects chain is added between the source node and the gain node */

    let prevNode: IAudioNode<IAudioContext> = node
    /** Connect the effect(s). There will always be at least one (gain) */
    for (const effect of effects) {
      prevNode.connect(effect)
      prevNode = effect
    }
    /** Connect the output of the effects chain to the destination */
    prevNode.connect(this.gainNode)
  }

  disconnect = () => {
    super.disconnect()

    this.outputNode.disconnect()
    this.wavRecorder?.disconnect()
    this.wavRecorder = null
  }
}
