import type { sortOptionsSchema } from '@tunasong/schemas'
import type { z } from 'zod'

export type SortOptions = z.infer<typeof sortOptionsSchema>

export const defaultSortOptions = {
  type: 'field',
  sortBy: 'updatedAt',
  order: 'desc',
  elementOrder: [],
} satisfies SortOptions
