/** Cognito Auth */
import { baseConfig as awsconfig, logger, shortUuid } from '@tunasong/models'
import type { AuthData } from './auth-data.js'
import { decodeOAuthData } from './decode-oauth.js'
import { clearJWT, getJWT, setJWT } from './jwt.js'
import { encodeLoginState } from './login-state-schema.js'
import { pkce } from './pkce.js'
import type { PKCE } from './pkce.js'

const {
  userPoolWebClientId,
  oauth: { scope, domain, loginProxy, redirectSignIn, logoutProxy, redirectSignOut },
} = awsconfig.Auth

const CODE_CHALLENGE_KEY = 'tunasong.auth.codechallenge'
const STATE_KEY = 'tunasong.auth.state'

function getUrlParameter(name: string) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const authorize = async ({ signup = false }: { signup?: boolean } = {}) => {
  const scopeString = scope.join(' ')

  const authState = encodeLoginState({
    clientCode: shortUuid(),
    callbackUrl: redirectSignIn,
  })

  // https://tools.ietf.org/html/rfc7636#appendix-A
  const codePair = await pkce()
  localStorage.setItem(CODE_CHALLENGE_KEY, JSON.stringify(codePair))
  localStorage.setItem(STATE_KEY, authState)

  const url = `https://${domain}/${signup ? 'signup' : 'oauth2/authorize'}?redirect_uri=${encodeURIComponent(
    loginProxy
  )}&response_type=code&client_id=${encodeURIComponent(
    userPoolWebClientId
  )}&identity_provider=COGNITO&scopes=${encodeURIComponent(scopeString)}&state=${encodeURIComponent(
    authState
  )}&code_challenge=${codePair.challenge}&code_challenge_method=S256`

  logger.debug('Redirecting to Cognito', url)

  document.location.replace(url)
}

/** Get a token using OAuth. Payload is an application/x-www-form-urlencoded list of parameters */
export const getToken = async (payload: string) => {
  const url = `https://${domain}/oauth2/token`
  const result = await fetch(url, {
    method: 'POST',
    body: payload,
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  })
  if (result.status !== 200) {
    throw new Error(`Autentication failed: ${result.statusText}`)
  }
  const json = (await result.json()) as {
    access_token: string
    refresh_token: string
    id_token: string
    expires_in: number
  }

  const { id_token: idToken, access_token: accessToken, refresh_token: refreshToken, expires_in: expiresIn } = json

  return decodeOAuthData({ idToken, accessToken, refreshToken, expiresIn })
}

/** Login using code in the URL - i.e., from a OAuth callback */
export const loginWithCode = async (): Promise<AuthData> => {
  const code = getUrlParameter('code')
  const stateBase64 = getUrlParameter('state')
  const stateValBase64 = localStorage.getItem(STATE_KEY)
  /** We remove immediately - loginWithCode is valid one roundtrip only */
  localStorage.removeItem(STATE_KEY)

  if (stateBase64 && stateBase64 !== stateValBase64) {
    throw new Error(`State in OAuth callback ${stateBase64} does not match stored value ${stateValBase64}`)
  }

  const challenge = localStorage.getItem(CODE_CHALLENGE_KEY)
  if (!challenge) {
    throw new Error('Auth: Code Challenge not found in localStorage')
  }
  localStorage.removeItem(CODE_CHALLENGE_KEY)

  const codePair = JSON.parse(challenge) as PKCE

  const payload = `code=${encodeURIComponent(code)}&grant_type=authorization_code&client_id=${encodeURIComponent(
    userPoolWebClientId
  )}&redirect_uri=${encodeURIComponent(loginProxy)}&code_verifier=${codePair.verifier}`

  const token = await getToken(payload)
  setJWT(token)
  return token
}

const getSessionFromStorage = () => {
  const config = typeof localStorage !== 'undefined' ? getJWT() : null
  return config
}

export const isSessionExpired = (session?: AuthData | null) => {
  if (!session) {
    return true
  }
  const jwtExp = session.idToken.payload.exp
  /** Date.now() is in millis */
  const threeSecondsFromNow = Date.now() / 1000 + 3

  const expired = jwtExp ? jwtExp <= threeSecondsFromNow : true

  return expired
}

/** `true` if the client has a session. It may be expired. However, having a session signals the user is logged in, and can have a refresh token set in a server cookie */
export const hasSession = () => Boolean(getSessionFromStorage())

/** Get the session. If refreshExpired is set to true (default), try to refresh expired sessions by using the refreshToken. */
export const getSession = async (refreshExpired = true) => {
  let config = getSessionFromStorage()
  if (!config) {
    return null
  }
  /** Check if the session needs to be refreshed. expires is the time (in millis) that the token expires */
  if (refreshExpired && isSessionExpired(config)) {
    config = await refreshOAuthSession(config)
  }
  return config
}

export const clearSession = clearJWT

export const refreshOAuthSession = async (config: AuthData) => {
  if (!config) {
    return null
  }
  const { refreshToken } = config
  const { jwtToken } = refreshToken

  /** Use the refresh token to refresh */
  const payload = `refresh_token=${jwtToken}&grant_type=refresh_token&client_id=${encodeURIComponent(
    userPoolWebClientId
  )}`

  const newConfig = await getToken(payload)
  if (!newConfig) {
    return null
  }
  /** Retain the refreshToken from the previous session */
  newConfig.refreshToken = config.refreshToken

  setJWT(newConfig)

  return newConfig
}

export const logout = () => {
  const logoutState = encodeLoginState({
    clientCode: shortUuid(),
    callbackUrl: redirectSignOut,
  })
  const url = `${logoutProxy}?state=${encodeURIComponent(logoutState)}`
  /** Removal of JWT from local storage will happen in the logout handler */
  document.location.replace(url)
}

/** The the logout URL for Cognito. Not used by the client directly. */
export const getCognitoLogoutURL = () => {
  const url = `https://${domain}/logout?client_id=${userPoolWebClientId}&logout_uri=${encodeURIComponent(logoutProxy)}`
  return url
}
