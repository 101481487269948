import { ErrorMessage } from '@hookform/error-message'
import type { FieldValuesFromFieldErrors } from '@hookform/error-message'
import { Typography } from '@mui/material'
import { Fragment } from 'react'
import type { FC } from 'react'
import { getFieldComponent } from './get-element.js'
import type { FormControlProps } from './form-control-props.js'
import type { FieldErrors, FieldName, FieldValues } from 'react-hook-form'

const ErrorLabel: FC<{ message: string }> = ({ message }) => (
  <Typography sx={{ my: 1 }} display="block" variant="caption" color="error">
    {message}
  </Typography>
)

export function FormControl<T extends FieldValues>(props: FormControlProps<T>) {
  const { name, formField, errors, control, register, autoFocus } = props
  const Component = getFieldComponent<T>(formField)

  return (
    <Fragment key={name}>
      <Component
        sx={{ my: 1 }}
        name={name}
        autoFocus={autoFocus}
        control={control}
        register={register}
        errors={errors}
        spec={formField}
        FormControl={FormControl} // Dependency injection to avoid circular dependency
      />

      {errors ? (
        <ErrorMessage
          name={name as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>}
          errors={errors}
          render={ErrorLabel}
        />
      ) : null}
    </Fragment>
  )
}

export default FormControl
