import { NoteLib, EasyScore } from '@tunasong/music-lib'
import type { NoteStr } from '@tunasong/music-lib'

export const noteToMidiNumber = (noteStr: NoteStr): number => {
  const { octave = 4, note } = EasyScore.parseEvent(noteStr)

  const index = NoteLib.getNoteChromaticIndex(note)

  return index + (octave + 1) * 12
}
