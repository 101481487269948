import { isAudioClip } from '@tunasong/schemas'
import type { AudioMedia } from '@tunasong/schemas'
import cn from 'classnames'
import React from 'react'
import { usePeaks } from '../../hooks/index.js'
import { Wave } from '../../waveform/wave.js'
import type { ClipProps } from './clip-container.js'
import { useStyles } from './clip.styles.js'

export interface AudioClipProps extends ClipProps {
  className?: string
  ref?: React.Ref<HTMLDivElement>
}

const AudioClip = (props: AudioClipProps) => {
  const { className, clip, ref } = props

  const { classes } = useStyles()

  if (!isAudioClip(clip)) {
    throw new Error(`AudioClip used for different clip type ${clip.type}`)
  }

  /** @todo  */
  const media: AudioMedia = {
    name: clip?.name ?? 'Clip',
    type: 'audio',
  }

  const { peaks } = usePeaks({ media })

  if (!clip) {
    return null
  }

  return (
    <div className={cn(className, classes.clip)} ref={ref}>
      <Wave className={classes.waveContainer} peaks={peaks} position={0} />
    </div>
  )
}

export default AudioClip
