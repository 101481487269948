import { useSearch } from './search.hook.js'
import type { SearchProps } from './search.hook.js'

/** Embeddings search for siminar content */
export const useEmbedding = (
  { entityId }: { entityId?: string },
  props: SearchProps = { queryType: 'relatedContent' }
) => {
  /** First, get the embeddings */
  const { results } = useSearch({ ...props, query: entityId, queryType: 'getId', size: 1, debounceDelay: 0 })
  const embedding = results?.[0]?.embedding as number[] | undefined
  return embedding
}
