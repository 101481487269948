/** Adapted from https://github.com/mdn/samples-server/blob/master/s/webrtc-from-chat/chatclient.js */

import type { Mixer } from '@tunasong/audio-ui'
import { logger } from '@tunasong/models'
import { features } from '@tunasong/redux'
import type {
  WebRTCMessage,
  WebRTCNewICECandidateMessage,
  WebRTCStopTransceiverMessage,
  WebRTCVideoAnswerMessage,
  WebRTCVideoOfferMessage,
} from '@tunasong/redux'

/** Props used by both pool and individual connections */

export interface VideoConnectionProps {
  userId?: string

  /** Context of a Video is an entity */
  entityId: string

  /** Our clientId */
  clientId: string

  /** Peer clientId */
  peerClientId: string

  /** Webcam media stream */
  stream: MediaStream

  /** Mixer - peer streams audio will be sent to the mixer */
  mixer: Mixer

  iceServers: RTCIceServer[]

  onTrack(ev: RTCTrackEvent): void
  onDisconnect(conn: VideoConnection): void

  /** Signalling channel */
  sendMessage(msg: WebRTCMessage): void
}

/**
 * Handle a single peer connection to another peer
 *
 * Signaling flow - @see https://www.pkc.io/blog/untangling-the-webrtc-flow/
 *
 */
export class VideoConnection {
  /** Public fields */
  public clientId: string
  public peerClientId: string
  public stream: MediaStream | null = null

  /** This is the original stream audio / video track from the webcam */
  private streamVideoSender: RTCRtpSender | null = null
  private streamAudioSender: RTCRtpSender | null = null

  private isNegotiating = false

  private peerConnection: RTCPeerConnection

  constructor(private props: VideoConnectionProps) {
    const { clientId, peerClientId, iceServers } = props
    this.peerClientId = peerClientId
    this.clientId = clientId
    this.peerConnection = this.createPeerConnection(iceServers)

    this.start()
  }

  get signalingState() {
    return this.peerConnection.signalingState
  }

  get primary() {
    return VideoConnection.isPrimary(this.clientId, this.peerClientId)
  }

  /** The primary peer should establish the connection and create the VideoConnection object */
  static isPrimary = (clientId: string, peerClientId: string) => clientId > peerClientId

  /** Adding tracks will trigger negotiation */
  start = () => {
    const pc = this.peerConnection
    const { stream: webcamStream } = this.props

    webcamStream.getAudioTracks().forEach(async (track, idx) => {
      const sender = pc.addTrack(track, webcamStream)
      logger.info('*** Audio track added', sender.getParameters(), await sender.getStats())
      if (idx === 0) {
        this.streamAudioSender = sender
      }
    })

    webcamStream.getVideoTracks().forEach((track, idx) => {
      const sender = pc.addTrack(track, webcamStream)
      if (idx === 0) {
        this.streamVideoSender = sender
      }
    })
  }

  replaceAudioTrack = async (track: MediaStreamTrack) => {
    if (!this.streamAudioSender) {
      logger.warn(`Cannot replace audio track - no active stream`)
      return
    }
    return this.streamAudioSender.replaceTrack(track)
  }

  replaceVideoTrack = async (track: MediaStreamTrack) => {
    if (!this.streamVideoSender) {
      logger.warn(`Cannot replace video track - no active stream`)
      return
    }
    logger.info('Connection: replaceTrack', track)
    return this.streamVideoSender.replaceTrack(track)
  }

  /** The master is responsible to initiate a call when receiving a Enter message */

  // Create the RTCPeerConnection which knows how to talk to our
  // selected STUN/TURN server and then uses getUserMedia() to find
  // our camera and microphone and add that stream to the connection for
  // use in our video call. Then we configure event handlers to get
  // needed notifications on the call.

  createPeerConnection = (iceServers: RTCIceServer[]) => {
    const pc = new RTCPeerConnection({
      iceServers,
    })

    // Set up event handlers for the ICE negotiation process.
    pc.addEventListener('icecandidate', this.handleICECandidateEvent)
    pc.addEventListener('iceconnectionstatechange', this.handleICEConnectionStateChange)
    pc.addEventListener('icegatheringstatechange', this.handleICEGatheringStateChange)
    pc.addEventListener('signalingstatechange', this.handleSignalingStateChange)
    pc.addEventListener('negotiationneeded', this.handleNegotiation)
    pc.addEventListener('track', this.handleTrackEvent)

    return pc
  }

  /** Utility methods */

  verifyMessage = (
    msg:
      | WebRTCVideoOfferMessage
      | WebRTCVideoAnswerMessage
      | WebRTCNewICECandidateMessage
      | WebRTCStopTransceiverMessage
  ) => {
    const { senderClientId, receiverClientId } = msg.payload
    if (senderClientId !== this.props.peerClientId) {
      throw new Error(
        `Received message for other peer clientId: ${senderClientId} (expected: ${this.props.peerClientId})`
      )
    }
    if (receiverClientId && receiverClientId !== this.props.clientId) {
      throw new Error(
        `Received message for other destination clientId: ${receiverClientId} (expected: ${this.props.clientId})`
      )
    }
  }

  // Close the RTCPeerConnection and reset variables so that the user can
  // make or receive another call if they wish. This is called both
  // when the user hangs up, the other user hangs up, or if a connection
  // failure is detected.

  closeVideoCall = () => {
    logger.info('--> Closing the peer connection', this.peerConnection)
    const {} = this.props
    const pc = this.peerConnection

    // Disconnect all our event listeners; we don't want stray events
    // to interfere with the hangup while it's ongoing.

    pc.removeEventListener('icecandidate', this.handleICECandidateEvent)
    pc.removeEventListener('iceconnectionstatechange', this.handleICEConnectionStateChange)
    pc.removeEventListener('icegatheringstatechange', this.handleICEGatheringStateChange)
    pc.removeEventListener('signalingstatechange', this.handleSignalingStateChange)
    pc.removeEventListener('negotiationneeded', this.handleNegotiation)
    pc.removeEventListener('track', this.handleTrackEvent)

    // Stop all transceivers on the connection. transceiver.stop() is not supported in Chrome
    pc.getTransceivers().forEach(transceiver => transceiver.stop && transceiver.stop())

    // Close the peer connection
    this.peerConnection.close()

    this.stream = null

    /**
     * Notify that we are closing. The connection pool will listen to this and remove us from the pool.
     */
    this.props.onDisconnect(this)
  }

  /** Add tranceiver with logging */
  addTransceiver = (track: MediaStreamTrack, streams: MediaStream[]) => {
    const init: RTCRtpTransceiverInit = {
      streams,
      sendEncodings: [
        // {
        // active: true,
        // maxBitrate:
        // maxFramerate:
        //  }
      ],
      // direction
    }
    const pc = this.peerConnection
    const t = pc.addTransceiver(track, init)
    logger.info('*** Added transceiver', t)
    t.receiver.track.addEventListener('mute', (ev: Event) => logger.info(`Receiver Track muted`, ev))
    t.receiver.track.addEventListener('unmute', (ev: Event) => logger.info(`Receiver Track unmuted`, ev))
    if (t.sender.track) {
      t.sender.track.addEventListener('mute', (ev: Event) => logger.info(`Sender Track muted`, ev))
      t.sender.track.addEventListener('unmute', (ev: Event) => logger.info(`Sender Track unmuted`, ev))
    }
  }

  // Accept an offer to video chat. We configure our local settings,
  // create our RTCPeerConnection, get and attach our local camera
  // stream, then create and send an answer to the caller.

  handleVideoOffer = async (msg: WebRTCVideoOfferMessage) => {
    const pc = this.peerConnection
    this.verifyMessage(msg)
    await pc.setRemoteDescription(msg.payload.sdp)
    const answer = await pc.createAnswer({
      // voiceActivityDetection: false, // Deprecated, setting it anyway
    })
    // /** Setting the local description to trigger the negotiation process */
    await pc.setLocalDescription(answer)

    /** @todo figure out why we were doing this */
    // try {
    //   /** @todo should we generate two transceivers here per audio track? */
    //   webcamStream.getTracks().forEach(track => this.addTransceiver(track, [webcamStream]))
    // } catch (err) {
    //   logger.error('*** Handle Offer - Unable to add transceiver for video offer', msg, err)
    // }

    const { sendMessage, clientId, peerClientId, entityId } = this.props
    sendMessage(
      features.webrtc.actions.videoAnswer({
        entityId,
        senderClientId: clientId,
        receiverClientId: peerClientId,
        sdp: answer,
      })
    )
  }

  // Responds to the "video-answer" message sent to the caller
  // once the callee has decided to accept our request to talk.

  handleVideoAnswer = async (msg: WebRTCVideoAnswerMessage) => {
    // logger.info(`*** Video Answer Call recipient ${this.peerClientId} has accepted our call`, msg)
    this.verifyMessage(msg)
    const { signalingState } = this.peerConnection
    // logger.info('*** Video Answer setRemoteDescription', signalingState, connectionState)
    if (signalingState !== 'stable') {
      await this.peerConnection.setRemoteDescription(msg.payload.sdp)
    }
  }

  // A new ICE candidate has been received from the other peer. Call
  // RTCPeerConnection.addIceCandidate() to send it along to the
  // local ICE framework.
  handleNewICECandidate = async (msg: WebRTCNewICECandidateMessage) => {
    this.verifyMessage(msg)
    try {
      await this.peerConnection.addIceCandidate(msg.payload.candidate)
      logger.info(`*** handleNewICECandidate successfully applied ICE candidate`, msg.payload.candidate)
    } catch (e) {
      logger.warn(`*** handleNewICECandidate failed to add ICE candidate`, e, this.peerConnection.signalingState)
    }
  }

  handleStopTransceiver = (msg: WebRTCStopTransceiverMessage) => {
    this.verifyMessage(msg)

    const { mid } = msg.payload
    const transceiverToStop = this.peerConnection.getTransceivers().filter(transceiver => transceiver.mid === mid)[0]
    if (!transceiverToStop) {
      return
    }
    transceiverToStop.receiver.track.stop()
    /** @todo when the peer has asked to sto the transceiver, the connection is dead. So we hang up */
    this.closeVideoCall()
  }

  // Called by the WebRTC layer to let us know when it's time to
  // begin, resume, or restart ICE negotiation.

  private handleNegotiation = async (ev: unknown) => {
    if (!this.primary) {
      // logger.info("---> Negotiation - allowing primary peer handle...")
      return
    }

    if (this.isNegotiating) {
      logger.warn('Already negotiating - ignoring event', ev)
    }
    this.isNegotiating = true

    // logger.info(`---> Negotiation - creating offer in state: ${this.peerConnection.signalingState}`)
    const offer = await this.peerConnection.createOffer()

    // Establish the offer as the local peer's current description.
    // logger.info('---> Setting local description to the offer')
    await this.peerConnection.setLocalDescription(offer)

    /** Send offer */
    const { entityId, sendMessage } = this.props
    sendMessage(
      features.webrtc.actions.videoOffer({
        entityId,
        receiverClientId: this.peerClientId,
        senderClientId: this.clientId,
        sdp: offer,
      })
    )

    this.isNegotiating = false
  }

  // Called by the WebRTC layer when events occur on the media tracks
  // on our WebRTC call. This includes when streams are added to and
  // removed from the call.
  //
  // track events include the following fields:
  //
  // RTCRtpReceiver       receiver
  // MediaStreamTrack     track
  // MediaStream[]        streams
  // RTCRtpTransceiver    transceiver
  //
  // In our case, we're just taking the first stream found and attaching
  // it to the <video> element for incoming media.

  private handleTrackEvent = (event: RTCTrackEvent) => {
    logger.info('*** Track event', event)
    this.stream = event.streams[0]
    /** Route the stream audio into the mixer */
    const audioTrack = this.stream.getAudioTracks()[0]
    /** @todo multiple audio tracks? */
    if (audioTrack) {
      /** @todo removed - don't think we need to clone here */
      // const newStream = this.stream.clone()

      const source = this.props.mixer.context.createMediaStreamSource(this.stream)

      this.props.mixer.addChannel(
        {
          type: 'peer',
          id: this.props.peerClientId,
          name: this.props.peerClientId,
          effects: [],
          inputNode: source,
        },
        'peer'
      )
      /** @note the peer stream player is always muted, so the audio will be served from the Mixer  */
    }
    /**
     *  Notify about a track event. This must result in a UI update
     */
    this.props.onTrack(event)
  }

  // Handles |icecandidate| events by forwarding the specified
  // ICE candidate (created by our local ICE agent) to the other
  // peer through the signaling server.

  private handleICECandidateEvent = (event: RTCPeerConnectionIceEvent) => {
    if (!event.candidate) {
      return
    }
    logger.info('*** Outgoing ICE candidate: ' + event.candidate.candidate)
    const { sendMessage, clientId, peerClientId, entityId } = this.props
    sendMessage(
      features.webrtc.actions.newIceCandidate({
        entityId,
        senderClientId: clientId,
        receiverClientId: peerClientId,
        candidate: event.candidate,
      })
    )
  }

  // Handle |iceconnectionstatechange| events. This will detect
  // when the ICE connection is closed, failed, or disconnected.
  //
  // This is called when the state of the ICE agent changes.

  private handleICEConnectionStateChange = () => {
    if (!this.peerConnection) {
      throw new Error(`Cannot handle ICE event because connection is down`)
    }
    logger.info('*** ICE connection state changed to ' + this.peerConnection.iceConnectionState, event)
    switch (this.peerConnection.iceConnectionState) {
      case 'failed':
        /** @see https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation */
        if (this.peerConnection.onnegotiationneeded) {
          this.peerConnection.onnegotiationneeded({ iceRestart: true } as never)
        }
        break
      case 'closed':
      case 'disconnected':
        this.closeVideoCall()
        break
    }
  }

  // Set up a |signalingstatechange| event handler. This will detect when
  // the signaling connection is closed.
  //
  // NOTE: This will actually move to the new RTCPeerConnectionState enum
  // returned in the property RTCPeerConnection.connectionState when
  // browsers catch up with the latest version of the specification!

  private handleSignalingStateChange = () => {
    if (!this.peerConnection) {
      throw new Error(`Cannot handle ICE event because connection is down`)
    }

    const { signalingState } = this.peerConnection

    logger.info(`*** WebRTC signaling state changed to: ${signalingState}`)

    this.isNegotiating = signalingState !== 'stable'

    switch (signalingState) {
      case 'stable':
        break
      case 'closed':
        this.closeVideoCall()
        break
    }
  }

  // Handle the |icegatheringstatechange| event. This lets us know what the
  // ICE engine is currently working on: "new" means no networking has happened
  // yet, "gathering" means the ICE engine is currently gathering candidates,
  // and "complete" means gathering is complete. Note that the engine can
  // alternate between "gathering" and "complete" repeatedly as needs and
  // circumstances change.
  //
  // We don't need to do anything when this happens, but we log it to the
  // logger.so you can see what's going on when playing with the sample.

  private handleICEGatheringStateChange(ev: unknown) {
    logger.info('*** ICE gathering state changed to: ', this.peerConnection?.iceGatheringState, ev)
  }
}

export default VideoConnection
