import { Box } from '@mui/material'
import type { ImageElement, Persisted } from '@tunasong/schemas'
import cn from 'classnames'
import type { FC } from 'react'
import { makeStyles } from '../styles.js'

interface BackgroundImageProps {
  image: Persisted<ImageElement>
}

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
  },
  img: {
    objectFit: 'cover',
    border: 'none',
  },
  box: {
    backgroundColor: `rgba(${theme.vars.palette.background.defaultChannel} / 0.8)`,
  },
}))

export const BackgroundImage: FC<BackgroundImageProps> = props => {
  const { classes } = useStyles()
  const { image } = props
  const url = image.storageUrls?.sizes?.large
  return (
    <>
      <img className={cn(classes.root, classes.img)} src={url} />
      <Box className={cn(classes.root, classes.box)} />
    </>
  )
}
