import type { Entity, Persisted } from '@tunasong/schemas'
import { usePlugin } from './editor-plugin.js'

export const useContentViews = (entity?: Persisted<Entity>): string[] => {
  const plugin = usePlugin(entity)
  // Filter out all the sys: view(s), which is a special case
  return plugin?.components?.Content
    ? Object.keys(plugin.components.Content).filter(view => !view.startsWith('sys:'))
    : []
}
