import { skipToken } from '@reduxjs/toolkit/query'
import { isTaggedSysEntity, logger } from '@tunasong/models'
import type { FilterFunc, SearchSummary } from '@tunasong/models'
import { searchApi } from '@tunasong/redux'
import { isEntity } from '@tunasong/schemas'
import type { SearchRequest } from '@tunasong/schemas'
import { useDelayedValue } from '@tunasong/ui-lib'
import { useEffect, useMemo, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { allQueries } from './queries/index.js'
import type { QueryType } from './queries/index.js'

export interface AdvancedSearchProps {
  /** Max size of result set */
  size?: number

  /** Debounce delay in ms. Default: 0ms */
  debounceDelay?: number
  /** Type of query */
  queryType: QueryType

  resultFilter?: FilterFunc
  /** Include sys: tagged entities */
  includeSys?: boolean
}

/** Advanced Search where the esQuery can be specified fully */
export const useAdvancedSearch = (esQuery?: SearchRequest, props: AdvancedSearchProps = { queryType: 'default' }) => {
  const { size = 50, debounceDelay = 0, resultFilter = Boolean, includeSys = false, queryType } = props

  /** The index we have retrieved results to */
  const [from, setFrom] = useState(0)
  const [results, setResults] = useState<SearchSummary[]>([])

  const debouncedQuery = useDelayedValue(esQuery, debounceDelay)

  const { currentData, isLoading, isFetching, isSuccess, isError, originalArgs } = searchApi.useSearchQuery(
    debouncedQuery
      ? {
          query: {
            ...debouncedQuery,
            from,
            size,
          },
          queryType,
        }
      : skipToken,
    {}
  )

  // If the serialized version of esQuery changes, reset the results
  const previousQuery = useRef(esQuery)
  useEffect(() => {
    if (JSON.stringify(esQuery) !== JSON.stringify(previousQuery.current)) {
      setFrom(0)
      previousQuery.current = esQuery
    }
  }, [esQuery])

  useEffect(() => {
    if (isFetching || !isSuccess) {
      return
    }

    // When currentData changes, update the results
    const queryFrom = originalArgs?.query?.from ?? 0
    const filteredResults = currentData?.body.hits.hits
      // eslint-disable-next-line no-underscore-dangle
      .map(r => r._source)
      /** Filter out sys: tagged entities */
      .filter(isEntity)
      .filter(i => (includeSys ? true : !isTaggedSysEntity(i)))
      .filter(r => !Boolean(r.trash))
      .filter(resultFilter) as SearchSummary[]

    setResults(results => {
      // If we are at the beginning, just set the results
      if (queryFrom === 0) {
        return filteredResults
      }
      // We are out of sync if result.length < queryFrom and filteredResults.length > 0
      const isOutOfSync = results.length < queryFrom && filteredResults.length > 0
      invariant(!isOutOfSync, `Results are out of sync: ${queryFrom} !== ${results?.length}`)
      // Otherwise, append the results.
      return [...(results ?? []), ...filteredResults]
    })
  }, [currentData?.body.hits.hits, includeSys, isFetching, isSuccess, originalArgs?.query?.from, resultFilter])

  const getNext = async () => {
    if (!results || results.length < size) {
      logger.debug('No more results to fetch')
      return
    }
    setFrom(from => from + size)
  }

  // We update the results in useEffect. Hence, we may have not yet updated the results
  const searching = isFetching || isLoading

  return { results: results ?? [], searching, getNext, isSuccess, isError }
}

export interface SearchProps extends AdvancedSearchProps {
  /** The query string. Some queries (e.g., recent) do not require query */
  query?: string
}

/** Search using pre-defined basic queries */
export const useSearch = (
  props: SearchProps & {
    /** Search when query string is empty. @default true */
    allowEmptyQuery?: boolean
  } = { queryType: 'default' }
) => {
  const { query, queryType, size = 100, debounceDelay = 50, allowEmptyQuery = true, ...restProps } = props

  const createQuery = (!query ? allowEmptyQuery : true) ? allQueries[queryType] : null
  const esQuery = useMemo(() => (createQuery ? createQuery({ query, size }) : undefined), [createQuery, query, size])

  return useAdvancedSearch(esQuery, { ...restProps, size, debounceDelay, queryType })
}
