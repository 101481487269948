import { Typography } from '@mui/material'
import { renderContent, usePlugins } from '@tunasong/plugin-lib'
import type { TunaPlugin } from '@tunasong/plugin-lib'
import { isEntity, isPersisted } from '@tunasong/schemas'
import type { Entity, Persisted } from '@tunasong/schemas'
import type { FC } from 'react'
import { VBox } from '../layout/vertical-box.js'
import { Question } from '@tunasong/icons'

export interface EntityContentProps {
  entity?: Persisted<Entity>
  plugins?: TunaPlugin[]
}

export const EntityContent: FC<EntityContentProps> = props => {
  const { entity, plugins: providedPlugins } = props

  const allPlugins = usePlugins('all')
  const plugins = providedPlugins ?? allPlugins ?? []

  if (!entity) {
    return null
  }

  if (!(isEntity(entity) && isPersisted(entity))) {
    return <div>Invalid entity</div>
  }

  const plugin = plugins.find(p => p.node?.type === entity.type)
  const content = plugins ? renderContent({ element: entity, plugins }) : null
  const Icon = plugin?.icon ?? Question

  if (content?.length === 0 && plugin) {
    return (
      <VBox sx={{ alignItems: 'center' }}>
        <Icon />
        <Typography sx={{ mt: 2, color: theme => theme.vars.palette.text.secondary }} variant="h5">
          {plugin.description}
        </Typography>
      </VBox>
    )
  }
  return <>{content}</>
}
