import type { EdgeType, Entity, Persisted } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../api/entities.js'
import { useEdges } from './edges.hook.js'
import { useEntitiesById } from './entities-by-id.js'

/** Load the entities referred by the edges */
export function useEdgeEntities<T extends Entity = Entity, S extends Entity = Entity>({
  sourceId,
  targetId,
  relation,
}: {
  sourceId?: string
  targetId?: string
  relation?: EdgeType
}) {
  const { edges, isLoading: isLoadingEdges } = useEdges({ sourceId, targetId, relation })

  const entityIds = edges.flatMap(e => [e.source, e.target])

  const { entities, isLoading: isLoadingEntities, isSuccess } = useEntitiesById(entityIds)

  const edgeEntities = isSuccess
    ? edges
        .map(edge => ({
          ...edge,
          source: entities.find(e => edge.source === e.id) as Persisted<T>,
          target: entities.find(e => edge.target === e.id) as Persisted<S>,
        }))
        .filter(e => e.source && e.target)
        .sort((a, b) => (a.target.updatedAt > b.target.updatedAt ? -1 : 1))
    : []

  const [createEdge, createEdgeStatus] = entitiesApi.useCreateEntityEdgeMutation()
  const [deleteEdgeMutation] = entitiesApi.useDeleteEntityEdgeMutation()

  const create = (targetId: string) => {
    invariant(sourceId && relation, 'Source entity and relation is required')
    return createEdge({ edge: { source: sourceId, target: targetId, relation } }).unwrap()
  }
  const deleteEdge = (targetId: string) => {
    invariant(sourceId && relation, 'Source entity and relation is required')
    return deleteEdgeMutation({ source: sourceId, target: targetId, relation })
  }

  return {
    edgeEntities,
    createEdge: create,
    deleteEdge,
    hasLoaded: isSuccess,
    isCreating: createEdgeStatus.isLoading,
    isLoading: isLoadingEdges || isLoadingEntities,
  }
}
