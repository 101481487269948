import { createContext } from 'react'
import type { TunaPlugin } from '../plugin-types.js'

type pluginConfigSets = [
  'none',
  'all',
  'local',
  'custom',
  'note',
  'chat',
  'song',
  'songCompose',
  'page',
  'poll',
  'setlist',
  'performance',
  'performance:guitar',
  'performance:vocals',
]
export type PluginConfigSet = pluginConfigSets[number]

export interface PluginRepository {
  /** When the collection is resolved, register it here */
  getCollection: (type: PluginConfigSet) => Promise<TunaPlugin[]>
}

/** Map of plugin collection name to plugins */
export const PluginContext = createContext<PluginRepository | null>(null)
export default PluginContext
