/** Default full-text query for entities */
import type { EntityType, SearchRequest } from '@tunasong/schemas'
import type { QueryProps } from './query-props.js'

const sortClause = {
  updated: [{ updatedAt: { order: 'desc' } }, { createdAt: 'desc' }, '_score'],
  // @todo name is text and not sortable
  // name: [{ name: { order: 'asc' } }, '_score'],
}

const typeQuery = ({
  query,
  types,
  size = 100,
  sort = 'updated',
}: QueryProps & { types?: EntityType[]; sort?: 'updated' }): SearchRequest => ({
  index: 'entities',
  size,
  from: 0,
  /** embeddings are large - don't return them by default */
  _source_excludes: ['embedding'],
  body: {
    query: {
      bool: {
        must: {
          terms: {
            type: types ? types : [query],
          },
        },
      },
    },
    sort: sortClause[sort],
  },
})

export default typeQuery
