/* eslint-disable @typescript-eslint/no-explicit-any */

import type { CoreElement } from '@tunasong/schemas'
import type { FC } from 'react'

export interface RenderElementPropsExt<TElement extends CoreElement = CoreElement> {
  element: TElement
  /** from RenderElementProps in Slate */
  attributes: {
    'data-slate-node': 'element'
    'data-slate-inline'?: true
    'data-slate-void'?: true
    dir?: 'rtl'
    ref: any
  }
  children: any
}

/** This is for the Slate-Plugins compatible render */
export const condRender =
  <T extends CoreElement>(predicate: (e: T) => boolean, Component: FC<RenderElementPropsExt<T>>) =>
  (props: RenderElementPropsExt<T>) => {
    /** We do this to keep compatibility with slate-plugins */
    if (!predicate(props?.element as any)) {
      return null
    }
    return <Component {...props} />
  }
