import { useDndNode } from '@udecode/plate-dnd'
import type { FC } from 'react'
import { useRef } from 'react'
import type { CoreBlockProps } from './core-block.js'
import { CoreBlock } from './core-block.js'

export const DndBlock: FC<CoreBlockProps> = props => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const id = props.element.id ?? 'unknown'

  const {
    dragRef,
    isDragging,
    isOver: isDragOver,
  } = useDndNode({
    id,
    nodeRef,
  })
  // const { dropLine } = useDropLine({ id, orientation: 'horizontal' })


  return (
    <CoreBlock
      {...props}
      dropLine={isDragOver ? 'top' : undefined}
      dragRef={dragRef}
      // we use the browser drag over to show the drop line
      blockRef={nodeRef}
      isDragOver={isDragOver}
    />
  )
}
