import { Link as MuiLink } from '@mui/material'
import React from 'react'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router'
import { getPathFromPathOrUrl } from './get-path.js'

export interface LinkProps {
  to: string
  external?: boolean
  children: React.ReactNode
}

export const Link: FC<LinkProps> = props => {
  const { to: pathOrUrl, external = false, ...restProps } = props
  /** If to is a URL, we parse out the path from it */
  const to = external ? pathOrUrl : getPathFromPathOrUrl(pathOrUrl)

  const Component = external ? 'a' : RouterLink
  return <MuiLink component={Component} to={to} href={external ? to : undefined} {...restProps} />
}

export default Link
