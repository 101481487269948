/** Core entities */
import { baseEntitySchema } from '../entity.js'
import {
  audioFeatureSchema,
  audioSchema,
  isAudio,
  isAudioChordsFeature,
  isAudioFeature,
  isAudioFeatureMap,
  isAudioSeparation,
} from './audio.js'
import type { Audio, AudioChordsFeature, AudioFeature, AudioFeatureUrls, AudioMedia } from './audio.js'
import { chatSchema, isChat } from './chat.js'
import type { Chat } from './chat.js'
import { commentSchema, isComment } from './comment.js'
import type { Comment } from './comment.js'
import { entityLinkSchema, isEntityLink } from './entitylink.js'
import type { EntityLink } from './entitylink.js'
import { linkSchema, isLink } from './link.js'
import type { Link } from './link.js'
import { folderSchema, isFolder } from './folder.js'
import type { Folder } from './folder.js'
import { imageSchema, isImage } from './image.js'
import type { Image, ImageElement } from './image.js'
import { isNote, noteSchema } from './note.js'
import type { Note } from './note.js'
import { isPage, pageSchema } from './page.js'
import type { Page } from './page.js'
import { isPdf, pdfSchema } from './pdf.js'
import type { PDF } from './pdf.js'
import { isProfile, profileSchema, profileServerSchema } from './profile.js'
import type { Profile, ProfileServerSchema } from './profile.js'
import { isTable, tableSchema } from './table.js'
import type { Table } from './table.js'
import { isTodo, todoSchema } from './todo.js'
import type { Todo } from './todo.js'
import { isVideo, videoSchema } from './video.js'
import type { Video, VideoMedia } from './video.js'

/** Exports */

export {
  audioFeatureSchema,
  audioSchema,
  chatSchema,
  commentSchema,
  folderSchema,
  imageSchema,
  isAudio,
  isAudioChordsFeature,
  isAudioFeature,
  isAudioFeatureMap,
  isLink,
  isAudioSeparation,
  isChat,
  isComment,
  isEntityLink,
  isFolder,
  isImage,
  isNote,
  isPage,
  isPdf,
  isProfile,
  isTable,
  isTodo,
  isVideo,
  noteSchema,
  pageSchema,
  pdfSchema,
  profileSchema,
  profileServerSchema,
  tableSchema,
  todoSchema,
  videoSchema,
  type Audio,
  type AudioChordsFeature,
  type AudioFeature,
  type AudioFeatureUrls,
  type AudioMedia,
  type Chat,
  type Comment,
  type EntityLink,
  type Folder,
  type Image,
  type ImageElement,
  type Note,
  type Page,
  type PDF,
  type Profile,
  type Link,
  type ProfileServerSchema,
  type Table,
  type Todo,
  type Video,
  type VideoMedia,
}

const schemas = {
  folder: folderSchema,
  audio: audioSchema,
  page: pageSchema,
  profile: profileSchema,
  image: imageSchema,
  entitylink: entityLinkSchema,
  chat: chatSchema,
  todo: todoSchema,
  comment: commentSchema,
  video: videoSchema,
  pdf: pdfSchema,
  note: noteSchema,
  table: tableSchema,
  link: linkSchema,
  tr: baseEntitySchema,
  th: baseEntitySchema,
  td: baseEntitySchema,
  thead: baseEntitySchema,
  tbody: baseEntitySchema,
}

/** Add new core entity types here */
export const coreEntityNames = Object.keys(schemas) as unknown as readonly (keyof typeof schemas)[]
export type CoreEntityName = (typeof coreEntityNames)[number]

export default schemas
