import VolumeDown from '@mui/icons-material/VolumeDown'
import VolumeMute from '@mui/icons-material/VolumeOff'
import VolumeUp from '@mui/icons-material/VolumeUp'
import { Grid, Slider, Typography } from '@mui/material'
import { Audio } from '@tunasong/icons'
import { isMobileDevice, makeStyles } from '@tunasong/ui-lib'
import type { FC } from 'react'
import { AudioController } from './engine/audio-controller.js'
import { Meter } from './meter.js'

export interface VolumeProps {
  title?: string
  controller: AudioController
  onChange?(value: number): void
  onClose?(): void
}

const useStyles = makeStyles()(theme => ({
  slider: { minWidth: 100 },
  popup: { margin: theme.spacing() },
  sliderBox: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const Volume: FC<VolumeProps> = props => {
  const { classes } = useStyles()
  const { controller, onChange, onClose, title = 'Volume' } = props

  const handleVolume = (ev: unknown, value: number | number[]) => {
    if (typeof value !== 'number') {
      return
    }

    controller.gain = value
    if (onChange) {
      onChange(value)
    }
  }

  const handleMute = () => {
    controller.muted = !controller.muted
  }

  const handleMonitor = (val: boolean) => () => {
    // eslint-disable-next-line react-compiler/react-compiler
    controller.monitor = val
    if (!isMobileDevice()) {
      controller.enableDSPFeatures(val)
    }
  }

  const handleMax = (ev: unknown) => handleVolume(ev, 1)

  return (
    <div className={classes.popup}>
      <Typography gutterBottom>{title}</Typography>
      <Grid container spacing={2}>
        <Grid item>
          {controller.type === 'mic' ? (
            controller.monitor ? (
              <Audio color="warning" onClick={handleMonitor(false)} />
            ) : (
              <Audio onClick={handleMonitor(true)} />
            )
          ) : null}
        </Grid>

        <Grid item>
          {controller.muted ? <VolumeMute color="error" onClick={handleMute} /> : <VolumeDown onClick={handleMute} />}
        </Grid>
        <Grid item xs className={classes.sliderBox}>
          <Slider
            className={classes.slider}
            disabled={controller.muted}
            value={controller.gain}
            step={0.01}
            min={0.0}
            max={1.0}
            onChange={handleVolume}
            onDragEnd={onClose}
          />
          <Meter controller={controller} at="pre" />
          {controller.trackType === 'bus' ? <Meter controller={controller} at="post" color="primary" /> : null}
        </Grid>
        <Grid item>
          <VolumeUp onClick={handleMax} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Volume
