import type { Track } from '@tunasong/schemas'
import { useDelayedInvoke, useRedraw } from '@tunasong/ui-lib'
import { useMixer } from '../../hooks/index.js'
import { useTrackChannel } from './track-channel.js'

export interface TrackControl {
  track: Track
  onUpdate?(track: Partial<Track>): void
}
export const useTrackControl = ({ track, onUpdate = () => {} }: TrackControl) => {
  const mixer = useMixer()
  const refresh = useRedraw()

  const channel = useTrackChannel(track)

  /** Create or get an existing channel for the Track */

  const toggleMute = () => {
    if (!(mixer && channel)) {
      return
    }
    // eslint-disable-next-line react-compiler/react-compiler
    channel.muted = !channel.muted
    onUpdate({ muted: channel.muted })
    refresh()
  }

  const toggleSolo = () => {
    if (!channel) {
      return
    }
    channel.solo = !channel.solo
    onUpdate({ solo: channel.solo })
    refresh()
  }

  const toggleArm = () => {
    if (!channel) {
      return
    }
    channel.armed = !channel.armed

    /** @todo more flexible routing here */

    onUpdate({ armed: channel.armed })
    refresh()
  }

  const delayedVolume = useDelayedInvoke(500)
  const onChangeVolume = (vol: number) => {
    if (!(channel && mixer)) {
      return
    }
    channel.gain = vol
    delayedVolume(() => onUpdate({ gain: channel.gain }))
    refresh()
  }

  const delayedPan = useDelayedInvoke(500)
  const onChangePan = (pan: number) => {
    if (!(channel && mixer)) {
      return
    }
    channel.pan = pan
    delayedPan(() => onUpdate({ pan: channel.pan }))
    refresh()
  }

  const volume = channel?.gain ?? 0
  const pan = channel?.pan

  return {
    toggleMute,
    toggleSolo,
    toggleArm,
    onChangeVolume,
    onChangePan,
    volume,
    pan,
    channel,
  }
}
