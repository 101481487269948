import { ListItemAvatar, ListItemText, ListItem as MuiListItem } from '@mui/material'
import { isFacade } from '@tunasong/models'
import type { RenderMenuItemProps } from '@tunasong/plugin-lib'
import classNames from 'classnames'
import { useMemo } from 'react'
import type { FC } from 'react'
import EntityAvatar from './entity-avatar.js'
import { useStyles } from './list-item.styles.js'

/** Facade for a inaccessible item */
interface EntityListItemFacadeProps extends RenderMenuItemProps {
  ref: React.Ref<HTMLDivElement>
}
export const EntityListItemFacade: FC<EntityListItemFacadeProps> = props => {
  const { className, element, size, ref, ...restProps } = props
  const { classes } = useStyles()

  const listItemClasses = useMemo(
    () => ({
      primary: classes.primarySmall,
      secondary: classes.secondarySmall,
    }),
    [classes.primarySmall, classes.secondarySmall]
  )

  if (!isFacade(element)) {
    return null
  }

  return (
    <MuiListItem className={classNames(className, classes.root)} component={'div'} {...restProps} ref={ref}>
      <ListItemAvatar>
        <EntityAvatar className={classes.avatar} type={element.type} size={size} />
      </ListItemAvatar>

      <ListItemText classes={listItemClasses} primary={element.description} secondary={element.id} />
    </MuiListItem>
  )
}

export default EntityListItemFacade
