/** Tools for partial messages */

import { shortUuid } from '@tunasong/models'
import type { WSMessage, WSPartialMessage } from '@tunasong/models'

/**
 * Slices a string into parts of a specified maximum size.
 * @param str - The string to be sliced.
 * @param maxSize - The maximum size of each part.
 * @returns An array of string parts.
 */
const sliceStringIntoParts = (str: string, maxSize: number): string[] => {
  const parts = []
  for (let i = 0; i < str.length; i += maxSize) {
    parts.push(str.slice(i, i + maxSize))
  }
  return parts
}

export const splitMessage = <T>(msg: WSMessage<T>, maxPartSize: number): WSPartialMessage[] => {
  const json = JSON.stringify(msg)
  // slice the json into parts of maxPartSize
  const parts = sliceStringIntoParts(json, maxPartSize)
  const partGroupId = shortUuid()

  return parts.map(
    (data, i) =>
      ({
        action: 'partial',
        partGroupId,
        part: i,
        lastPart: parts.length - 1,
        data,
      }) satisfies WSPartialMessage
  )
}

export const joinMessage = <T>(parts: WSPartialMessage[]): WSMessage<T> => {
  // Verify that we have all the parts and that they are all part of the same group
  if (parts.length === 0) {
    throw new Error('No parts to join')
  }
  const partGroupId = parts[0].partGroupId
  if (parts.some(part => part.partGroupId !== partGroupId)) {
    throw new Error('Parts are not part of the same group')
  }
  // Check that we have all the parts
  const lastPart = parts[0].lastPart
  if (parts.length !== lastPart + 1) {
    throw new Error(`Not all parts are present: expected ${lastPart + 1}, got ${parts.length}`)
  }

  const json = parts
    .sort((a, b) => a.part - b.part)
    .map(part => part.data)
    .join('')

  return JSON.parse(json) as WSMessage<T>
}
