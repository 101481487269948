import { isCoreElement } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'

export interface Mention extends CoreElement {
  id: string
  type: 'mention'

  /** The userId of the mentioner - i.e., the user mentioning the other user */
  mentionerId: string

  /** Metadata about the mentioned user */
  userId: string
  email: string
  picture?: string | null
  name?: string
}

export function isMention(node?: unknown): node is Mention {
  return Boolean(isCoreElement(node) && node.type === 'mention')
}
