import type { AIConfig } from '@tunasong/ai-lib'
import type { SectionType } from '@tunasong/models'
import { generatePromptSpec } from '../../generate-prompt.js'

export default ({
  title,
  lyrics,
  userConfig,
  sectionType,
}: {
  /** Title of song/document */
  title: string
  style?: string
  lyrics: string
  sectionType: SectionType
  userConfig?: AIConfig
}) => {
  const context = 'song:line'
  return generatePromptSpec({
    promptText: `Write the next line in this ${sectionType} for the "${title}" song. The current lyrics for the ${sectionType} are: \n\n ${lyrics}.`,
    context,
    userConfig: userConfig?.contexts[context],
  })
}
