import { Grid, IconButton, Toolbar, Typography } from '@mui/material'

import { Pause, Play, Record, Stop } from '@tunasong/icons'
import type { AudioPosition } from '@tunasong/models'
import { makeStyles } from '@tunasong/ui-lib'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import type { Transport as ToneTransport } from 'tone'
import { useMixer } from '../hooks/index.js'
import { formatTime } from '../lib/format-time.js'

export interface TransportProps {
  className?: string
  /** sticky means we don't clear the events when we move past */
  sticky?: boolean
  transport: typeof ToneTransport
  enableRecord?: boolean
  onStartRecord?({ contextTime, transportTime }: { contextTime: number; transportTime: number }): void
  onStopRecord?({ contextTime, transportTime }: { contextTime: number; transportTime: number }): void
}

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
    height: 48,
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
  toolbar: {
    minHeight: 32,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const Transport: FC<TransportProps> = ({
  className,
  transport,
  enableRecord = false,
  onStartRecord,
  onStopRecord,
}) => {
  const { classes } = useStyles()
  const mixer = useMixer()

  const [position, setPosition] = useState<AudioPosition>()
  const isRecording = useRef(false)

  useEffect(() => {
    const id = setInterval(() => {
      setPosition({
        seconds: transport.seconds,
        label: formatTime(transport.seconds),
      })
    }, 50)
    return () => {
      clearInterval(id)
    }
  }, [transport])

  /**
   * We want to start/stop the transport without a "click"
   * @see https://stackoverflow.com/questions/29378875/how-can-i-avoid-this-clicking-sound-when-i-stop-playing-a-sound
   */
  const fadeIn = useCallback(
    (duration = 0.1) => {
      if (!mixer) {
        return
      }
      const monitor = mixer.getBus('monitor')
      const startTime = mixer.context.currentTime + 0.01
      monitor.fadeIn(startTime, duration)

      return startTime
    },
    [mixer]
  )
  const fadeOut = useCallback(
    (duration = 0.2) => {
      if (!mixer) {
        return
      }
      const monitor = mixer.getBus('monitor')
      const endTime = mixer.context.currentTime + duration
      monitor.fadeOut(endTime)
      return endTime
    },
    [mixer]
  )
  const handleStart = useCallback(() => {
    const startTime = fadeIn()
    transport.start(startTime)
  }, [fadeIn, transport])

  const handlePause = useCallback(() => {
    const endTime = fadeOut()
    transport.pause(endTime)
  }, [fadeOut, transport])

  const handleStop = useCallback(() => {
    const endTime = fadeOut()
    if (onStopRecord && isRecording.current) {
      onStopRecord({ contextTime: transport.context.currentTime, transportTime: transport.seconds })
      isRecording.current = false
    }
    transport.stop(endTime)
  }, [fadeOut, onStopRecord, transport])

  const handleRecord = useCallback(() => {
    /** @todo handle count-in */
    /** @todo handle punch-in / out */

    if (onStartRecord) {
      onStartRecord({ contextTime: transport.context.currentTime, transportTime: transport.seconds })
    }
    transport.start()
    isRecording.current = true
  }, [onStartRecord, transport])

  return (
    <>
      {/* <AppBar color="default" elevation={0} position="relative" className={classNames(className, classes.root)}> */}
      <Toolbar className={classNames(className, classes.root, classes.toolbar)}>
        <Grid className={classNames(className, classes.root)} container>
          <Grid item xs={2} className={classes.item}></Grid>
          <Grid item xs={2} className={classes.item}>
            <Typography variant="h4">{position?.label ?? formatTime(0)}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.item}>
            <IconButton onClick={handleStart}>
              <Play />
            </IconButton>
          </Grid>
          <Grid item xs={1} className={classes.item}>
            <IconButton onClick={handlePause}>
              <Pause />
            </IconButton>
          </Grid>
          <Grid item xs={1} className={classes.item}>
            <IconButton onClick={handleStop}>
              <Stop />
            </IconButton>
          </Grid>
          <Grid item xs={1} className={classes.item}>
            <IconButton onClick={handleRecord} disabled={!enableRecord}>
              <Record />
            </IconButton>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Typography variant="h5" display="block">
              {transport.bpm.value}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </>
  )
}

export default Transport
