import { Box, LinearProgress } from '@mui/material'
import { defaultSortOptions } from '@tunasong/models'
import { entitiesApi } from '@tunasong/redux'
import type { FC } from 'react'
import EmptyLinks from './empty-links.js'
import { useEntitySort } from '../hooks/sort.js'
import { EntityLink } from '../entity/entity-link.js'
import { HBox } from '../layout/horizontal-box.js'

export interface BacklinksProps {
  id?: string
}

export const Backlinks: FC<BacklinksProps> = ({ id = '' }) => {
  const { currentData: backlinks = [] } = entitiesApi.useLoadEntityBacklinksQuery({ id }, { skip: id === '' })

  const ids = backlinks
    .map(backlink => backlink.source)
    .filter(id => id !== 'ROOT')
    .filter(Boolean)
  const { currentData: linkedEntities = [], isLoading } = entitiesApi.useLoadEntitiesQuery(
    {
      ids,
    },
    { skip: ids.length === 0 }
  )
  const entities = useEntitySort(linkedEntities, defaultSortOptions)

  return (
    <Box>
      {isLoading ? <LinearProgress /> : null}

      {entities.map(entity => {
        const backlink = backlinks.find(backlink => backlink.source === entity.id)
        return entity ? (
          <HBox key={entity.id} sx={{ justifyContent: 'space-between' }}>
            <EntityLink entity={entity} />
            <Box>[{backlink?.relation}]</Box>
          </HBox>
        ) : null
      })}
      {backlinks.length === 0 ? <EmptyLinks text="No backlinks" /> : null}
    </Box>
  )
}

export default Backlinks
