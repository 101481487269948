import { Box, LinearProgress } from '@mui/material'
import React, { useState } from 'react'
import type { FC } from 'react'
import { useVisibilityTrigger } from './visibility-trigger.hook.js'

export interface VisibilityTriggerProps {
  onVisible?(): Promise<void>
}

export const VisibilityTrigger: FC<VisibilityTriggerProps> = props => {
  const { onVisible } = props

  const [endRef, setEndRef] = useState<React.ElementRef<'div'> | null>(null)
  const [loading, setLoading] = useState(false)
  const cb = () => {
    if (!onVisible) {
      return
    }
    setLoading(true)
    onVisible().then(() => setLoading(false))
  }

  useVisibilityTrigger({ el: endRef, cb })

  return (
    <>
      {loading && <LinearProgress />}
      <Box sx={{ height: 1 }} ref={setEndRef} />
    </>
  )
}

export default VisibilityTrigger
