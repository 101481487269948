/** Hook to handle things element list */

import type { ListMenuOptions } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useCallback, useState } from 'react'
import { features } from '../features/index.js'
import { useEntityUpdate } from './entity-update.js'
import { useThunkDispatch } from './thunk-dispatch.hook.js'

/** Convenience methods for entities.
 * @todo Profile
 */
export const useEntityUtil = () => {
  const dispatch = useThunkDispatch()
  const updateEntity = useEntityUpdate()
  const [] = useState()
  const updateListMenuOptions = useCallback(
    (entity: Persisted<Entity>, listOptions: ListMenuOptions) => {
      const options = {
        ...entity.options,
        list: listOptions,
      }

      updateEntity(entity.id, { options })
    },
    [updateEntity]
  )

  const reorderChildren = useCallback(
    (entity: Persisted<Entity>, children: Persisted<Entity>[]) => {
      updateEntity(entity.id, {
        options: {
          ...entity.options,
          sort: {
            ...entity.options?.sort,
            type: 'order', // use order when reordering
            elementOrder: children.map(sn => sn.id),
          },
        },
      })
    },
    [updateEntity]
  )

  const moveEntity = useCallback(
    async ({ entity, newParent }: { entity: Persisted<Entity>; newParent: Persisted<Entity> }) =>
      dispatch(
        features.entities.thunks.addChild({
          parentId: newParent.id,
          childId: entity.id,
        })
      ),
    [dispatch]
  )

  return {
    moveEntity,
    updateListMenuOptions,
    reorderChildren,
  }
}
