import type { StandardNoteNameWithOctave } from '@tunasong/schemas'

export type Tuning = Record<number, StandardNoteNameWithOctave>

/** @see https://en.wikipedia.org/wiki/Guitar_tunings */

export const GuitarTunings: Record<string, Tuning> = {
  normal: {
    1: 'E4',
    2: 'B3',
    3: 'G3',
    4: 'D3',
    5: 'A2',
    6: 'E2',
  },
  dropd: {
    1: 'E4',
    2: 'B3',
    3: 'G3',
    4: 'D3',
    5: 'A2',
    6: 'D2',
  },
}
