import type { BoxProps } from '@mui/material'
import type { BaseLayoutSpec } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import { MenuButtonSelector } from '../menu/menu-button.js'

export interface LayoutSelectorProps extends Omit<BoxProps, 'onChange'> {
  layouts: BaseLayoutSpec[]
  selected?: BaseLayoutSpec
  onChange(layout: BaseLayoutSpec): void
}

export const LayoutSelector: FC<LayoutSelectorProps> = props => {
  const { layouts, selected, onChange } = props

  return <MenuButtonSelector items={layouts} onChange={onChange} selected={selected} />
}

export default LayoutSelector
