import type { CardProps } from '@mui/material'
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Dialog as MuiDialog,
  TextField,
} from '@mui/material'
import { Copy, Delete, Favorite, Move, Rename, RestoreFromTrash, RestorePage, Settings, Share } from '@tunasong/icons'
import { getActionsComponent, getPlugin, usePlugins } from '@tunasong/plugin-lib'
import {
  entitiesApi,
  features,
  useEntity,
  useEntityUpdate,
  useEntityUtil,
  useThunkDispatch,
  useTrashEntity,
} from '@tunasong/redux'
import type { Entity, Persisted } from '@tunasong/schemas'
import { isFolderContainer, isVersioned } from '@tunasong/schemas'
import {
  ConfirmDialog,
  Dialog,
  DialogTitle,
  EntityCard,
  getEntityPath,
  useFavorites,
  useIsOwner,
} from '@tunasong/ui-lib'
import type { MouseEvent } from 'react'
import React, { useCallback, useState } from 'react'
import { EntitySelectDialog } from '../entity/entity-select-dialog.js'
import EntityHistory from '../entity/history.js'
import { useShare } from '../share/share.js'

export interface EntityMenuItemsProps extends CardProps {
  entity?: Persisted<Entity>
  /** Close the menu, but keep the dialogs open */
  onCloseMenu(): void
  /** Close everything */
  onClose(): void
}

/** @todo this is slowing down long lists */
export const useEntityMenuItems = ({ entity, onClose, onCloseMenu }: EntityMenuItemsProps) => {
  const dispatch = useThunkDispatch()

  const plugins = usePlugins('all')
  const { moveEntity } = useEntityUtil()
  const { entity: parent } = useEntity(entity?.parentId) ?? null
  const updateEntity = useEntityUpdate({ debounceDelay: 0 })

  const [createEntity] = entitiesApi.useCreateEntityMutation()
  const [cloneEntity] = entitiesApi.useCloneEntityMutation()

  const { dialog: shareDialog, show: showShare } = useShare(entity, onClose)

  const handleShare = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()
      showShare()
      onCloseMenu()
    },
    [onCloseMenu, showShare]
  )

  const isOwner = useIsOwner(entity)

  const [showMoveDialog, setShowMoveDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const handleShowMoveDialog = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowMoveDialog(true)
      onCloseMenu()
    },
    [onCloseMenu]
  )

  const handleCopy = (entity: Persisted<Entity>) => async (ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()

    await cloneEntity({ entityId: entity.id, name: `Copy of ${entity.name}` }).unwrap()

    onClose()
  }

  const trash = useTrashEntity(entity)
  const handleTrash = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowDeleteDialog(false)
      trash()
      onClose()
    },
    [onClose, trash]
  )

  const handleRestore = useCallback(
    (entity: Persisted<Entity>) => (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      updateEntity(entity.id, { trash: false })
      onClose()
    },
    [onClose, updateEntity]
  )

  const handleShowDelete = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowDeleteDialog(true)
      onCloseMenu()
    },
    [onCloseMenu]
  )

  const handleHideDelete = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowDeleteDialog(false)
      onClose()
    },
    [onClose]
  )

  /** Rename */
  const [showRenameDialog, setShowRenameDialog] = useState(false)
  const handleShowRenameDialog = useCallback(
    (open: boolean) => (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowRenameDialog(open)
      if (open) {
        onCloseMenu()
      } else {
        onClose()
      }
    },
    [onClose, onCloseMenu]
  )
  const [name, setName] = useState<string>()
  const handleRenameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value), [])
  const handleRename = useCallback(
    (entity: Persisted<Entity>) => (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      updateEntity(entity.id, { name })
      setShowRenameDialog(false)
      setName(undefined)
      onClose()
    },
    [name, onClose, updateEntity]
  )
  const [showHistory, setShowHistory] = useState(false)
  const handleShowHistory = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowHistory(true)
      onCloseMenu()
    },
    [onCloseMenu]
  )

  const handleHideHistory = useCallback(() => {
    setShowHistory(false)
    onClose()
  }, [onClose])

  const [showProperties, setShowProperties] = useState(false)
  const handleShowProperties = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowDeleteDialog(false)
      setShowProperties(true)
      onCloseMenu()
    },
    [onCloseMenu]
  )

  const onCloseProperties = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      setShowDeleteDialog(false)
      setShowProperties(false)
      onClose()
    },
    [onClose]
  )

  const handleProperties = useCallback(
    (entity: Persisted<Entity>) => {
      updateEntity(entity.id, entity)
    },
    [updateEntity]
  )

  const handleMoveSelect = useCallback(
    (entity: Persisted<Entity>) => (selected: Persisted<Entity> | undefined) => {
      setShowMoveDialog(false)
      onClose()

      if (!selected) {
        return
      }

      /** Move the entity */
      /** @todo handle ACLs */
      moveEntity({ entity, newParent: selected })
      dispatch(
        features.notifications.actions.setAlert({
          title: 'Entity moved',
          severity: 'success',
          message: `${entity.name} moved to ${selected.name}`,
          link: getEntityPath({ entity: selected }),
        })
      )
    },
    [dispatch, moveEntity, onClose]
  )
  const { addFavorite, removeFavorite, isFavorite } = useFavorites()
  const handleAddFavorite = useCallback(
    (entity: Persisted<Entity>) => (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      addFavorite(entity)
      onClose()
    },
    [addFavorite, onClose]
  )
  const handleRemoveFavorite = useCallback(
    (entity: Persisted<Entity>) => (ev: MouseEvent) => {
      ev.preventDefault()
      ev.stopPropagation()

      removeFavorite(entity)
      onClose()
    },
    [onClose, removeFavorite]
  )
  const plugin = entity ? getPlugin(entity, plugins) : null

  const PluginBlockActions = getActionsComponent(plugins)

  const entityIsFavorite = isFavorite(entity)

  const excludeIds = entity ? ([entity.id, entity.parentId].filter(Boolean) as string[]) : []

  return {
    dialogs: [
      shareDialog,
      ...(entity
        ? [
            <Dialog key={12} open={showProperties} title="Properties" onClose={onCloseProperties}>
              {plugin?.components?.Properties ? (
                <plugin.components.Properties element={entity} onChange={handleProperties} />
              ) : null}
            </Dialog>,
            entity ? (
              <Dialog key={13} open={showHistory} title="History" onClose={handleHideHistory}>
                <EntityHistory entity={entity} onComplete={handleHideHistory} />
              </Dialog>
            ) : null,
            entity ? (
              <EntitySelectDialog
                key={14}
                open={showMoveDialog}
                filter={isFolderContainer}
                title="Select folder"
                onClose={handleMoveSelect(entity)}
                excludeIds={excludeIds}
              />
            ) : null,
            <ConfirmDialog
              key={15}
              open={showDeleteDialog}
              text={`Really move ${entity.type}: ${entity.name} to the trash?`}
              onCancel={handleHideDelete}
              onConfirm={handleTrash}
            >
              <EntityCard sx={{ mt: 2 }} entity={entity} showMenu={false} headerAction={null} />
            </ConfirmDialog>,
            <MuiDialog key={16} open={showRenameDialog} onClose={handleShowRenameDialog(false)}>
              <DialogTitle onClose={handleShowRenameDialog(false)} id="alert-dialog-title">
                Rename {entity.type}
              </DialogTitle>
              <DialogContent>
                <TextField fullWidth autoFocus defaultValue={entity.name} onChange={handleRenameChange} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRename(entity)} disabled={!name}>
                  Rename
                </Button>
              </DialogActions>
            </MuiDialog>,
          ]
        : []),
    ],
    menuItems: entity
      ? [
          <MenuItem key={1} onClick={entityIsFavorite ? handleRemoveFavorite(entity) : handleAddFavorite(entity)}>
            <ListItemIcon>
              <Favorite color={entityIsFavorite ? 'error' : 'inherit'} />
            </ListItemIcon>
            <ListItemText primary={entityIsFavorite ? 'Remove from Favorites' : 'Add to Favorites'} />
          </MenuItem>,
          <Divider key={2} />,

          <PluginBlockActions key={122} onClose={onClose} element={entity} />,
          <Divider key={3} />,

          <MenuItem key={4} onClick={handleShowMoveDialog} disabled={entity.trash}>
            <ListItemIcon>
              <Move />
            </ListItemIcon>
            <ListItemText primary="Move..." />
          </MenuItem>,
          <MenuItem key={5} onClick={handleShowRenameDialog(true)} disabled={entity.trash}>
            <ListItemIcon>
              <Rename />
            </ListItemIcon>
            <ListItemText primary="Rename..." />
          </MenuItem>,
          <MenuItem key={6} onClick={handleCopy(entity)} disabled={entity.trash}>
            <ListItemIcon>
              <Copy />
            </ListItemIcon>
            <ListItemText primary="Copy" />
          </MenuItem>,
          <MenuItem key={7} onClick={handleShare} disabled={entity.trash}>
            <ListItemIcon>
              <Share />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>,
          plugin?.components?.Properties ? (
            <MenuItem key={8} onClick={handleShowProperties}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Properties" />
            </MenuItem>
          ) : null,

          isVersioned(entity) ? (
            <MenuItem key={9} onClick={handleShowHistory} disabled={entity.version <= 1 || !isOwner}>
              <ListItemIcon>
                <RestorePage />
              </ListItemIcon>
              <ListItemText primary={`Version History`} />
            </MenuItem>
          ) : null,

          !entity.trash ? (
            <MenuItem key={10} onClick={handleShowDelete} disabled={!isOwner}>
              <ListItemIcon>
                <Delete color="error" />
              </ListItemIcon>
              <ListItemText primary={'Move to Trash'} />
            </MenuItem>
          ) : null,

          entity.trash ? (
            <MenuItem key={11} onClick={handleRestore(entity)} disabled={!isOwner}>
              <ListItemIcon>
                <RestoreFromTrash />
              </ListItemIcon>
              <ListItemText primary={'Restore from Trash'} />
            </MenuItem>
          ) : null,
        ].filter(Boolean)
      : [],
  }
}
