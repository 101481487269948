import type { FilterFunc, SearchSummary } from '@tunasong/models'
import type { SearchRequest } from '@tunasong/schemas'
import type { Entity } from '@tunasong/schemas'
import { useMemo } from 'react'
import typeQuery from './queries/type-query.js'
import { SearchResults } from './search-results.js'
import { useAdvancedSearch } from './search.hook.js'

export interface SearchListProps {
  query?: SearchRequest

  /** Fetch size for getNext */
  fetchSize?: number
  inputRef?: HTMLElement | null
  resultFilter?: FilterFunc
  selected?: Pick<Entity, 'id'>
  onChange(summary: SearchSummary): void
}

export function SearchList(props: SearchListProps) {
  const { query: initialQuery, fetchSize, inputRef, resultFilter, onChange, selected } = props

  const query = useMemo(() => initialQuery ?? typeQuery({ query: 'song', size: fetchSize }), [initialQuery, fetchSize])

  /** @todo filter on type */
  const { results, searching, getNext } = useAdvancedSearch(query, {
    resultFilter,
    debounceDelay: 0,
    size: fetchSize,
    queryType: 'type',
  })

  return (
    <SearchResults
      searching={searching}
      inputRef={inputRef}
      entities={results}
      onChange={onChange}
      onEndOfResults={getNext}
      selectedIndex={results.findIndex(r => r.id === selected?.id)}
    />
  )
}
