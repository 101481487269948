import { isEntity } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'
import type { SongSection } from '../song/song-section.js'

export interface Section extends SongSection, CoreElement {
  type: 'section'
}

export function isSection(node?: unknown): node is Section {
  return Boolean(isEntity(node) && node.type === 'section')
}
