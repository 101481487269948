import { isCoreElement } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'

export interface ListItem extends CoreElement {
  type: 'li'
}

export interface UnorderedList extends CoreElement {
  type: 'ul'
}

export interface OrderedList extends CoreElement {
  type: 'ol'
}

export function isListItem(node?: unknown): node is ListItem {
  return Boolean(isCoreElement(node) && (node.type === 'li' || node.type === 'lic'))
}

export function isOrderedList(node?: unknown): node is OrderedList {
  return Boolean(isCoreElement(node) && node.type === 'ol')
}

export function isUnorderedList(node?: unknown): node is OrderedList {
  return Boolean(isCoreElement(node) && node.type === 'ul')
}

export const isList = (node?: unknown): node is UnorderedList | OrderedList | ListItem =>
  Boolean(isOrderedList(node) || isUnorderedList(node) || isListItem(node))

export const isListContainer = (node?: unknown): node is UnorderedList | OrderedList =>
  Boolean(isOrderedList(node) || isUnorderedList(node))
