import type { Media } from '@tunasong/schemas'
import { MediaStreamRecorder, useMixer } from '@tunasong/audio-ui'
import type { Recorder } from '@tunasong/audio-ui'
import { dayjs, logger } from '@tunasong/models'
import type { Recording } from '@tunasong/models'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface RecordProps {
  mediaEl: HTMLMediaElement | null
  srcObject?: MediaStream | Blob | null
  onRecording?(media: Media, blob: Blob): void
}

export const useStreamRecord = ({ srcObject, onRecording }: RecordProps) => {
  const ref = useRef<HTMLMediaElement | null>(null)
  const [recorder, setRecorder] = useState<Recorder>()
  const [recording, setRecording] = useState(false)
  const mixer = useMixer()

  /** Setup recorder for  live video */
  useEffect(() => {
    const mediaEl = ref.current
    if (!((srcObject || mediaEl) && mixer && onRecording)) {
      logger.debug(`Cannot record without srcObject and an active mixer and a loaded media element`)
      return
    }
    // @ts-expect-error: captureStream does not exist on mediaEl
    const recordStream: MediaStream = srcObject instanceof MediaStream ? srcObject.clone() : mediaEl.captureStream()
    recordStream.getAudioTracks().forEach(t => recordStream.removeTrack(t))
    const recordTracks = mixer.getTracks('record')
    if (!recordTracks) {
      throw new Error(`Recorder: cannot record without a "record" bus`)
    }

    recordStream.addTrack(recordTracks[0])
    const tracks = recordStream.getTracks()
    logger.debug('Stream recording', recordStream, tracks.map(t => t.readyState).join(', '), tracks)
    const r = new MediaStreamRecorder(recordStream)
    setRecorder(r)

    return () => {
      /** AudioTrack is a mixer track, so we don't want to stop that */
      recordStream.getVideoTracks().forEach(t => t.stop())
      setRecorder(undefined)
    }
  }, [mixer, onRecording, srcObject])

  const handleRecording = useCallback(
    (rec: Recording) => {
      logger.debug('New recording', rec)
      if (onRecording && rec) {
        onRecording(
          {
            type: 'video',
            name: `${dayjs().format('YYYY-MM-DD-HH-mm')}`,
          },
          rec.content
        )
      }
    },
    [onRecording]
  )

  const handleOverdub = useCallback(
    (rec: Media, blob: Blob) => {
      logger.debug('New overdub recording', rec)
      if (onRecording && rec) {
        onRecording(
          {
            ...rec,
            name: `Overdub${new Date().toISOString()}`,
          },
          blob
        )
      }
    },
    [onRecording]
  )

  const handleRecord = useCallback(() => {
    if (!recorder) {
      throw new Error(`handleRecord: started without a recorder`)
    }
    if (recorder.state === 'recording') {
      setRecording(false)
      recorder.stop()
      handleRecording({
        content: recorder.getRecording(),
      })
    } else {
      recorder.start()
      setRecording(true)
    }
  }, [handleRecording, recorder])

  return { recording, recorder, handleRecord, handleOverdub }
}

export default useStreamRecord
