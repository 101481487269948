/** Find related content to the specified entity */

import type { Entity, Persisted, SearchFilter } from '@tunasong/schemas'
import { allQueries, useAdvancedSearch } from '@tunasong/search'
import { useMemo } from 'react'

export const useRelatedContent = ({ entity, filter }: { entity?: Persisted<Entity>; filter?: SearchFilter }) => {
  /** This must be stable */
  const query = useMemo(
    () =>
      entity?.name
        ? allQueries.relatedContent({
            query: entity.name,
            filter,
          })
        : undefined,
    [entity, filter]
  )
  return useAdvancedSearch(query)
}
