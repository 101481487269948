import { z } from 'zod'

export const aiFunction = z.object({
  name: z.string(),
  description: z.string(),
  /** e.g., 
     *   "functions": [
    {
      "name": "get_current_weather",
      "description": "Get the current weather in a given location",
      "parameters": {
        "type": "object",
        "properties": {
          "location": {
            "type": "string",
            "description": "The city and state, e.g. San Francisco, CA"
          },
          "unit": {
            "type": "string",
            "enum": ["celsius", "fahrenheit"]
          }
        },
        "required": ["location"]
      }
    }
  ]
     * 
     */
  parameters: z.object({
    type: z.enum(['object']),
    properties: z.record(
      z.object({
        type: z.enum(['string', 'array']),
        description: z.string(),
        enum: z.array(z.string()).optional(),
        items: z.record(z.any()).optional(),
      })
    ),
    required: z.array(z.string()).optional(),
  }),
})
export type AIFunction = z.infer<typeof aiFunction>
