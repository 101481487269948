import { useMidi } from '@tunasong/audio-ui'
import { ChordLib } from '@tunasong/music-lib'
import type { Chord, NoteOctave } from '@tunasong/music-lib'
import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { Keyboard } from './keyboard.js'
import type { KeyboardNoteInfo, NoteLabelType } from './keyboard/keyboard.js'
import type { Scale } from '@tunasong/schemas'

interface KeyboardChordProps {
  scale?: Scale | null
  label?: NoteLabelType
  showLabelOnActiveOnly?: boolean
  largeKeyboard?: boolean
  /** The initial chord */
  defaultChord?: Chord

  onChordChange: (chords: Chord[]) => void
}

/** Use a keyboard to input chord(s) */
export const KeyboardChord: FC<KeyboardChordProps> = ({
  scale,
  label = 'note-scale',
  showLabelOnActiveOnly = false,
  largeKeyboard = true,
  defaultChord,
  onChordChange,
}) => {
  const [activeNotes, setActiveNotes] = useState<NoteOctave[]>(
    defaultChord ? ChordLib.getNotes(defaultChord).map(n => `${n}4` as NoteOctave) : []
  )

  /** Update when chords change */
  const handleUpdateChords = useCallback(
    (activeNotes: NoteOctave[]) => {
      setActiveNotes(activeNotes)

      const chords = ChordLib.fromNotes(activeNotes)
      onChordChange(chords)
    },
    [onChordChange]
  )

  /** Midi input */
  const { activeNotes: midiActiveNotes } = useMidi()
  useEffect(() => {
    /** Only set notes when there are non-empty notes for > 0.5 second */
    const timeout = setTimeout(() => {
      if (midiActiveNotes.length > 0) {
        handleUpdateChords(midiActiveNotes)
      }
    }, 50)
    return () => clearTimeout(timeout)
  }, [handleUpdateChords, midiActiveNotes])

  /** Manual keyboard toggles */
  const toggleNote = useCallback(
    (note: KeyboardNoteInfo) => {
      let notes = [...activeNotes]
      if (notes.includes(note.note)) {
        notes = notes.filter(n => n !== note.note)
      } else {
        notes.push(note.note)
      }
      handleUpdateChords(notes)
    },
    [activeNotes, handleUpdateChords]
  )

  const noteRange = largeKeyboard ? { firstNote: 'C3', lastNote: 'b5' } : { firstNote: 'C4', lastNote: 'b5' }

  return (
    <Keyboard
      noteRange={noteRange}
      onPlayNote={toggleNote}
      activeNotes={activeNotes}
      showLabelOnActiveOnly={showLabelOnActiveOnly}
      scale={scale}
      label={label}
    />
  )
}
