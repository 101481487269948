import { usePlugins } from '@tunasong/plugin-lib'
import type { EntityType } from '@tunasong/schemas'

// Entity types that support event commands
export const useEventCommandEntityTypes = () => {
  const plugins = usePlugins('all')

  /** Find all types that provide entity commands */
  const types = plugins
    ?.filter(p => p.getEntityCommands)
    .map(p => p.node?.type)
    .filter(Boolean)
  return types ? (types as EntityType[]) : []
}
