import { logger } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../api/entities.js'
import { useEntityUpdate } from '../hooks/entity-update.js'

export const useDeleteEntity = () => {
  const [deleteEntity] = entitiesApi.useDeleteEntityMutation()
  return async (entity: Persisted<Entity>) => {
    invariant(entity, 'Entity is required')
    return deleteEntity({ entity }).unwrap()
  }
}

export const useTrashEntity = (entity?: Persisted<Entity>) => {
  const update = useEntityUpdate({ debounceDelay: 0 })
  const trash = () => {
    logger.debug('useTrashEntity', entity)
    invariant(entity?.id, 'Entity is required')

    update(entity.id, { trash: true })
  }

  return trash
}
