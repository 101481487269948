import { Typography } from '@mui/material'
import { useRedrawInterval } from '@tunasong/ui-lib'
import { dayjs } from '@tunasong/models'
import type { FC } from 'react'

export interface TimeProps {
  className?: string
  startTime?: Date | null
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

export const Time: FC<TimeProps> = props => {
  const { className, startTime } = props

  /** Redraw component */
  useRedrawInterval(100)

  let seconds = startTime ? dayjs().diff(startTime, 's', true) : 0

  const minutes = Math.floor(seconds / 60)
  seconds = Math.floor(seconds - minutes * 60)

  return (
    <Typography className={className} variant="caption">
      {zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}
    </Typography>
  )
}

export default Time
