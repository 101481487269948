import { getImageSizeString } from '@tunasong/models'
import type { Entity, ImageSizeType } from '@tunasong/schemas'
import { usePendingUploadBlobUrl } from '../upload/upload.js'

/** Get URLs to the image and scaled sizes. We use a Lambda@Edge function to resize images for URLs from storage */
export const useImage = (image?: Entity, size: ImageSizeType = 'large') => {
  /** Signed wildcard URL, we add the dimensions as query parameters. That is handled in a Lambda@Edge function running sharp */

  const url = image?.storageUrls?.url
  const pendingUploadUrl = usePendingUploadBlobUrl(image)
  const isBlobUrl = url?.startsWith('blob:')

  if (isBlobUrl) {
    return url
  }
  if (url) {
    return `${url}&d=${getImageSizeString(size)}`
  }

  return pendingUploadUrl
}
