import type { Persisted } from '@tunasong/schemas'
import type { Entity, EntityLink } from '@tunasong/schemas'

export const toEntityLink = ({ id, type, description, name }: Persisted<Entity>) =>
  ({
    type: 'entitylink',
    ref: {
      id,
      type,
      description,
      name,
    },
    children: [{ text: '' }],
  }) satisfies EntityLink & { children: [{ text: string }] }
