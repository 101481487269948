import { LinearProgress } from '@mui/material'
import { makeStyles, useRedraw } from '@tunasong/ui-lib'
import React, { useEffect } from 'react'
import type { FC } from 'react'
import { AudioController } from './engine/audio-controller.js'
import { getDBPercentage } from './lib/db.js'

export interface AudioActivityProps {
  controller: AudioController
}

const useStyles = makeStyles()(() => ({
  root: {
    minWidth: 50,
  },
}))

export const AudioActivity: FC<AudioActivityProps> = props => {
  const { controller } = props
  const { classes } = useStyles()
  const refresh = useRedraw()

  /** Re-render the audio meters every 50ms */
  useEffect(() => {
    const interval = setInterval(() => refresh(), 50)
    return () => {
      clearInterval(interval)
    }
  }, [refresh])

  const peak = controller?.getDB().peak ?? 0
  const percentage = getDBPercentage({ value: peak, reference: 0 })
  const color = 'secondary'

  return <LinearProgress className={classes.root} variant="determinate" color={color} value={percentage} />
}

export default AudioActivity
