import {
  Alert,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { dayjs } from '@tunasong/models'
import { entitiesApi, useEntityUpdate } from '@tunasong/redux'
import { ConfirmDialog, EntityAvatar, TunaEntityBreadcrumbs, VisibilityTrigger, useTrash } from '@tunasong/ui-lib'
import { useCallback, useState } from 'react'
import type { FC } from 'react'

export interface TrashProps {
  className?: string
}

export const Trash: FC<TrashProps> = () => {
  const { trashed, getNext, reload } = useTrash({ pageSize: 10 })
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const handleShowDelete = useCallback(() => setShowDeleteDialog(true), [])
  const handleHideDelete = useCallback(() => setShowDeleteDialog(false), [])
  const [deleteEntity] = entitiesApi.useDeleteEntityMutation()
  const update = useEntityUpdate()

  const handleRestore = (entityId: string) => () => {
    update(entityId, { trash: false })
    reload()
  }

  const handleEmpty = () => {
    setShowDeleteDialog(false)
    trashed.map(async entity => deleteEntity({ entity }))
    reload()
  }

  return (
    <>
      <Alert
        severity="error"
        action={
          <>
            <Button onClick={handleShowDelete} disabled={trashed.length === 0} color="error">
              Empty Trash
            </Button>
          </>
        }
      >
        These entites are in the Trash. You can restore individual items, or choose to Empty Trash and permanently
        delete them.
      </Alert>

      {/* {isFetching ? <LinearProgress /> : null} */}
      <List sx={{ overflow: 'auto', flex: 1, display: 'flex', flexDirection: 'column' }}>
        {trashed.map(entity => (
          <ListItem key={entity.id}>
            <ListItemAvatar>
              <EntityAvatar type={entity.type} size={'small'} />
            </ListItemAvatar>
            <ListItemText
              primary={entity.name}
              secondary={
                <>
                  <TunaEntityBreadcrumbs entity={entity} variant="caption" showHome={false} showLeaf={false} />
                  <Typography variant="caption"> Last updated: {dayjs(entity.updatedAt).fromNow()}</Typography>
                </>
              }
            />
            <ListItemSecondaryAction>
              <Button onClick={handleRestore(entity.id)}>Restore</Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <VisibilityTrigger onVisible={getNext} />
      </List>

      <ConfirmDialog
        open={showDeleteDialog}
        text={`Really permanently empty ${trashed.length} items in the Trash? This action is non-reversable and it will not be possible to restore these pages after this action.`}
        onCancel={handleHideDelete}
        onConfirm={handleEmpty}
      />
    </>
  )
}

export default Trash
