import { Button, Dialog as MuiDialog, DialogActions, DialogContent } from '@mui/material'
import type { DialogProps } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import type { FC, MouseEvent } from 'react'
import { makeStyles } from '../styles.js'
import { DialogTitle } from './dialog-title.js'

export interface CDialogProps extends DialogProps {
  className?: string
  open: boolean
  controls?: React.ReactNode
  showClose?: boolean
  closeText?: string
  title: string
  showOK?: boolean
  onClose(ev: MouseEvent): void
  onOK?(ev: MouseEvent): void
}

const useStyles = makeStyles()(theme => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2, 1, 2),
  },
}))

export const Dialog: FC<CDialogProps> = props => {
  const {
    className,
    children,
    open,
    controls = null,
    onClose,
    closeText = 'Close',
    title,
    showClose = false,
    showOK = false,
    onOK,
    ...restProps
  } = props
  const { classes } = useStyles()

  if (!open) {
    return null
  }

  return (
    <MuiDialog
      open={open}
      className={classNames(classes.root, className)}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...restProps}
    >
      <DialogTitle onClose={onClose} id="alert-dialog-title" controls={controls}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.container}>{children}</DialogContent>
      <DialogActions>
        {showOK && <Button onClick={onOK}>OK</Button>}
        {showClose && <Button onClick={onClose}>{closeText}</Button>}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
