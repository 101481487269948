import { useLayoutsExtended } from '@tunasong/plugin-lib'
import { useEntity } from '@tunasong/redux'
import { AppChrome, EntityChrome } from '@tunasong/tunasong-ui'
import type { EntityChromeProps } from '@tunasong/tunasong-ui'
import { ContentGroupTabs, useParams, useSearchParams } from '@tunasong/ui-lib'
import type { ContentGroupTabsSpec } from '@tunasong/ui-lib'
import { useEffect, useState } from 'react'

/** Wrapper component for the tabbed view */
export const EntityTabsChrome = (props: EntityChromeProps) => {
  const { entity, ...componentProps } = props
  const [content, setContent] = useState<ContentGroupTabsSpec[]>([])
  useEffect(() => {
    if (!entity || content.find(c => c.entityId === entity.id)) {
      return
    }
    setContent(content => [
      ...content,
      {
        panelType: 'tab',
        entityId: entity.id,
      },
    ])
  }, [content, entity])
  const handleOpen = (newContent: ContentGroupTabsSpec) => {
    // Check if we have the content already
    if (content.find(c => c.entityId === newContent.entityId)) {
      return
    }
    setContent(content => [...content, newContent])
  }
  const handleClose = (oldContent: ContentGroupTabsSpec) =>
    setContent(content => content.filter(c => c.entityId !== oldContent.entityId))
  return (
    <ContentGroupTabs
      content={content}
      alwaysShowTabs={false}
      onOpenContent={handleOpen}
      onCloseContent={handleClose}
      ContentComponent={EntityChrome}
      componentsProps={componentProps}
    />
  )
}

/** Router wrapper for the AppChrome */
export const RouterAppGroupChrome = () => {
  const { entityId } = useParams()
  const [searchParams] = useSearchParams()
  const layoutName = searchParams.get('layout') ?? undefined

  const { entity } = useEntity(entityId)
  const layouts = useLayoutsExtended(entity)

  return (
    <AppChrome entity={entity} layouts={layouts} currentLayout={layoutName}>
      <EntityTabsChrome entity={entity} layoutName={layoutName} showSidebar={true} showHeader={true} />
    </AppChrome>
  )
}
