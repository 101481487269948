import { Box, ButtonBase, Typography } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { Menu } from '@tunasong/icons'
import { renderBlockDecorations, useEditor, useEditorMessage } from '@tunasong/plugin-lib'
import type { RenderElementPropsExt } from '@tunasong/plugin-lib'
import type { CoreElement } from '@tunasong/schemas'
import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import type { FC, RefObject } from 'react'
import type { DragElementWrapper, DragSourceOptions } from 'react-dnd'
import { useReadOnly, useSelected } from 'slate-react'
import { BlockMenu } from './block-menu.js'
import { useBlockStyles } from './block-styles.js'
import { useMergeRefs } from '@tunasong/ui-lib'

export interface ConfigureBlock {
  prop: string
  validate: (val: unknown) => boolean
  type?: 'text'

  title?: string
}

export interface CoreBlockProps extends RenderElementPropsExt, Omit<BoxProps, 'component' | 'children' | 'border'> {
  className?: string
  component?: 'div' | 'span'
  element: CoreElement
  focus?: boolean
  /** Right border on focus */
  border?: boolean
  menu?: boolean

  /** Internal */
  dragRef?: DragElementWrapper<DragSourceOptions>
  blockRef?: RefObject<HTMLDivElement | null>
  dropLine?: 'bottom' | 'left' | 'right' | 'top' | ''
  isDragOver?: boolean
}

/** A Block. Extends Box, so all Box */
export const CoreBlock: FC<CoreBlockProps> = props => {
  const {
    className,
    component = 'div',
    focus = true,
    menu = props.menu ?? false,
    border = true,
    attributes,
    element,
    children,
    /** DnD props */
    blockRef,
    dragRef,
    dropLine,
    isDragOver,
    ...restProps
  } = props

  const { classes } = useBlockStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  // Timestamp when it was clicked away
  const [clickedAway, setClickedAway] = useState(0)
  const blockSelected = useSelected()

  const editor = useEditor()
  const readOnly = useReadOnly()
  const { type } = element

  /** We use state here since we want to re-render if showHandle changes */
  const multiRootRef = useMergeRefs(attributes.ref, blockRef)

  const showProperties = useEditorMessage({ editor, type: 'show-block-properties' })
  const handleShowProperties = useCallback(() => showProperties(element, anchorEl), [anchorEl, element, showProperties])

  const showComments = useEditorMessage({ editor, type: 'show-comments' })
  const handleShowComments = useCallback(() => showComments(element, anchorEl), [anchorEl, element, showComments])

  const handleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      /** If clicked away recently, we do not re-open it */
      if (anchorEl || Date.now() < clickedAway + 1000) {
        setAnchorEl(null)
        setClickedAway(0)
      } else {
        setAnchorEl(event.currentTarget)
      }
    },
    [anchorEl, clickedAway]
  )
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    /** Register that the menu has been closed */
    setClickedAway(Date.now())
  }, [])

  const blockName = `${type[0].toUpperCase()}${type.substr(1)} Block`
  const hasFocus = focus && blockSelected

  const blockDecorations = renderBlockDecorations(editor, element)

  const handleMouseDown = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation()
      /**
       * @todo we do this because slate-plugins DND requires selection null
       * @see https://www.notion.so/Editor-8a19967621ba4683aeec388ad224d3f8?p=711bda3cf8754629994c0874e1faccde&showMoveTo=true
       */
      editor.selection = null
    },
    [editor]
  )

  return (
    <Box
      {...attributes}
      ref={multiRootRef}
      component={component}
      className={cn(className, classes.root, {
        [classes.inline]: component === 'span',
        [classes.focus]: hasFocus,
        [classes.border]: border || isDragOver,
        [classes.selected]: component === 'span' && blockSelected,
        [classes.blockAndGutter]: Boolean(dragRef),
      })}
      {...restProps}
    >
      {Boolean(dropLine) && (
        <div
          className={cn(classes.dropLine, {
            [classes.dropLineBottom]: dropLine === 'bottom',
            [classes.dropLineTop]: dropLine === 'top',
          })}
          contentEditable={false}
        />
      )}
      {menu && !readOnly && (
        <>
          <div
            className={cn(classes.blockAndGutter, classes.gutterLeft)}
            tabIndex={-1}
            title={blockName}
            contentEditable={false}
          >
            <div tabIndex={-1} className={classes.blockToolbar} ref={dragRef as never}>
              <ButtonBase
                className={classes.dragButton}
                onClick={handleMenu}
                onMouseDown={handleMouseDown}
                aria-label="Block menu"
                contentEditable={false}
              >
                <Menu className={classes.icon} fontSize="inherit" />
              </ButtonBase>
            </div>
          </div>
          {anchorEl ? (
            <BlockMenu
              open={true}
              anchorEl={anchorEl}
              block={element}
              onShowProperties={handleShowProperties}
              onComments={handleShowComments}
              onClose={handleCloseMenu}
            />
          ) : null}
        </>
      )}
      {blockDecorations.length > 0 ? (
        <Box
          contentEditable={false}
          tabIndex={-1}
          sx={{
            position: 'absolute',
            right: 0,
            userSelect: 'none',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            mr: 1,
          }}
        >
          {element.options?.activeView ? (
            <Typography tabIndex={-1} variant="caption" color={'primary.main'} contentEditable={false}>
              View: {element.options?.activeView}
            </Typography>
          ) : null}
          {blockDecorations}
        </Box>
      ) : null}
      {/* {sketches ? <SketchBlock element={element}>{children}</SketchBlock> : <>{children}</>} */}
      {/* 
      In Chrome the caret jumps to the end of the contenteditable element when it is the last child of a parent element.
      This is not an issue in Firefox or Safari.
    
       @see https://stackoverflow.com/questions/27786048/why-is-my-contenteditable-caret-jumping-to-the-end-in-chrome
      */}
      <>{children}</>
    </Box>
  )
}
