import type { AudioBus } from '@tunasong/models'
import { AnalyserNode } from 'standardized-audio-context'
import type { IAudioContext, IAudioNode, IDelayNode, IDynamicsCompressorNode } from 'standardized-audio-context'
import type { Seconds } from 'tone/build/esm/core/type/Units.js'
import { createLimiter } from '../ nodes/limiter.js'
import { AudioController } from './audio-controller.js'
import type { TrackType } from './audio-controller.js'
import type { IMixer } from './interfaces.js'

export class BusController extends AudioController<AudioBus> implements AudioBus {
  protected inputNode: IAudioNode<IAudioContext>
  protected limiterNode: IDynamicsCompressorNode<IAudioContext>
  protected delayNode?: IDelayNode<IAudioContext>
  protected outputNode: IAudioNode<IAudioContext>

  constructor(
    mixer: IMixer,
    private bus: AudioBus
  ) {
    super(mixer, bus)

    const { delay: delayCompensation = 0 } = bus

    /**
     * Delay compensation. Use a non-zero value as Web Audio does not accept 0
     */
    if (delayCompensation > 0) {
      this.delayNode = mixer.context.createDelay(1) // capped at 1 second
      this.delayNode.delayTime.value = delayCompensation / 1000
      /** Insert delay before the gain node */
      this.delayNode.connect(this.gainNode)
    }

    /** The input to the bus is the delayNode if defined, otherwise the gainNode */
    this.inputNode = this.delayNode ?? this.gainNode

    /** We route the gainNode to a limiter */
    this.limiterNode = createLimiter(mixer.context)
    this.gainNode.connect(this.limiterNode)

    this.postAnalyserNode = new AnalyserNode(mixer.context)
    this.limiterNode.connect(this.postAnalyserNode)

    /** Route the limiter to the output, and to the post analyzer node */
    this.outputNode = this.limiterNode
  }

  get name() {
    return this.bus.name
  }

  get trackType(): TrackType {
    return 'bus'
  }

  get input() {
    return this.inputNode
  }

  get output() {
    return this.outputNode
  }

  get preAnalyser() {
    return this.preAnalyserNode
  }
  get postAnalyser() {
    return this.postAnalyserNode
  }

  get delayCompensation() {
    return this.bus.delay ?? 0.0
  }
  set delayCompensation(delay: number) {
    if (this.delayNode) {
      this.delayNode.delayTime.value = delay / 1000
    }
  }

  /**
   * FadeOut at the context time specified in endTime
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/AudioParam/exponentialRampToValueAtTime
   * */
  fadeOut = (endTime: Seconds) => {
    if (endTime < this.mixer.context.currentTime) {
      throw new Error(`Cannot schedule fadeout back in time`)
    }
    // The change starts at the time specified for the previous
    this.gainNode.gain.setValueAtTime(this.gain, this.mixer.context.currentTime)
    /** 0 is not a valid value */
    this.gainNode.gain.exponentialRampToValueAtTime(0.000001, endTime)

    /** Reset the gain */
    this.gainNode.gain.setValueAtTime(this.gain, endTime + 0.01)
  }
  fadeIn = (startTime: Seconds, duration: Seconds) => {
    if (startTime < this.mixer.context.currentTime) {
      startTime = this.mixer.context.currentTime
    }
    // The change starts at the time specified for the previous
    this.gainNode.gain.setValueAtTime(0, startTime)
    this.gainNode.gain.linearRampToValueAtTime(this.gain, startTime + duration)
  }
}
