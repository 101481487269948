import { logger } from '@tunasong/models'
import type { AudioChannel } from '@tunasong/models'
import type { Clip } from '@tunasong/schemas'
import { useDragMove } from '@tunasong/ui-lib'
import cn from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import type { FC } from 'react'
import { useClipPlayer } from '../../hooks/clip-player.js'
import { useStyles } from './clip.styles.js'
import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'

export interface ClipProps extends Omit<BoxProps, 'ref'> {
  clip: Clip
  onUpdate?(clip: Clip): void
  onDelete?(clip: Clip): void
}
export interface ClipContainerProps extends ClipProps {
  className?: string
  channel: AudioChannel
  // height: number
  pixelsPerSecond: number
  Component: (props: ClipProps) => React.ReactNode
}

export const ClipContainer: FC<ClipContainerProps> = props => {
  const { className, clip, channel, pixelsPerSecond, Component, onUpdate, onDelete, ...restProps } = props
  const { classes } = useStyles()

  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  // useWhyDidYouUpdate('ClipContainer', props)

  /** Schedule the event on the timeline according to the clip start */
  useClipPlayer(channel, clip)

  const dragStart = useRef<number | null>(null)
  const handleDragStart = useCallback(() => {
    dragStart.current = clip.start
  }, [clip])

  const handleDragStop = useCallback(() => {
    if (!dragStart.current) {
      logger.warn(`Drag stopped with no drag clip state`)
      return
    }
    const updatedClip = { ...clip, start: dragStart.current }
    if (!onUpdate) {
      return
    }
    onUpdate(updatedClip)
    dragStart.current = null
  }, [clip, onUpdate])

  const handleDragMove = useCallback(
    (ev: globalThis.MouseEvent) => {
      if (!ref) {
        return
      }
      const deltaSeconds = ev.movementX / pixelsPerSecond
      dragStart.current = Math.max((dragStart.current ?? clip.start) + deltaSeconds, 0)
      ref.style.setProperty('left', `${dragStart.current * pixelsPerSecond}px`)
    },
    [clip.start, pixelsPerSecond, ref]
  )

  const isDragging = useDragMove({
    el: ref,
    onMove: handleDragMove,
    onStart: handleDragStart,
    onStop: handleDragStop,
  })

  const toggleSelect = useCallback(() => {
    ref?.focus()
  }, [ref])

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (onDelete && (ev.key === 'Backspace' || ev.key === 'Delete')) {
        onDelete(clip)
      }
    },
    [clip, onDelete]
  )

  const clipStart = dragStart.current ?? clip.start
  const clipDuration = clip.duration
  const clipStyle = useMemo(
    () => ({
      left: pixelsPerSecond * clipStart,
      // height: height - 8,
      width: pixelsPerSecond * clipDuration,
    }),
    [clipDuration, clipStart, pixelsPerSecond]
  )

  return (
    <Box
      className={cn(className, classes.clipContainer, {
        [classes.dragging]: isDragging,
        [classes.selected]: document.activeElement === ref,
      })}
      ref={setRef}
      style={clipStyle}
      onClick={toggleSelect}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      {...restProps}
    >
      <Component clip={clip} onUpdate={onUpdate} />
    </Box>
  )
}

export default ClipContainer
