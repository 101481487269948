import type { NavigationEntity } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import type { RenderEntityProps, TunaPlugin } from '@tunasong/plugin-lib'
import { useEntitiesById } from '@tunasong/redux'
import type { Entity } from '@tunasong/schemas'
import React, { useEffect } from 'react'
import type { FC } from 'react'
import { EntityContent } from '../entity/entity-content.js'
import { getEntityPath } from '../entity/entity-link-util.js'
import { useStableRef } from '../hooks/stable-ref.js'
import { useNavigate, useNavigateToEntityStore } from '../navigation/navigate.js'
import type { NavigationMode } from '../navigation/navigate.js'
import ContentContainer from './content-container.js'
import type { ContentGroupSpec } from './content-group.js'
import { PanelContainer } from './panel-container.js'

export interface ContentPanelSpec extends ContentGroupSpec {}

export interface ContentGroupPanelsProps {
  content: ContentPanelSpec[]
  direction: 'horizontal' | 'vertical'
  // The component to render the content
  RenderComponent?: FC<RenderEntityProps<Entity>>

  plugins?: TunaPlugin[]
  onOpenContent?: (content: ContentPanelSpec) => void
  onCloseContent?: (content: ContentPanelSpec) => void
}

export const ContentGroupPanels: FC<ContentGroupPanelsProps> = props => {
  const {
    content,
    RenderComponent = EntityContent,
    direction = 'horizontal',
    plugins: providedPlugins,
    onOpenContent,
    onCloseContent,
  } = props

  const allPlugins = usePlugins('all')
  const plugins = providedPlugins ?? allPlugins ?? []
  const entityIds = content.map(content => content.entityId)
  const { entities } = useEntitiesById(entityIds)

  const byId = (id: string) => entities.find(entity => entity.id === id)

  const autoSaveId = content
    .map(content => content.entityId)
    .sort()
    .join('-')

  // Handle navigation to the current entity
  const routerNavigate = useNavigate()
  const { setCustomNavigateFn, clearCustomNavigateFn } = useNavigateToEntityStore()

  const onCloseContentRef = useStableRef(onCloseContent)
  // Avoid infinite recursion by using ref for onOpenEntity
  const onOpenContentRef = useStableRef(onOpenContent)

  useEffect(() => {
    setCustomNavigateFn((entity?: NavigationEntity, props?: { layout?: string; navigationMode?: NavigationMode }) => {
      if (!entity?.id) {
        return
      }
      if (typeof props?.navigationMode === 'undefined' || props?.navigationMode === 'page') {
        return routerNavigate(getEntityPath({ entity, layout: props?.layout }), {
          replace: false,
        })
      }

      if (onOpenContentRef.current) {
        onOpenContentRef.current({
          entityId: entity.id,
          panelType: props?.navigationMode ?? 'panel',
        })
      }
    })
    return () => {
      clearCustomNavigateFn()
    }
  }, [clearCustomNavigateFn, onOpenContentRef, routerNavigate, setCustomNavigateFn])

  const panelContent = content.filter(content => content.panelType === 'panel')

  return (
    <PanelContainer direction={direction} autoSaveId={autoSaveId}>
      {panelContent
        .map((content, idx) => {
          const entity = byId(content.entityId)
          const contentView = content.viewName ?? entity?.defaultView
          if (!entity) {
            return null
          }

          return (
            <React.Fragment key={content.entityId}>
              <ContentContainer
                entity={byId(content.entityId)}
                onClose={idx > 0 ? () => onCloseContentRef.current && onCloseContentRef.current(content) : undefined}
              >
                <RenderComponent entity={entity} contentView={contentView} plugins={plugins} />
              </ContentContainer>
            </React.Fragment>
          )
        })
        .filter(Boolean)}
    </PanelContainer>
  )
}
