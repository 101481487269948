import type { Maturity } from '@tunasong/models'
import type { TunaPlugin } from '../plugin-types.js'

export const maturityFilter = (maturity: Maturity) => (p: TunaPlugin) => {
  if (!p.maturity) {
    return true
  }
  if (maturity === 'stable') {
    return typeof p.maturity === 'undefined' || p.maturity === 'stable'
  }
  if (maturity === 'beta') {
    return p.maturity === 'beta' || p.maturity === 'stable' || typeof p.maturity === 'undefined'
  }
  return true
}
