import type { NoteName } from '@tunasong/schemas'
import { getNoteByChromaticIndex, getNoteChromaticIndex, isNoteAugmented, isNoteMinor } from '../note/index.js'
import type { NoteDegree } from '../note/index.js'
import { getNote } from '../note/note-util.js'
import { ALL_CHORD_TYPES } from './chord-types.js'
import type { ChordElement, ChordType, ChordVariant } from './chord-types.js'
import { noteSignature as signature } from './util.js'

export class ChordBuilder {
  elementNames: ChordElement[] = []
  constructor(public root: NoteName) {}

  static create = (root: NoteName) => new ChordBuilder(root)

  getName() {
    return `${this.root}${this.elementNames.join('')}`
  }

  /** Normalized note signature - i.e., a string of all the notes in the Chord */
  getNoteSignature() {
    return signature(this.getNotes())
  }

  getChord(): ChordVariant {
    return { root: this.root, variant: this.elementNames.join('') as ChordType }
  }

  getNotes(): NoteName[] {
    return this.elementNames
      .map(element => {
        const spec = ALL_CHORD_TYPES[element]
        if (!spec) {
          throw new Error(`Spec ${element} does not exist!`)
        }
        /** @todo Must use bias here for the map. We should probably use chromatic indices instead to avoid problems with different names for the same note */
        return spec.intervals.map(n => {
          const note = getNote(this.root, n as NoteDegree)

          const noteIsAugmentedOrMinor = isNoteAugmented(note) || isNoteMinor(note)
          const rootIsAugmentedOrMinor = isNoteAugmented(this.root) || isNoteMinor(this.root)
          const type = this.root.endsWith('b') ? 'minor' : 'augmented'
          const alternativeNote = getNoteByChromaticIndex(getNoteChromaticIndex(note), type)
          return rootIsAugmentedOrMinor && noteIsAugmentedOrMinor && alternativeNote ? alternativeNote : note
        })
      })
      .flat()
  }

  /** Add an element, If none is given, start with a major triad */
  add(element: ChordElement = '') {
    this.elementNames.push(element)
    return this
  }
}
