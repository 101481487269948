/** ToneJS based Wav player */

import { logger } from '@tunasong/models'
import type { AudioChannel } from '@tunasong/models'
import type { AudioClip } from '@tunasong/schemas'
import { Player } from 'tone'
import type { AudioInstrument } from '../instruments/index.js'

/** @see https://tonejs.github.io/docs/14.7.77/Player.html */
export interface ClipPlayerProps {
  clip: AudioClip
  track: AudioChannel
}
export class ClipPlayer extends Player implements AudioInstrument {
  ready = false
  constructor(private props: ClipPlayerProps) {
    super()
    const { clip } = props

    this.connect(props.track.inputNode as never)

    this.load(clip.url)
      .then(() => {
        this.sync().start(clip.start)
      })
      .catch(e => logger.warn(`Failed to load clip`, clip, e))
  }

  onEvent = () => {
    logger.debug(`Clip play`, this.props.clip)
  }
}
