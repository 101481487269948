import { isEntity } from '@tunasong/schemas'
import type { FilterFunc } from '../filters/index.js'
import type { SystemTag } from './tag.js'

export const hasSystemTag =
  (tag: SystemTag): FilterFunc =>
  entity => {
    if (!isEntity(entity)) {
      return false
    }
    const { tags = [] } = entity

    return tags.includes(tag)
  }
