/** We need to completely reload an editor when we restore an earlier version */

import type { Persisted } from '@tunasong/schemas'
import type { Entity } from '@tunasong/schemas'
import { useEffect, useRef, useState } from 'react'

type EntityProps = Partial<Pick<Persisted<Entity>, 'id' | 'updatedAt'>>

export const getEntityKey = (entity: EntityProps) => `${entity.id}-${entity.updatedAt}`

export const getEditorKey = (previousEntity: EntityProps | undefined, next: EntityProps | undefined) => {
  if (!next) {
    return undefined
  }
  const lastUpdatedAt = previousEntity?.updatedAt
  const lastId = previousEntity?.id
  /** If updatedAt is LESS than the previousEntity updatedAt, we'll update they key */
  if (!next.updatedAt) {
    return next.id
  }
  if (!previousEntity) {
    return getEntityKey(next)
  }
  if (next.id !== lastId) {
    return getEntityKey(next)
  }
  if (lastUpdatedAt && lastUpdatedAt > next.updatedAt) {
    return getEntityKey(next)
  }

  return getEntityKey(previousEntity)
}

/**
 * Provide a key to the editor that ensures it is refreshed when the entity have been restored to an older version
 * @todo this is hacky - fix this
 */
export const useEntityEditorKey = (entity?: EntityProps) => {
  const previousEntity = useRef<EntityProps>(null)
  const previousKey = useRef<string>(null)
  const [key, setKey] = useState<string | undefined>(undefined)
  useEffect(() => {
    const key = getEditorKey(previousEntity.current ?? undefined, entity)
    if (key !== previousKey.current) {
      previousEntity.current = entity ?? null
      previousKey.current = key ?? null
    }
    setKey(key)
  }, [entity])
  return key
}
