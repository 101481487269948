import { activitiesApi } from '../api/activities.js'
import { prioritySort } from '../priority-sort.js'

export const useActivities = (entityId: string) => {
  const { currentData: activities } = activitiesApi.useLoadActivitiesForEntityQuery({ entityId })
  /** Sort by createdAt */
  return prioritySort(activities ?? [], 'createdAt')
}

export const useAllActivities = () => {
  const { currentData: activities = [], isLoading, isFetching } = activitiesApi.useLoadActivitiesQuery()

  const sorted = prioritySort(activities, 'createdAt')

  return { activities: sorted, isLoading: isLoading || isFetching }
}
