import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material'
import { ALL_SCALES } from '@tunasong/music-lib'
import type { ScaleType } from '@tunasong/music-lib'
import { HBox } from '@tunasong/ui-lib'
import type { FC } from 'react'
import NoteSelect from './note-select.js'
import type { NoteName, Scale } from '@tunasong/schemas'

export interface ScaleSelectProps {
  className?: string
  value?: Partial<Scale> | null
  title?: string
  fullWidth?: boolean
  onChange(scale: Scale | null): void
}

export const ScaleSelect: FC<ScaleSelectProps> = props => {
  const { value, onChange, fullWidth = false } = props

  const handleScaleType = (ev: SelectChangeEvent<string>) => {
    onChange({ root: value?.root ?? 'C', type: ev.target.value as ScaleType })
  }

  const handleRoot = (note?: NoteName) => {
    onChange(note ? { root: note, type: value?.type ?? 'major' } : null)
  }

  const scaleLabel = 'Scale Type'

  return (
    <>
      <HBox sx={{ mt: 1 }}>
        <NoteSelect value={value?.root} title="Scale Root" type="SCALE" onChange={handleRoot} noValueAllowed={true} />
        <FormControl sx={{ ml: 1, flex: 1 }} fullWidth={fullWidth}>
          <InputLabel id="scale-type">{scaleLabel}</InputLabel>
          <Select
            id="scale-type-select"
            variant="standard"
            fullWidth={fullWidth}
            labelId="scale-type"
            label={scaleLabel}
            value={value?.type ?? ''}
            onChange={handleScaleType}
          >
            {ALL_SCALES.map((s, idx) => (
              <MenuItem key={idx} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </HBox>
    </>
  )
}
