import cn from 'classnames'

import React from 'react'
import type { FC } from 'react'
import type { Track } from '@tunasong/schemas'
import { makeStyles } from '@tunasong/ui-lib'

const useStyles = makeStyles()(() => ({
  root: {},
}))

export interface DetailsProps {
  className?: string
  track?: Track
}

export const Details: FC<DetailsProps> = props => {
  const { className, track } = props
  const { classes } = useStyles()
  if (!track) {
    return null
  }
  return <div className={cn(className, classes.root)}>{track.name}</div>
}

export default Details
