import { isCoreElement } from '@tunasong/schemas'
import type { CoreElement } from '@tunasong/schemas'
export interface Divider extends CoreElement {
  type: 'divider'
  variant: 'horizontal' | 'vertical'
}

export function isDivider(element?: unknown): element is Divider {
  return Boolean(isCoreElement(element) && element.type === 'divider')
}
