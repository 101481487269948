/** Hook to control the video and audio tracks of the local and shared streams */

import { getDevices, getEnvironmentCamVideo, getScreenShare, getUsercamVideo, useMixer } from '@tunasong/audio-ui'
import { logger } from '@tunasong/models'
import { useCallback, useEffect, useState } from 'react'
import { muteStreams } from '../video/lib/stream-util.js'

export type VideoInputType = 'webcam' | 'screenshare' | 'webcam-user' | 'webcam-environment'

const VIDEO_STORAGE_KEY = 'tunasong.video'

export const useStream = () => {
  const mixer = useMixer()

  const [isMuted, setIsMuted] = useState(false)
  const [isSharing, setIsSharing] = useState(false)

  /** When switching streams, we keep the previous stream around  */
  const [previousStream, setPreviousStream] = useState<MediaStream | null>(null)

  const [localStream, setLocalStream] = useState<MediaStream | null>(null)
  const [shareStream, setShareStream] = useState<MediaStream | null>(null)

  const lsDevice = localStorage.getItem(VIDEO_STORAGE_KEY)

  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo>()

  useEffect(() => {
    getDevices('videoinput')
      .then(devices => {
        const localDevice = lsDevice ? (JSON.parse(lsDevice) as MediaDeviceInfo) : null
        const device = localDevice && devices.find(d => d.deviceId === localDevice?.deviceId) ? localDevice : devices[0]
        setSelectedDevice(device)
      })
      .catch(e => {
        logger.warn('Unable to get video input', e)
        setSelectedDevice(undefined)
      })
  }, [lsDevice])

  useEffect(() => {
    muteStreams(localStream, isMuted, 'video')
  }, [localStream, isMuted])
  useEffect(() => {
    muteStreams(shareStream, isMuted, 'video')
  }, [isMuted, shareStream])

  /** For a strange reason, webcamStream is undefined if I use useCallback here (even with webcamStream as a dependency) */
  const muteVideo = useCallback(
    (mt: boolean) => {
      muteStreams(localStream, mt, 'video')
      muteStreams(shareStream, mt, 'video')
      setIsMuted(mt)
    },
    [localStream, shareStream]
  )

  /** @see https://developers.google.com/web/updates/2015/07/mediastream-deprecations */
  const stop = useCallback(() => {
    localStream?.getTracks().forEach(t => t.stop())
    shareStream?.getTracks().forEach(t => t.stop())
    setLocalStream(null)
    setShareStream(null)
  }, [localStream, shareStream])

  /** Create a stream that can be shared to peers. The audio is the output of the 'share' bus. */
  const createShareStream = useCallback(
    (source: MediaStream) => {
      if (!mixer) {
        throw new Error(`Cannot add media stream without mixer set in environment`)
      }

      /** @todo clone or not to clone */
      // const sharedStream = source.clone()
      const sharedStream = source

      /** Remove all audio tracks from the stream */
      sharedStream.getAudioTracks().forEach(t => sharedStream.removeTrack(t))
      /** Add the 'share' audio to the stream */
      const shareTracks = mixer.getTracks('share') || []
      shareTracks.forEach(t => {
        if (t.readyState === 'ended') {
          throw new Error(`Adding track with readyState "ended"`)
        }
        sharedStream.addTrack(t)
      })

      logger.info('Share stream with audio', sharedStream, sharedStream.getAudioTracks())
      return sharedStream
    },
    /** @todo localStream dependency will create an infinite loop */
    [mixer]
  )

  /** Start the local and share streams. */
  const startWebcam = useCallback(
    async (device?: MediaDeviceInfo) => {
      logger.info('WebCam: starting', selectedDevice)
      const stream = await navigator.mediaDevices.getUserMedia({ video: device })
      setIsSharing(false)
      return stream
    },
    [selectedDevice]
  )

  /** Get the platform-provided best defaults for environment and usercam respectively */
  const startSecondaryCam = useCallback(async (type: 'webcam-user' | 'webcam-environment') => {
    // deepcode ignore BadAwaitExpression: false positive
    const cam = type === 'webcam-environment' ? await getEnvironmentCamVideo() : await getUsercamVideo()
    return cam
  }, [])

  const startScreenShare = useCallback(
    async () =>
      getScreenShare()
        .then((mediaStream: MediaStream) => {
          setIsSharing(true)
          const endShare = () => {
            setIsSharing(false)
            startWebcam(selectedDevice).catch(e => logger.info('Unable to re-start webcam', e))
          }

          mediaStream.addEventListener('inactive', endShare)
          mediaStream.addEventListener('removetrack', endShare)
          mediaStream.getVideoTracks()[0].addEventListener('ended', endShare)
          return mediaStream
        })
        .catch(() => {
          setIsSharing(false)
          return null
        }),
    [selectedDevice, startWebcam]
  )

  const start = useCallback(
    async (type: VideoInputType, device: MediaDeviceInfo | undefined = selectedDevice) => {
      if (!device) {
        return {}
      }
      /** Stop stream(s) before starting */
      stop()

      let selectedStream: MediaStream | null
      switch (type) {
        case 'screenshare':
          selectedStream = await startScreenShare()
          break
        case 'webcam':
          selectedStream = await startWebcam(device)
          break
        case 'webcam-user':
        case 'webcam-environment':
          selectedStream = await startSecondaryCam(type)
          break
        default:
          throw new Error(`Unknown type: ${type}`)
      }
      if (!selectedStream) {
        return {}
      }
      /** Clone stream for peer */
      setLocalStream(selectedStream)
      const s = createShareStream(selectedStream)
      setShareStream(s)
      return { localStream: selectedStream, shareStream: s }
    },
    [createShareStream, selectedDevice, startScreenShare, startSecondaryCam, startWebcam, stop]
  )

  /** Stream a media element (video or audio) to peers */
  const share = useCallback(
    (name: string, player: HTMLMediaElement) => {
      const srcObject = player.srcObject
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mStream: MediaStream = srcObject === null ? (player as any).captureStream() : srcObject

      /** We will re-route the audio, so we'll mute the player audio */
      // player.muted = true

      logger.info('Share content to Peer', mStream, srcObject)
      /** Send audio to media mixer bus.  */
      if (mixer && mStream.getAudioTracks().length > 0) {
        const source = mixer.context.createMediaStreamSource(mStream)
        mixer.addChannel({ type: 'general', id: name, inputNode: source, effects: [], name }, 'media')
      }

      const shareS = createShareStream(mStream)
      /** If the share stream does not have video, add the local video track(s) */
      if (shareS.getVideoTracks().length === 0 && localStream) {
        localStream.getVideoTracks().forEach(t => shareS.addTrack(t.clone()))
      }

      setPreviousStream(shareStream)

      setShareStream(shareS)
    },
    [createShareStream, localStream, mixer, shareStream]
  )

  const unshare = useCallback(
    async (name: string) => {
      logger.info('Un-share content', name)

      if (mixer) {
        mixer.removeChannel(name)
      }
      const stream = previousStream ? previousStream : await start('webcam')

      setPreviousStream(null)

      setIsSharing(false)

      return stream
    },
    [mixer, previousStream, start]
  )

  const startDevice = useCallback(async (device?: MediaDeviceInfo) => {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({ video: device })
    setLocalStream(stream)
    setShareStream(stream)
  }, [])

  const setDevice = useCallback(
    (newDevice: MediaDeviceInfo) => {
      localStorage.setItem(VIDEO_STORAGE_KEY, JSON.stringify(newDevice))
      setSelectedDevice(newDevice)
      /** Start device. If a device fails to start, we will keep the setting, but revert to using the default mic */
      startDevice(newDevice)
    },
    [setSelectedDevice, startDevice]
  )

  useEffect(() => {
    const startAll = () => {
      logger.info('Devices changed - restarting...')
      /** Remove all mic channels from mixer */
      if (mixer) {
        mixer.removeSources('mic')
      }
      start('webcam', selectedDevice)
    }
    navigator.mediaDevices.addEventListener('devicechange', startAll)
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', startAll)
    }
  }, [mixer, selectedDevice, start, stop])

  return {
    /** The local and share streams. Video and audio tracks are managed by this hook */
    localStream,
    shareStream,
    selectedDevice,
    start,
    stop,
    setDevice,
    mute: muteVideo,
    isMuted,
    /* Sharing */
    share,
    unshare,
    isSharing,
  }
}

export type StreamControl = ReturnType<typeof useStream>
