import { Box } from '@mui/material'
import { useProfiles } from '@tunasong/redux'
import type { FC } from 'react'
import UserAvatar from '../profile/user-avatar.js'

export interface AvatarListProps {
  userIds: string[]
}

/**
 * Display a list of user avatars that have access to the entity.
 */
export const AvatarList: FC<AvatarListProps> = props => {
  const { userIds } = props
  const { profiles } = useProfiles({
    userIds,
  })
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {profiles.map(profile => (
        <UserAvatar key={profile.userId} sx={{ m: 1 }} size={'small'} userId={profile.userId} />
      ))}
    </Box>
  )
}

export default AvatarList
