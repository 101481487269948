import * as React from 'react'
import cn from 'classnames'
import useStyles from './icon.styles.js'
function ElectricGuitar(props: { className?: string }) {
  const { className, ...restProps } = props
  const { classes } = useStyles()
  return (
    <svg
      className={cn(classes.root, className)}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 313.926 313.926"
      {...restProps}
    >
      <g>
        <path
          d="M64.647,210.666c-2.733-2.732-7.166-2.733-9.899-0.001c-2.734,2.734-2.734,7.166-0.001,9.899l38.282,38.283
		c1.367,1.367,3.158,2.051,4.95,2.051c1.791,0,3.583-0.684,4.949-2.05c2.734-2.734,2.734-7.166,0.001-9.899L64.647,210.666z"
        />
        <path
          d="M103.849,191.187c-2.732-2.733-7.165-2.733-9.899-0.001c-2.734,2.734-2.734,7.166-0.001,9.899l18.561,18.562
		c1.367,1.367,3.158,2.051,4.95,2.051c1.791,0,3.583-0.684,4.949-2.05c2.734-2.734,2.734-7.166,0.001-9.899L103.849,191.187z"
        />
        <path
          d="M312.64,22.521c-1.567-6.819-3.348-11.263-5.288-13.201c-2.568-2.569-5.753-3.984-8.967-3.984
		c-1.64,0-3.231,0.357-4.729,1.06c-1.563,0.732-3.252,1.555-5.027,2.442l-6.547-6.548c-2.732-2.732-7.165-2.733-9.899-0.001
		c-2.734,2.733-2.734,7.166-0.001,9.899l3.441,3.441c-3.056,1.646-6.136,3.333-9.121,4.987l-3.877-3.878
		c-2.734-2.733-7.166-2.733-9.9,0c-2.733,2.734-2.733,7.166,0,9.9l1.101,1.101c-5.987,3.407-10.185,5.854-10.656,6.13
		c-0.52,0.304-0.997,0.672-1.423,1.098c-3.324,3.326-4.343,8.109-3.093,12.412l-85.52,85.52c-0.584-0.281-1.162-0.556-1.728-0.822
		c-3.704-1.747-6.903-3.256-9.918-6.271c-2.689-2.689,4.236-13.904,7.957-19.93c6.492-10.513,13.205-21.384,5.478-29.111
		c-1.462-1.462-4.078-3.205-8.237-3.205c-10.996,0-26.237,12.858-29.421,16.042c-12.234,12.234-17.166,21.781-21.937,31.014
		c-4.199,8.128-8.166,15.806-17.563,25.203c-7.012,7.012-16.218,10.502-25.965,14.198c-11.222,4.255-22.825,8.654-32.828,18.656
		c-43.276,43.277-1.157,85.398,14.698,101.255c15.718,15.717,36.824,33.759,60.724,33.758c14.246-0.001,27.883-6.414,40.531-19.063
		c10.001-10.002,14.401-21.605,18.656-32.827c3.695-9.747,7.186-18.953,14.197-25.964c6.785-6.786,12.096-9.278,18.244-12.164
		c6.388-2.998,13.628-6.396,22.087-14.854c20.867-20.868,19.424-31.071,14.538-35.957c-2.621-2.621-6.113-3.949-10.379-3.949
		c-4.824,0-10.106,1.698-15.7,3.497c-5.554,1.785-11.298,3.633-16.074,3.633c-3.153-0.001-5.349-0.789-7.115-2.557
		c-1.195-1.195-2.021-2.198-2.581-3.119l95.411-95.411c1.162,0.339,2.375,0.521,3.614,0.521c3.342,0,6.47-1.286,8.809-3.623
		l32.636-32.637C314.314,36.163,314.7,31.483,312.64,22.521z M247.407,58.425l7.763,7.764l-84.21,84.21
		c-0.879-2.973-2.678-6.174-6.015-9.51l-0.001-0.001L247.407,58.425z M180.493,190.039
		C180.494,190.039,180.493,190.039,180.493,190.039c6.972,0,14.085-2.287,20.36-4.306c3.612-1.161,7.328-2.356,9.985-2.716
		c-1.885,3.648-6.551,9.818-12.63,15.897c-6.726,6.726-12.014,9.207-18.136,12.08c-6.411,3.01-13.678,6.42-22.196,14.938
		c-9.137,9.138-13.331,20.201-17.388,30.9c-3.903,10.293-7.589,20.016-15.466,27.892c-10.066,10.067-20.086,14.961-30.632,14.962
		c-18.759,0-37.015-15.85-50.822-29.657c-10.553-10.553-23.257-24.585-27.872-39.549c-4.558-14.777-0.249-28.485,13.173-41.907
		c7.877-7.877,17.6-11.563,27.892-15.466c10.699-4.057,21.764-8.252,30.901-17.389c10.936-10.937,15.595-19.955,20.102-28.677
		c4.476-8.662,8.703-16.845,19.397-27.54c4.192-4.192,11.39-8.974,16.093-10.978c-1.427,3.039-3.978,7.17-5.724,9.997
		c-6.948,11.252-16.466,26.663-5.944,37.185c4.738,4.737,9.576,7.02,13.845,9.033c3.62,1.708,6.747,3.183,9.613,6.05
		c3.256,3.256,2.944,4.518,1.912,8.699c-1.37,5.549-3.44,13.934,6.52,23.895C167.895,187.798,173.619,190.038,180.493,190.039z
		 M269.73,60.95l-16.645-16.646c7.912-4.567,30.409-17.434,44.362-24.205c1.018,2.968,2.224,8.05,2.44,10.694L269.73,60.95z"
        />
      </g>
    </svg>
  )
}

export default ElectricGuitar
