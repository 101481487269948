import { Typography, alpha } from '@mui/material'
import type { Theme } from '@mui/material'
import type { FC } from 'react'
import { makeStyles } from '../styles.js'
import { HBox } from '../layout/horizontal-box.js'

export interface PulseProps {
  size?: 'small' | 'medium' | 'large'
  placeholder?: string
}

/** from https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */

const useStyles = makeStyles<{ size: 'small' | 'medium' | 'large' }>()((theme: Theme, { size }) => {
  const scale = size === 'small' ? 0.5 : size === 'medium' ? 1 : 2
  return {
    blob: {
      background: theme.vars.palette.secondary.main,
      boxShadow: `0 0 0 0 ${alpha(theme.palette.secondary.main, 1)}`,
      borderRadius: '50%',
      margin: theme.spacing(1),
      height: theme.spacing(2 * scale),
      width: theme.spacing(2 * scale),
      transform: 'scale(1)',
      animation: 'pulse 2s infinite',

      '@keyframes pulse': {
        '0%': {
          transform: 'scale(0.8)',
          boxShadow: `0 0 0 0 ${alpha(theme.palette.secondary.light, 0.7)}`,
        },

        '70%': {
          transform: 'scale(1)',
          boxShadow: `0 0 0 ${theme.spacing(1)} ${alpha(theme.palette.secondary.light, 0)}`,
        },

        '100%': {
          transform: 'scale(0.8)',
          boxShadow: `0 0 0 0 ${alpha(theme.palette.secondary.light, 0)}`,
        },
      },
    },
  }
})

export const Pulse: FC<PulseProps> = ({ size = 'small', placeholder }) => {
  const { classes } = useStyles({ size })
  return (
    <HBox>
      <div className={classes.blob} />
      {placeholder ? (
        <Typography sx={{ ml: 1 }} variant="caption">
          {placeholder}
        </Typography>
      ) : null}
    </HBox>
  )
}

export { Pulse as PulseProgress }
export default Pulse
