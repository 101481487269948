import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { NoteLib, ScaleLib } from '@tunasong/music-lib'
import type { NoteOctave } from '@tunasong/music-lib'
import type { NoteName, Scale } from '@tunasong/schemas'
import type { FC } from 'react'
import { NoteDegree } from './note-degree.js'

export interface NoteProps extends BoxProps {
  note: NoteName | NoteOctave
  scale?: Scale | null
}

export const Note: FC<NoteProps> = props => {
  const { note: providedNote, scale } = props
  const note = NoteLib.toNote(providedNote)

  const inScale = scale ? ScaleLib.noteInScale(scale, note) : true
  const scaleNote = scale ? ScaleLib.getScaleNoteName(scale, note) : note
  const niceNote = NoteLib.prettifyNote(scaleNote)
  const degree = scale ? ScaleLib.getDegree(scale, note) : null
  return (
    <Box
      component="span"
      contentEditable={false}
      sx={{
        position: 'relative',
        alignSelf: 'center',
        color: theme => (scale ? (inScale ? theme.notes?.inScale : theme.notes?.outOfScale) : 'inherit'),
        display: 'inline-flex',
        ...props.sx,
      }}
    >
      {niceNote}
      {degree ? <NoteDegree degree={degree} sx={props.sx} /> : null}
    </Box>
  )
}
