import type { EventDuration } from '../note/index.js'

/** @deprecated use EventDuration instead */
export type EasyScoreDuration = 'w' | 'h' | 'q' | '8' | '16' | '32'

export const toTicks = (note: EasyScoreDuration, ppq = 192): number => {
  switch (note) {
    case 'w':
      return ppq * 4
    case 'h':
      return ppq * 2
    case 'q':
      return ppq
    case '8':
      return ppq / 2
    case '16':
      return ppq / 4
    case '32':
      return ppq / 8
    default:
      throw new Error(`Unknown note duration: ${note}`)
  }
}

export const toEventDuration = (note: EasyScoreDuration): EventDuration => {
  switch (note) {
    case 'w':
      return '1m'
    case 'h':
      return '2n'
    case 'q':
      return '4n'
    case '8':
      return '8n'
    case '16':
      return '16n'
    case '32':
      return '32n'
    default:
      throw new Error(`Unknown note duration: ${note}`)
  }
}
